import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { Loader } from 'src/components/common/Loader';
import { QueryError } from 'src/components/common/QueryError';
import { Table } from 'src/components/common/MuiTable/Table';
import { DisplaySwitcher } from 'src/components/common/DisplaySwitcher/DisplaySwitcher';
import { HeadCellItem } from 'src/models/tableModels';
import { Fund } from 'src/types/Funds';
import { usePagination } from 'src/hooks/usePagination';
import { useFunds } from 'src/hooks/react-query/funds';
import EnhancedTableToolbar from 'src/uiLibrary/reusables/data-table/enhancedTableToolbar';
import { Routes } from 'src/consts/routes';
import { renderFundCard } from '../my-funds/utils/renderFundCard';
import { useProtected } from 'src/hooks/useProtected';
import { Permission } from 'src/consts/permissions';
import { useAuth } from 'src/context/AuthContext';
import { useUserRole } from 'src/hooks/usePermission';

export const Analytics = () => {
  const navigate = useNavigate();

  const [searchString, setSearchString] = useState<string>('');
  const [isTableView, setIsTableView] = useState(true);

  const { userData } = useAuth();
  const { isMainGp } = useUserRole();

  const { data: funds, isLoading, isError } = useFunds('Fund access', searchString);

  const { page, pageSize, setPageSize, setActivePage } = usePagination();

  const isFundProtected = useProtected({
    permissions: [Permission.MyFunds],
  });

  const isFundAvailable = !!userData?.fundPermissions.length;

  const resetPage = () => setActivePage(0);

  const handleSearchEvent = (search: string) => {
    setSearchString(search);
    resetPage();
  };

  const handleChangeView = (isTableView: boolean) => {
    setIsTableView(isTableView);
  };

  const handleOpenFundAnalytics = (fundId: number) => {
    navigate(`${Routes.analytics}/${fundId}`);
  };

  const columns: HeadCellItem<Fund>[] = [
    {
      id: 'FundsName',
      align: 'left',
      disablePadding: false,
      label: 'Fund Name',
      sortable: true,
      render: ({ id, name }) => <Link to={`${Routes.analytics}/${id}`}>{name}</Link>,
      maxWidth: 170,
      tooltip: ({ name }) => name,
    },
    {
      id: 'CreationDateTime',
      align: 'left',
      disablePadding: false,
      label: 'Date Created',
      sortable: true,
      render: ({ CreationDateTime }) => (CreationDateTime ? format(new Date(CreationDateTime), 'MM-dd-yyyy') : '-'),
    },
  ];

  if (isLoading) return <Loader />;

  if (isError) return <QueryError />;

  if ((!isFundProtected || !isFundAvailable) && !isMainGp) {
    return (
      <div className="main-body-content-frame">
        <h1>You don’t have any Funds yet</h1>
      </div>
    );
  }

  return (
    <>
      <EnhancedTableToolbar
        onSearch={handleSearchEvent}
        placeholder="Search by fund name"
        initialSearchValue={searchString}
        searchCount={funds?.length}
      >
        <DisplaySwitcher isTableView={isTableView} onChangeView={handleChangeView} />
      </EnhancedTableToolbar>

      {isTableView ? (
        <div className="theme-table-wrap">
          <Table
            data={funds}
            columns={columns}
            pagination={{
              page,
              rowsPerPage: pageSize,
              onPageChange: setActivePage,
              onPageSizeChange: setPageSize,
            }}
          />
        </div>
      ) : (
        <div className="theme-table-wrap">
          <div className="fund-cards-container">
            {funds?.map(fund =>
              renderFundCard({
                fund,
                isAssetVisible: false,
                onClick: handleOpenFundAnalytics,
              }),
            )}
          </div>
        </div>
      )}
      {!funds.length && !isTableView && (
        <div className="main-body-content-frame">
          <h1>No Results Found.</h1>
        </div>
      )}
    </>
  );
};
