import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import IconArrowLeft from 'src/uiLibrary/icons/IconArrowLeft';
import { OtpInput } from 'src/components/common/OtpInput/OtpInput';
import { colors } from 'src/themes/colors/colors';

interface SignInFormProps {
  isError: boolean;
  onSubmit: (otpCode: string) => void;
  onBack(): void;
  onResend(): void;
}

export const CodeVerificationForm = ({ isError, onSubmit, onBack, onResend }: SignInFormProps) => {
  const [otp, setOtp] = useState('');
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [timer, setTimer] = useState(59);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer(prev => prev - 1);
      }, 1000);
    } else {
      setIsResendDisabled(false);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const handleResendCode = () => {
    onResend();
    setIsResendDisabled(true);
    setTimer(59);
  };

  const submitForm = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    onSubmit(otp);
  };

  return (
    <div className="content-input">
      <div className="back-login" onClick={onBack}>
        <IconButton size="large" component="span">
          <IconArrowLeft stroke={colors.grey} />
        </IconButton>
        <p className="back-text">Back</p>
      </div>
      <h2 className="login-title">Enter your code</h2>
      <p className="lead">A code has been sent to your email, enter it in order to login </p>
      <form noValidate autoComplete="off">
        <div className="from-row">
          <div className="otp-form">
            <OtpInput value={otp} label={'Sign in code'} isError={isError} onChange={setOtp} length={6} />
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 btn-row">
            <Button type="submit" color="info" variant="contained" className="button" onClick={submitForm}>
              Continue
            </Button>
          </div>
          <Tooltip
            className="resend-code-btn-tooltip"
            title={isResendDisabled ? 'Before resending the code, wait for a minute and check your spam folder ' : ''}
          >
            <span className="col-lg-12 col-md-12 col-sm-12 btn-row">
              <Button
                fullWidth
                variant="contained"
                className="resend-code-btn"
                onClick={handleResendCode}
                disabled={isResendDisabled}
              >
                {isResendDisabled ? `Resend code 00:${timer}` : 'Resend code'}
              </Button>
            </span>
          </Tooltip>
        </div>
      </form>
    </div>
  );
};
