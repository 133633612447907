import { AxiosError } from 'axios';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'src/context/ShackbarContext';
import { createUser } from 'src/services/adminUserServices';
import { ApiError } from 'src/types/api';

export const useUserCreate = (...cbs: Array<() => void>) => {
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: (formData: FormData) => createUser(formData),
    onSuccess: async () => {
      showSuccessSnackbar('User`s credentials have been sent to their email address');
      cbs.forEach(cb => cb());
    },
    onError: e => {
      showErrorSnackbar((e as AxiosError<ApiError>).response?.data.message);
    },
  });
};
