import { useEffect } from 'react';

type HeaderTabSelectionProps = {
  tabs: { key: string; label: string }[];
  chipFilters?: { key: string; label: string }[];
  // handleOnClick?: any;
  activeTab?: string;
  setActiveTab?: React.Dispatch<React.SetStateAction<string>>;
  activeChip?: string;
  setActiveChip?: React.Dispatch<React.SetStateAction<string>>;
  fullWidth?: boolean;
};

const HeaderTabSelection = ({
  tabs,
  chipFilters,
  // handleOnClick,
  activeTab,
  setActiveTab,
  activeChip,
  setActiveChip,
  fullWidth = false,
}: HeaderTabSelectionProps) => {
  useEffect(() => {
    setActiveTab && setActiveTab(tabs[0].key);
    if (chipFilters) {
      setActiveChip && setActiveChip(chipFilters[0].key);
    }
  }, []);
  return (
    <div className="tab-header-wrapper">
      <div className="tabs-container" style={fullWidth ? { flexGrow: 1 } : {}}>
        {tabs.map(p => (
          <div
            key={p.key}
            onClick={() => {
              setActiveTab && setActiveTab(p.key);
            }}
            style={fullWidth ? { flexGrow: 1 } : {}}
            className={`tab-item ${activeTab === p.key ? 'tab-active' : ''}`}
          >
            {p.label}
          </div>
        ))}
      </div>
      {chipFilters && (
        <div className="operation-type-chip-selector">
          {chipFilters.map(p => (
            <div
              key={p.key}
              onClick={() => {
                setActiveChip && setActiveChip(p.key);
              }}
              className={`chip-item ${activeChip === p.key ? 'active-chip' : ''} ${p.key === 'capital-call' ? 'cap-call-chip' : p.key === 'position-sellout' ? 'pos-sellout-chip' : ''}`}
            >
              {p.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default HeaderTabSelection;
