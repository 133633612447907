import React from 'react';

const IconProperty = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.4727 16.9453H16.9453V4.65848C16.9453 4.40922 16.8249 4.17302 16.6231 4.02662C16.4215 3.88023 16.1598 3.83903 15.9229 3.91621L13.7082 4.63856V0.932187C13.7082 0.632672 13.5629 0.349637 13.3197 0.174817C13.0768 0.000271691 12.7624 -0.0469695 12.4787 0.0484742L5.03421 2.55514C4.59023 2.70469 4.29181 3.12011 4.29181 3.58896V6.74231L1.86548 7.5336C1.38057 7.6918 1.05469 8.141 1.05469 8.65146V16.9453H0.527344C0.236069 16.9453 0 17.1814 0 17.4727C0 17.7639 0.236069 18 0.527344 18H17.4727C17.7639 18 18 17.7639 18 17.4727C18 17.1814 17.7639 16.9453 17.4727 16.9453ZM2.10938 8.65146C2.10938 8.59886 2.14275 8.55258 2.19246 8.53638L4.29181 7.85165V16.9453H3.72794V14.2763C3.72794 13.985 3.49187 13.749 3.20059 13.749C2.90946 13.749 2.67325 13.985 2.67325 14.2763V16.9453H2.10938V8.65146ZM11.5987 16.9453V14.2763C11.5987 13.985 11.3626 13.749 11.0713 13.749C10.7802 13.749 10.544 13.985 10.544 14.2763V16.9453H9.52735V14.2851C9.52735 13.9938 9.29128 13.7578 9 13.7578C8.70873 13.7578 8.47266 13.9938 8.47266 14.2851V16.9453H7.45601V14.2763C7.45601 13.985 7.2198 13.749 6.92867 13.749C6.63739 13.749 6.40132 13.985 6.40132 14.2763V16.9453H5.34663V3.58896C5.34663 3.57344 5.35639 3.55957 5.37094 3.55476L12.6535 1.10261V16.9453H11.5987ZM15.3268 16.9453V14.2851C15.3268 13.9938 15.0905 13.7578 14.7994 13.7578C14.5081 13.7578 14.2721 13.9938 14.2721 14.2851V16.9453H13.7082V5.74804L15.8906 5.03627V16.9453H15.3268Z"
        fill="#909090"
      />
      <path
        d="M6.92822 10.583C6.63695 10.583 6.40088 10.8191 6.40088 11.1104V12.5383C6.40088 12.8294 6.63695 13.0656 6.92822 13.0656C7.21936 13.0656 7.45557 12.8294 7.45557 12.5383V11.1104C7.45557 10.8191 7.21936 10.583 6.92822 10.583Z"
        fill="#909090"
      />
      <path
        d="M11.0718 10.583C10.7806 10.583 10.5444 10.8191 10.5444 11.1104V12.5383C10.5444 12.8294 10.7806 13.0656 11.0718 13.0656C11.3631 13.0656 11.5991 12.8294 11.5991 12.5383V11.1104C11.5991 10.8191 11.3631 10.583 11.0718 10.583Z"
        fill="#909090"
      />
      <path
        d="M9 10.5918C8.70873 10.5918 8.47266 10.828 8.47266 11.1191V12.5471C8.47266 12.8382 8.70873 13.0744 9 13.0744C9.29128 13.0744 9.52734 12.8382 9.52734 12.5471V11.1191C9.52734 10.828 9.29128 10.5918 9 10.5918Z"
        fill="#909090"
      />
      <path
        d="M6.92822 7.42139C6.63695 7.42139 6.40088 7.65759 6.40088 7.94873V9.37668C6.40088 9.66782 6.63695 9.90402 6.92822 9.90402C7.21936 9.90402 7.45557 9.66782 7.45557 9.37668V7.94873C7.45557 7.65759 7.21936 7.42139 6.92822 7.42139Z"
        fill="#909090"
      />
      <path
        d="M11.0718 7.42139C10.7806 7.42139 10.5444 7.65759 10.5444 7.94873V9.37668C10.5444 9.66782 10.7806 9.90402 11.0718 9.90402C11.3631 9.90402 11.5991 9.66782 11.5991 9.37668V7.94873C11.5991 7.65759 11.3631 7.42139 11.0718 7.42139Z"
        fill="#909090"
      />
      <path
        d="M9 7.43018C8.70873 7.43018 8.47266 7.66638 8.47266 7.95752V9.38547C8.47266 9.67674 8.70873 9.91281 9 9.91281C9.29128 9.91281 9.52734 9.67674 9.52734 9.38547V7.95752C9.52734 7.66638 9.29128 7.43018 9 7.43018Z"
        fill="#909090"
      />
      <path
        d="M6.92822 4.25879C6.63695 4.25879 6.40088 4.49486 6.40088 4.78613V6.21394C6.40088 6.50522 6.63695 6.74129 6.92822 6.74129C7.21936 6.74129 7.45557 6.50522 7.45557 6.21394V4.78613C7.45557 4.49486 7.21936 4.25879 6.92822 4.25879Z"
        fill="#909090"
      />
      <path
        d="M11.0718 2.85107C10.7806 2.85107 10.5444 3.08714 10.5444 3.37842V6.21399C10.5444 6.50526 10.7806 6.74133 11.0718 6.74133C11.3631 6.74133 11.5991 6.50526 11.5991 6.21399V3.37842C11.5991 3.08714 11.3631 2.85107 11.0718 2.85107Z"
        fill="#909090"
      />
      <path
        d="M9 3.56348C8.70873 3.56348 8.47266 3.79968 8.47266 4.09082V6.22258C8.47266 6.51386 8.70873 6.74992 9 6.74992C9.29128 6.74992 9.52734 6.51386 9.52734 6.22258V4.09082C9.52734 3.79968 9.29128 3.56348 9 3.56348Z"
        fill="#909090"
      />
      <path
        d="M14.7998 10.5918C14.5085 10.5918 14.2725 10.828 14.2725 11.1191V12.5471C14.2725 12.8382 14.5085 13.0744 14.7998 13.0744C15.0909 13.0744 15.3271 12.8382 15.3271 12.5471V11.1191C15.3271 10.828 15.0909 10.5918 14.7998 10.5918Z"
        fill="#909090"
      />
      <path
        d="M14.7998 6.37451C14.5085 6.37451 14.2725 6.61072 14.2725 6.90186V9.38559C14.2725 9.67686 14.5085 9.91293 14.7998 9.91293C15.0909 9.91293 15.3271 9.67686 15.3271 9.38559V6.90186C15.3271 6.61072 15.0909 6.37451 14.7998 6.37451Z"
        fill="#909090"
      />
      <path
        d="M3.20068 12.9009C3.49196 12.9009 3.72803 12.6648 3.72803 12.3735V9.67871C3.72803 9.38744 3.49196 9.15137 3.20068 9.15137C2.90955 9.15137 2.67334 9.38744 2.67334 9.67871V12.3735C2.67334 12.6648 2.90955 12.9009 3.20068 12.9009Z"
        fill="#909090"
      />
    </svg>
  );
};

export default IconProperty;
