import React from 'react';
import { connect } from 'react-redux';
import { SnackbarPayload } from '../../../models/snackbarModels';
import { ShowSnackbarAction } from '../../../redux/actions/snackbarActions';
import { ShowLoadingAction, HideLoadingAction } from '../../../redux/actions/loadingActions';
import { createGroupChat, updateGroupChat } from '../../../services/chatService';
import CreateGroupChatComponent from './createGroupChatComponent';
import { Conversation } from '@twilio/conversations';
import { CustomConversation } from '../../../models/chatModels';
import { FieldValues } from 'react-hook-form';

const CreateGroupChatContainer = ({
  ShowLoadingAction,
  HideLoadingAction,
  ShowSnackbarAction,
  setScreenVal,
  conversation,
  setCustomConvo,
}: CreateGroupChatContainerProps) => {
  const onSubmit = (formData: FieldValues) => {
    const payload = new FormData();
    payload.append('isPrivate', 'false');
    if (conversation?.sid) {
      payload.append('groupSid', conversation.sid);
    }
    if (formData['GroupName']) {
      payload.append('groupName', formData['GroupName']);
    }
    if (formData['GroupIcon']) {
      payload.append('groupIcon', formData['GroupIcon']);
    }
    createGroup(payload);
  };

  const createGroup = (payload: FormData) => {
    ShowLoadingAction();

    const addEditGroupChat = () => {
      if (conversation?.sid) {
        return updateGroupChat(payload);
      } else {
        return createGroupChat(payload);
      }
    };

    addEditGroupChat()
      .then(response => {
        HideLoadingAction();
        if (response.data.status === 200) {
          ShowSnackbarAction({
            message: response?.data?.message,
            severity: 'success',
          });
          setCustomConvo();
          setScreenVal(0);
        } else {
          ShowSnackbarAction({
            message: response?.data?.message,
            severity: 'error',
          });
        }
      })
      .catch(() => {
        HideLoadingAction();
        ShowSnackbarAction({
          message: 'Error Occurred',
          severity: 'error',
        });
      });
  };

  return (
    <CreateGroupChatComponent
      onSubmit={onSubmit}
      setScreenVal={setScreenVal}
      conversation={conversation}
      setCustomConvo={setCustomConvo}
    />
  );
};

interface CreateGroupChatContainerProps {
  ShowSnackbarAction: (payload: SnackbarPayload) => void;
  ShowLoadingAction: (payload?: string) => void;
  HideLoadingAction: () => void;
  setScreenVal: (screenNum: number) => void;
  conversation?: Conversation;
  setCustomConvo: (customConvo?: CustomConversation) => void;
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {
  ShowSnackbarAction,
  ShowLoadingAction,
  HideLoadingAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateGroupChatContainer);
