import { useQuery } from '@tanstack/react-query';
import { FundsPerformanceSlideComponent } from './FundsPerformanceSlideComponent';
import { getFundsPerformanceData } from 'src/utils/getFundsPerformanceData';
import { Loader } from 'src/components/common/Loader';
import { QueryError } from 'src/components/common/QueryError';
import { Fund } from 'src/types/Funds';
import { QueryKey } from 'src/consts/query';

interface FundsPerformanceSlideWidgetProps {
  funds: Fund[];
}

export const FundsPerformanceSlideWidget = ({ funds }: FundsPerformanceSlideWidgetProps) => {
  const {
    data: fundsPerformanceInformationData,
    isLoading,
    isError,
  } = useQuery({
    queryKey: [QueryKey.FundsPerformanceInformation, funds],
    queryFn: () => getFundsPerformanceData(funds),
  });

  if (isLoading) return <Loader />;

  if (isError) return <QueryError />;

  if (!fundsPerformanceInformationData)
    return (
      <div className="funds-performance-empty-data">
        <h4>Funds Performance is empty.</h4>
      </div>
    );

  return <FundsPerformanceSlideComponent data={fundsPerformanceInformationData} />;
};
