import { useCallback, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useProtected } from 'src/hooks/useProtected';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { SearchFilterBarContainer } from 'src/components/common/SearchFilterBarContainer';
import { FilterComponent } from './components/filterComponent';
import { Loader } from 'src/components/common/Loader';
import { usePagination } from 'src/hooks/usePagination';
import { useUsersList } from 'src/hooks/react-query/user/useUsersList';
import { useRoleList } from 'src/hooks/react-query/user/userRoleList';
import { useModal } from 'src/hooks/useModal';
import ConfirmDialogComponent from 'src/uiLibrary/reusables/confirm-dialog/confirmDialogComponent';
import { useUserDelete } from 'src/hooks/react-query/user/useUserDelete';
import { Table } from 'src/components/common/MuiTable/Table';
import { SortFields, SortFieldsId } from './consts/sortFields';
import { HeadToolbarButton } from 'src/models/tableModels';
import EnhancedTableToolbar from 'src/uiLibrary/reusables/data-table/enhancedTableToolbar';
import { format } from 'date-fns';
import { HeadCellItem } from 'src/models/tableModels';
import { UserSuggestion } from 'src/models/chatModels';
import { ButtonTooltip } from 'src/components/common/ButtonTooltip/ButtonTooltip';
import IconActive from 'src/uiLibrary/icons/IconActive';
import IconInactive from 'src/uiLibrary/icons/IconInactive';
import { IconEdit } from 'src/uiLibrary/icons';
import IconDelete from 'src/uiLibrary/icons/IconDelete';
import { Permission } from 'src/consts/permissions';
import { Protected } from 'src/components/layouts/Protected/Protected';
import { useUserStatus } from '../../hooks/react-query/user/useUserStatus';

export const UserStatus = {
  InActive: 0,
  Active: 1,
  All: undefined,
};

export enum UserDialog {
  Delete,
  ChangeStatus,
}

export type UserSortField = 'Name' | 'Role' | 'Account Created' | 'Account Status';

export const UserComponent = () => {
  const navigate = useNavigate();

  const [filterStatus, setFilterStatus] = useState<0 | 1 | undefined>(UserStatus.All);
  const [filterRole, setFilterRole] = useState<string | undefined>('all');
  const [searchString, setSearchString] = useState<string | undefined>('');
  const [sortField, setSortField] = useState<UserSortField>('Name');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const selectedUser = useRef<UserSuggestion | null>(null);
  const { isOpen, handleCloseModal, handleOpenModal } = useModal<UserDialog>();

  const { page, pageSize, setPageSize, setActivePage } = usePagination();
  const { data: rolesList, isLoading: isRolesLoading } = useRoleList();

  const isManageUserAvailable = useProtected({
    permissions: [Permission.ManageUser],
  });

  const handleManageDocument = useCallback(
    (user: UserSuggestion, modalKey: UserDialog) => {
      selectedUser.current = user;
      handleOpenModal(modalKey);
    },
    [handleOpenModal],
  );

  const editUser = useCallback(
    (id: number) => {
      navigate(`/users/edit-user/${id}`);
    },
    [navigate],
  );

  const columns: HeadCellItem<UserSuggestion>[] = useMemo(
    () => [
      {
        id: 'firstname',
        align: 'left',
        disablePadding: false,
        label: 'Name',
        sortable: true,
        render: user => `${user.firstname} ${user.lastname}`,
      },
      {
        id: 'email',
        align: 'left',
        disablePadding: false,
        label: 'Email',
        sortable: false,
        render: user => user.email,
      },
      {
        id: 'contactnumber',
        align: 'left',
        disablePadding: false,
        label: 'Contact number',
        sortable: false,
        render: user => user.contactnumber,
      },
      {
        id: 'role',
        align: 'left',
        disablePadding: false,
        label: 'Role',
        sortable: true,
        render: user => user.rolename,
      },
      {
        id: 'creationdatetime',
        align: 'left',
        disablePadding: false,
        label: 'Acc. created',
        sortable: false,
        render: (user: { creationdatetime: string | number | Date }) =>
          format(new Date(user.creationdatetime), 'MM / dd / yyyy'),
      },
      {
        id: 'actions',
        align: 'center',
        disablePadding: false,
        label: 'Actions',
        hidden: !isManageUserAvailable,
        sortable: false,
        render: user => (
          <>
            <Protected permissions={[Permission.ManageUser]}>
              <ButtonTooltip
                icon={user.isactive === 1 ? <IconActive /> : <IconInactive color="#B6B6B6" />}
                tooltipTitle={user.isactive === 1 ? 'Deactivate' : 'Activate'}
                onClick={() => handleManageDocument(user, UserDialog.ChangeStatus)}
              />
            </Protected>
            <Protected permissions={[Permission.ManageUser]}>
              <ButtonTooltip
                icon={<IconEdit color="#B6B6B6" />}
                tooltipTitle={'Detail/Edit'}
                onClick={() => editUser(user.userid)}
              />
            </Protected>
            {user.roleslug !== 'main-gp' && (
              <Protected permissions={[Permission.ManageUser]}>
                <ButtonTooltip
                  icon={<IconDelete color="#B6B6B6" />}
                  tooltipTitle={'Delete'}
                  onClick={() => handleManageDocument(user, UserDialog.Delete)}
                />
              </Protected>
            )}
          </>
        ),
      },
    ],
    [editUser, handleManageDocument, isManageUserAvailable],
  );

  const { data: usersList, isLoading } = useUsersList(
    page,
    pageSize,
    searchString,
    sortField,
    sortOrder,
    filterStatus,
    2,
    filterRole === 'all' ? undefined : filterRole,
  );

  const { mutateAsync: handleDeleteUser } = useUserDelete(selectedUser.current?.firstname || '', handleCloseModal);

  const { mutateAsync: onChangeStatus } = useUserStatus();

  const users: Array<
    UserSuggestion & {
      id: number;
    }
  > = useMemo(
    () =>
      usersList?.users
        ? usersList.users.map(user => ({
            id: user.userid,
            ...user,
          }))
        : [],
    [usersList],
  );

  const handleSearchEvent = (search: string) => {
    setActivePage(0);
    setSearchString(search);
  };

  const handleSortChange = (sortField: string, sortOrder: 'asc' | 'desc') => {
    setSortField(SortFields[sortField as keyof typeof SortFields]);
    setSortOrder(sortOrder);
  };

  const addUser = () => {
    navigate('/users/add-user');
  };

  const handleFilterChange = (status: string, role: string) => {
    setActivePage(0);
    setFilterStatus(+status === 0 ? 0 : +status === 1 ? 1 : undefined);
    setFilterRole(!!role && role !== 'all' ? role : undefined);
  };

  const handleChangeStatus = async () => {
    if (selectedUser.current) {
      await onChangeStatus({
        UserId: selectedUser.current?.userid,
        IsActive: selectedUser.current?.isactive === 0 ? 1 : 0,
      });
    }

    handleCloseModal();
  };

  const onDeleteUser = async () => {
    await handleDeleteUser(selectedUser.current?.userid);

    handleCloseModal();
  };

  const headButtons: HeadToolbarButton[] = [
    {
      variant: 'contained',
      startIcon: <PersonAddAltIcon />,
      handleClick: addUser,
      text: 'Add User',
      hidden: !isManageUserAvailable,
    },
  ];

  if (isLoading || isRolesLoading) return <Loader />;

  return (
    <>
      <SearchFilterBarContainer
        filterComponent={
          <FilterComponent
            handleFilterChange={handleFilterChange}
            filterStatus={filterStatus}
            filterRole={filterRole}
            roleList={rolesList}
          />
        }
      />

      <EnhancedTableToolbar
        onSearch={handleSearchEvent}
        placeholder="Search by user name"
        buttons={headButtons}
        initialSearchValue={searchString}
        searchCount={usersList?.totalUsers}
      />

      <div className="theme-table-wrap">
        <Table
          data={users}
          columns={columns}
          order={sortOrder}
          handleSortChange={handleSortChange}
          orderBy={SortFieldsId[sortField as keyof typeof SortFieldsId]}
          pagination={{
            page,
            rowsPerPage: pageSize,
            total: usersList?.totalUsers,
            onPageChange: setActivePage,
            onPageSizeChange: setPageSize,
          }}
        />
      </div>

      <ConfirmDialogComponent
        open={isOpen(UserDialog.Delete)}
        title="Delete user"
        text={`Are you sure you want to delete user ${selectedUser.current?.firstname} ?`}
        confirmModal={onDeleteUser}
        handleClose={handleCloseModal}
      />

      <ConfirmDialogComponent
        open={isOpen(UserDialog.ChangeStatus)}
        title="Are You Sure?"
        text={`You want to ${selectedUser.current?.isactive === 1 ? 'inactive' : 'active'} this account ?`}
        confirmModal={handleChangeStatus}
        handleClose={handleCloseModal}
      />
    </>
  );
};
