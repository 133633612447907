import { configureStore } from '@reduxjs/toolkit';
import AuthReducer from './reducers/authReducer';
import LoadingReducer from './reducers/loadingReducer';
import SnackbarReducer from './reducers/snackbarReducer';
import FilterReducer from './reducers/filterReducer';

const store = configureStore({
  reducer: {
    auth: AuthReducer,
    loading: LoadingReducer,
    snackbar: SnackbarReducer,
    filter: FilterReducer,
  },
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
