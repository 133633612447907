import React from 'react';
import { Link } from 'react-router-dom';
import { TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import WhiteLogo from '../../uiLibrary/icons/WhiteLogo';
import IconEyeOff from '../../uiLibrary/icons/IconEyeOff';
import IconEye from '../../uiLibrary/icons/IconEye';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

interface State {
  password: string;
  showPassword: boolean;
}

const SignUpComponent = () => {
  const [values, setValues] = React.useState<State>({
    password: '',
    showPassword: false,
  });

  const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  return (
    <div className="login-wrapper">
      <PerfectScrollbar>
        <div className="inner-wrap">
          <div className="m-header-logo">
            <WhiteLogo />
          </div>
          <div className="row align-items-center h-100 flex-row-reverse">
            <div className="col-md-6 col-lg-6">
              <div className="comm-content">
                <h5>
                  <span>Unlocking the</span> Potential of
                </h5>
                <h1>
                  Commercial Real Estate <span>Investments</span>
                </h1>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 h-100">
              <div className="fromfield-wrap">
                <div className="login-header">
                  <WhiteLogo />
                </div>
                <div className="content-input">
                  <h2 className="title">Login</h2>
                  <p className="lead">Enter the credentials and proceed</p>
                  <form noValidate autoComplete="off">
                    <div className="from-row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <FormControl fullWidth variant="outlined" className="formControl">
                          <FormLabel>
                            Email <span className="require">*</span>
                          </FormLabel>
                          <TextField fullWidth type="email" margin="dense" color="info" required />
                        </FormControl>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <FormControl fullWidth variant="outlined" className="formControl">
                          <FormLabel>
                            Password <span className="require">*</span>
                          </FormLabel>
                          <OutlinedInput
                            type={values.showPassword ? 'text' : 'password'}
                            value={values.password}
                            onChange={handleChange('password')}
                            color="info"
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton onClick={handleClickShowPassword} edge="end">
                                  {values.showPassword ? <IconEyeOff /> : <IconEye />}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 btn-row">
                        <Button color="info" variant="contained">
                          Login
                        </Button>
                        <Link className="forgot-text" to="/forgot-password">
                          Forgot Password?
                        </Link>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="copywright">
                  <p>© 2022 EXtrance Inc. All Rights Reserved. Terms & Conditions | Privacy Policy</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default SignUpComponent;
