import React, { useState } from 'react';
import { connect } from 'react-redux';
import { SnackbarPayload } from '../../../models/snackbarModels';
import { ChangePasswordPayload } from '../../../models/authModels';
import { changePassword } from '../../../services/loginService';
import { RootState } from '../../../redux/store';
import { HideLoadingAction, ShowLoadingAction } from '../../../redux/actions/loadingActions';
import { ShowSnackbarAction } from '../../../redux/actions/snackbarActions';
import ChangePasswordModalComponent from './changePasswordModalComponent';
import { RequestError } from 'src/models/supportRequestModels';
const ChangePasswordModalContainer = ({
  isVisible,
  closeModal,
  ShowLoadingAction,
  HideLoadingAction,
  ShowSnackbarAction,
  visible,
}: ChangePasswordModalContainerProps) => {
  const [resetForm, setResetForm] = useState(false);

  const submitHandler = async (data: ChangePasswordPayload) => {
    ShowLoadingAction();

    try {
      const result = await changePassword({
        CurrentPassword: data.CurrentPassword,
        NewPassword: data.NewPassword,
      });

      HideLoadingAction();
      ShowSnackbarAction({
        message: result?.data && result?.data?.message ? result?.data?.message : 'Submit Successfull',
        severity: 'success',
      });
      setResetForm(true);
    } catch (error) {
      const e = error as RequestError;
      HideLoadingAction();
      ShowSnackbarAction({
        message: e?.response?.data?.message ? e.response.data.message : 'Something went wrong.',
        severity: 'error',
      });
    }
  };
  return (
    <ChangePasswordModalComponent
      isVisible={isVisible}
      closeModal={closeModal}
      submitHandler={submitHandler}
      resetForm={resetForm}
      visible={visible}
    />
  );
};

interface ChangePasswordModalContainerProps {
  isVisible: boolean;
  closeModal: () => void;
  HideLoadingAction: () => void;
  ShowLoadingAction: (payload?: string) => void;
  ShowSnackbarAction: (payload: SnackbarPayload) => void;
  visible: boolean;
}

const mapStateToProps = (state: RootState) => ({
  visible: state.loading.visible,
});

const mapDispatchToProps = {
  HideLoadingAction,
  ShowLoadingAction,
  ShowSnackbarAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordModalContainer);
