import { useState } from 'react';
import { Grid, IconButton, Dialog, DialogContent, Button } from '@mui/material';
import { UserListMetaData } from './../../../models/frontendUserModels';
import IconArrowLeft from '../../icons/IconArrowLeft';
import NdaApproveTable from './ndaApproveTable';
import { approveRejectNda } from '../../../services/governanceService';
import { RequestError } from 'src/models/supportRequestModels';

const NdaApproveComponent = ({
  closeModal,
  isVisible,
  //onSubmit,
  handleChangePage,
  //handleFilterChange,
  handlePageSizeChange,
  //handleSearchEvent,
  //handleSortChange,
  limit,
  metaData,
  page,
  ndaList,
  loadNdaData,
  handleError,
}: NdaApproveComponentProps) => {
  const [approvalQueue, setApprovalQueue] = useState<{ id: number; value: string }[]>([]);
  // TODO: get type from API
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [positions, setPositions] = useState<any>([]);

  const saveChangesApproveQueue = async () => {
    const responses = await Promise.all(
      approvalQueue.map(async item => {
        return await approveOrReject(item.id, item.value === 'Approve');
      }),
    );
    let newPositions = [...positions];
    responses.forEach(({ id, status }) => {
      // TODO: get type from API
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      newPositions = newPositions.map((item: any) => (item.id === id ? { ...item, status: status } : item));
    });
    setPositions(newPositions);
    setApprovalQueue([]);
  };

  const approveOrReject = (id: number, approved: boolean) => {
    const status = approved ? 'accept' : 'reject';
    return approveRejectNda(id, status)
      .then(() => {
        loadNdaData();
        return { id, status };
      })
      .catch(err => {
        handleError(err);
        return { id, status };
      });
  };

  return (
    <Dialog
      open={isVisible}
      onClose={closeModal}
      sx={{
        backdropFilter: 'blur(20px)',
      }}
      className="approvenda-dialog"
      PaperProps={{ sx: { maxWidth: '863px' } }}
    >
      <DialogContent className="approvenda-dialog-content ">
        <div className="dialog-content">
          <div className="back-login" onClick={closeModal}>
            <IconButton size="large" component="span">
              <IconArrowLeft stroke="#8F8F8F" />
            </IconButton>
            <p className="back-text">Back</p>
          </div>
          <div className="upload-approvenda-form-wrap">
            <h4 className="title">Approve NDAs</h4>
            <Grid container direction="column" justifyContent="flex-start" alignItems="stretch" spacing={0}>
              <Grid item xs={12}>
                <NdaApproveTable
                  handleChangePage={handleChangePage}
                  handlePageSizeChange={handlePageSizeChange}
                  //handleSortChange={handleSortChange}
                  limit={limit}
                  metaData={metaData}
                  page={page}
                  ndaList={ndaList}
                  approvalQueue={approvalQueue}
                  setApprovalQueue={setApprovalQueue}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Button className={'mr-10'} variant={'contained'} onClick={saveChangesApproveQueue}>
                      Save changes
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

interface NdaApproveComponentProps {
  isVisible: boolean;
  closeModal: () => void;
  //onSubmit: (formData: any) => void;
  handleChangePage: (newPage: number) => void;
  //handleFilterChange: (status: string, role: string) => void;
  //handleSortChange: (sortColumn: SortColumn) => void;
  handlePageSizeChange: (pageSize: number) => void;
  metaData: UserListMetaData;
  page: number;
  limit: number;
  // TODO: get type from API
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ndaList: any[];
  handleError: (error: RequestError) => void;
  loadNdaData: () => void;
}

export default NdaApproveComponent;
