import { useForm, Controller } from 'react-hook-form';
import { Stack, OutlinedInput, FormControl, FormHelperText } from 'src/components/common';
import Button from 'src/components/common/Button/Button';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const schema = yup.object({
  name: yup.string().required('Pleace enter folder name').max(50, 'You have exceeded the character limit'),
});

type FormData = {
  name: string;
};

export interface CreateFolderFormProps {
  onSubmit: ({ name }: FormData) => void;
  onCancel: () => void;

  submitButtonText?: string;
  cancelButtonText?: string;
  inputPlaceholder?: string;
  padding?: string | number;
}

export const CreateFolderForm = ({
  onSubmit,
  onCancel,

  submitButtonText = 'Save',
  cancelButtonText = 'Cancel',
  inputPlaceholder = 'Name...',
  padding,
}: CreateFolderFormProps) => {
  const { control, handleSubmit } = useForm<FormData>({
    resolver: yupResolver(schema as yup.ObjectSchema<FormData>),
    defaultValues: {
      name: '',
    },
  });

  return (
    <Stack direction="column" spacing={4} padding={padding}>
      <Controller
        name="name"
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <FormControl fullWidth>
            <OutlinedInput
              name="Folder Name"
              placeholder={inputPlaceholder}
              fullWidth
              size="small"
              value={value}
              onChange={onChange}
              error={Boolean(error)}
            />
            {error && <FormHelperText error={Boolean(error)}>{error.message}</FormHelperText>}
          </FormControl>
        )}
      />

      <Stack direction="row" justifyContent="space-between" flexBasis="100%" gap={2}>
        <Button onClick={onCancel} size="small" order="secondary" variant="secondary" fullWidth>
          {cancelButtonText}
        </Button>
        <Button onClick={handleSubmit(onSubmit)} size="small" order="primary" variant="primary" fullWidth>
          {submitButtonText}
        </Button>
      </Stack>
    </Stack>
  );
};
