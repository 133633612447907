import React from 'react';

const IconDownload = () => {
  return (
    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.33333 15.6666H14.6667C14.8877 15.6666 15.0996 15.7544 15.2559 15.9107C15.4122 16.067 15.5 16.2789 15.5 16.5C15.5 16.721 15.4122 16.9329 15.2559 17.0892C15.0996 17.2455 14.8877 17.3333 14.6667 17.3333H1.33333C1.11232 17.3333 0.900358 17.2455 0.744078 17.0892C0.587797 16.9329 0.5 16.721 0.5 16.5C0.5 16.2789 0.587797 16.067 0.744078 15.9107C0.900358 15.7544 1.11232 15.6666 1.33333 15.6666ZM8 0.666626C7.77899 0.666626 7.56702 0.754423 7.41074 0.910704C7.25446 1.06698 7.16667 1.27895 7.16667 1.49996V11.155L5.25583 9.24413C5.17896 9.16453 5.08701 9.10105 4.98534 9.05737C4.88367 9.0137 4.77432 8.99071 4.66367 8.98975C4.55302 8.98879 4.44329 9.00987 4.34087 9.05177C4.23846 9.09368 4.14541 9.15555 4.06717 9.2338C3.98893 9.31204 3.92705 9.40508 3.88515 9.5075C3.84325 9.60991 3.82216 9.71964 3.82312 9.83029C3.82409 9.94094 3.84707 10.0503 3.89075 10.152C3.93442 10.2536 3.99791 10.3456 4.0775 10.4225L7.41083 13.7558C7.48841 13.833 7.58044 13.8942 7.68167 13.9358C7.78245 13.9781 7.89068 14 8 14C8.10932 14 8.21755 13.9781 8.31833 13.9358C8.41956 13.8942 8.51159 13.833 8.58917 13.7558L11.9225 10.4225C12.0743 10.2653 12.1583 10.0548 12.1564 9.83629C12.1545 9.61779 12.0669 9.40878 11.9123 9.25428C11.7578 9.09977 11.5488 9.01213 11.3303 9.01023C11.1118 9.00833 10.9013 9.09233 10.7442 9.24413L8.83333 11.155V1.49996C8.83333 1.27895 8.74554 1.06698 8.58926 0.910704C8.43297 0.754423 8.22101 0.666626 8 0.666626Z"
        fill="#B6B6B6"
      />
    </svg>
  );
};

export default IconDownload;
