import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'src/context/ShackbarContext';
import { AxiosError } from 'axios';
import { getRoleDetailsById } from 'src/services/rolesService';
import { ApiError } from 'src/types/api';

export const useRoleById = (roleId: string | undefined) => {
  const { showErrorSnackbar } = useSnackbar();

  return useQuery({
    queryKey: ['roleById', roleId],
    queryFn: async () => {
      if (roleId) {
        try {
          const responce = await getRoleDetailsById(roleId);
          return responce.data;
        } catch (e) {
          console.log('useRoleById error', e);
          showErrorSnackbar((e as AxiosError<ApiError>).response?.data.message);
          throw new Error((e as AxiosError<ApiError>).response?.data.message);
        }
      }
      throw new Error('No Role was provided');
    },
    enabled: !!roleId,
  });
};
