import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'src/context/ShackbarContext';
import { getUserDetails } from 'src/services/adminUserServices';
import { ApiError } from 'src/types/api';

export const useUserDetails = (id?: string) => {
  const { showErrorSnackbar } = useSnackbar();

  return useQuery({
    queryKey: ['userDetails'],
    queryFn: async () => {
      try {
        const response = await getUserDetails(id);

        if (response?.data) {
          return response.data;
        }
      } catch (error) {
        showErrorSnackbar((error as AxiosError<ApiError>).response?.data.message);
        console.error('useUserDetails error', error);
        throw new Error((error as AxiosError<ApiError>).response?.data.message);
      }
    },
    enabled: !!id,
    cacheTime: 0,
  });
};
