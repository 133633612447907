import { Button, Container, Grid, IconButton, Popover } from '@mui/material';
import { FieldValues, UseFormHandleSubmit } from 'react-hook-form';
import { IconClose } from 'src/uiLibrary/icons/IconClose';
import { useMemo } from 'react';

export const SearchFilters = <T,>({
  clearFilter,
  handleCloseFilter,
  onFilter,
  openFilter,
  anchorEl,
  handleSubmit,
  children,
}: FilterComponentProps<T>) => {
  const id = useMemo(() => (openFilter ? 'simple-popover' : undefined), [openFilter]);

  return (
    <Popover
      id={id}
      className="popover-filter"
      open={openFilter}
      anchorEl={anchorEl}
      onClose={handleCloseFilter}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Container>
        <form noValidate autoComplete="off" onSubmit={handleSubmit(onFilter)} className={'filter-form'}>
          <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
            <Grid item xs={12} className="from-row1"></Grid>
            <Grid item xs={12} className="from-row1">
              <h4 className="flex-grow-1">Filters</h4>
              <IconButton className="filter-close" onClick={handleCloseFilter} size="small">
                <IconClose />
              </IconButton>
            </Grid>
            {children}
            <Grid item xs={12} className="from-row1">
              <Grid container spacing={1} className="btn-grid">
                <Grid item>
                  <Button variant="outlined" type="button" onClick={clearFilter}>
                    Clear
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="contained" type="submit">
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className="from-row1"></Grid>
          </Grid>
        </form>
      </Container>
    </Popover>
  );
};
interface FilterComponentProps<T> {
  clearFilter: () => void;
  handleCloseFilter: () => void;
  onFilter: (payload: T) => void;
  openFilter: boolean;
  anchorEl: HTMLButtonElement | null;
  handleSubmit: UseFormHandleSubmit<T extends FieldValues ? T : never, undefined>;
  children: React.ReactNode;
}
