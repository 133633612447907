import { IconButton } from '@mui/material';
import TableViewIcon from '@mui/icons-material/TableView';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import styles from './styles.module.scss';

interface DisplaySwitcherProps {
  isTableView: boolean;
  onChangeView(isTable: boolean): void;
}

export const DisplaySwitcher = ({ isTableView, onChangeView }: DisplaySwitcherProps) => {
  const setTableView = () => {
    onChangeView(true);
  };

  const setGridView = () => {
    onChangeView(false);
  };

  return (
    <div className={styles.displaySwitcher}>
      <IconButton disabled={isTableView} onClick={setTableView}>
        <TableViewIcon />
      </IconButton>
      <IconButton disabled={!isTableView} onClick={setGridView}>
        <ViewModuleIcon />
      </IconButton>
    </div>
  );
};
