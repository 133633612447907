import { useState } from 'react';
import IconCollapsed from 'src/uiLibrary/icons/IconCollapsed';
import { FundPrivilege, Privilege as PrivilegeType } from 'src/types/Roles';

interface FundPrivilegeProps {
  privilege: FundPrivilege;
  onPrivilegeChange: (parentId: number) => void;
  parent?: FundPrivilege;
}

export const FundPrivilegeRow = ({ onPrivilegeChange, privilege, parent }: FundPrivilegeProps) => {
  const [open, setOpen] = useState(true);
  const childPrivileges = privilege?.ChildPrivilege;

  return (
    <div>
      <div className="dropdown-row">
        <div className="label-group" onClick={() => setOpen(state => !state)}>
          {!parent ? (
            <div className="title-privilege">{privilege?.Name}</div>
          ) : (
            <div className="sub-title-privilege">{privilege?.Name}</div>
          )}
          {privilege?.ChildPrivilege?.length > 0 && (
            <div style={{ transform: !open ? 'rotate(180deg)' : '' }}>
              <IconCollapsed />
            </div>
          )}
        </div>

        <div className="separator"></div>
        <div>
          <input
            disabled={parent ? !parent?.checked : false}
            id={privilege.id.toString()}
            type="checkbox"
            checked={privilege.checked}
            onChange={() => onPrivilegeChange(privilege.id)}
          />
        </div>
      </div>

      {open && !!childPrivileges?.length && (
        <div className="children-wrapper">
          {childPrivileges.map(childPrivilege => (
            <FundPrivilegeRow
              key={childPrivilege.id}
              parent={privilege}
              privilege={childPrivilege}
              onPrivilegeChange={id => onPrivilegeChange(id)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

interface PrivilegeProps {
  privilege: PrivilegeType;
  onParentChange: (parentId: number) => void;
  onChildChange: (parentId: number, childId: number) => void;
}

export const GeneralPrivilege = ({ onParentChange, onChildChange, privilege }: PrivilegeProps) => {
  const [open, setOpen] = useState(true);
  const childPrivileges = privilege?.childPrivilege;

  return (
    <div>
      <div className="row-header">
        <div className="dropdown-row">
          <div className="parent-row">
            <div className="label-group" onClick={() => setOpen(state => !state)}>
              <div className="title-privilege">{privilege?.Name}</div>
              {privilege?.childPrivilege?.length > 0 && (
                <div style={{ transform: !open ? 'rotate(180deg)' : '' }}>
                  <IconCollapsed />
                </div>
              )}
            </div>
          </div>
          <div className="separator"></div>
          <div>
            <input
              id={privilege.id.toString()}
              type="checkbox"
              checked={privilege.checked}
              onChange={() => onParentChange(privilege.id)}
            />
          </div>
        </div>
      </div>
      {open && childPrivileges?.length > 0 && (
        <div className="children-wrapper">
          {childPrivileges.map(childPriv => {
            return (
              <div key={childPriv.id} className="dropdown-row">
                <div className="label-group">
                  <div className="sub-title-privilege">{childPriv?.Name}</div>
                </div>
                <div className="separator"></div>
                <div>
                  <input
                    disabled={!privilege.checked}
                    checked={childPriv.checked}
                    id={childPriv.id.toString()}
                    type="checkbox"
                    onChange={() => {
                      onChildChange(privilege.id, childPriv.id);
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
