import { IconProps } from './types/types';

export const IconEdit = ({ color = '#B6B6B6', onClick }: IconProps) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
      <path
        d="M15.1233 0.876658C15.2428 0.996001 15.3376 1.13773 15.4023 1.29373C15.4669 1.44973 15.5002 1.61695 15.5002 1.78583C15.5002 1.9547 15.4669 2.12192 15.4023 2.27792C15.3376 2.43392 15.2428 2.57565 15.1233 2.69499L8.76 9.05833L6.0325 9.96749L6.94167 7.23999L13.305 0.876658C13.4243 0.757175 13.5661 0.662387 13.7221 0.597715C13.8781 0.533043 14.0453 0.499756 14.2142 0.499756C14.383 0.499756 14.5503 0.533043 14.7063 0.597715C14.8623 0.662387 15.004 0.757175 15.1233 0.876658ZM1.33333 15.5H13.8333C14.0543 15.5 14.2663 15.4122 14.4226 15.2559C14.5789 15.0996 14.6667 14.8877 14.6667 14.6667V7.99999C14.6667 7.77898 14.5789 7.56702 14.4226 7.41074C14.2663 7.25446 14.0543 7.16666 13.8333 7.16666C13.6123 7.16666 13.4004 7.25446 13.2441 7.41074C13.0878 7.56702 13 7.77898 13 7.99999V13.8333H2.16667V2.99999H8C8.22101 2.99999 8.43298 2.91219 8.58926 2.75591C8.74554 2.59963 8.83333 2.38767 8.83333 2.16666C8.83333 1.94564 8.74554 1.73368 8.58926 1.5774C8.43298 1.42112 8.22101 1.33333 8 1.33333H1.33333C1.11232 1.33333 0.900358 1.42112 0.744078 1.5774C0.587797 1.73368 0.5 1.94564 0.5 2.16666V14.6667C0.5 14.8877 0.587797 15.0996 0.744078 15.2559C0.900358 15.4122 1.11232 15.5 1.33333 15.5Z"
        fill={color}
      />
    </svg>
  );
};
