import React, { Dispatch, SetStateAction, useRef } from 'react';
import { Input as BaseInput } from '@mui/base/Input';
import styles from './styles.module.scss';

interface OtpInputProps {
  length: number;
  value: string;
  label: string;
  isError: boolean;
  onChange: Dispatch<SetStateAction<string>>;
}

export const OtpInput = ({ length, value, label, isError, onChange }: OtpInputProps) => {
  const inputRefs = useRef<HTMLInputElement[]>(new Array(length).fill(null));

  const focusInput = (targetIndex: number) => {
    const targetInput = inputRefs.current[targetIndex];
    targetInput.focus();
  };

  const selectInput = (targetIndex: number) => {
    const targetInput = inputRefs.current[targetIndex];
    targetInput.select();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, currentIndex: number) => {
    switch (event.key) {
      case 'ArrowUp':
      case 'ArrowDown':
      case ' ':
        event.preventDefault();
        break;
      case 'ArrowLeft':
        event.preventDefault();
        if (currentIndex > 0) {
          focusInput(currentIndex - 1);
          selectInput(currentIndex - 1);
        }
        break;
      case 'ArrowRight':
        event.preventDefault();
        if (currentIndex < length - 1) {
          focusInput(currentIndex + 1);
          selectInput(currentIndex + 1);
        }
        break;
      case 'Delete':
        event.preventDefault();
        onChange(prevOtp => {
          return prevOtp.slice(0, currentIndex) + prevOtp.slice(currentIndex + 1);
        });

        break;
      case 'Backspace':
        event.preventDefault();
        if (currentIndex > 0) {
          focusInput(currentIndex - 1);
          selectInput(currentIndex - 1);
        }

        onChange(prevOtp => {
          return prevOtp.slice(0, currentIndex) + prevOtp.slice(currentIndex + 1);
        });
        break;

      default:
        break;
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, currentIndex: number) => {
    const currentValue = event.target.value;
    let indexToEnter = 0;

    while (indexToEnter <= currentIndex) {
      if (inputRefs.current[indexToEnter].value && indexToEnter < currentIndex) {
        indexToEnter += 1;
      } else {
        break;
      }
    }
    onChange(prev => {
      const otpArray = prev.split('');
      otpArray[indexToEnter] = currentValue[currentValue.length - 1];
      return otpArray.join('');
    });
    if (currentValue !== '') {
      if (currentIndex < length - 1) {
        focusInput(currentIndex + 1);
      }
    }
  };

  const handleClick = (currentIndex: number) => {
    selectInput(currentIndex);
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>, currentIndex: number) => {
    event.preventDefault();
    const clipboardData = event.clipboardData;

    if (clipboardData.types.includes('text/plain')) {
      let pastedText = clipboardData.getData('text/plain');
      pastedText = pastedText.substring(0, length).trim();
      let indexToEnter = 0;

      while (indexToEnter <= currentIndex) {
        if (inputRefs.current[indexToEnter].value && indexToEnter < currentIndex) {
          indexToEnter += 1;
        } else {
          break;
        }
      }

      const otpArray = value.split('');

      for (let i = indexToEnter; i < length; i += 1) {
        otpArray[i] = pastedText[i - indexToEnter] ?? ' ';
      }

      onChange(otpArray.join(''));
    }
  };

  return (
    <div>
      <p className={styles['otp-label']}>{label}</p>
      <div className={styles['otp-input']}>
        {new Array(length).fill(null).map((_, index) => (
          <>
            <BaseInput
              aria-label={`Digit ${index + 1} of OTP`}
              error={isError}
              slotProps={{
                input: {
                  ref: element => {
                    if (element) {
                      inputRefs.current[index] = element;
                    }
                  },
                  onKeyDown: event => handleKeyDown(event, index),
                  onChange: event => handleChange(event, index),
                  onClick: () => handleClick(index),
                  onPaste: event => handlePaste(event, index),
                  value: value[index] ?? '',
                },
              }}
            />
            {index !== 2 ? null : <span>-</span>}
          </>
        ))}
      </div>
    </div>
  );
};
