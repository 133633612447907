import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CreatePasswordForm, UserDetails } from '../../models/authModels';
import { CreatePasswordPayload, ResetPasswordForm, ResetPasswordPayload } from '../../models/forgotPasswordModels';
import { SnackbarPayload } from '../../models/snackbarModels';
import { CreatePasswordAction } from '../../redux/actions/authActions';
import { HideLoadingAction, ShowLoadingAction } from '../../redux/actions/loadingActions';
import { ShowSnackbarAction } from '../../redux/actions/snackbarActions';
import { RootState } from '../../redux/store';
import CreateNewPasswordComponent from './createNewPasswordComponent';
import { connect } from 'react-redux';
import { resetPassword } from '../../services/loginService';

const CreateNewPasswordContainer = ({
  userDetails,
  userTemp,
  CreatePasswordAction,
  ShowLoadingAction,
  HideLoadingAction,
  ShowSnackbarAction,
  isResetPassword,
}: CreatePasswordContainerProps) => {
  const navigate = useNavigate();
  const params = useParams();
  const [token, setToken] = useState<string>('');
  const onSubmit = (formData: CreatePasswordForm) => {
    if (isResetPassword) {
      resetPasswordHandler(formData);
    } else {
      createPasswordHandler(formData);
    }
  };
  useEffect(() => {
    if (params && params?.token) {
      setToken(params?.token);
    }
  }, []);
  const resetPasswordHandler = (formData: ResetPasswordForm) => {
    const formDta = formData;
    delete formDta.Confirm_password;
    const payload: ResetPasswordPayload = {
      ...formDta,
    };
    ShowLoadingAction();
    resetPassword(payload, token)
      .then(response => {
        if (response.data.status === 200) {
          ShowSnackbarAction({
            message: response?.data?.message,
            severity: 'success',
          });
          navigate('/login', { replace: true });
        } else {
          ShowSnackbarAction({
            message: response?.data?.message ? response?.data?.message : 'Something went wrong.',
            severity: 'error',
          });
        }
        HideLoadingAction();
      })
      .catch(error => {
        ShowSnackbarAction({
          message: error?.response?.data?.message ? error?.response?.data?.message : 'Something went wrong.',
          severity: 'error',
        });
        HideLoadingAction();
      });
  };

  const createPasswordHandler = (formData: CreatePasswordForm) => {
    if (userTemp) {
      const payload: CreatePasswordPayload = {
        Password: formData.Password,
        Confirm_password: formData.Confirm_password,
      };
      CreatePasswordAction(payload);
    }
  };
  useEffect(() => {
    if (userDetails) {
      let isBankRedirect = false;
      if (userDetails?.UserType === 2 && !userDetails?.IsBankExist) {
        isBankRedirect = true;
      }

      navigate(isBankRedirect ? '/documents' : '/dashboard', { replace: true });
    }
  }, [userDetails]);

  return <CreateNewPasswordComponent onSubmit={onSubmit} isResetPassword={isResetPassword} />;
};
const mapStateToProps = (state: RootState) => {
  return {
    userDetails: state.auth.userDetails,
    userTemp: state.auth.userTemp,
  };
};

const mapDispatchToProps = {
  ShowLoadingAction,
  HideLoadingAction,
  ShowSnackbarAction,
  CreatePasswordAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewPasswordContainer);

export interface CreatePasswordContainerProps {
  userDetails: UserDetails;
  userTemp: UserDetails;
  ShowSnackbarAction: (payload: SnackbarPayload) => void;
  ShowLoadingAction: (payload?: string) => void;
  HideLoadingAction: () => void;
  CreatePasswordAction: (payload: CreatePasswordPayload) => void;
  isResetPassword?: boolean;
}
