import { createTheme } from '@mui/material/styles';
import {
  ButtonComponentsTheme,
  dialogComponentsTheme,
  formLabelComponentsTheme,
  textFieldComponentsTheme,
  accordionComponentsTheme,
} from './components';
import { typography } from './typography/typography';

export const extranceThemeOptions = createTheme({
  palette: {
    primary: {
      main: '#024A72',
    },
    secondary: {
      main: '#cf0',
    },
    info: {
      main: '#fff',
    },
    success: {
      main: '#00E497',
    },
    warning: {
      main: '#FFBF4C',
    },
    error: {
      main: '#FF5953',
    },
  },
  typography: {
    button: {
      fontFamily: 'Poppins',
    },
    fontFamily: 'Poppins',

    dialogTitle: {
      ...typography.s20_lh32_b,
      color: '#000000',
    },

    dialogContentText: {
      ...typography.s14_lh22_r,
      color: '#8F8F8F',
    },

    ...typography,
  },

  components: {
    ...dialogComponentsTheme,
    ...ButtonComponentsTheme,
    ...formLabelComponentsTheme,
    ...textFieldComponentsTheme,
    ...accordionComponentsTheme,
  },
});
