import { useQuery } from '@tanstack/react-query';
import { getActiveCapCall } from 'src/services/governanceService';
import { useSnackbar } from 'src/context/ShackbarContext';
import { AxiosError } from 'axios';
import { QueryKey } from 'src/consts/query';
import { ApiError } from 'src/types/api';

export const useActiveCapCall = (fundId?: number) => {
  const { showErrorSnackbar } = useSnackbar();

  const fetchActiveCapCall = async () => {
    try {
      const response = await getActiveCapCall(fundId);
      return response.data;
    } catch (error) {
      showErrorSnackbar((error as AxiosError<ApiError>).response?.data.message);
      console.error('Fetch Active CapCall error', error);
    }
  };

  return useQuery({
    queryKey: [QueryKey.ActiveCapCall, fundId],
    queryFn: () => fetchActiveCapCall(),
    enabled: !!fundId,
  });
};
