import { Box } from '@mui/material';

export const Separator = () => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '1px',
        background: '#e1e1e1',
      }}
    />
  );
};
