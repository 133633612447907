import { UserPayload } from 'src/models/adminUserModels';
import { UserDetailsData } from 'src/models/frontendUserModels';

export const appendFormData = (formData: FormData, data: UserPayload, userDetails?: UserDetailsData) => {
  if (userDetails && userDetails.UserId) {
    formData.append('UserId', userDetails.UserId.toString());
  }

  formData.append('FirstName', data.firstname);
  formData.append('LastName', data.lastname);
  formData.append('ContactNumber', data.contactnumber);

  if (!userDetails) {
    formData.append('UserType', data.userType);
  }

  data.DateOfBirth && formData.append('DateOfBirth', new Date(data.DateOfBirth).toISOString());

  formData.append('roleId', data.roleId);
  formData.append('StateId', data.state?.length ? data.state : '0');
  formData.append('City', data.city);
  formData.append('ZipCode', data.pinCode);
  formData.append('CountryCode', data.countryCode);
  formData.append('CompanyName', data.agencyName);
  formData.append('CountryId', '1');
  formData.append('Email', data.email.toLowerCase());
  formData.append('Address', data.address);
  formData.append('IsActive', data.isactive.toString());

  if (data.profileimage) {
    formData.append('ProfileImage', data.profileimage);
  }
};
