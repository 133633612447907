const IconDeployCap = () => {
  return (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="60" height="60" rx="8" fill="white" fillOpacity="0.1" />
      <path
        d="M22 23C22 23.2652 22.1054 23.5196 22.2929 23.7071C22.4804 23.8946 22.7348 24 23 24H39C39.2652 24 39.5196 24.1054 39.7071 24.2929C39.8946 24.4804 40 24.7348 40 25V39C40 39.2652 39.8946 39.5196 39.7071 39.7071C39.5196 39.8946 39.2652 40 39 40H34C33.7348 40 33.4804 39.8946 33.2929 39.7071C33.1054 39.5196 33 39.2652 33 39C33 38.7348 33.1054 38.4804 33.2929 38.2929C33.4804 38.1054 33.7348 38 34 38H38V26H23C22.6586 25.9967 22.3203 25.9344 22 25.816V37C22 37.2652 22.1054 37.5196 22.2929 37.7071C22.4804 37.8946 22.7348 38 23 38H28C28.2652 38 28.5196 37.8946 28.7071 37.7071C28.8946 37.5196 29 37.2652 29 37V32.414L27.707 33.707C27.5184 33.8892 27.2658 33.99 27.0036 33.9877C26.7414 33.9854 26.4906 33.8802 26.3052 33.6948C26.1198 33.5094 26.0146 33.2586 26.0123 32.9964C26.01 32.7342 26.1108 32.4816 26.293 32.293L29.293 29.293C29.3861 29.1997 29.497 29.1259 29.619 29.076C29.8636 28.9749 30.1384 28.9749 30.383 29.076C30.505 29.1259 30.6159 29.1997 30.709 29.293L33.709 32.293C33.8912 32.4816 33.992 32.7342 33.9897 32.9964C33.9874 33.2586 33.8822 33.5094 33.6968 33.6948C33.5114 33.8802 33.2606 33.9854 32.9984 33.9877C32.7362 33.99 32.4836 33.8892 32.295 33.707L31 32.414V37C31 37.7956 30.6839 38.5587 30.1213 39.1213C29.5587 39.6839 28.7956 40 28 40H23C22.2044 40 21.4413 39.6839 20.8787 39.1213C20.3161 38.5587 20 37.7956 20 37V23C20 22.2044 20.3161 21.4413 20.8787 20.8787C21.4413 20.3161 22.2044 20 23 20H39C39.2652 20 39.5196 20.1054 39.7071 20.2929C39.8946 20.4804 40 20.7348 40 21C40 21.2652 39.8946 21.5196 39.7071 21.7071C39.5196 21.8946 39.2652 22 39 22H23C22.7348 22 22.4804 22.1054 22.2929 22.2929C22.1054 22.4804 22 22.7348 22 23Z"
        fill="#909090"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28 40C28.7956 40 29.5587 39.6839 30.1213 39.1213C30.6839 38.5587 31 37.7956 31 37V32.414L32.295 33.707C32.4836 33.8892 32.7362 33.99 32.9984 33.9877C33.2606 33.9854 33.5114 33.8802 33.6968 33.6948C33.8822 33.5094 33.9874 33.2586 33.9897 32.9964C33.992 32.7342 33.8912 32.4816 33.709 32.293L30.709 29.293C30.6159 29.1997 30.505 29.1259 30.383 29.076C30.1384 28.9749 29.8636 28.9749 29.619 29.076C29.497 29.1259 29.3861 29.1997 29.293 29.293L26.293 32.293C26.1108 32.4816 26.01 32.7342 26.0123 32.9964C26.0146 33.2586 26.1198 33.5094 26.3052 33.6948C26.4906 33.8802 26.7414 33.9854 27.0036 33.9877C27.2658 33.99 27.5184 33.8892 27.707 33.707L29 32.414V37C29 37.2652 28.8946 37.5196 28.7071 37.7071C28.5196 37.8946 28.2652 38 28 38C28 38 27 38 27 39C27 40 28 40 28 40Z"
        fill="white"
      />
    </svg>
  );
};

export default IconDeployCap;
