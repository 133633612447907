import { useParams } from 'react-router-dom';
import { Loader } from 'src/components/common/Loader';
import { QueryError } from 'src/components/common/QueryError';
import { useFundById } from 'src/hooks/react-query/funds';
import GovernanceFundDetailComponent from './governanceFundDetailComponent';

const GovernanceFundDetailContainer = () => {
  const params = useParams();
  const fundId = Number(params.fundId);

  const { data: fundInfo, isLoading: fundLoading, isError: fundError } = useFundById(fundId);

  // TODO: Remove 'main-body-content-loader' block when will fix main layout
  if (fundLoading) {
    return (
      <div className="main-body-content-frame">
        <Loader />
      </div>
    );
  }

  if (fundError) return <QueryError />;

  return <GovernanceFundDetailComponent fundName={fundInfo?.fund.name} />;
};

export default GovernanceFundDetailContainer;
