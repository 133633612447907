import React from 'react';
import styles from './styles.module.scss';
import TableBody from './TableBody';
import TableHeader from './TableHeader';
import { Column, Row, SortColumn, SortDirection } from './types';

interface TableProps {
  columns: Column[];
  data: Row[];
  sortField?: string;
  sortOrder?: SortDirection;
  loading?: boolean;
  onSortColumnChange?: (sortColumn: SortColumn) => void;
  onClick?: (row: Row) => void;
}

const Table = ({ columns, data, sortField, sortOrder, loading, onSortColumnChange, onClick }: TableProps) => {
  return (
    <div className={styles.tableContainer}>
      <table className={styles.table}>
        <TableHeader
          columns={columns}
          sortField={sortField}
          sortOrder={sortOrder}
          onSortColumnChange={onSortColumnChange}
        />
        <TableBody columns={columns} loading={loading} data={data} onClick={onClick} />
      </table>
    </div>
  );
};

export default Table;
