import { useEffect } from 'react';

const Hotjar = () => {
  useEffect(() => {
    if (process.env.REACT_APP_HOTJAR_ENABLED) {
      (function (h, o, t, j, a, r) {
        h.hj =
          h.hj ||
          function () {
            (h.hj.q = h.hj.q || []).push(arguments);
          };
        h._hjSettings = { hjid: process.env.REACT_APP_HOTJAR_ID, hjsv: process.env.REACT_APP_HOTJAR_V };
        a = o.getElementsByTagName('head')[0];
        r = o.createElement('script');
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
    }
  }, []);

  return null;
};

export default Hotjar;
