import ConfirmDialogComponent from './confirmDialogComponent';
import { ConfirmDialogComponentProps } from './confirmDialogComponent';

const ConfirmDialogContainer = ({
  isVisible,
  title,
  dialogContentText,
  confirmButtonText,
  closeButtonText,
  loading,
  confirmModal,
  closeModal,
}: ConfirmDialogComponentProps) => {
  return (
    <ConfirmDialogComponent
      isVisible={isVisible}
      title={title}
      dialogContentText={dialogContentText}
      confirmButtonText={confirmButtonText}
      loading={loading}
      closeButtonText={closeButtonText}
      confirmModal={confirmModal}
      closeModal={closeModal}
    />
  );
};

export default ConfirmDialogContainer;
