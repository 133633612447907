import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useSnackbar } from 'src/context/ShackbarContext';
import { resendOtp } from 'src/services/loginService';
import { ApiError } from 'src/types/api';

export const useResendOtp = () => {
  const { showErrorSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: (email: string) => {
      return resendOtp(email);
    },
    onSuccess: async () => {
      console.log('OTP code is resented');
    },
    onError: e => {
      showErrorSnackbar((e as AxiosError<ApiError>).response?.data.message);
    },
  });
};
