import { useState, useEffect } from 'react';

interface UseSlideAnimationReturnProps {
  showFundsInformation: string;
  selectedSlidePage: number;
}

const useSlideAnimation = (selectedSlide: number, totalSlides: number): UseSlideAnimationReturnProps => {
  const [showFundsInformation, setShowFundsInformation] = useState('');
  const [selectedSlidePage, setSelectedSlidePage] = useState(selectedSlide);

  useEffect(() => {
    if (selectedSlide !== selectedSlidePage) {
      let slideOutAnimation: string;

      let slideInAnimation: string;

      if (selectedSlide === 0 && selectedSlidePage === totalSlides - 1) {
        slideOutAnimation = 'slide-out-left';
        slideInAnimation = 'slide-in-left';
      } else if (selectedSlide === totalSlides - 1 && selectedSlidePage === 0) {
        slideOutAnimation = 'slide-out-right';
        slideInAnimation = 'slide-in-right';
      } else {
        const newDirection = selectedSlide > selectedSlidePage ? 'left' : 'right';

        slideOutAnimation = `slide-out-${newDirection}`;
        slideInAnimation = `slide-in-${newDirection}`;
      }

      setShowFundsInformation(slideOutAnimation);

      const timeout = setTimeout(() => {
        setSelectedSlidePage(selectedSlide);
        setShowFundsInformation(slideInAnimation);
      }, 300);

      return () => clearTimeout(timeout);
    }
  }, [selectedSlide, selectedSlidePage, totalSlides]);

  return { showFundsInformation, selectedSlidePage };
};

export default useSlideAnimation;
