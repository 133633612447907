import { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { MetaData, Settings } from 'src/types/custom';
import { getGeneralSettingPublic } from 'src/services/settingsService';

const useMetaData = () => {
  const [metaData, setMetaData] = useState<MetaData | null>(null);

  const { data: generalSettings, isLoading } = useQuery(['generalSettings'], getGeneralSettingPublic);

  useEffect(() => {
    if (isLoading) return;

    const fetchMetaData = () => {
      let metaTitle = '';
      let metaDescription = '';
      let metaKeyWords = '';

      generalSettings?.data.data?.forEach((setting: Settings) => {
        if (setting.KeySlug === 'company_title') {
          metaTitle = setting.KeyValue;
        }

        if (setting.KeySlug === 'meta_description') {
          metaDescription = setting.KeyValue;
        }

        if (setting.KeySlug === 'meta_keyword') {
          metaKeyWords = setting.KeyValue;
        }
      });

      setMetaData({
        metaTitle,
        metaDescription,
        metaKeyWords,
      });
    };

    fetchMetaData();
  }, [generalSettings, isLoading]);

  return metaData;
};

export default useMetaData;
