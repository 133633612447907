import ServiceClient from './serviceClientConfig';

const serviceClient = new ServiceClient({
  baseURL: process.env.REACT_APP_BASE_URL,
});
export const newNotificationCount = (userId: number) => {
  return serviceClient.get(`usernotifications/count/latest/notifications/${userId}`);
};
export const resetNewNotificationCount = (data: { userId: number }) => {
  return serviceClient.put(`usernotifications/update/count/notification`, data);
};
export const getNotifications = (userId: number, page: number, limit: number) => {
  return serviceClient.get(`/usernotifications/notification/list?UserId=${userId}&Page=${page}&limit=${limit}`);
};

export const notificationStatus = (data: { id: number; IsRead: number }) => {
  return serviceClient.put(`usernotifications/change/notification/status`, data);
};
