import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DocumentsComponent from './documentsComponent';
import { ExistentFolderData, LeftSideSection, LeftSideSectionListItem } from 'src/models/documentsModels';
import IconPlus from 'src/uiLibrary/icons/IconPlus';
import IconFolder from 'src/uiLibrary/icons/IconFolder';
import IconPaper from 'src/uiLibrary/icons/IconPaper';
import {
  createNonExpandableDocumentData,
  DocumentData,
} from 'src/components/documents/document-card/document-card-helpers';
import { FolderData } from 'src/components/FolderCard';
import { HideLoadingAction, ShowLoadingAction } from 'src/redux/actions/loadingActions';
import { useDispatch } from 'react-redux';
import { getDocumentsFolders, getLatestDocuments, getStarredFunds } from 'src/services/documentsService';
import CreateFolderModalContainer from 'src/uiLibrary/modals/createFolderModal/createFolderModalContainer';

import { useCreateDocumentsFolder } from 'src/hooks/react-query/documents';
import { useFunds } from 'src/hooks/react-query/funds/useFunds';
import { Loader } from 'src/components/common/Loader';

const DocumentsContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [createFolderExistentFolders, setCreateFolderExistentFolders] = useState<ExistentFolderData[]>([]);

  const { mutate: createDocumentsFolder } = useCreateDocumentsFolder();

  const fetchDocumentsFolders = async () => {
    const leftSideDataLocal = leftSideData.slice();
    const customFoldersLocal = leftSideDataLocal.find(i => i.id === 'Custom Folders');

    try {
      const response = await getDocumentsFolders({});

      if (response.data) {
        // Update the folders' data on the leftSideData variable.
        const foldersItemsLocal: LeftSideSectionListItem[] = [];
        // Also, save the folders' information for the "Create Folder" modal
        const createFolderExistentFoldersLocal: ExistentFolderData[] = [];
        // TODO: get type from API
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        response.data.forEach((f: any) => {
          foldersItemsLocal.push({
            id: f.id,
            icon: <IconFolder />,
            text: f.name,
          });
          createFolderExistentFoldersLocal.push({
            id: f.id,
            name: f.name,
            numberOfDocs: f.documentFolder?.length || 0,
          });
        });
        const customFoldersLocal = leftSideDataLocal.find(i => i.id === 'Custom Folders');

        if (customFoldersLocal) {
          customFoldersLocal.items = foldersItemsLocal;
          setLeftSideData(leftSideDataLocal);
        }

        setCreateFolderExistentFolders(createFolderExistentFoldersLocal);
      }
    } catch (e) {
      if (customFoldersLocal) {
        customFoldersLocal.items = [];
        setLeftSideData(leftSideDataLocal);
      }
    }
  };

  const handleCreateFolderOnSubmit = async (folderName: string) => {
    createDocumentsFolder({ name: folderName });
  };

  const handleStarredFundOnClick = (fundId: string | number) => {
    navigate(`/documents/fund/${fundId}`, {
      state: {
        selectedFundName: fundFolder?.find(f => f.id === fundId)?.title,
      },
    });
  };

  const handleFolderCardOnClick = (folderId: string | number) => {
    navigate(`/documents/folder/${folderId}`, {
      state: {
        folderName: leftSideData.find(i => i.id === 'Custom Folders')?.items?.find(c => c.id === folderId)?.text,
      },
    });
  };

  const handleCustomFoldersAction = () => setOpenModal(true);
  const closeModal = () => setOpenModal(false);

  const [leftSideData, setLeftSideData] = useState<LeftSideSection[]>([
    {
      id: 'Custom Folders',
      title: 'Custom Folders',
      action: handleCustomFoldersAction,
      actionPlaceholder: <IconPlus />,
      actionType: 'button',
      items: null,
      itemsAction: handleFolderCardOnClick,
    },
    {
      id: 'File Type',
      title: 'File Type',
      action: () => {},
      actionPlaceholder: 'view all',
      actionType: 'link',
      items: [
        { id: 'Fund ILPA', icon: <IconPaper />, text: 'Fund ILPA' },
        { id: 'NDA’s', icon: <IconPaper />, text: 'NDA’s' },
        { id: 'FIP File', icon: <IconPaper />, text: 'FIP File' },
        {
          id: 'Asset Summary Report',
          icon: <IconPaper />,
          text: 'Asset Summary Report',
        },
      ],
      itemsAction: null,
    },
    {
      id: 'Starred Funds',
      title: 'Starred Funds',
      actionType: 'none',
      items: null,
      itemsAction: handleStarredFundOnClick,
    },
  ]);

  const [latestDocuments, setLatestDocuments] = useState<DocumentData[] | null>(null);
  const [openModal, setOpenModal] = useState(false);

  //TODO: Move request to DocumentsComponent
  const { data: funds, isLoading } = useFunds();

  const fundFolder: FolderData[] = useMemo(
    () =>
      funds
        ? funds?.map(f => ({
            id: f.id,
            title: f.name,
            subtitle: `${f.fundAssets.length} assets`,
          }))
        : [],
    [funds],
  );

  useEffect(() => {
    const fetchLatestDocuments = async () => {
      try {
        const response = await getLatestDocuments('fund');

        if (response.data.data) {
          let latestDocumentsLocal: DocumentData[] = [];
          // TODO: get type from API
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          latestDocumentsLocal = response.data.data.map((doc: any) => createNonExpandableDocumentData(doc));
          // Sorting documents array by Date
          latestDocumentsLocal.sort(function (a: DocumentData, b: DocumentData) {
            return b.date.getTime() - a.date.getTime();
          });
          setLatestDocuments(latestDocumentsLocal);
        }
      } catch (e) {
        setLatestDocuments([]);
      }
    };

    const fetchStarredFunds = async () => {
      const leftSideDataLocal = leftSideData.slice();
      const starredFundsLocal = leftSideDataLocal.find(i => i.id === 'Starred Funds');

      try {
        const response = await getStarredFunds();

        if (response.data.data) {
          // Update the starred funds data on the leftSideData variable.
          const starredFundsItemsLocal: LeftSideSectionListItem[] = [];
          // TODO: get type from API
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          response.data.data.forEach((f: any) => {
            starredFundsItemsLocal.push({
              id: f.fund.id,
              icon: <IconFolder />,
              text: f.fund.name,
            });
          });

          if (starredFundsLocal) {
            starredFundsLocal.items = starredFundsItemsLocal;
            setLeftSideData(leftSideDataLocal);
          }
        }
      } catch (e) {
        if (starredFundsLocal) {
          starredFundsLocal.items = [];
          setLeftSideData(leftSideDataLocal);
        }
      }
    };

    dispatch(ShowLoadingAction());
    fetchLatestDocuments();
    fetchDocumentsFolders();
    fetchStarredFunds();
    dispatch(HideLoadingAction());
  }, []);

  if (isLoading) return <Loader />;

  return (
    <>
      {openModal && (
        <CreateFolderModalContainer
          isVisible={openModal}
          existentFolders={createFolderExistentFolders}
          closeModal={closeModal}
          handleOnSubmit={handleCreateFolderOnSubmit}
        />
      )}
      <DocumentsComponent
        leftSideData={leftSideData}
        fundFolder={fundFolder}
        documents={latestDocuments}
        handleStarredFundOnClick={handleStarredFundOnClick}
      />
    </>
  );
};

export default DocumentsContainer;
