import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Button } from '@mui/material';

import { IconButton, TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';

import FormLabel from '@mui/material/FormLabel';

import { useForm, Controller } from 'react-hook-form';

import { ChangePasswordPayload } from '../../../models/authModels';
import { passwordValidator } from '../../reusables/form-validators/formValidators';
import InputAdornment from '@mui/material/InputAdornment';

import IconInfo from '../../icons/IconInfo';
import { IconClose } from '../../icons/IconClose';
import Popover from '@mui/material/Popover';
import IconEyeOff from '../../icons/IconEyeOff';
import IconEye from '../../icons/IconEye';
const ChangePasswordModalComponent = ({
  isVisible,
  closeModal,
  submitHandler,
  resetForm,
  visible,
}: ChangePasswordModalComponentProps) => {
  const { handleSubmit, control, reset } = useForm<ChangePasswordPayload>({
    defaultValues: {
      CurrentPassword: '',
      NewPassword: '',
    },
  });
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const onSubmit = (data: ChangePasswordPayload) => {
    setButtonDisabled(true);
    setTimeout(() => {
      setButtonDisabled(false);
    }, 1000);
    submitHandler(data);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const showCurrentPasswordEvent = () => {
    setShowCurrentPassword(showPassword => !showPassword);
  };

  const showNewPasswordEvent = () => {
    setShowNewPassword(showPassword => !showPassword);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (resetForm) {
      reset();
      closeModal();
    }
  }, [resetForm]);
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={isVisible}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className=""
      onClose={(event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
          return;
        }
      }}
    >
      <DialogTitle id="alert-dialog-title">Change Password</DialogTitle>
      <DialogContent>
        <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <div className="from-row theam-black">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <FormControl fullWidth variant="outlined" className="formControl">
                <FormLabel>
                  Current Password <span className="require">*</span>
                </FormLabel>
                <Controller
                  name="CurrentPassword"
                  control={control}
                  rules={{
                    required: 'Current password field is required',
                    pattern: passwordValidator,
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      required
                      onChange={event => {
                        field.onChange(event);
                      }}
                      error={!!fieldState.error}
                      helperText={fieldState.error ? fieldState.error.message : ''}
                      type={showCurrentPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={showCurrentPasswordEvent}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                              className="color-btn"
                            >
                              {showCurrentPassword ? <IconEyeOff /> : <IconEye />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <FormControl fullWidth variant="outlined" className="formControl">
                <FormLabel>
                  New Password <span className="require">*</span>
                </FormLabel>
                <Controller
                  name="NewPassword"
                  control={control}
                  rules={{
                    required: 'New password field is required',
                    pattern: passwordValidator,
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      required
                      onChange={event => {
                        field.onChange(event);
                      }}
                      error={!!fieldState.error}
                      helperText={fieldState.error ? fieldState.error.message : ''}
                      type={showNewPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <>
                            <InputAdornment position="end">
                              <IconButton
                                onClick={showNewPasswordEvent}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                className="color-btn"
                              >
                                {showNewPassword ? <IconEyeOff /> : <IconEye />}
                              </IconButton>
                            </InputAdornment>
                            <InputAdornment position="end">
                              <IconButton edge="end" aria-describedby={id} onClick={handleClick} className="color-btn">
                                <IconInfo />
                              </IconButton>
                            </InputAdornment>
                          </>
                        ),
                      }}
                    />
                  )}
                />
                <Popover
                  className="popup-content"
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <div className="pass-info-text">
                    <div className="d-flex align-items-center">
                      <p className="flex-grow-1 mb-0">Rules:</p>
                      <IconButton onClick={handleClose}>
                        <IconClose />
                      </IconButton>
                    </div>
                    <ul>
                      <li>(1) Min 8 characters</li>
                      <li>(2) Max 20 characters</li>
                      <li>
                        (3) Should be a combination of at least one upper, one lower, one number and one special
                        character
                      </li>
                    </ul>
                  </div>
                </Popover>
              </FormControl>
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12 mt-1">
              <div className="filter-btn justify-content-center">
                <Button className="mr-1" type="button" variant="outlined" onClick={closeModal}>
                  Close
                </Button>
                <Button type="submit" variant="contained" disabled={buttonDisabled || visible}>
                  Save
                </Button>
              </div>
            </div>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

interface ChangePasswordModalComponentProps {
  isVisible: boolean;
  closeModal: () => void;
  submitHandler: (data: ChangePasswordPayload) => void;
  resetForm: boolean;
  visible: boolean;
}
export default ChangePasswordModalComponent;
