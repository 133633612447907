import { Breadcrumbs, Typography } from 'src/components/common';
import FolderCard from 'src/components/FolderCard';
import { DocumentData } from 'src/components/documents/document-card/document-card-helpers';
import { Link } from 'react-router-dom';
import { SearchFilterBarContainer } from 'src/components/common/SearchFilterBarContainer';
import { SearchComponent } from 'src/components/common/Search';
import { Fund, FundAsset } from 'src/types/Funds';
import { FoldersAndDocuments } from 'src/components/documents/FoldersAndDocuments/FoldersAndDocuments';
import { UploadDocument } from './components/UploadDocument';
import { Folder } from 'src/types/Documents';

interface DocumentsFundDetailComponentProps {
  fund?: Fund;
  assets: FundAsset[];
  documents: DocumentData[];
  folders: Folder[];
  numberOfDocuments: number;
  handleInvestmentSearch: (payload: string) => void;
  handleFolderCardOnClick: (investmentId: string | number) => void;
  handleDeleteOnClick: (documentId: number, documentName: string) => Promise<void>;
  openCreateFolderModal: () => void;
  openUpdateFolderModal: (folderId: number, oldFolderName: string) => void;
}

const DocumentsFundDetailComponent = ({
  fund,
  assets,
  numberOfDocuments,
  handleInvestmentSearch,
  documents,
  folders,
  handleFolderCardOnClick,
  handleDeleteOnClick,
  openCreateFolderModal,
  openUpdateFolderModal,
}: DocumentsFundDetailComponentProps) => {
  const renderBreadcrumb = () => (
    <Breadcrumbs className="breadcrumb mb-15">
      {[
        <Link key="documents" color="inherit" to="/documents">
          Documents
        </Link>,
        <Typography key="fund">{fund?.name}</Typography>,
      ]}
    </Breadcrumbs>
  );

  const renderTitleAndSubtitle = () => (
    <div className="d-flex">
      <div className="title-and-subtitle-section">
        <h2>{fund?.name}</h2>
        <h4>{numberOfDocuments} Fund Level Documents</h4>
      </div>
      <div style={{ marginLeft: 'auto' }}>
        <UploadDocument />
      </div>
    </div>
  );

  return (
    <>
      {renderBreadcrumb()}
      {renderTitleAndSubtitle()}

      <div className="documents-details-page-section no-margin-top">
        <div className="documents-details-page-section-header">
          <h2>Assets</h2>
          {assets && (
            <div className="documents-details-page-section-header-actions">
              <SearchFilterBarContainer
                searchComponent={
                  <SearchComponent
                    onSearch={handleInvestmentSearch}
                    initialSearchValue=""
                    placeholder="Search by name"
                  />
                }
              />
            </div>
          )}
        </div>
        <div className="documents-details-page-section-body-folders">
          {assets.length > 0 ? (
            assets.map(({ asset }) => (
              <FolderCard
                key={asset.id}
                type="asset"
                onClick={() => handleFolderCardOnClick(asset.id)}
                information={{
                  id: asset.id,
                  title: asset.Title,
                  // TODO: add documentsCount to asset type
                  subtitle: `${asset.documentsCount ?? 0} documents`,
                }}
              />
            ))
          ) : (
            <p>There are no assets.</p>
          )}
        </div>
      </div>

      <FoldersAndDocuments
        documents={documents}
        folders={folders}
        handleAddFolderButtonClick={openCreateFolderModal}
        handleEditFolderButtonClick={openUpdateFolderModal}
        handleDeleteDocumentClick={handleDeleteOnClick}
      />
    </>
  );
};

export default DocumentsFundDetailComponent;
