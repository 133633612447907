import { Component, ReactNode } from 'react';
import ExtranceLogo from 'src/assets/images/logo.svg';
import ErrorImg from 'src/assets/images/error.svg';
import Button from '@mui/material/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

interface Props {
  children?: ReactNode;
}

interface State {
  error: string;
}

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { error: '' };
  }

  componentDidCatch(error: Error) {
    console.error('error boundary', error);
    this.setState({ error: `${error.name}: ${error.message}` });
  }

  refreshPage() {
    window.location.reload();
  }

  render() {
    const { error } = this.state;
    if (error) {
      return (
        <>
          <header className="others-header-wrap">
            <div className="others-wrap">
              <div className="others-logo">
                <img alt="logo" src={ExtranceLogo} />
              </div>
            </div>
          </header>
          <div className="others-body-content error-wrap">
            <div className="error-content">
              <img alt="logo" src={ErrorImg} />
              <h2>Something went wrong</h2>
              <p>Please try again.</p>
              <Button
                disableElevation
                onClick={() => this.refreshPage()}
                endIcon={<ArrowForwardIcon className="arrow-icon" />}
                className="view-details-items"
              >
                <div>
                  <h4>Reload the page</h4>
                </div>
              </Button>
            </div>
          </div>
        </>
      );
    } else {
      return <>{this.props.children}</>;
    }
  }
}
