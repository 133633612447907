import React, { useState } from 'react';
import { connect } from 'react-redux';
import { UserListMetaData } from '../../../models/frontendUserModels';
import { SnackbarPayload } from '../../../models/snackbarModels';
import { ShowSnackbarAction } from '../../../redux/actions/snackbarActions';
import NdaApproveComponent from './ndaApproveComponent';
import { RequestError } from 'src/models/supportRequestModels';
import { Fund } from 'src/types/Funds';

const NdaAproveContainer = ({
  closeModal,
  isVisible,
  loadNdaData,
  ShowSnackbarAction,
  agreementDocs,
}: // onSubmit,
// setType,
// type,
// setFreq,
// freq,
NdaAproveContainerProps) => {
  const initialMetaObject = { total: 0, page: 0, limit: 20, count: 0 };
  const [page, setPage] = React.useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [metaData, setMetaData] = useState<UserListMetaData>(initialMetaObject);
  const [limit, setLimit] = useState(10);
  const handlePageSizeChange = (pageSize: number) => {
    setPage(0);
    setLimit(pageSize);
  };

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleError = (error: RequestError) => {
    ShowSnackbarAction({
      message:
        error?.response?.data && error?.response?.data?.message
          ? error?.response?.data?.message
          : 'Something went wrong',
      severity: 'error',
    });
  };

  return (
    <NdaApproveComponent
      closeModal={closeModal}
      isVisible={isVisible}
      handlePageSizeChange={handlePageSizeChange}
      handleChangePage={handleChangePage}
      metaData={metaData}
      page={page}
      limit={limit}
      ndaList={agreementDocs}
      handleError={handleError}
      loadNdaData={loadNdaData}
      //onSubmit={onSubmit}
      //setType={setType}
      //type={docType}
      //setFreq={setFreq}
      //freq={freq}
    />
  );
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {
  ShowSnackbarAction,
};

interface NdaAproveContainerProps {
  isVisible: boolean;
  closeModal: () => void;
  loadNdaData: () => void;
  fund: Fund | null | undefined;
  ShowSnackbarAction: (payload: SnackbarPayload) => void;
  // TODO: get type from API
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  agreementDocs: any[];
}

export default connect(mapStateToProps, mapDispatchToProps)(NdaAproveContainer);
