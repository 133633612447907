import { Stack, Typography } from 'src/components/common';
import { DistributeStepForm } from './DistributeStep.form';

export const DistributeStep = () => {
  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="column">
        <Typography variant="dialogTitle">Trigger capital distribution</Typography>
        <Typography variant="dialogContentText">
          Distribute capital among the investments stakeholders based on the waterfall model
        </Typography>
      </Stack>

      <DistributeStepForm />
    </Stack>
  );
};
