import { PromoteStructureLevelDTO, PromoteStructureDTO } from 'src/types/PromoteStructure';

export type WaterfallPreviewGraphData = {
  id: number;
  levelName: string;
  total: number;
  distribution: number;
  capitalToDistribute?: number;
  gpSplit: number;
  lpSplit: number;
  irrHurdle: number;
  sortOrder?: number;
};

const getDistribution = (distribution: number, total: number) => {
  const result = distribution <= total ? distribution : total;
  if (result <= 0) {
    return 0;
  }
  return result;
};

export const getWaterfallGraphData = (data: PromoteStructureDTO): WaterfallPreviewGraphData[] => {
  const { totalInvested, totalProjectedDistribution, promoteStructureLevelList } = data;
  if (!totalInvested || !totalProjectedDistribution || !promoteStructureLevelList) {
    return [];
  }

  const getRequestedSum = (levelIrrHurdle: number) => {
    return (totalInvested / 100) * levelIrrHurdle;
  };

  const list = promoteStructureLevelList.slice();
  const maxAvailableRequestedSum = totalProjectedDistribution - totalInvested;
  const tierWithBiggestIrrIndex = list.findIndex(tier => {
    return getRequestedSum(tier.irrHurdle) > maxAvailableRequestedSum;
  });

  let peviousRequestedSum = 0;
  let cashBalance = totalProjectedDistribution;
  let waterfallIsCrashed = false;
  //TODO: Add reality distribution data
  let distributedCashBalance = 108000;

  return promoteStructureLevelList
    .sort((a: PromoteStructureLevelDTO, b: PromoteStructureLevelDTO) => a.sortOrder - b.sortOrder)
    .map((level: PromoteStructureLevelDTO, index: number, arr) => {
      if (index === 0) {
        const distribution = getDistribution(distributedCashBalance, totalInvested);
        cashBalance -= totalInvested;
        distributedCashBalance -= totalInvested;

        if (index === tierWithBiggestIrrIndex - 1) {
          waterfallIsCrashed = true;

          return {
            ...level,
            total: totalProjectedDistribution,
            distribution: getDistribution(distributedCashBalance, totalProjectedDistribution),
          };
        }

        return {
          ...level,
          total: totalInvested,
          distribution,
        };
      } else if (index === 1) {
        if (waterfallIsCrashed) {
          return {
            ...level,
            total: 0,
            distribution: 0,
          };
        }

        const requestedSum = getRequestedSum(level.irrHurdle);
        const distribution = getDistribution(distributedCashBalance, requestedSum);

        if (index === tierWithBiggestIrrIndex - 1) {
          waterfallIsCrashed = true;
          const total = requestedSum + (cashBalance - requestedSum);
          const distribution = getDistribution(distributedCashBalance, total);

          return {
            ...level,
            total,
            distribution,
          };
        }

        peviousRequestedSum = requestedSum;
        cashBalance -= requestedSum;
        distributedCashBalance -= requestedSum;

        return {
          ...level,
          total: requestedSum,
          distribution,
        };
      } else {
        if (waterfallIsCrashed) {
          return {
            ...level,
            total: 0,
            distribution: 0,
          };
        }

        let total = 0;

        if (level.irrHurdle) {
          const requestedSum = getRequestedSum(level.irrHurdle);

          if (index === tierWithBiggestIrrIndex - 1) {
            waterfallIsCrashed = true;
            const total = requestedSum + (cashBalance - requestedSum);
            const distribution = getDistribution(distributedCashBalance, total);

            return {
              ...level,
              total,
              distribution,
            };
          }

          total = requestedSum - peviousRequestedSum;
          if (total < 0) {
            total = 0;
          }

          peviousRequestedSum = requestedSum;
          cashBalance -= total;
        } else {
          total = cashBalance;
        }

        if (index === arr.length - 1) {
          return {
            ...level,
            total,
            distribution: distributedCashBalance >= 0 ? distributedCashBalance : 0,
          };
        }

        const distribution = getDistribution(distributedCashBalance, total);
        distributedCashBalance -= total;

        return {
          ...level,
          total,
          distribution,
        };
      }
    });
};

export const addCapitalToDistributeToWaterfallGraphData = (
  capitalToDistribute: number,
  graphData: WaterfallPreviewGraphData[],
) => {
  let capitalToDistributeCashBalance = capitalToDistribute;
  let isDistributeStop = false;

  return graphData.map((graph, index) => {
    const { distribution, total } = graph;

    if (isDistributeStop) {
      return graph;
    }

    if (index === graphData.length - 1) {
      return {
        ...graph,
        capitalToDistribute: capitalToDistributeCashBalance,
      };
    }

    if (distribution < total) {
      if (capitalToDistributeCashBalance + distribution <= total) {
        isDistributeStop = true;

        return {
          ...graph,
          capitalToDistribute: capitalToDistributeCashBalance,
        };
      }

      capitalToDistributeCashBalance -= total - distribution;

      return {
        ...graph,
        capitalToDistribute: total - distribution,
      };
    }

    return graph;
  });
};
