import { RoleById } from 'src/types/Roles';

export const getSlugsWithPrivilege = (privileges: RoleById['privilege']) => {
  const slugs: string[] = [];

  privileges.forEach(data => {
    if (data.has_privilege === 1) {
      slugs.push(data.Slug);
    }

    data.childPrivilege.forEach(child => {
      if (child?.has_privilege === 1) {
        slugs.push(child.Slug);
      }
    });
  });

  return slugs;
};
