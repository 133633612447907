import { ReactChild, useMemo } from 'react';
import { Box, Stack, Accordion, AccordionSummary, AccordionDetails, Typography } from 'src/components/common';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button } from '@mui/material';
import { WaterfallPreview } from 'src/components/governance/WaterfallPreview/WaterfallPreview';
import { useDistributeCapitalContext } from '../../DistributeCapital.context';
import { usePromoteStructure } from 'src/hooks/react-query/funds';
import { getDistributionPaymentsBreakdownData } from './FinalReviewStep.helpers';
import { renderCurrency } from 'src/views/utils';
import {
  getWaterfallGraphData,
  addCapitalToDistributeToWaterfallGraphData,
} from 'src/components/governance/WaterfallPreview/WaterfallPreview.helpers';
import { WaterfallPreviewGraphData } from 'src/components/governance/WaterfallPreview/WaterfallPreview.helpers';
import { Loader } from 'src/components/common/Loader';

const ContentBox = ({ children }: { children: ReactChild }) => (
  <Box padding="24px" borderRadius="8px" border="1px solid #E1E1E1">
    {children}
  </Box>
);

export const FinalReviewStep = () => {
  const { fundData, capitalToDistribute, onClose } = useDistributeCapitalContext();
  const { data: promoteStructure, isLoading: isPromoteStructureLoading } = usePromoteStructure(fundData!.id);

  const waterfallGraphsData = useMemo(() => {
    if (!promoteStructure) return [];

    let result = getWaterfallGraphData(promoteStructure);

    if (capitalToDistribute) {
      result = addCapitalToDistributeToWaterfallGraphData(capitalToDistribute, result) as WaterfallPreviewGraphData[];
    }

    return result;
  }, [promoteStructure, capitalToDistribute]);

  const { gpAmount, lpAmount } = useMemo(() => {
    if (waterfallGraphsData) {
      return getDistributionPaymentsBreakdownData({
        waterfallGraphsData,
      });
    }

    return { gpAmount: 0, lpAmount: 0 };
  }, [waterfallGraphsData]);

  if (isPromoteStructureLoading) {
    return <Loader />;
  }

  return (
    <Stack direction="column" spacing={2}>
      <Box>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Stack direction="column" spacing={0.5}>
              <Typography variant="dialogTitle">Waterfall progress</Typography>
              <Typography variant="dialogContentText">Waterfall status update</Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <ContentBox>
              <WaterfallPreview graphsData={waterfallGraphsData} />
            </ContentBox>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Stack direction="column" spacing={0.5}>
              <Typography variant="dialogTitle">Distribution payments breakdown</Typography>
              <Typography variant="dialogContentText">
                Extensive breakdown of distibution payments allocation
              </Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <ContentBox>
              <Stack direction="column" spacing={0.5}>
                <Typography variant="s16_lh24_b">
                  GP amount:
                  <Box component="span" fontWeight={400}>
                    {' ' + renderCurrency(gpAmount)}
                  </Box>
                </Typography>

                <Typography variant="s16_lh24_b">
                  LP amount:
                  <Box component="span" fontWeight={400}>
                    {' ' + renderCurrency(lpAmount)}
                  </Box>
                </Typography>
              </Stack>
            </ContentBox>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Stack direction="column" spacing={0.5}>
              <Typography variant="dialogTitle">Payment method</Typography>
              <Typography variant="dialogContentText">
                Details about how the distribution is going to be funded
              </Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <ContentBox>Content</ContentBox>
          </AccordionDetails>
        </Accordion>
      </Box>

      <Button
        variant="contained"
        size="large"
        sx={{
          width: 'max-content',
          alignSelf: 'flex-end',
        }}
        onClick={onClose}
      >
        Trigger capital distribution
      </Button>
    </Stack>
  );
};
