import { AddBankAccountPayload } from '../models/gpBankingModel';
import ServiceClient from './serviceClientConfig';

const serviceClient = new ServiceClient({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export const addBankAccount = (payload: AddBankAccountPayload) => {
  // cleaning unused fieldss
  Object.keys(payload).forEach(key => {
    const keyName = key as keyof typeof payload;
    if (payload[keyName] === '' || payload[keyName] === undefined) {
      delete payload[keyName];
    }
  });
  //payload.AccountHolderName = "AccountHolderName";
  payload.ConfirmAccountNumber = payload.AccountNumber;
  //return serviceClient.post("banks", payload)
  //TODO:verificar esto
  if (payload.SponsorFundId) {
    return serviceClient.post('banks/sponsor', payload);
  }
  return serviceClient.post('banks', payload);
};

export const getValidatedFundBankAccounts = (fundId: number) => {
  const url = `banks/business/list?level=Fund&id=${fundId}&verified=true`;
  return serviceClient.get(url);
};

export const postVerifyAccount = (accountId: number, code: string) => {
  const url = `banks/account/verify`;
  return serviceClient.post(url, {
    accountId: accountId,
    code: code,
  });
};
