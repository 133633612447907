import { RefObject, useEffect, useRef, useState } from 'react';
import { Bar, CartesianGrid, BarChart as Chart, Tooltip, XAxis, YAxis } from 'recharts';
import styles from './styles.module.scss';

interface BarChartMargin {
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
}

interface BarChartProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  chartData: any[];
  height?: number;
  barSize?: number;
  strokeDasharray?: string;
  margin?: BarChartMargin;
  tooltipFormatter?: (data: number) => void;
}

const BarChart = ({
  chartData,
  height = 210,
  barSize = 40,
  strokeDasharray = '0',
  margin,
  tooltipFormatter,
}: BarChartProps) => {
  const [barWidth, setBarWidth] = useState(0);
  const chartContainerRef: RefObject<HTMLDivElement> = useRef(null);

  useEffect(() => {
    const updateWidth = () => {
      if (chartContainerRef.current) {
        setBarWidth(chartContainerRef.current.offsetWidth);
      }
    };

    updateWidth();

    window.addEventListener('resize', updateWidth);

    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  const tickFormatter = (value: number) => {
    return new Intl.NumberFormat('en', {
      notation: 'compact',
      compactDisplay: 'short',
    }).format(value);
  };

  return (
    <div ref={chartContainerRef} className={styles['bar-chart-container']}>
      <Chart data={chartData} width={barWidth} barSize={5} height={height} margin={margin}>
        <CartesianGrid strokeDasharray={strokeDasharray} vertical={false} />
        <XAxis dataKey="Period" tickLine={false} interval={0} fontSize={12} />
        <YAxis
          className={styles['y-axis']}
          tickLine={false}
          // automatically abbreviates large number values
          tickFormatter={tickFormatter}
        />
        <Tooltip
          labelClassName={styles['tooltip-label']}
          contentStyle={{ borderRadius: 4, borderColor: 'grey' }}
          cursor={{ fill: '#297c803d' }}
          formatter={tooltipFormatter}
        />
        <Bar dataKey="Value" fill="#16575B" barSize={barSize} />
      </Chart>
    </div>
  );
};

export default BarChart;
