import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'src/context/ShackbarContext';
import { changeUserStatus } from 'src/services/adminUserServices';
import { UserStatusPayload } from 'src/models/adminUserModels';
import { ApiError } from 'src/types/api';

export const useUserStatus = (...cbs: Array<() => void>) => {
  const queryClient = useQueryClient();

  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: (userData: UserStatusPayload) => changeUserStatus(userData),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['frontendUsers'] });

      showSuccessSnackbar('User`s status have been changed');
      cbs.forEach(cb => cb());
    },
    onError: e => {
      showErrorSnackbar((e as AxiosError<ApiError>).response?.data.message);
    },
  });
};
