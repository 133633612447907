import React from 'react';

const IconPlus = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.00065 6.33337H6.33398V1.00004C6.33398 0.82323 6.40422 0.65366 6.52925 0.528636C6.65427 0.403612 6.82384 0.333374 7.00065 0.333374C7.17746 0.333374 7.34703 0.403612 7.47205 0.528636C7.59708 0.65366 7.66732 0.82323 7.66732 1.00004V6.33337H13.0007C13.1775 6.33337 13.347 6.40361 13.4721 6.52864C13.5971 6.65366 13.6673 6.82323 13.6673 7.00004C13.6673 7.17685 13.5971 7.34642 13.4721 7.47144C13.347 7.59647 13.1775 7.66671 13.0007 7.66671H7.66732V13C7.66732 13.1769 7.59708 13.3464 7.47205 13.4714C7.34703 13.5965 7.17746 13.6667 7.00065 13.6667C6.82384 13.6667 6.65427 13.5965 6.52925 13.4714C6.40422 13.3464 6.33398 13.1769 6.33398 13V7.66671H1.00065C0.82384 7.66671 0.654271 7.59647 0.529246 7.47144C0.404222 7.34642 0.333984 7.17685 0.333984 7.00004C0.333984 6.82323 0.404222 6.65366 0.529246 6.52864C0.654271 6.40361 0.82384 6.33337 1.00065 6.33337Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconPlus;
