import React from 'react';
import Button from '@mui/material/Button';
import ExtranceLogo from 'src/assets/images/logo.svg';
import ForbiddenImg from 'src/assets/images/forbidden.svg';
import IconNavArrow from 'src/uiLibrary/icons/IconNavArrow';
import { useAuth } from 'src/context/AuthContext';

export const ForbiddenComponent = () => {
  const { logout } = useAuth();

  const logOut = () => {
    navigator.geolocation.getCurrentPosition(
      location => {
        logout({
          Location: `${location.coords.latitude},${location.coords.longitude}`,
        });
      },
      () => {
        logout({ Location: null });
      },
      {
        timeout: 2000,
      },
    );
  };

  return (
    <>
      <header className="others-header-wrap">
        <div className="others-wrap">
          <div className="others-logo">
            <img alt="logo" src={ExtranceLogo} />
          </div>
          <Button className={'nav-primary'} endIcon={<IconNavArrow />} variant={'text'} onClick={logOut}>
            Logout
          </Button>
        </div>
      </header>
      <div className="others-body-content error-wrap">
        <div className="error-content">
          <img alt="logo" src={ForbiddenImg} />
          <h2>Forbidden</h2>
          <p>We are sorry, but you do not have access to this page or resource. Please try again later.</p>
        </div>
      </div>
    </>
  );
};
