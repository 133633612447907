import { DocumentData } from 'src/components/documents/document-card/document-card-helpers';
import { searchByRegex } from 'src/utils/searchByRegex';

type DocumentsObj = {
  [key: string]: DocumentData[];
};

export const createDocumentsObj = (documents: DocumentData[]) => {
  const documentsObj: DocumentsObj = {};

  documents.forEach(document => {
    if (typeof document.folderId === 'number') {
      if (documentsObj[document.folderId]) {
        documentsObj[document.folderId].push(document);
      } else {
        documentsObj[document.folderId] = [document];
      }
    }
  });

  return documentsObj;
};

export const filterDocumentsBySearch = (documents: DocumentData[], search: string) => {
  if (!search) {
    return documents;
  }

  return documents.filter(document => {
    return searchByRegex(document.name, search);
  });
};

export const filterDocumentsByExtension = (documents: DocumentData[], extension: string) => {
  if (!extension) {
    return documents;
  }

  return documents.filter(document => {
    return document.extension === extension;
  });
};
