import { AxiosResponse } from 'axios';
import { UserStatusPayload } from '../models/adminUserModels';
import ServiceClient from './serviceClientConfig';
import { validateError } from '../utils/validateError';
import { RoleItem } from '../models/rolesModels';
import { UserDetailsData } from '../models/frontendUserModels';
import { UserResponse } from '../types/User';

const serviceClient = new ServiceClient({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export const getUserList = async (
  page: number,
  sortOrderField: 'Name' | 'Role' | 'Account Created' | 'Account Status',
  sortOrderBy: 'asc' | 'desc',
  page_size: number,
  filterStatus?: 1 | 0,
  filterRole?: string,
  userType?: 1 | 2 | 3,
  searchString?: string,
) => {
  let url = `users/user/list?page=${page}&page_size=${page_size}&order_field=${sortOrderField}&order_sort=${sortOrderBy.toUpperCase()}&user_type=${userType}`;

  if (filterStatus === 0) url = url + `&status=Inactive`;
  if (filterStatus === 1) url = url + `&status=active`;

  const params = {
    ...(searchString && { search_string: searchString }),
    ...(filterRole && { role: filterRole }),
    ...(filterStatus === 0 && { status: 'Inactive' }),
    ...(filterStatus === 1 && { status: 'active' }),
  };

  try {
    const response = await serviceClient.get(url, { params });

    return response.data as UserResponse;
  } catch (error) {
    console.error('getUserList error: ', error);
    throw await validateError(error);
  }
};

export const getAllRoleList = async () => {
  const url = `roles/list/all?filter_status=1&order_field=name&order_sort=ASC`;

  try {
    const response = await serviceClient.get(url);
    return response.data as AxiosResponse<RoleItem[]>;
  } catch (error) {
    console.error('getAllRoleList error: ', error);
    throw await validateError(error);
  }
};
export const userSoftDelete = (id?: number) => {
  return serviceClient.delete(`users/delete?id=${id}`);
};

export const createUser = (payload: FormData) => {
  return serviceClient.post('users/gp-user', payload);
};
export const updateUser = (payload: FormData) => {
  return serviceClient.put('users/user/update', payload);
};
export const getUserDetails = async (userId?: string) => {
  const url = `users/user-details/${userId}`;

  try {
    const response = await serviceClient.get(url);

    return response.data as AxiosResponse<UserDetailsData>;
  } catch (error) {
    console.error('getUserDetails error: ', error);
    throw await validateError(error);
  }
};

export const changeUserStatus = (userStatusPayload: UserStatusPayload) => {
  return serviceClient.put(`users/status`, userStatusPayload);
};
