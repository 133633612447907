import React from 'react';
import { createContext, useContext, useRef, FC, ReactNode } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import Alert from '@mui/material/Alert';

type SnackbarContextType = {
  showSuccessSnackbar: (summary?: string) => void;
  showInfoSnackbar: (summary?: string) => void;
  showWarnSnackbar: (summary?: string) => void;
  showErrorSnackbar: (summary?: string) => void;
};

const SnackbarContext = createContext<SnackbarContextType | null>(null);

export const useSnackbar = (): SnackbarContextType => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }
  return context;
};

export const SnackbarProvider: FC<{ children: ReactNode }> = ({ children }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const snackbarRef = useRef<any>(null);
  const [open, setOpen] = React.useState(false);

  const showSuccessSnackbar = (summary?: string) => {
    if (snackbarRef.current) {
      snackbarRef.current.severity = 'success';
      snackbarRef.current.message = summary;
      setOpen(true);
    }
  };

  const showInfoSnackbar = (summary?: string) => {
    if (snackbarRef.current) {
      snackbarRef.current.severity = 'info';
      snackbarRef.current.message = summary;
      setOpen(true);
    }
  };

  const showWarnSnackbar = (summary?: string) => {
    if (snackbarRef.current) {
      snackbarRef.current.severity = 'warning';
      snackbarRef.current.message = summary;
      setOpen(true);
    }
  };

  const showErrorSnackbar = (summary?: string) => {
    if (snackbarRef.current) {
      snackbarRef.current.severity = 'error';
      snackbarRef.current.message = summary;
      setOpen(true);
    }
  };

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <SnackbarContext.Provider
      value={{
        showSuccessSnackbar,
        showInfoSnackbar,
        showWarnSnackbar,
        showErrorSnackbar,
      }}
    >
      <span ref={snackbarRef}>
        {open && (
          <Snackbar
            autoHideDuration={6000}
            open={open}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            TransitionComponent={props => <Slide {...props} direction="left" />}
          >
            <Alert variant="filled" severity={snackbarRef.current?.severity ?? 'info'}>
              {snackbarRef.current?.message ?? 'Something wend wrong'}
            </Alert>
          </Snackbar>
        )}
      </span>

      {children}
    </SnackbarContext.Provider>
  );
};
