type ActionsHubProps = {
  title?: string;
  list?: {
    actionTitle: string;
    icon: React.ReactNode;
    activity: boolean;
    onclick?: () => void;
    disabled?: boolean;
  }[];
  customClasses?: string;
  customStyle?: { [k: string]: string };
};

const ActionsHub = ({ customClasses, customStyle, list = [], title }: ActionsHubProps) => (
  <div style={customStyle} className={`actions-hub-body ${customClasses}`}>
    <div className="actions-header">{title}</div>
    <div className="actions-items">
      {list.map(p => {
        return (
          <div
            className="action-item"
            key={p.actionTitle}
            style={{
              flexBasis: `${(100 / list.length).toFixed(4)}%`,
              backgroundColor: p.disabled ? '#202020' : '',
              cursor: p.disabled ? 'not-allowed' : '',
            }}
            onClick={() => (p.onclick && !p.disabled ? p.onclick() : null)}
          >
            <div className="action-icon-box">
              {p.activity && <div className="action-activity-indicator" />}
              <div className="action-icon">{p.icon}</div>
            </div>
            <div className="action-name" style={{ color: p.disabled ? 'rgba(255,255,255,0.44)' : '' }}>
              {p.actionTitle}
            </div>
          </div>
        );
      })}
    </div>
  </div>
);

export default ActionsHub;
