export const emailValidator = {
  value:
    /^[a-z\d!#$%&'*+/=?^_‘{|}~-]+(?:\.[a-z\d!#$%&'*+/=?^_‘{|}~-]+)*@(?:[a-z\d](?:[a-z\d-]*[a-z\d])?\.)+[a-z\d](?:[a-z\d-]*[a-z\d])?$/,
  message: 'Email is invalid.',
};

export const passwordValidator = {
  value: /^(?=.*?[a-z])(?=.*?\d)(?=.*?[(){}[\]#?!@$%^&*-]).{8,20}$/,
  message: 'Password must contain at least a special character, upper and lower case letters and a digit.',
};

export const numberValidator = {
  value: /^\d+$/,
  message: 'Numbers only.',
};

export const stringValidator = {
  value: /^[a-zA-Z\s]+$/,
  message: 'Alphabets only.',
};

export const alphaNumericValidator = {
  value: /^[a-zA-Z\d\s]+$/,
  message: 'Alpha-numeric characters only.',
};

export const spaceValidator = {
  value: /^\S*$/,
  message: 'Must provide a character',
};

export const expiryDateValidator = {
  value: /^(0[1-9]|1[0-2])\/?(\d{4})$/,
  message: 'Invalid expire date.',
};

export const decimalNumberValidator = {
  value: /^-?\d*(\.\d+)?$/,
  message: 'Numbers only.',
};
