import React from 'react';

const IconCall = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.9997 8.48692V9.99232C11.0003 10.1321 10.9716 10.2704 10.9155 10.3985C10.8594 10.5265 10.7771 10.6414 10.6739 10.7359C10.5707 10.8304 10.4489 10.9023 10.3163 10.9471C10.1836 10.9919 10.0431 11.0085 9.90359 10.9959C8.3564 10.8281 6.87022 10.3005 5.56445 9.4554C4.34961 8.68496 3.31963 7.65703 2.54767 6.44459C1.69793 5.13549 1.16912 3.64504 1.00408 2.09397C0.991516 1.95521 1.00804 1.81536 1.0526 1.68331C1.09716 1.55127 1.16878 1.42994 1.2629 1.32703C1.35703 1.22413 1.47158 1.14191 1.59929 1.08562C1.72699 1.02932 1.86504 1.00018 2.00465 1.00005H3.51304C3.75705 0.997652 3.99361 1.08389 4.17862 1.24269C4.36364 1.40148 4.48448 1.622 4.51863 1.86315C4.5823 2.34491 4.70037 2.81794 4.87059 3.27321C4.93824 3.45281 4.95288 3.64801 4.91278 3.83567C4.87268 4.02332 4.77952 4.19557 4.64433 4.33201L4.00578 4.96929C4.72154 6.22558 5.76379 7.26576 7.02256 7.9801L7.66112 7.34281C7.79782 7.2079 7.97041 7.11492 8.15844 7.0749C8.34647 7.03488 8.54205 7.04949 8.72202 7.117C9.17819 7.28689 9.65216 7.40472 10.1349 7.46826C10.3791 7.50265 10.6022 7.62543 10.7616 7.81325C10.9211 8.00107 11.0058 8.24083 10.9997 8.48692Z"
        stroke="#177785"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconCall;
