import React, { useState } from 'react';
import { RootState } from '../../../../redux/store';
import { connect } from 'react-redux';
import { HideLoadingAction, ShowLoadingAction } from '../../../../redux/actions/loadingActions';
import { ShowSnackbarAction } from '../../../../redux/actions/snackbarActions';
import { SnackbarPayload } from '../../../../models/snackbarModels';
import { addMemberToGroup, getChatUserList } from '../../../../services/chatService';
import AddParticipantComponent from './addParticipantComponent';
import { Conversation } from '@twilio/conversations';
import { UserDetails } from '../../../../models/authModels';

const AddParticipantContainer = ({
  userDetails,
  ShowSnackbarAction,
  ShowLoadingAction,
  HideLoadingAction,
  setOpenModal,
  conversation,
  participantsObj,
  getParticipantList,
}: AddParticipantContainerProps) => {
  // TODO: get type from API
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [userSuggestions, setUserSuggestions] = useState([] as any[]);

  const getUserList = (e: React.SyntheticEvent) => {
    const val = (e.target as HTMLInputElement).value;
    if (val) {
      ShowLoadingAction();
      getChatUserList(val)
        .then(response => {
          HideLoadingAction();
          setUserSuggestions(response.data.data);
        })
        .catch(() => {
          HideLoadingAction();
          ShowSnackbarAction({
            message: 'Error Fetching Suggestions',
            severity: 'error',
          });
        });
    } else {
      setUserSuggestions([]);
    }
  };

  const addToGroup = (identity: string) => {
    ShowLoadingAction();
    if (conversation?.sid) {
      addMemberToGroup({
        groupSid: conversation.sid,
        identity: identity,
      })
        .then(response => {
          HideLoadingAction();
          if (response.data.status === 200) {
            ShowSnackbarAction({
              message: response.data.message,
              severity: 'success',
            });
            getParticipantList();
          } else {
            ShowSnackbarAction({
              message: response.data.message,
              severity: 'error',
            });
          }
        })
        .catch(() => {
          HideLoadingAction();
          ShowSnackbarAction({
            message: 'Member already exists',
            severity: 'error',
          });
        });
    }
  };

  return (
    <AddParticipantComponent
      setOpenModal={setOpenModal}
      userSuggestions={userSuggestions}
      getUserList={getUserList}
      addToGroup={addToGroup}
      userDetails={userDetails}
      participantsObj={participantsObj}
    />
  );
};

interface AddParticipantContainerProps {
  userDetails: UserDetails;
  ShowSnackbarAction: (payload: SnackbarPayload) => void;
  ShowLoadingAction: (payload?: string) => void;
  HideLoadingAction: () => void;
  setOpenModal: (openModal: number) => void;
  conversation?: Conversation;
  // TODO: get type from API
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  participantsObj?: any;
  getParticipantList: () => void;
}

const mapStateToProps = (state: RootState) => {
  return { userDetails: state.auth.userDetails };
};

const mapDispatchToProps = {
  ShowSnackbarAction,
  ShowLoadingAction,
  HideLoadingAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddParticipantContainer);
