export type DocSortOrder = 'asc' | 'desc';
export type DocSortField = 'DurationType' | 'DocType' | 'DocDate';

export interface DocMetaData {
  total: number;
  page: number;
  limit: number;
}

export interface DocumentItem {
  AssetName: string;
  DocName: string;
  Id: number;
  ReportFrequency: string;
  ReportType: string;
  ReportUploadDate: string;
  UploadedBy: string;
  DocLink: string;
  DocType: string;
}
export type DocFilterType =
  | 'Profit & loss statements (P&Ls)'
  | 'Revenue'
  | undefined
  | 'monthly'
  | 'quarterly'
  | 'half yearly'
  | 'yearly'
  | 'all';
export interface DocFilterForm {
  reportFilterType: 'Profit & loss statements (P&Ls)' | 'Revenue' | 'all';
  frequencyFilterType: 'monthly' | 'quarterly' | 'half yearly' | 'yearly' | 'all';
}
export type LpSortOrder = 'asc' | 'desc';
export type LpSortField = 'Name' | 'InvestedAmount' | 'PartnershipSince';

export interface LpMetaData {
  total: number;
  page: number;
  limit: number;
}

export interface LimitedPartnerItem {
  id: number;
  Stack: number;
  InvestedAmount: number;
  PartnershipSince: string;
  Type: string | null;
  userId: number;
  FirstName: string;
  LastName: string;
  Email: string;
  Address: string;
  ProfileImage: string | null;
}

export type TenantSortOrder = 'asc' | 'desc';
export type TenantSortField = 'TenantDate';

export interface TenantItem {
  id: number;
  Name: string;
  TenantDate: string;
  Agreement_up: string;
  LockPeriod: number;
  AgreementType: string;
  IsActive: number;
  IsDeleted: number;
  LastModificationDateTime: string;
  LastModificationUserID: number;
  DeletionDateTime: null | string;
  DeletionUserID: null | number;
}
export interface TenantMetaData {
  total: number;
  page: number;
  limit: number;
}

export interface ActivityItem {
  Id: number;
  Title: string;
  Description: string;
  CreationDateTime: string;
}

export interface IndicatorsData {
  id: number;
  Title: string;
  SubTitle: string;
  Value: number;
  ValueType: KPIValueType;
  Variance: number;
  UnrealizedValue?: number;
  ChartType?: string;
}

export enum KPIValueType {
  PERCENTAGE = 'Percentage',
  CURRENCY = 'Currency',
  NUMBER = 'Number',
  MULTIPLE = 'Multiple',
}

export enum KPIUnitType {
  PERCENTAGE = '%',
  CURRENCY = '$',
  NUMBER = '',
  MULTIPLE = 'x',
}

export interface KpiModalsData extends IndicatorsData {
  // TODO: get type from API
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  chartData: Array<any>;
}

export interface KpiWidgetsData {
  id: number;
  kpi: string;
  // TODO: get type from API
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  chartData: Array<any>;
}
