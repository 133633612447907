import { renderCurrency, renderPercentage } from '../../utils';
import { format } from 'date-fns';

// TODO: get type from API
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateLimitedPartners = (limitedPartners?: any) => {
  if (!limitedPartners) return [];

  // TODO: get type from API
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return limitedPartners?.map(({ username, equity, investedamount, investeddate, commitment, paidamount }: any) => ({
    username,
    investedDate: format(new Date(investeddate), 'yyyy-MM-dd'),
    ownPercentage: renderPercentage(equity),
    contributed: renderCurrency(investedamount),
    committed: renderCurrency(commitment),
    received: renderCurrency(paidamount),
  }));
};
