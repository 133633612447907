import React, { ReactNode } from 'react';
import { Box, Button, FormControl, IconButton, InputAdornment, TextField, Toolbar } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { HeadToolbarButton, SearchForm } from '../../../models/tableModels';
import { Controller, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { RootState } from '../../../redux/store';
import { Clear } from '@mui/icons-material';

const EnhancedTableToolbar = ({
  buttons,
  onSearch,
  placeholder,
  initialSearchValue,
  searchCount,
  loadingVisible,
  isSearchBox,
  children,
}: EnhancedTableToolbarProps) => {
  const { control, handleSubmit, setValue, watch } = useForm<SearchForm>({
    defaultValues: {
      search: initialSearchValue ? initialSearchValue : '',
    },
  });

  const onSubmit = (payload: SearchForm) => {
    onSearch(payload.search);
  };

  const handleMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleClickClear = () => {
    setValue('search', '');
    onSearch('');
  };

  return (
    <Toolbar
      style={{ float: isSearchBox === false ? 'right' : 'none' }}
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      {isSearchBox === false ? (
        ''
      ) : (
        <Box sx={{ flex: '1 1 100%' }} className="table-search-input">
          <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="search"
              control={control}
              render={({ field }) => (
                <>
                  <FormControl variant="outlined" className="formControl">
                    <TextField
                      {...field}
                      variant="outlined"
                      margin="normal"
                      placeholder={placeholder}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="clear search box"
                              onClick={handleClickClear}
                              onMouseDown={handleMouseDown}
                              edge="end"
                              disabled={!watch('search')}
                              className={!watch('search') ? 'transparent-search-clear' : ''}
                            >
                              <Clear />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <IconButton className="search-btn" edge="end" type="submit">
                      <SearchIcon />
                    </IconButton>
                  </FormControl>
                  {initialSearchValue && !loadingVisible ? (
                    <div className="search-msg">
                      <p>{searchCount} Search Results Found</p>
                    </div>
                  ) : (
                    ''
                  )}
                </>
              )}
            />
          </form>
        </Box>
      )}
      {children}
      {buttons &&
        buttons.map(
          (button, index) =>
            !button.hidden && (
              <Button
                className={index === buttons.length - 1 ? '' : 'mr-10'}
                variant={button.variant}
                startIcon={button.startIcon}
                onClick={button.handleClick}
                key={index}
              >
                {button.text}
              </Button>
            ),
        )}
    </Toolbar>
  );
};

interface EnhancedTableToolbarProps {
  buttons?: HeadToolbarButton[];
  onSearch: (searchString: string) => void;
  placeholder: string;
  initialSearchValue?: string;
  searchCount?: number;
  loadingVisible: boolean;
  isSearchBox?: boolean;
  children?: ReactNode;
}

const mapStateToProps = (state: RootState) => {
  return {
    loadingVisible: state.loading.visible,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EnhancedTableToolbar);
