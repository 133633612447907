import { SnackbarPayload } from '../../models/snackbarModels';

export enum SnackbarActionTypes {
  SHOW_SNACKBAR = '[SNACKBAR] Show Snackbar',
  HIDE_SNACKBAR = '[SNACKBAR] Hide Snackbar',
}

export interface SnackbarActions {
  type: SnackbarActionTypes;
  // TODO: get type from API
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
}

export const ShowSnackbarAction = (payload: SnackbarPayload) => {
  return { type: SnackbarActionTypes.SHOW_SNACKBAR, payload: payload };
};

export const HideSnackbarAction = () => {
  return { type: SnackbarActionTypes.HIDE_SNACKBAR };
};
