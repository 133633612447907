import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { getFunds } from 'src/services/fundsService';
import { useSnackbar } from 'src/context/ShackbarContext';
import { QueryKey } from 'src/consts/query';
import { ApiError } from 'src/types/api';

export const useFunds = (tab: 'Fund access' | 'Governance' | 'Banking' = 'Fund access', searchString?: string) => {
  const { showErrorSnackbar } = useSnackbar();

  const fetchFund = async () => {
    try {
      const response = await getFunds(tab, searchString);

      return response.data;
    } catch (e) {
      console.log('useFunds error', e);
      showErrorSnackbar((e as AxiosError<ApiError>).response?.data.message);
      throw new Error((e as AxiosError<ApiError>).response?.data.message);
    }
  };

  return useQuery({
    queryKey: [QueryKey.MyFunds, tab, searchString],
    queryFn: fetchFund,
  });
};
