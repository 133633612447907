import React from 'react';

const IconAddress = () => {
  return (
    <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.89111 6.62793C8.07363 6.62793 9.03564 5.66591 9.03564 4.4834C9.03564 3.30088 8.07363 2.33887 6.89111 2.33887C5.7086 2.33887 4.74658 3.30088 4.74658 4.4834C4.74658 5.66591 5.7086 6.62793 6.89111 6.62793ZM6.89111 3.39355C7.49207 3.39355 7.98096 3.88247 7.98096 4.4834C7.98096 5.08432 7.49207 5.57324 6.89111 5.57324C6.29015 5.57324 5.80127 5.08432 5.80127 4.4834C5.80127 3.88247 6.29015 3.39355 6.89111 3.39355Z"
        fill="#909090"
      />
      <path
        d="M13.7812 7.94531C13.7812 7.07298 13.0716 6.36328 12.1992 6.36328H10.9628C12.2976 3.49088 10.2505 0 6.89108 0C3.54333 0 1.47832 3.48008 2.81928 6.36328H1.58203C0.709699 6.36328 0 7.07298 0 7.94531V16.418C0 17.2903 0.709699 18 1.58203 18H12.1992C13.0716 18 13.7812 17.2903 13.7812 16.418C13.7812 16.2772 13.7812 8.09072 13.7812 7.94531ZM12.7266 14.3822L11.2002 12.8559L12.7266 11.3296V14.3822ZM12.7266 7.94531V9.83802L5.8377 16.7269L2.7065 13.5957L5.55581 10.7464L6.44583 12.1487C6.65262 12.4745 7.12934 12.4749 7.3363 12.1487L10.3389 7.41797H12.1992C12.49 7.41797 12.7266 7.65454 12.7266 7.94531ZM6.89108 1.05469C9.46445 1.05469 11.275 3.86307 9.77189 6.3431L6.89108 10.882C6.61254 10.4431 4.01207 6.34577 4.00936 6.34159C2.5464 4.07827 4.16637 1.05469 6.89108 1.05469ZM1.58203 7.41797H3.44334L4.97668 9.83394L1.05469 13.7559V7.94531C1.05469 7.65454 1.29125 7.41797 1.58203 7.41797ZM1.05469 16.418V15.2475L1.9607 14.3415L4.56458 16.9453H1.58203C1.29125 16.9453 1.05469 16.7087 1.05469 16.418ZM12.1992 16.9453H7.11081L10.4544 13.6017L12.7266 15.8738V16.418C12.7266 16.7087 12.49 16.9453 12.1992 16.9453Z"
        fill="#909090"
      />
    </svg>
  );
};

export default IconAddress;
