export enum CashKpi {
  'CapEx Reserve (TTM)' = 'CapEx Reserve (TTM)',
  'Total Commitment' = 'Total Commitment',
  'Cash' = 'Cash',
  'Total Invested' = 'Total Invested',
}

export enum GovernanceKpi {
  'Current Cost' = 'Current Cost',
  'Multiple (Realizations)' = 'Multiple (Realizations)',
  'Multiple (Total Return)' = 'Multiple (Total Return)',
  'Multiple (Unrealized)' = 'Multiple (Unrealized)',
  'Realized Proceeds' = 'Realized Proceeds',
  'Reported Value' = 'Reported Value',
  'Total Commitment' = 'Total Commitment',
  'Total Invested' = 'Total Invested',
}
