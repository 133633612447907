import ErrorImg from 'src/assets/images/error.svg';

export const QueryError = () => (
  <div className="loader">
    {
      // TODO: add beautifull ui error handler
    }
    <img alt="logo" src={ErrorImg} style={{ height: '100%' }} />
  </div>
);
