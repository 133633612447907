import { useRef, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Loader } from 'src/components/common/Loader';
import { renderCurrency } from 'src/views/utils';
import { EmptyState } from 'src/components/common/EmptyState';
import { calcActiveCapitalCallPercentage } from 'src/views/governance-fund-detail/utils';
import { useActiveCapCall } from 'src/hooks/react-query/governance';

type ActiveCapcallProps = {
  fundName?: string;
};

export const ActiveCapcall = ({ fundName }: ActiveCapcallProps) => {
  const params = useParams();
  const navigate = useNavigate();
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState<string | number>('100%');
  const fundID = Number(params.fundId);

  const { data: activeCapCall, isLoading: activeCapCallLoading } = useActiveCapCall(fundID);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        if (entry.target === containerRef.current) {
          setContainerWidth(entry.contentRect.width);
        }
      }
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
      setContainerWidth(containerRef.current.clientWidth);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };

    // This dependency causes the effect to run when the page reloaded
  }, [activeCapCall]);

  if (activeCapCallLoading) return <Loader />;

  if (!activeCapCall) return <EmptyState label="There is no active capicall call" />;

  const contributed = parseFloat(activeCapCall.InvestedAmount);
  const called = parseFloat(activeCapCall.RequestedAmount);

  const percentage = calcActiveCapitalCallPercentage(contributed, called);

  return (
    <div
      className="active-capcall-container"
      ref={containerRef}
      onClick={() => navigate(`capcall-history/${activeCapCall.id}`, { state: { fundName } })}
    >
      <div className="mask-wrapper" style={{ width: `${percentage}%` }}>
        <div className="negative-container" style={{ width: containerWidth }}>
          <div className="capcall-label">
            <div className="label">Active capital call</div>
            <div className="closing">open until {activeCapCall.ClosingDate}</div>
          </div>
          <div className="completion">
            <div className="percentage">{`${percentage}% collected`}</div>
            <div className="contributed">{`${renderCurrency(contributed)} contributed / ${renderCurrency(called)} called`}</div>
          </div>
        </div>
      </div>
      <div className="capcall-label">
        <div className="label">Active capital call</div>
        <div className="closing">open until {activeCapCall.ClosingDate}</div>
      </div>
      <div className="completion">
        <div className="percentage">{`${percentage}% collected`}</div>
        <div className="contributed">{`${renderCurrency(contributed)} contributed / ${renderCurrency(called)} called`}</div>
      </div>
    </div>
  );
};
