import { Dispatch, SetStateAction, useState } from 'react';

interface UsePaginationReturnedProps {
  page: number;
  pageSize: number;
  count: number;
  total: number;
  setPaginationParams: Dispatch<SetStateAction<PaginationParams>>;
  setActivePage(page: number): void;
  setPagesCount(count: number): void;
  setPageSize(limit: number): void;
  resetPage(): void;
}

export interface PaginationParams {
  total: number;
  page: number;
  pageSize: number;
  count: number;
}

const initialPaginationParams = {
  total: 0,
  page: 0,
  pageSize: 10,
  count: 0,
};

export const usePagination = (): UsePaginationReturnedProps => {
  const [paginationParams, setPaginationParams] = useState<PaginationParams>(initialPaginationParams);

  const { page, pageSize, count, total } = paginationParams;

  const setActivePage = (page: number) => {
    setPaginationParams(prevState => ({
      ...prevState,
      page,
    }));
  };

  const setPagesCount = (count: number) => {
    setPaginationParams(prevState => ({
      ...prevState,
      count,
    }));
  };

  const setPageSize = (pageSize: number) => {
    setPaginationParams(prevState => ({
      ...prevState,
      pageSize,
    }));
  };

  const resetPage = () => setActivePage(0);

  return {
    page,
    pageSize,
    count,
    total,
    setPaginationParams,
    setActivePage,
    setPagesCount,
    setPageSize,
    resetPage,
  };
};
