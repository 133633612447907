import { useQuery } from '@tanstack/react-query';
import { getIndicators } from 'src/services/governanceService';
import { useSnackbar } from 'src/context/ShackbarContext';
import { isValidGovernanceRelevantKpi } from 'src/views/governance-fund-detail/utils';
import { AxiosError } from 'axios';
import { QueryKey } from 'src/consts/query';
import { ApiError } from 'src/types/api';

export const useCashKpi = (fundId: number | undefined) => {
  const { showErrorSnackbar } = useSnackbar();

  return useQuery({
    queryKey: [QueryKey.IndicatorsData, fundId],
    queryFn: async () => {
      if (fundId) {
        try {
          const response = await getIndicators(fundId);
          if (response?.data) {
            const relevantIndicatorsFiltered = response.data.filter((ind: KPI) =>
              isValidGovernanceRelevantKpi(ind.KPI),
            );

            return relevantIndicatorsFiltered;
          }
        } catch (error) {
          showErrorSnackbar((error as AxiosError<ApiError>).response?.data.message);
          console.error('useCashKpi error', error);
          throw new Error((error as AxiosError<ApiError>).response?.data.message);
        }
      }
      showErrorSnackbar('No Fund was provided');
    },
  });
};

// TODO: get type from API
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type KPI = any;
