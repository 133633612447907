import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@mui/material';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './redux/store';
import { SnackbarProvider } from 'src/context/ShackbarContext';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ErrorBoundary } from './components/ErrorBoundary';
import { extranceThemeOptions } from './themes/styles/theme';
import Hotjar from './Hotjar';
import { AuthProvider } from './context/AuthContext';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 2000,
      retry: 3,
      retryDelay: attemptIndex => Math.min(1000 * Math.random() * 2 ** attemptIndex, 30000),
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      refetchOnMount: 'always',
    },
    mutations: {
      retry: 0,
      retryDelay: attemptIndex => Math.min(1000 * Math.random() * 2 ** attemptIndex, 30000),
    },
  },
  queryCache: new QueryCache({
    onError: error => console.error('queryError', error),
  }),
});

ReactDOM.render(
  <React.StrictMode>
    <Hotjar />
    <Provider store={store}>
      <BrowserRouter basename="/">
        <ErrorBoundary>
          <SnackbarProvider>
            <QueryClientProvider client={queryClient}>
              <ThemeProvider theme={extranceThemeOptions}>
                <AuthProvider>
                  <App />
                </AuthProvider>
              </ThemeProvider>
            </QueryClientProvider>
          </SnackbarProvider>
        </ErrorBoundary>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
