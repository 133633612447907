import { useState } from 'react';
import { ListOfLpsSortField } from '../models/governanceModels';
import { SortColumn, SortDirection } from '../components/common/table/types';

interface UseSortingReturnedProps {
  sortField: ListOfLpsSortField;
  sortOrder: SortDirection;

  handleSortChange(sortColumn: SortColumn): void;
}

interface UseSortingProps {
  sortField: ListOfLpsSortField;
  sortOrder: SortDirection;
}

const useSorting = ({
  sortField: initialSortField,
  sortOrder: initialSortOrder,
}: UseSortingProps): UseSortingReturnedProps => {
  const [sortField, setSortField] = useState<ListOfLpsSortField>(initialSortField);
  const [sortOrder, setSortOrder] = useState<SortDirection>(initialSortOrder);

  const handleSortChange = (sortColumn: SortColumn) => {
    if (!sortColumn.id) return;

    if (sortColumn.id === sortField) {
      setSortOrder(sortColumn.direction === SortDirection.DESC ? SortDirection.ASC : SortDirection.DESC);
    } else {
      setSortField(sortColumn.id as ListOfLpsSortField);
      setSortOrder(SortDirection.ASC);
    }
  };

  return {
    sortField,
    sortOrder,
    handleSortChange,
  };
};

export default useSorting;
