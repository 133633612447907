import { format } from 'date-fns';
import {
  OpportunitiesSortOrder,
  OpportunitiesSortField,
  OpportunitiesFilterType,
  MyOrdersSortOrder,
  MyOrdersSortField,
  MyOrdersFilterType,
  MyOrdersFilterStatus,
  MyRequestsSortOrder,
  MyRequestsSortField,
  MyRequestsFilterType,
  InterestReceivedSortOrder,
  InterestReceivedSortField,
  InterestReceivedFilterType,
  ApprovalRequiredSortOrder,
  ApprovalRequiredSortField,
  ApprovalRequiredFilterType,
  PendingPaymentSortField,
  PendingPaymentSortOrder,
  TransactionHistorySortField,
  TransactionHistorySortOrder,
  TransactionHistoryFilterTypeStatus,
  TransactionHistoryFilterTypeTransactionType,
  TransactionHistoryReceivedSortField,
  TransactionHistoryReceivedSortOrder,
  TransactionHistoryReceivedFilterTypeStatus,
  TransactionHistoryReceivedFilterTypeTransactionType,
  PartnershipValuationSortOrder,
  PartnershipValuationSortField,
  PartnershipValuationFilterTypeStatus,
  PartnershipValuationFilterTypePaymentStatus,
  UserDetailsView,
  OpportunityType,
} from '../models/marketPlaceModels';

import ServiceClient from './serviceClientConfig';
import { SupportRequestFilterPayload } from 'src/models/supportRequestModels';
import { validateError } from 'src/utils/validateError';

const serviceClient = new ServiceClient({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export const getOpportunities = (
  page: number,
  limit: number,
  orderField: OpportunitiesSortField,
  orderSort: OpportunitiesSortOrder,
  filterType?: OpportunitiesFilterType,
  searchString?: string,
) => {
  let url = `opportunities?page=${page}&limit=${limit}&order_field=${orderField}&order_sort=${orderSort.toUpperCase()}`;

  if (filterType) {
    url += `&filter_type=${filterType}`;
  }

  if (searchString) {
    url += `&search_string=${searchString}`;
  }
  return serviceClient.get(url);
};

export const getMrktAct = (
  fundId: number | undefined,
  page: number,
  limit: number,
  orderField: OpportunitiesSortField,
  orderSort: OpportunitiesSortOrder,
  filterType?: OpportunitiesFilterType,
  searchString?: string,
) => {
  let url = `fundCap/opportunities?fund=${fundId}&filter_status=Closed&order_field=date&filter_type=Sell`;

  if (filterType) {
    url += `&filter_type=${filterType}`;
  }

  if (searchString) {
    url += `&search_string=${searchString}`;
  }
  return serviceClient.get(url);
};

export const getOp = () => {
  const url = `/fundCap/opportunities`;
  return serviceClient.get(url);
};
export const getOpPagination = (page: number, limit: number) => {
  const url = `/fundCap/opportunities?page=${page}&limit=${limit}`;
  return serviceClient.get(url);
};

export const getBid = () => {
  const url = `/fund-sell-request/bid/list`;
  return serviceClient.get(url);
};

export const getAuctions = () => {
  const url = `/fund-sell-request/bid/auctions`;
  return serviceClient.get(url);
};
export const partnershipRequestDetails = (id: number) => {
  return serviceClient.get(`fundCap/fund-request/${id}`);
};
export const partnershipRequestOrder = (
  id: number,
  data: {
    Type: OpportunityType;
    Stack: null;
    RequestedAmount: string;
  },
) => {
  return serviceClient.post(`partnership-request-order/${id}`, data);
};
export const getOrders = (
  page: number,
  limit: number,
  orderField: MyOrdersSortField,
  orderSort: MyOrdersSortOrder,
  filterType: MyOrdersFilterType,
  filterStatus: MyOrdersFilterStatus,
  searchString?: string,
) => {
  let url = `order-page?page=${page}&limit=${limit}&order_field=${orderField}&order_sort=${orderSort.toUpperCase()}`;

  if (filterType) {
    url += `&filter_type=${filterType}`;
  }

  if (filterStatus) {
    url += `&filter_status=${filterStatus}`;
  }

  if (searchString) {
    url += `&search_string=${searchString}`;
  }
  return serviceClient.get(url);
};

export const partnershipRequestOrderCancel = (id: number) => {
  return serviceClient.delete(`partnership-request-order/${id}`);
};

export const partnershipRequestCancelOrder = (id: number, data: unknown) => {
  return serviceClient.put(`cancel-request-order/${id}`, data);
};

export const getMyRequests = (
  page: number,
  limit: number,
  orderField: MyRequestsSortField,
  orderSort: MyRequestsSortOrder,
  filterType: MyRequestsFilterType,
  searchString?: string,
) => {
  let url = `partnership-request?page=${page}&limit=${limit}&order_field=${orderField}&order_sort=${orderSort.toUpperCase()}`;

  if (filterType) {
    url += `&filter_type=${filterType}`;
  }

  if (searchString) {
    url += `&search_string=${searchString}`;
  }
  return serviceClient.get(url);
};
export const updatePartnershipRequest = (
  id: number,
  data: {
    RequestedAmount: string;
    Description: string;
  },
) => {
  return serviceClient.put(`partnership-request/${id}`, data);
};
export const deletePartnershipRequest = (id: number) => {
  return serviceClient.delete(`partnership-request/${id}`);
};

export const getInterestReceived = (
  page: number,
  limit: number,
  orderField: InterestReceivedSortField,
  orderSort: InterestReceivedSortOrder,
  filterType: InterestReceivedFilterType,
  searchString?: string,
) => {
  let url = `interest-received?Page=${page}&Limit=${limit}&order_field=${orderField}&order_sort=${orderSort.toUpperCase()}`;

  if (filterType) {
    url += `&type=${filterType}`;
  }
  if (searchString) {
    url += `&search_string=${searchString}`;
  }
  return serviceClient.get(url);
};

export const statusInterestReceived = (
  id: number,
  data: {
    Status: 'Rejected' | 'Accepted' | 'Cancel';
    AgreedAmount: number;
  },
) => {
  return serviceClient.patch(`interest-received/${id}`, data);
};
export const getApprovalRequired = (
  page: number,
  limit: number,
  orderField: ApprovalRequiredSortField,
  orderSort: ApprovalRequiredSortOrder,
  filterType: ApprovalRequiredFilterType,
  searchString?: string,
) => {
  let url = `approval-required?Page=${page}&Limit=${limit}&order_field=${orderField}&order_sort=${orderSort.toUpperCase()}`;

  if (filterType) {
    url += `&type=${filterType}`;
  }
  if (searchString) {
    url += `&search_string=${searchString}`;
  }
  return serviceClient.get(url);
};
export const getPartnershipUserDetails = (id: number, userDetailsViewData?: UserDetailsView) => {
  let url = `seller-details/${id}`;
  if (userDetailsViewData) {
    url += `?partnershipId=${userDetailsViewData.partnershipId}&for=${userDetailsViewData.forUser}`;
  }
  return serviceClient.get(url);
};
export const statusApprovalRequired = (
  id: number,
  data: {
    gpApprovalStatus: number | undefined;
  },
) => {
  return serviceClient.patch(`approval-required/${id}`, data);
};

export const getPendingPartnershipPayment = (
  page: number,
  limit: number,
  orderField: PendingPaymentSortField,
  orderSort: PendingPaymentSortOrder,
  searchString?: string,
) => {
  let url = `partnershippayment/pending-partnership-payment?page=${page}&limit=${limit}&order_field=${orderField}&order_sort=${orderSort.toUpperCase()}`;

  if (searchString) {
    url += `&search_string=${searchString}`;
  }
  return serviceClient.get(url);
};

export const makePartnershipPayment = (
  id: number,
  data: {
    PaymentType: 'bank';
    SourceId: number;
    PaymentAmount: string | undefined;
  },
) => {
  return serviceClient.post(`partnershippayment/partnership-payment/${id}`, data);
};

export const partnershipLock = (
  id: number,
  data: {
    softLockStatus: string;
  },
) => {
  return serviceClient.patch(`locking-logic/${id}`, data);
};

export const pendingPartnershipPaymentDetails = (id: number) => {
  return serviceClient.get(`partnershippayment/pending-partnership-payment-details/${id}`);
};

export const partnershipPaymentHistory = (
  page: number,
  limit: number,
  orderField: TransactionHistorySortField,
  orderSort: TransactionHistorySortOrder,
  filterTypeStatus: TransactionHistoryFilterTypeStatus,
  filterTypeTransactionType: TransactionHistoryFilterTypeTransactionType,
  filterTypeDateRange?: SupportRequestFilterPayload['filterTypeDateRange'],
  searchString?: string,
) => {
  let url = `partnershippayment/partnership-payment-history?page=${page}&limit=${limit}&order_field=${orderField}&order_sort=${orderSort.toUpperCase()}`;

  if (filterTypeDateRange) {
    url += `&filter_start_date=${format(
      filterTypeDateRange[0] ?? new Date(),
      'yyyy-MM-dd',
    )}&end_start_date=${format(filterTypeDateRange[1] ?? new Date(), 'yyyy-MM-dd')}`;
  }

  if (filterTypeStatus) {
    url += `&filter_status=${filterTypeStatus}`;
  }

  if (filterTypeTransactionType) {
    url += `&filter_transaction_type=${filterTypeTransactionType === 'Received' ? 'Paid' : 'Sent'}`;
  }

  if (searchString) {
    url += `&search_string=${searchString}`;
  }
  return serviceClient.get(url);
};

export const partnershipPaymentHistoryDetails = (id: number) => {
  return serviceClient.get(`partnershippayment/partnership-payment-details/${id}`);
};

export const partnershipPaymentReceived = (
  page: number,
  limit: number,
  orderField: TransactionHistoryReceivedSortField,
  orderSort: TransactionHistoryReceivedSortOrder,
  filterTypeStatus: TransactionHistoryReceivedFilterTypeStatus,
  filterTypeTransactionType: TransactionHistoryReceivedFilterTypeTransactionType,
  filterTypeDateRange?: SupportRequestFilterPayload['filterTypeDateRange'],
  searchString?: string,
) => {
  let url = `partnershippayment/partnership-payment-receive?page=${page}&limit=${limit}&order_field=${orderField}&order_sort=${orderSort.toUpperCase()}`;

  if (filterTypeDateRange) {
    url += `&filter_start_date=${format(
      filterTypeDateRange[0] ?? new Date(),
      'yyyy-MM-dd',
    )}&end_start_date=${format(filterTypeDateRange[1] ?? new Date(), 'yyyy-MM-dd')}`;
  }

  if (filterTypeStatus) {
    url += `&filter_status=${filterTypeStatus}`;
  }

  if (filterTypeTransactionType) {
    url += `&filter_transaction_type=${filterTypeTransactionType === 'Received' ? 'Paid' : 'Sent'}`;
  }

  if (searchString) {
    url += `&search_string=${searchString}`;
  }
  return serviceClient.get(url);
};
// export const agreementRequestFront = (id: number, data: any) => {
//   return serviceClient.post(`agreementrequest/front/${id}`, data);
// };

export const getPartnershipValuation = (
  page: number,
  limit: number,
  orderField: PartnershipValuationSortField,
  orderSort: PartnershipValuationSortOrder,
  filterTypeStatus: PartnershipValuationFilterTypeStatus,
  filterTypePaymentStatus: PartnershipValuationFilterTypePaymentStatus,
  searchString?: string,
) => {
  let url = `partnershipvaluation?Page=${page}&Limit=${limit}&order_field=${orderField}&order_sort=${orderSort.toUpperCase()}`;

  if (filterTypeStatus) {
    url += `&status=${filterTypeStatus}`;
  }
  if (filterTypePaymentStatus) {
    url += `&PaymentStatus=${filterTypePaymentStatus}`;
  }
  if (searchString) {
    url += `&search_string=${searchString}`;
  }
  return serviceClient.get(url);
};
export const getPartnershipValuationDetails = (id: number) => {
  return serviceClient.get(`partnershipvaluation/${id}`);
};
export const addComment = (data: FormData) => {
  return serviceClient.post('partnershipvaluation/add/comment', data);
};

export const getComments = (id: number, page: number, limit: number) => {
  return serviceClient.get(`partnershipvaluation/comment/list/${id}?Page=${page}&Limit=${limit}`);
};

export const canAcceptRequest = (id: number) => {
  return serviceClient.get(`interest-received/can-accept-request/${id}`);
};

export const canDoPayment = (id: number) => {
  return serviceClient.get(`partnershippayment/can-do-payment/${id}`);
};

export const marketplaceKpi = () => {
  return serviceClient.get(`market-place-kpi`);
};

export const createBid = (opId: number, amount: number) => {
  return serviceClient.post(`fund-sell-request/bid/${opId}`, { bid: amount });
};

export const getAgreementRequest = async (fundId: number) => {
  try {
    const response = await serviceClient.get(`fundagreementrequest?fund=${fundId}`);
    //TODO: add type from codegen
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return response.data as any;
  } catch (error) {
    console.error('getIndicators error: ', error);
    throw await validateError(error);
  }
};

export const postHelloSign = (requestId: string) => {
  return serviceClient.post(`fundagreementrequest/front/${requestId}`, {});
};

export const terminateAnAuction = (id: number) => {
  return serviceClient.get(`fund-sell-request/terminate/${id}`);
};
