import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ShareNDAAgreementComponent from './shareNDAAgreementComponent';
import { SnackbarPayload } from '../../../models/snackbarModels';
import { RootState } from '../../../redux/store';
import { HideLoadingAction, ShowLoadingAction } from '../../../redux/actions/loadingActions';
import { ShowSnackbarAction } from '../../../redux/actions/snackbarActions';
import { getGpPartnershipListing } from '../../../services/partnershipService';
import { partnershipUsers } from '../../../services/ndaAgreementService';
import { UserDetails } from '../../../models/authModels';
import {
  ShareNDAAgreementPayload,
  PartnershipUsersData,
  PartnershipUserPayload,
} from '../../../models/ndaAgreementModels';
import { createNda, sendNdaAgreementRequest } from '../../../services/ndaAgreementService';
import { RequestError } from 'src/models/supportRequestModels';
const ShareNDAAgreementContainer = ({
  closeModal,
  isVisible,
  HideLoadingAction,
  ShowLoadingAction,
  ShowSnackbarAction,
  userDetails,

  visible,
}: ShareNDAAgreementContainerProps) => {
  // TODO: get type from API
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [partnershipList, setPartnershipList] = useState<any>([]);
  const [partnershipUsersData, setPartnershipUsersData] = useState<PartnershipUserPayload[]>();

  const getGpPartnershipList = () => {
    ShowLoadingAction();
    getGpPartnershipListing(1, userDetails.UserId, undefined, undefined, '')
      .then(response => {
        HideLoadingAction();
        if (response.data.status === 200) {
          setPartnershipList(response.data.data.partnerships);
        } else {
          setPartnershipList([]);
        }
      })
      .catch((error: RequestError) => {
        HideLoadingAction();
        setPartnershipList([]);
        ShowSnackbarAction({
          message:
            error?.response?.data && error?.response?.data?.message
              ? error?.response?.data?.message
              : 'Something went wrong',
          severity: 'error',
        });
      });
  };

  const getPartnershipUsersHandler = async (search: string) => {
    if (search.length <= 2) {
      setPartnershipUsersData([]);
      return;
    }
    try {
      ShowLoadingAction();
      const result = await partnershipUsers(userDetails.UserId, search);
      HideLoadingAction();
      if (result.data?.data?.length > 0) {
        setPartnershipUsersData(
          result.data.data.map((user: PartnershipUsersData) => ({
            id: user.userid,
            label: user.username + ' (' + user.email + ') ',
          })),
        );
      } else {
        setPartnershipUsersData([]);
      }
    } catch (error) {
      const e = error as RequestError;
      ShowSnackbarAction({
        message: e?.response?.data?.message ? e?.response?.data?.message : 'Something went wrong',
        severity: 'error',
      });
      HideLoadingAction();
      setPartnershipUsersData([]);
    }
  };

  const shareNDARequestSubmitHandler = async (shareNDARequest: ShareNDAAgreementPayload) => {
    try {
      ShowLoadingAction();
      if (typeof shareNDARequest.user !== 'string') {
        const result = await createNda({
          PartnershipId: shareNDARequest.partnership,
          RequestedUserId: shareNDARequest.user.id,
          UserId: userDetails.UserId,
        });

        if (result.data.data?.id) {
          await sendNdaAgreementRequest(result.data.data.id, {});
        }

        ShowSnackbarAction({
          message: result?.data && result?.data?.message ? result?.data?.message : 'Submit Successfully',
          severity: 'success',
        });
      }
      HideLoadingAction();
      closeModal();
    } catch (error) {
      const e = error as RequestError;
      ShowSnackbarAction({
        message: e?.response?.data?.message ? e?.response?.data?.message : 'Something went wrong',
        severity: 'error',
      });
      HideLoadingAction();
    }
  };

  useEffect(() => {
    if (userDetails) {
      if (userDetails?.UserType === 2) {
        getGpPartnershipList();
      } else if (userDetails?.UserType === 3) {
        getGpPartnershipList();
      }
    }
  }, []);
  return (
    <ShareNDAAgreementComponent
      closeModal={closeModal}
      isVisible={isVisible}
      partnershipList={partnershipList}
      shareNDARequestSubmitHandler={shareNDARequestSubmitHandler}
      visible={visible}
      getPartnershipUsersHandler={getPartnershipUsersHandler}
      partnershipUsersData={partnershipUsersData}
    ></ShareNDAAgreementComponent>
  );
};

interface ShareNDAAgreementContainerProps {
  isVisible: boolean;
  closeModal: () => void;
  HideLoadingAction: () => void;
  ShowLoadingAction: (payload?: string) => void;
  ShowSnackbarAction: (payload: SnackbarPayload) => void;
  visible: boolean;
  userDetails: UserDetails;
}

const mapStateToProps = (state: RootState) => ({
  visible: state.loading.visible,
  userDetails: state.auth.userDetails,
});

const mapDispatchToProps = {
  HideLoadingAction,
  ShowLoadingAction,
  ShowSnackbarAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(ShareNDAAgreementContainer);
