import { Button, IconButton } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import WrapTabs from 'src/components/WrapTabs';
import IconArrowLeft from 'src/uiLibrary/icons/IconArrowLeft';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { Loader } from 'src/components/common/Loader';
import { getFundDetail } from 'src/services/fundsService';
import { Overview } from './components/Overview';
import { Assets } from './components/Assets';
import { QueryError } from 'src/components/common/QueryError';
import IconNavArrow from 'src/uiLibrary/icons/IconNavArrow';
import { Fund, FundAsset } from 'src/types/Funds';
import FundBg from 'src/assets/images/fund_bg.png';
import { QueryKey } from 'src/consts/query';
import { useProtected } from 'src/hooks/useProtected';
import { Permission } from 'src/consts/permissions';

export const FundDetailComponent = () => {
  const params = useParams();
  const navigate = useNavigate();

  const {
    data: fundInfo,
    isLoading,
    isError,
  } = useQuery({
    queryKey: [QueryKey.FundDetailsById, params.fundId],
    queryFn: () => getFundDetail(Number(params.fundId)),
  });

  const fund: Fund = useMemo(() => fundInfo?.data?.fund ?? ({} as Fund), [fundInfo]);
  const assets: FundAsset[] = useMemo(() => fundInfo?.data?.assets ?? [], [fundInfo]);

  const isFundDetailProtected = useProtected({
    permissions: [Permission.Documents],
  });

  const goToDocuments = () => {
    navigate(`/documents/fund/${params.fundId}`);
  };

  if (isLoading) return <Loader />;
  if (isError) return <QueryError />;

  return (
    <>
      <div
        className="image-header-container"
        style={{
          //TODO: get bannerImage for fund
          backgroundImage: `url('${fund?.BannerImage ?? FundBg}')`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      >
        <div className="back-login">
          <div className="back-text" onClick={() => navigate(-1)} style={{ color: 'black' }}>
            <IconButton size="large" component="span">
              <IconArrowLeft stroke="black" />
            </IconButton>
            Back
          </div>
        </div>
      </div>
      <div className="main-content-container">
        <div className="main-body-header-container">
          <div className="main-body-header-data">
            <div className="main-body-header-data-titles d-flex align-items-center justify-content-between">
              <div>
                <div className="header-title">{fund?.name}</div>
                <div className="header-subtitle">Fund View</div>
              </div>

              {isFundDetailProtected && (
                <Button disableElevation onClick={goToDocuments} className="min-width-primary-button">
                  <h4 className="mr-1">Go to Documets </h4> <IconNavArrow />
                </Button>
              )}
            </div>
          </div>
        </div>
        <div className="relative">
          <WrapTabs
            itemList={[
              {
                tabName: 'Overview',
                component: <Overview />,
              },
              {
                tabName: (
                  <div>
                    Assets <span className="counter">{assets.length}</span>
                  </div>
                ),
                component: <Assets assets={assets} fund={fund} />,
              },
            ]}
          />
        </div>
      </div>
    </>
  );
};
