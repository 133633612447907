import { format } from 'date-fns';
import { IndicatorsData, KPIValueType } from 'src/models/partnershipModel';

// Populates the existent indicators cards with fetched data.
// Maps the BE KPI information to FE cards.
export const updateIndicatorInfo = (
  indicatorsArray: IndicatorsData[],
  indicatorName: string,
  // TODO: get type from API
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  kpi: any,
): IndicatorsData[] => {
  const { unit, value, reporting_date } = kpi;

  const indicator = indicatorsArray.find(({ SubTitle }) => SubTitle === indicatorName);

  if (!indicator) return indicatorsArray;

  indicator.Value = unit === KPIValueType.PERCENTAGE ? (value ? value * 100 : 0) : +value || 0;
  indicator.Title = reporting_date ? `As of ${format(new Date(reporting_date), 'MMM dd yyyy')}` : 'No data';

  return indicatorsArray;
};
