import { ExistentFolderData } from '../../../models/documentsModels';
import CreateFolderModalComponent, { CreateFolderForm } from './createFolderModalComponent';

const CreateFolderModalContainer = ({
  isVisible,
  existentFolders,
  closeModal,
  handleOnSubmit,
}: CreateFolderModalContainerProps) => {
  const onSubmit = (formData: CreateFolderForm) => {
    if (!formData.FolderName || formData.FolderName === '') {
      // don't send request
    } else {
      handleOnSubmit(formData.FolderName);
    }
    closeModal();
  };

  return (
    <CreateFolderModalComponent
      isVisible={isVisible}
      existentFoldersData={existentFolders}
      closeModal={closeModal}
      onSubmit={onSubmit}
    />
  );
};

interface CreateFolderModalContainerProps {
  isVisible: boolean;
  existentFolders: ExistentFolderData[];
  closeModal: () => void;
  handleOnSubmit: (folderName: string) => void;
}

export default CreateFolderModalContainer;
