import React, { useEffect } from 'react';
import classNames from 'classnames';
import { ReactComponent as ChevronDown } from 'src/assets/images/icons/chevron-down.svg';
import { ReactComponent as ChevronUp } from 'src/assets/images/icons/chevron-up.svg';
import { Column, SortColumn, SortDirection } from './types';
import styles from './styles.module.scss';

interface TableHeaderProps {
  columns: Column[];
  sortField?: string;
  sortOrder?: SortDirection;
  onSortColumnChange?: (sortColumn: SortColumn) => void;
}

const TableHeader = ({ columns, sortField, sortOrder, onSortColumnChange }: TableHeaderProps) => {
  const [sortColumn, setSortColumn] = React.useState<SortColumn>({
    id: sortField,
    direction: sortOrder,
  });

  const renderSortIcon = (column: Column) => {
    const sortIcons = {
      [SortDirection.ASC]: ChevronUp,
      [SortDirection.DESC]: ChevronDown,
    };

    const Component =
      sortColumn.id !== column.id || sortColumn.direction === undefined ? ChevronUp : sortIcons[sortColumn.direction];

    return <Component fill={sortColumn.id === column.id ? '#8F8F8F' : '#E1E1E1'} />;
  };

  const handleColumnClick = (column: Column) => {
    if (!column.sortable) return;

    if (sortColumn.id !== column.id) {
      return setSortColumn({
        id: column.id || undefined,
        direction: SortDirection.DESC,
      });
    }
    if (sortColumn.direction === SortDirection.DESC) {
      return setSortColumn({
        id: column.id,
        direction: SortDirection.ASC,
      });
    }
    if (sortColumn.direction === SortDirection.ASC) {
      return setSortColumn({
        id: column.id,
        direction: SortDirection.DESC,
      });
    }

    return setSortColumn({
      id: undefined,
      direction: undefined,
    });
  };

  useEffect(() => {
    if (onSortColumnChange) onSortColumnChange(sortColumn);
  }, [onSortColumnChange, sortColumn]);

  return (
    <thead>
      <tr>
        {columns?.map(column => (
          <th
            key={column.id}
            className={classNames(styles.headerCell, {
              [styles.sortable]: column.sortable,
              [styles.active]: sortField === column.id,
            })}
            onClick={() => {
              handleColumnClick(column);
            }}
            style={{ width: column.fixedWidth || 'auto' }}
          >
            <div className={styles.headerCellContent}>
              {column.label && <span>{column.label}</span>}
              {column.sortable && renderSortIcon(column)}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHeader;
