export interface LeftSideSectionListItem {
  id: string;
  icon: JSX.Element;
  text: string;
}

export interface LeftSideSection {
  id: string;
  title: string;
  actionType: 'button' | 'link' | 'none';
  action?: () => void;
  actionPlaceholder?: JSX.Element | string; // Can be a button or a text
  items: LeftSideSectionListItem[] | null;
  itemsAction: ((folderId: string | number) => void) | null;
}

export interface ExistentFolderData {
  id: number;
  name: string;
  numberOfDocs: number;
}

export enum DocType {
  FIPFile = 'FIP File',
  MultiFiles = 'Multifiles',
  PPM = 'Private placement memorandum (PPM)',
  PM = 'Operating memorandum',
  SA = 'Subscription agreements',
  PLS = 'Profit & loss statements (P&Ls)',
  ED = '1031 exchange documents',
  SKD = 'Schedule K-1 documents',
  ATS = 'Annual tax statements',
  REVENUE = 'Revenue',
  DIVIDEND_DIS = 'Dividend Distribution Statement',
  OTHER = 'Other',
}

export enum DurationType {
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
  HALF_YEARLY = 'half yearly',
  YEARLY = 'yearly',
}
