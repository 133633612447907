import { DialogContent } from '@mui/material';
import 'react-perfect-scrollbar/dist/css/styles.css';
import IconButton from '@mui/material/IconButton';
import IconArrowLeft from '../../icons/IconArrowLeft';
import { useParams } from 'react-router-dom';
import { useFundById } from '../../../hooks/react-query/funds';
import { DeployCapitalCallFormData } from '../../../types/Governance';
import { Loader } from '../../../components/common/Loader';
import { CapitalCallForm } from './components/CapitalCallForm';

interface DeployCapitalCallModalComponentProps {
  fundId?: string;
  closeModal: () => void;
  onSubmit: (formData: DeployCapitalCallFormData) => void;
}

export const DeployCapitalCallModalComponent = ({
  fundId,
  closeModal,
  onSubmit,
}: DeployCapitalCallModalComponentProps) => {
  const { fundId: paramsFundId } = useParams();

  const { data: fundInfo, isLoading } = useFundById(fundId || paramsFundId);

  if (isLoading) return <Loader />;

  return (
    <DialogContent className="generic-form-dialog-content">
      <div className={'deploy-capital-call-content'}>
        <div className="dialog-content">
          <div className="back-login" onClick={closeModal}>
            <IconButton size="large" component="span">
              <IconArrowLeft stroke="#8F8F8F" />
            </IconButton>
            <p className="back-text">Back</p>
          </div>
          <div className="title-and-subtitle-wrap">
            <p className="title">Create Capital Call</p>
            <p className="subtitle">Create a capital call request to get funds from the limited partners</p>
          </div>
          <CapitalCallForm fund={fundInfo?.fund} onSubmit={onSubmit} />
        </div>
      </div>
    </DialogContent>
  );
};
