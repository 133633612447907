import { capitalizeFirstLetter, formatFileExtension } from '../../../views/utils';
import { DocumentsLevel } from 'src/types/common';

export type DocumentData =
  | {
      id: number | string; // TODO: TBD which type
      isExpandable: false; // This parameter defines the other required parameters to be passed to the component.
      name: string;
      extension: 'xls' | 'png' | 'csv' | 'pdf' | 'file';
      date: Date;
      level: 'Fund' | 'Investment' | 'Asset' | 'No Info';
      fundId?: number;
      status?: 'completed' | 'failed' | 'in_progress' | 'not_processable';
      route?: string[];
      folderId?: number;
      folderName?: string;
      isLpVisible?: boolean;
    }
  | {
      id: number | string; // TODO: TBD which type
      isExpandable: true; // This parameter defines the other required parameters to be passed to the component.
      name: string;
      extension: 'xls' | 'png' | 'csv' | 'pdf' | 'file' | 'fam'; // "FAM" represents family of docs.
      date: Date;
      level: 'Fund' | 'Investment' | 'Asset' | 'No Info';
      status?: 'completed' | 'failed' | 'in_progress' | 'not_processable';
      document_type: string;
      frequency: string;
      fundId?: number;
      // TODO: get type from API
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      downloadable_document?: any;
      past_instances?: string;
      upcoming?: string;
      folderId?: number;
      folderName?: string;
      isLpVisible?: boolean;
    };

const determineDocumentLevel = (levelArray: string[]) => {
  switch (levelArray.length) {
    case 1:
      return 'Fund';
    case 2:
      return 'Investment';
    case 3:
      return 'Asset';
    default:
      return 'No Info';
  }
};

// Receives a document straight from backend. Any change that happens on BE side will impact directly on this.
// TODO: get type from API
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createNonExpandableDocumentData = (doc: any): DocumentData => ({
  id: doc.id,
  isExpandable: false,
  name: doc.Title || 'No title',
  date: new Date(doc.DocDate || Date.now()),
  folderId: doc.FolderId,
  folderName: doc.FolderName,
  fundId: doc.Fund?.fundId,
  extension: formatFileExtension(doc.extension),
  level: determineDocumentLevel(doc.level || []),
  route: doc.level || [],
});

// Receives a document straight from backend. Any change that happens on BE side will impact directly on this.

export const createExpandableDocumentData = (
  // TODO: get type from API
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  doc: any,
  level: DocumentsLevel,
): DocumentData => ({
  id: doc.id,
  isExpandable: true,
  name: doc.Title || 'No title',
  date: new Date(doc.DocDate || Date.now()),
  folderId: doc.FolderId,
  folderName: doc.FolderName,
  fundId: doc.Fund?.fundId,
  extension: formatFileExtension(doc.extension),
  level: capitalizeFirstLetter(level) as DocumentData['level'],
  document_type: doc.DocType || '',
  frequency: capitalizeFirstLetter(doc.DurationType) || '',
  downloadable_document: doc.DocLinkPath,
  isLpVisible: Boolean(doc.IsLpVisible),
});

export const mapDocStatusToString = (docStatus: string) => {
  switch (docStatus) {
    case 'completed':
      return 'Completed';
    case 'failed':
      return 'Failed';
    case 'in_progress':
      return 'In Progress';
    case 'not_processable':
      return 'Not Processable';
    default:
      return '';
  }
};
