import { Loader } from 'src/components/common/Loader';
import { currentFormatNoDollarSign } from 'src/views/utils';
import KpiListCard from 'src/components/common/KpiListCard';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import FboFundingModalContainer from 'src/uiLibrary/modals/fboFunding/FboFundingContainer';
import { FieldValues } from 'react-hook-form';
import { QueryError } from 'src/components/common/QueryError';
import { CashKpi } from '../consts/governanceKpi';
import { useCashKpi } from 'src/hooks/react-query/kpi';
import { useFboBalance } from 'src/hooks/react-query/governance';

interface CashKPIDetailsProps {
  id?: number;
  hasEtbTooltip?: boolean;
}

export const CashKPIDetails = ({ id, hasEtbTooltip = false }: CashKPIDetailsProps) => {
  const params = useParams();
  const [isFboFundingOpen, setIsFboFundingOpen] = useState(false);

  const fundId = Number(params.fundId) || id;

  const { data: fboBalance, isLoading, isError } = useFboBalance(fundId);
  const { data: indicators, isLoading: isIndicatorsLoading, isError: isIndicatorsError } = useCashKpi(fundId);

  const handleFboFundingOnClose = () => setIsFboFundingOpen(false);

  // TODO: add submit funtion
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleFboFundingOnSubmit = (formData: FieldValues) => {
    // handle On Submit
  };

  if (isLoading || isIndicatorsLoading) return <Loader />;
  if (isError || isIndicatorsError) return <QueryError />;

  const ttm = indicators.find((ind: KPI) => ind.KPI === CashKpi['CapEx Reserve (TTM)'])?.value;
  const totalCommitment = indicators.find((ind: KPI) => ind.KPI === CashKpi['Total Commitment'])?.value;
  const cash = indicators.find((ind: KPI) => ind.KPI === CashKpi.Cash)?.value;
  const totalInvested = indicators.find((ind: KPI) => ind.KPI === CashKpi['Total Invested'])?.value;

  const totalCash = parseFloat(cash) + parseFloat(ttm);

  return (
    <>
      <KpiListCard
        title={"Cash Relevant KPI's"}
        list={[
          {
            label: 'Extrance Treasury Balance',
            value: `$ ${currentFormatNoDollarSign(fboBalance)}`,
            ...(hasEtbTooltip && {
              tooltip: {
                title: 'Extrance Treasury Balance',
                content:
                  'Having funds available in the Treasury Balance enables the Fund to perform instant payouts, such as distributions.',
                action: (
                  <div
                    style={{
                      textDecoration: 'underline',
                      marginTop: 4,
                      color: 'white',
                      fontSize: 14,
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setIsFboFundingOpen(true);
                    }}
                  >
                    Fund the ETB
                  </div>
                ),
              },
            }),
          },
          {
            label: 'Total Cash',
            value: `$ ${totalCash ? currentFormatNoDollarSign(totalCash) : '-'}`,
          },
          {
            label: 'Commited capital',
            value: `$ ${totalCommitment ? currentFormatNoDollarSign(totalCommitment) : '-'}`,
          },
          {
            label: 'Capital Drawn',
            value: `$ ${totalInvested ? currentFormatNoDollarSign(totalInvested) : '-'}`,
          },
        ]}
      />
      <FboFundingModalContainer
        isVisible={isFboFundingOpen}
        closeModal={handleFboFundingOnClose}
        handleOnSubmit={handleFboFundingOnSubmit}
        fboBalance={fboBalance}
      />
    </>
  );
};

// TODO: get type from API
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type KPI = any;
