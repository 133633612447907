import React, { ReactNode, MouseEvent, useState } from 'react';
import { Container, Grid, IconButton, List, ListItem, Popover, PopoverOrigin } from '@mui/material';
import IconThreeDots from 'src/uiLibrary/icons/IconThreeDots';
import { IconClose } from 'src/uiLibrary/icons/IconClose';
import { useModal } from 'src/hooks/useModal';
import styles from './styles.module.scss';

interface MenuPopupProps {
  title: string;
  children?: ReactNode;
}

enum Modal {
  MenuPopup,
}

export const MenuPopup = ({ title, children }: MenuPopupProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const { isOpen, handleOpenModal, handleCloseModal } = useModal<Modal>();

  const transformPopover: PopoverOrigin = {
    vertical: 'top',
    horizontal: 'right',
  };

  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    handleOpenModal(Modal.MenuPopup);
  };

  const handleClose = () => {
    setAnchorEl(null);
    handleCloseModal();
  };

  return (
    <Grid container spacing={0} className="document-popup-menu">
      <div>
        <IconButton
          role={'open-button'}
          className={`${styles['menu-popup-action-button']} action-button`}
          aria-describedby={'simple-popover'}
          onClick={handleOpen}
        >
          <IconThreeDots />
        </IconButton>
        <Popover
          id={'simple-popover'}
          className="popover-filter"
          data-testid="simple-popover"
          open={isOpen(Modal.MenuPopup)}
          anchorEl={anchorEl}
          onClose={handleCloseModal}
          anchorOrigin={transformPopover}
          transformOrigin={transformPopover}
        >
          <Container className={styles['menu-popup']}>
            <List className={styles['menu-popup-list']}>
              <ListItem className={styles['menu-popup-list-item']} sx={{ justifyContent: 'space-between' }}>
                <p className={styles['menu-popup-list-item-title']}>{title}</p>
                <IconButton role={'close-button'} onClick={handleClose}>
                  <IconClose />
                </IconButton>
              </ListItem>
              <div onClick={handleClose}>{children}</div>
            </List>
          </Container>
        </Popover>
      </div>
    </Grid>
  );
};
