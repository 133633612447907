import { validateError } from 'src/utils/validateError';
import { ListOfLpsSortField } from '../models/governanceModels';
import { SortOrder } from '../models/tableModels';
import ServiceClient from './serviceClientConfig';

const serviceClient = new ServiceClient({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export const getFunds = () => {
  const url = `funds`;

  return serviceClient.get(url);
};
export const getFundById = (fundId: number) => {
  const url = `funds/${fundId}`;

  return serviceClient.get(url);
};

export const getActiveCapCall = async (fundId?: number) => {
  const url = `funds/${fundId}/active-cap-call`;
  try {
    const response = await serviceClient.get(url);
    //TODO: add type from codegen
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return response.data as any;
  } catch (error) {
    console.error('getIndicators error: ', error);
    throw await validateError(error);
  }
};

export const getIndicators = async (fundId: number) => {
  const url = `funds/${fundId}/indicators`;
  try {
    const response = await serviceClient.get(url);
    //TODO: add type from codegen
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return response.data as any;
  } catch (error) {
    console.error('getIndicators error: ', error);
    throw await validateError(error);
  }
};
export const getRate = (fundId: number) => {
  const url = `funds/${fundId}/rate`;
  return serviceClient.get(url);
};
export const getWaterfallDistribution = (fundId: number) => {
  const url = `funds/${fundId}/waterfall-distribution`;
  return serviceClient.get(url);
};
export const getKpiList = async (fundId: string, kpiId: string) => {
  const url = `funds/${fundId}/indicators?kpi=${encodeURIComponent(kpiId)}`;
  try {
    const response = await serviceClient.get(url);
    //TODO: add type from codegen
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return response.data as any;
  } catch (error) {
    console.error('getKpiList error: ', error);
    throw await validateError(error);
  }
};

export const getLimitedPartners = async (
  fundId: number,
  page: number,
  page_size: number,
  sortOrderBy: SortOrder,
  sortOrderField: ListOfLpsSortField,
  searchString?: string,
) => {
  try {
    let url = `funds/${fundId}/lp-user-list?page_size=${page_size}&page=${page}&sortOrderField=${sortOrderField}&sortOrderBy=${sortOrderBy}`;

    if (searchString) url = url + `&search_string=${searchString}`;

    const response = await serviceClient.get(url);

    //TODO: add type from codegen
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return response.data as any;
  } catch (error) {
    console.error('getLimitedPartners error: ', error);

    throw await validateError(error);
  }
};

export const getOpenPositionSellRequests = async (fundId: number) => {
  const url = `fund-sell-request/sell-request-list?filter_type=Sell&GPapproval_type=Open&fund=${fundId}`;
  try {
    const response = await serviceClient.get(url);
    //TODO: add type from codegen
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return response.data as any;
  } catch (error) {
    console.error('getIndicators error: ', error);
    throw await validateError(error);
  }
};

export const getCapDistribution = (fundId: number) => {
  const url = `partnershipdistribution?page=0&fund=${fundId}&status=Initiated`;
  return serviceClient.get(url);
};
// export const postCapDistribution = (id: number, body: any) => {
//   let url = `partnershipdistribution/${id}`;
//   return serviceClient.post(url, body);
// };

export const getFboAvailable = async (fundId: number) => {
  const url = `funds/${fundId}/fbo-amount`;
  try {
    const response = await serviceClient.get(url);
    //TODO: add type from codegen
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return response.data as any;
  } catch (error) {
    console.error('getIndicators error: ', error);
    throw await validateError(error);
  }
};

export const approveRejectSellRequest = (fundId: number, payload: { GPApproved: string }) => {
  const url = `fund-sell-request/fund-sell-request/${fundId}`;
  return serviceClient.put(url, payload);
};

export const approveRejectNda = (id: number, status: string) => {
  const url = `fundagreementrequest/fund/approve/nda/${id}`;
  return serviceClient.put(url, { status: status });
};

export const getWaterfallState = (id: number) => {
  const url = `capitalEvents/waterfall-state/${id}`;
  return serviceClient.get(url);
};
export const previewWaterfall = (id: number, amount: number) => {
  const url = `capitalEvents/preview-waterfall-distribution/${id}?amount=${amount}`;
  return serviceClient.get(url);
};
export const getDistBreakdownFile = (id: number) => {
  const url = `capitalEvents/distribution-file?fundId=${id}`;
  return serviceClient.get(url, { responseType: 'arraybuffer' });
};

export const triggerCapDistribution = (id: number, amount: number, isAch: number) => {
  const url = `capitalEvents/trigger-waterfall-distribution/${id}?amount=${amount}&isACH=${isAch}`;
  return serviceClient.get(url);
};
// export const getRoleDetailsById = (roleId: string) => {
//   let url = `roles/${roleId}`;
//   return serviceClient.get(url);
// };

// export const getPrivilegeListForRole = () => {
//   let url = "privileges/privilege-list";
//   return serviceClient.get(url);
// };

// export const getRolePrivilege = (userId: number | undefined) => {
//   return serviceClient.get(`users/user/role-privilege?userId=${userId}`);
// };

// export const deleteRole = (roleId: number) => {
//   let url = `roles/${roleId}`;
//   return serviceClient.delete(url);
// };

// export const updateRoleStatus = (roleId: number, isActive: number) => {
//   let url = `roles/update-status/${roleId}`;
//   return serviceClient.put(url, { IsActive: isActive });
// };

export const getCapCallHistory = (fundId: number) => {
  const url = `fund-opportunity/cap-call-history/${fundId}`;
  return serviceClient.get(url);
};

export const getFundOpportunity = (capId: number) => {
  const url = `fund-opportunity/${capId}`;
  return serviceClient.get(url);
};

export const getDistributionHistory = (fundId: number) => {
  const url = `capitalEvents/distributions-history?fundId=${fundId}`;
  return serviceClient.get(url);
};

export const getDistribution = (distributionId: number) => {
  const url = `capitalEvents/distribution-detail/${distributionId}`;
  return serviceClient.get(url);
};
