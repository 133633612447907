import React, { useState } from 'react';
import { connect } from 'react-redux';
import { ForgotPasswordForm } from '../../models/forgotPasswordModels';
import { SnackbarPayload } from '../../models/snackbarModels';
import { HideLoadingAction, ShowLoadingAction } from '../../redux/actions/loadingActions';
import { ShowSnackbarAction } from '../../redux/actions/snackbarActions';

import { forgotPassword } from '../../services/loginService';
import ForgotPasswordComponent from './forgotPasswordComponent';

const ForgotPasswordContainer = ({
  ShowSnackbarAction,
  ShowLoadingAction,
  HideLoadingAction,
}: ForgotPasswordContainerProps) => {
  const [resetForm, setResetForm] = useState(false);
  const [isEmailSend, setIsEmailSend] = useState<boolean>(false);

  const onSubmit = (formData: ForgotPasswordForm) => {
    ShowLoadingAction();
    forgotPassword(formData.Email)
      .then(response => {
        if (response.data.status === 200) {
          ShowSnackbarAction({
            message: response.data.message,
            severity: 'success',
            duration: 5000,
          });
          setResetForm(true);
          setIsEmailSend(true);
          setTimeout(() => {
            setResetForm(false);
          }, 1);
        } else {
          ShowSnackbarAction({
            message: response?.data?.message ? response?.data?.message : 'Something went wrong.',
            severity: 'error',
          });
        }
        HideLoadingAction();
      })
      .catch(error => {
        ShowSnackbarAction({
          message: error?.response?.data?.message ? error?.response?.data?.message : 'Something went wrong.',
          severity: 'error',
        });
        HideLoadingAction();
      });
  };
  return (
    <ForgotPasswordComponent
      resetForm={resetForm}
      onSubmit={onSubmit}
      isEmailSend={isEmailSend}
    ></ForgotPasswordComponent>
  );
};

interface ForgotPasswordContainerProps {
  ShowSnackbarAction: (payload: SnackbarPayload) => void;
  ShowLoadingAction: (payload?: string) => void;
  HideLoadingAction: () => void;
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {
  ShowLoadingAction,
  HideLoadingAction,
  ShowSnackbarAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordContainer);
