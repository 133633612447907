import React from 'react';

const IconLink = () => {
  return (
    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.3337 8.50008V16.0001C17.3337 16.2211 17.2459 16.4331 17.0896 16.5893C16.9333 16.7456 16.7213 16.8334 16.5003 16.8334H1.50033C1.27931 16.8334 1.06735 16.7456 0.91107 16.5893C0.75479 16.4331 0.666992 16.2211 0.666992 16.0001V1.00008C0.666992 0.779068 0.75479 0.567106 0.91107 0.410826C1.06735 0.254545 1.27931 0.166748 1.50033 0.166748H9.00033C9.22134 0.166748 9.4333 0.254545 9.58958 0.410826C9.74586 0.567106 9.83366 0.779068 9.83366 1.00008C9.83366 1.2211 9.74586 1.43306 9.58958 1.58934C9.4333 1.74562 9.22134 1.83341 9.00033 1.83341H2.33366V15.1667H15.667V8.50008C15.667 8.27907 15.7548 8.06711 15.9111 7.91083C16.0673 7.75455 16.2793 7.66675 16.5003 7.66675C16.7213 7.66675 16.9333 7.75455 17.0896 7.91083C17.2459 8.06711 17.3337 8.27907 17.3337 8.50008ZM16.8187 0.230915C16.7178 0.188668 16.6096 0.166858 16.5003 0.166748H12.3337C12.1126 0.166748 11.9007 0.254545 11.7444 0.410826C11.5881 0.567106 11.5003 0.779068 11.5003 1.00008C11.5003 1.2211 11.5881 1.43306 11.7444 1.58934C11.9007 1.74562 12.1126 1.83341 12.3337 1.83341H14.4887L8.41116 7.91091C8.33157 7.98779 8.26808 8.07974 8.22441 8.18141C8.18073 8.28308 8.15774 8.39243 8.15678 8.50308C8.15582 8.61373 8.17691 8.72346 8.21881 8.82588C8.26071 8.92829 8.32259 9.02133 8.40083 9.09958C8.47907 9.17782 8.57212 9.2397 8.67453 9.2816C8.77694 9.3235 8.88668 9.34459 8.99733 9.34362C9.10798 9.34266 9.21733 9.31967 9.319 9.276C9.42067 9.23233 9.51262 9.16884 9.58949 9.08925L15.667 3.01175V5.16675C15.667 5.38776 15.7548 5.59972 15.9111 5.756C16.0673 5.91228 16.2793 6.00008 16.5003 6.00008C16.7213 6.00008 16.9333 5.91228 17.0896 5.756C17.2459 5.59972 17.3337 5.38776 17.3337 5.16675V1.00008C17.3331 0.890811 17.3113 0.782688 17.2695 0.681748C17.1849 0.477651 17.0228 0.31549 16.8187 0.230915Z"
        fill="black"
      />
    </svg>
  );
};

export default IconLink;
