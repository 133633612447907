import React from 'react';
import { Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Button, FormControl, FormHelperText, FormLabel, Grid, MenuItem, Select, TextField } from '@mui/material';
import { StateOptions, UserDetailsData } from 'src/models/frontendUserModels';
import { UserPayload } from 'src/models/adminUserModels';
import { userStatus } from '../consts/userStatus';
import { IMAGE_EXTENSIONS } from 'src/consts/fileExtensions';
import { RoleItem } from 'src/models/rolesModels';
import { Loader } from 'src/components/common/Loader';
import { useUserForm } from '../utils/useUserForm';
import { useUserUpdate } from 'src/hooks/react-query/user/useUserUpdate';
import { useUserCreate } from 'src/hooks/react-query/user/useUserCreate';
import { appendFormData } from '../utils/appentFormData';
import { UploadImage } from 'src/components/common/UploadImage/UploadImage';
import styles from './styles.module.scss';

interface UserFormProps {
  states: StateOptions[];
  roleList?: RoleItem[];
  userDetails?: UserDetailsData;
  isLoading?: boolean;
}

export const UserForm = ({ states, roleList, userDetails, isLoading }: UserFormProps) => {
  const navigate = useNavigate();

  const { control, setValue, watch, getValues, handleSubmit } = useUserForm(userDetails);

  const { mutateAsync: updateUser } = useUserUpdate(getValues('firstname'));
  const { mutateAsync: createUser } = useUserCreate();

  const handleFileChange = (file?: File) => {
    if (file) {
      const extension = file?.name.toLowerCase().split('.').pop();
      if (extension && IMAGE_EXTENSIONS.includes(extension)) {
        setValue('profileimage', file);
      }
    } else {
      setValue('profileimage', undefined);
    }
  };

  const onSubmit = async (data: UserPayload) => {
    const formData = new FormData();

    appendFormData(formData, data, userDetails);

    if (userDetails) {
      await updateUser(formData);
    } else {
      await createUser(formData);
    }

    navigate('/users');
  };

  if (isLoading) return <Loader />;

  const isMainGp = userDetails?.RoleSlug === 'main-gp';

  return (
    <form noValidate className={styles.userForm} autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <Grid container>
        <Grid item xs={2}></Grid>
        <Grid item xs={8}>
          <Grid container className={styles.formContainer} spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined" className={styles.formControl}>
                <FormLabel>
                  First name <span className="require">*</span>
                </FormLabel>
                <Controller
                  name="firstname"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      type="text"
                      required
                      onChange={event => {
                        field.onChange(event);
                      }}
                      error={!!fieldState.error}
                      helperText={fieldState.error ? fieldState.error.message : ''}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined" className={styles.formControl}>
                <FormLabel>
                  Last name <span className="require">*</span>
                </FormLabel>
                <Controller
                  name="lastname"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      type="text"
                      required
                      onChange={event => {
                        field.onChange(event);
                      }}
                      error={!!fieldState.error}
                      helperText={fieldState.error ? fieldState.error.message : ''}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormLabel>
                Contact number <span className="require">*</span>
              </FormLabel>
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <FormControl fullWidth variant="outlined">
                    <Controller
                      name="countryCode"
                      control={control}
                      render={({ field, fieldState }) => (
                        <>
                          <Select
                            fullWidth
                            labelId="demo-simple-select-required-label"
                            required
                            displayEmpty
                            className={styles.formControlSelect}
                            inputProps={{ 'aria-label': 'Without label' }}
                            {...field}
                            onChange={event => {
                              field.onChange(event);
                            }}
                            error={!!fieldState.error}
                          >
                            <MenuItem value="">Select</MenuItem>
                            <MenuItem value="1">+1</MenuItem>
                          </Select>
                          {!!fieldState.error && <FormHelperText error>{fieldState.error.message}</FormHelperText>}
                        </>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={7.8}>
                  <FormControl fullWidth variant="outlined">
                    <Controller
                      name="contactnumber"
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          fullWidth
                          type="text"
                          inputProps={{ pattern: '[0-9]*' }}
                          required
                          onChange={event => {
                            const { value } = event.target;

                            if (/^\d*$/.test(value)) {
                              field.onChange(event);
                            }
                          }}
                          className={'phoneTextField'}
                          error={!!fieldState.error}
                          helperText={fieldState.error ? fieldState.error.message : ''}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined" className={styles.formControl}>
                <FormLabel>Email address {!userDetails && <span className="require">*</span>}</FormLabel>
                <Controller
                  name="email"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      type="text"
                      required
                      onChange={event => {
                        field.onChange(event);
                      }}
                      error={!!fieldState.error}
                      helperText={fieldState.error ? fieldState.error.message : ''}
                      disabled={!!userDetails}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined" className={styles.formControl}>
                <FormLabel id="demo-simple-select-required-label">
                  State <span className="require">*</span>
                </FormLabel>
                <Controller
                  name="state"
                  control={control}
                  render={({ field, fieldState }) => (
                    <>
                      <Select
                        fullWidth
                        labelId="demo-simple-select-required-label"
                        className={styles.formControlSelect}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        {...field}
                        onChange={event => {
                          field.onChange(event);
                        }}
                        error={!!fieldState.error}
                      >
                        <MenuItem value="">Select</MenuItem>
                        {states?.map((state: StateOptions) => (
                          <MenuItem key={state.value} value={state.value}>
                            {state.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {!!fieldState.error && <FormHelperText error>{fieldState.error.message}</FormHelperText>}
                    </>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined" className={styles.formControl}>
                <FormLabel>
                  City <span className="require">*</span>
                </FormLabel>
                <Controller
                  name="city"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      type="text"
                      required
                      onChange={event => {
                        field.onChange(event);
                      }}
                      error={!!fieldState.error}
                      helperText={fieldState.error ? fieldState.error.message : ''}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined" className={styles.formControl}>
                <FormLabel>
                  Zip Code <span className="require">*</span>
                </FormLabel>
                <Controller
                  name="pinCode"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      type="text"
                      required
                      onChange={event => {
                        field.onChange(event);
                      }}
                      error={!!fieldState.error}
                      helperText={fieldState.error ? fieldState.error.message : ''}
                    />
                  )}
                />
              </FormControl>
              <FormControl fullWidth variant="outlined" className={styles.formControlDate}>
                <FormLabel>
                  Date of birth <span className="require">*</span>
                </FormLabel>
                <Controller
                  name="DateOfBirth"
                  control={control}
                  render={({ field, fieldState }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        maxDate={new Date()}
                        mask="__/__/__"
                        label=""
                        {...field}
                        inputFormat="MM/dd/yy"
                        renderInput={params => (
                          <TextField
                            InputProps={{
                              readOnly: true,
                            }}
                            {...params}
                            error={!!fieldState.error}
                            helperText={fieldState.error ? fieldState.error.message : ''}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined" className={styles.formControl}>
                <FormLabel>
                  Address <span className="require">*</span>
                </FormLabel>
                <Controller
                  name="address"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      type="text"
                      required
                      onChange={event => {
                        field.onChange(event);
                      }}
                      error={!!fieldState.error}
                      helperText={fieldState.error ? fieldState.error.message : ''}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl variant="outlined" className={styles.formControl}>
                <UploadImage
                  name={'profileimage'}
                  label={'Profile picture'}
                  imageSrc={watch('profileimage') ? URL.createObjectURL(watch('profileimage')) : undefined}
                  onChange={handleFileChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined" className={styles.formControlRole}>
                <FormLabel id="demo-simple-select-required-label">
                  Select role <span className="require">*</span>
                </FormLabel>
                <Controller
                  name="roleId"
                  control={control}
                  render={({ field, fieldState }) => (
                    <>
                      <Select
                        fullWidth
                        labelId="demo-simple-select-required-label"
                        className={styles.formControlSelect}
                        required
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        {...field}
                        onChange={event => {
                          field.onChange(event);
                        }}
                        disabled={isMainGp}
                        error={!!fieldState.error}
                      >
                        <MenuItem value={''}>Select</MenuItem>
                        {roleList?.map(userType => (
                          <MenuItem key={userType.id} value={userType.id}>
                            {userType.Name}
                          </MenuItem>
                        ))}
                      </Select>
                      {!!fieldState.error && <FormHelperText error>{fieldState.error.message}</FormHelperText>}
                    </>
                  )}
                />
              </FormControl>
              <FormControl fullWidth variant="outlined" className={styles.formControlStatus}>
                <FormLabel id="demo-simple-select-required-label">
                  Select status <span className="require">*</span>
                </FormLabel>
                <Controller
                  name="isactive"
                  control={control}
                  render={({ field, fieldState }) => (
                    <>
                      <Select
                        fullWidth
                        labelId="demo-simple-select-required-label"
                        className={styles.formControlSelect}
                        required
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        {...field}
                        onChange={event => {
                          field.onChange(event);
                        }}
                        disabled={isMainGp}
                        error={!!fieldState.error}
                      >
                        {userStatus.map(status => (
                          <MenuItem key={status.value} value={status.value}>
                            {status.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {!!fieldState.error && <FormHelperText error>{fieldState.error.message}</FormHelperText>}
                    </>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} className={styles.btnContainer}>
              <Button
                className={'mr-10 submitUserBtn'}
                disabled={isLoading}
                variant={'contained'}
                startIcon={<PersonAddAltIcon />}
                type="submit"
              >
                {userDetails ? 'Update User' : 'Add user'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
    </form>
  );
};
