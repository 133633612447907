import { IconProps } from './types/types';

export const IconClose = ({ onClick, size = 8 }: IconProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
      <path d="M7 1L1 7" stroke="#CCC4C4" strokeWidth="1.4" />
      <path d="M1 1L7 7" stroke="#CCC4C4" strokeWidth="1.4" />
    </svg>
  );
};
