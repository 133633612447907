const getInitials = (userName: string | null | undefined) => {
  if (!userName) return null;
  const [FirstName, LastName] = userName.split(' ');
  if (!!FirstName && !!LastName) {
    return (FirstName[0] + LastName[0]).toUpperCase();
  } else if (!!FirstName && !LastName) {
    return (FirstName[0] + FirstName[1]).toUpperCase();
  }
};
export default getInitials;
