import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { UserPayload } from 'src/models/adminUserModels';
import { schema } from '../validations/validations';
import { UserDetailsData } from 'src/models/frontendUserModels';
import { ObjectSchema } from 'yup';

export const useUserForm = (userDetails?: UserDetailsData) => {
  const { handleSubmit, control, watch, reset, setValue, getValues } = useForm<UserPayload>({
    resolver: yupResolver(schema as ObjectSchema<UserPayload>),
    defaultValues: {
      firstname: userDetails?.FirstName ?? '',
      lastname: userDetails?.LastName ?? '',
      countryCode: userDetails?.CountryId ?? '1',
      contactnumber: userDetails?.ContactNumber ?? '',
      email: userDetails?.Email ?? '',
      agencyName: userDetails?.AgencyName ?? '',
      countryId: userDetails?.CountryId ?? '',
      state: userDetails?.StateId ?? '',
      city: userDetails?.City ?? '',
      pinCode: userDetails?.ZipCode ?? '',
      address: userDetails?.Address ?? '',
      status: '',
      userType: userDetails?.UserType ? userDetails?.UserType.toString() : '',
      roleId: userDetails?.RoleId.toString() ?? '',
      profileimage: userDetails?.ProfileImage || undefined,
      userid: userDetails?.UserId,
      username: '',
      DateOfBirth: userDetails?.DateOfBirth ?? '',
      accountstatus: '',
      rolename: '',
      roleslug: '',
      isactive: userDetails?.IsActive ?? 0,
    },
  });

  return {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    watch,
  };
};
