import { useEffect, useState } from 'react';
import { FundsSpotlightComponent } from './FundsSpotlightComponent';
import { useQuery } from '@tanstack/react-query';
import { Loader } from 'src/components/common/Loader';
import { Fund } from 'src/types/Funds';
import { getFundsSpotlightChartData } from 'src/utils/getFundsSpotlightChartData';
import { getFundsSpotlightIndicators } from 'src/utils/getFundsSpotlightIndicators';
import { QueryKey } from 'src/consts/query';

interface FundsOverviewWidgetProps {
  funds: Fund[];
}

export const FundsSpotlightWidget = ({ funds }: FundsOverviewWidgetProps) => {
  const [selectedFundsSpotlightFundId, setSelectedFundsSpotlightFundId] = useState<number | null>(null);

  useEffect(() => {
    if (funds.length) {
      funds.sort(({ name: firstName }, { name: secondName }) => firstName.localeCompare(secondName));

      setSelectedFundsSpotlightFundId(funds[0].id);
    }
  }, [funds]);

  const { data: fundsSpotlightChartData, isLoading } = useQuery({
    queryKey: [QueryKey.FundsSpotlightChartData, selectedFundsSpotlightFundId],
    queryFn: () => getFundsSpotlightChartData(selectedFundsSpotlightFundId),
  });
  const { data: fundsSpotlightIndicatorsData, isLoading: fundsSpotlightIndicatorsDataLoading } = useQuery({
    queryKey: [QueryKey.FundsSpotlightIndicators, selectedFundsSpotlightFundId],
    queryFn: () => getFundsSpotlightIndicators(selectedFundsSpotlightFundId),
  });

  const handleOnChangeFundsSpotlightSelectedFund = (fundId: number | null) => {
    setSelectedFundsSpotlightFundId(fundId);
  };

  if (isLoading || fundsSpotlightIndicatorsDataLoading) return <Loader />;

  return (
    <FundsSpotlightComponent
      funds={funds}
      selectedFundId={selectedFundsSpotlightFundId}
      handleOnChangeSelectedFund={handleOnChangeFundsSpotlightSelectedFund}
      indicators={fundsSpotlightIndicatorsData ?? []}
      chartData={fundsSpotlightChartData}
    />
  );
};
