import { validateError } from 'src/utils/validateError';
import ServiceClient from './serviceClientConfig';

const serviceClient = new ServiceClient({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export enum SingleGeneralSettingSlug {
  CompanyTitle = 'company_title',
  CompanyName = 'company_name',
  CompanyEmail = 'company_email',
  CompanyMobile = 'company_mobile',
  CompanyAddress = 'company_address',
  CompanyLogo = 'company_logo',
  AutoLogoutTime = 'auto_logout_time',
}

export const getSingleGeneralSetting = (keySlug: SingleGeneralSettingSlug) => {
  return serviceClient.get(`settings/general-setting-details?KeySlug=${keySlug}`);
};

//TODO: add type from codegen
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getGeneralSettingPublic = async (): Promise<any> => {
  try {
    const response = await serviceClient.get('settings/general-settings-public');
    //TODO: add type from codegen
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return response.data as any;
  } catch (error: unknown) {
    // TODO: remove no-console rule
    // eslint-disable-next-line no-console
    console.error('getGeneralSettingPublic error: ', error);
    throw await validateError(error);
  }
};

export const getTermsAndConditions = async () => {
  try {
    const response = await serviceClient.get('cms/terms-and-conditions');
    //TODO: add type from codegen
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return response.data as any;
  } catch (error: unknown) {
    // TODO: remove no-console rule
    // eslint-disable-next-line no-console
    console.error('getTermsAndConditions error: ', error);
    throw await validateError(error);
  }
};
export const getPrivacyPolicy = async () => {
  try {
    const response = await serviceClient.get('cms/privacy-policy');
    //TODO: add type from codegen
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return response.data as any;
  } catch (error: unknown) {
    // TODO: remove no-console rule
    // eslint-disable-next-line no-console
    console.error('getPrivacyPolicy error: ', error);
    throw await validateError(error);
  }
};
