import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'src/context/ShackbarContext';
import { countryStateList } from 'src/services/partnershipService';

export const useCountryList = (id?: string) => {
  const { showErrorSnackbar } = useSnackbar();

  const fetchCountryList = async () => {
    try {
      const response = await countryStateList(id);

      if (response.data.data) {
        return response.data.data;
      }

      return [];
    } catch (error) {
      console.error('Fetch country list error', error);
      showErrorSnackbar('Fetch country list error');
    }
  };

  return useQuery({
    queryKey: ['country_list'],
    queryFn: () => fetchCountryList(),
  });
};
