import { Box } from '@mui/material';
import { AssetById } from 'src/types/Assets';
import IconAddress from 'src/uiLibrary/icons/IconAddress';
import IconArea from 'src/uiLibrary/icons/IconArea';
import IconProperty from 'src/uiLibrary/icons/IconProperty';
import { IconPurchasePrice } from 'src/uiLibrary/icons/IconPurchasePrice';
import { currentFormatWithDecimals } from 'src/views/utils';

interface BannerWidgetProps {
  asset: AssetById;
}

export const BannerWidget = ({ asset }: BannerWidgetProps) => {
  return (
    <Box className="property-bg">
      <div className="property-flex">
        <div className="property-col border-r pro-1">
          <h3>{asset?.Title}</h3>
          <h5 className="mb-0">Asset Code : {asset?.Code}</h5>
        </div>
        <div className="property-col pro-2">
          <div className="media">
            <IconProperty />
            <div className="media-body">
              <h5 className="mt-0">Property Type</h5>
              <p>{asset?.PropertyType}</p>
            </div>
          </div>
        </div>
        <div className="property-col pro-3">
          <div className="media">
            <IconPurchasePrice />
            <div className="media-body">
              <h5 className="mt-0">Purchase Price</h5>
              <p>{asset?.PurchasePrice ? currentFormatWithDecimals(asset?.PurchasePrice) : '-'}</p>
            </div>
          </div>
        </div>
        <div className="property-col pro-4">
          <div className="media">
            <IconArea />
            <div className="media-body">
              <h5 className="mt-0">Area</h5>
              <p>{asset?.Area} sq ft</p>
            </div>
          </div>
        </div>
        <div className="property-col pro-5">
          <div className="media">
            <IconAddress />
            <div className="media-body">
              <h5 className="mt-0">Address</h5>
              <p>{asset?.Address}</p>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};
