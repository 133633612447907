import {
  OpportunitiesFilter,
  MyOrdersFilter,
  MyRequestsFilter,
  InterestReceivedFilter,
  ApprovalRequiredFilter,
  PartnershipValuationFilter,
  PendingPaymentFilter,
  TransactionHistoryFilter,
  TransactionHistoryReceivedFilter,
} from '../../models/marketPlaceModels';
import { SupportRequestFilter } from '../../models/supportRequestModels';
import { FilterActions, FilterActionTypes } from '../../redux/actions/filterActions';
import { AdminUsersFilter } from '../../models/adminUserModels';
import { RolesFilter } from '../../models/rolesModels';
import { ListOfLpsFilter } from '../../models/governanceModels';

interface FilterReducerState {
  opportunitiesFilter?: OpportunitiesFilter;
  myOrdersFilter?: MyOrdersFilter;
  myRequestsFilter?: MyRequestsFilter;
  interestReceivedFilter?: InterestReceivedFilter;
  approvalRequiredFilter?: ApprovalRequiredFilter;
  partnershipValuationFilter?: PartnershipValuationFilter;
  pendingPaymentFilter?: PendingPaymentFilter;
  transactionHistoryFilter?: TransactionHistoryFilter;
  transactionHistoryReceivedFilter?: TransactionHistoryReceivedFilter;
  supportRequestFilter?: SupportRequestFilter;
  marketplaceTabIndex: number;
  bankingPaymentTabIndex: number;
  adminUsersFilter?: AdminUsersFilter;
  rolesFilter?: RolesFilter;
  listOfLpsFilter?: ListOfLpsFilter;
}

const initialState: FilterReducerState = {
  opportunitiesFilter: undefined,
  myOrdersFilter: undefined,
  myRequestsFilter: undefined,
  interestReceivedFilter: undefined,
  approvalRequiredFilter: undefined,
  partnershipValuationFilter: undefined,
  pendingPaymentFilter: undefined,
  transactionHistoryFilter: undefined,
  supportRequestFilter: undefined,
  marketplaceTabIndex: 0,
  bankingPaymentTabIndex: 0,
  adminUsersFilter: undefined,
  listOfLpsFilter: undefined,
};
export default function filterReducer(state = initialState, action: FilterActions) {
  switch (action.type) {
    case FilterActionTypes.SAVE_OPPORTUNITIES_FILTER:
      return {
        ...state,
        opportunitiesFilter: action.payload,
        pendingPaymentFilter: undefined,
        transactionHistoryFilter: undefined,
        supportRequestFilter: undefined,
        bankingPaymentTabIndex: 0,
      };
    case FilterActionTypes.SAVE_MY_ORDERS_FILTER:
      return {
        ...state,
        myOrdersFilter: action.payload,
        pendingPaymentFilter: undefined,
        transactionHistoryFilter: undefined,
        supportRequestFilter: undefined,
        bankingPaymentTabIndex: 0,
      };
    case FilterActionTypes.SAVE_MY_REQUESTS_FILTER:
      return {
        ...state,
        myRequestsFilter: action.payload,
        pendingPaymentFilter: undefined,
        transactionHistoryFilter: undefined,
        supportRequestFilter: undefined,
        bankingPaymentTabIndex: 0,
      };
    case FilterActionTypes.SAVE_INTEREST_RECEIVED_FILTER:
      return {
        ...state,
        interestReceivedFilter: action.payload,
        pendingPaymentFilter: undefined,
        transactionHistoryFilter: undefined,
        supportRequestFilter: undefined,
        bankingPaymentTabIndex: 0,
      };
    case FilterActionTypes.SAVE_APPROVAL_REQUIRED_FILTER:
      return {
        ...state,
        approvalRequiredFilter: action.payload,
        pendingPaymentFilter: undefined,
        transactionHistoryFilter: undefined,
        supportRequestFilter: undefined,
        bankingPaymentTabIndex: 0,
      };
    case FilterActionTypes.SAVE_PARTNERSHIP_VALUATION_FILTER:
      return {
        ...state,
        partnershipValuationFilter: action.payload,
        pendingPaymentFilter: undefined,
        transactionHistoryFilter: undefined,
        supportRequestFilter: undefined,
        bankingPaymentTabIndex: 0,
      };
    case FilterActionTypes.SAVE_PENDING_PAYMENT_HISTORY:
      return {
        ...state,
        pendingPaymentFilter: action.payload,
        opportunitiesFilter: undefined,
        myOrdersFilter: undefined,
        myRequestsFilter: undefined,
        interestReceivedFilter: undefined,
        approvalRequiredFilter: undefined,
        partnershipValuationFilter: undefined,
        supportRequestFilter: undefined,
        marketplaceTabIndex: 0,
      };
    case FilterActionTypes.SAVE_TRANSACTION_HISTORY:
      return {
        ...state,
        transactionHistoryFilter: action.payload,
        opportunitiesFilter: undefined,
        myOrdersFilter: undefined,
        myRequestsFilter: undefined,
        interestReceivedFilter: undefined,
        approvalRequiredFilter: undefined,
        partnershipValuationFilter: undefined,
        supportRequestFilter: undefined,
        marketplaceTabIndex: 0,
      };
    case FilterActionTypes.SAVE_TRANSACTION_HISTORY_RECEIVED:
      return {
        ...state,
        transactionHistoryReceivedFilter: action.payload,
        opportunitiesFilter: undefined,
        myOrdersFilter: undefined,
        myRequestsFilter: undefined,
        interestReceivedFilter: undefined,
        approvalRequiredFilter: undefined,
        partnershipValuationFilter: undefined,
        supportRequestFilter: undefined,
        marketplaceTabIndex: 0,
      };
    case FilterActionTypes.SAVE_SUPPORT_REQUEST:
      return {
        ...state,
        supportRequestFilter: action.payload,
        transactionHistoryReceivedFilter: undefined,
        opportunitiesFilter: undefined,
        myOrdersFilter: undefined,
        myRequestsFilter: undefined,
        interestReceivedFilter: undefined,
        approvalRequiredFilter: undefined,
        partnershipValuationFilter: undefined,
        marketplaceTabIndex: 0,
      };
    case FilterActionTypes.SAVE_MARKETPLACE_TAB_INDEX:
      return {
        ...state,
        marketplaceTabIndex: action.payload,
      };
    case FilterActionTypes.SAVE_BANKING_PAYMENT_TAB_INDEX:
      return {
        ...state,
        bankingPaymentTabIndex: action.payload,
      };
    case FilterActionTypes.CLEAR_FILTER:
      return {
        ...state,
        opportunitiesFilter: undefined,
        myOrdersFilter: undefined,
        myRequestsFilter: undefined,
        interestReceivedFilter: undefined,
        approvalRequiredFilter: undefined,
        partnershipValuationFilter: undefined,
        pendingPaymentFilter: undefined,
        transactionHistoryFilter: undefined,
        transactionHistoryReceivedFilter: undefined,
        supportRequestFilter: undefined,
        marketplaceTabIndex: 0,
        bankingPaymentTabIndex: 0,
      };
    case FilterActionTypes.SAVE_ADMIN_USERS_FILTER:
      return {
        ...state,
        ...initialState,
        adminUsersFilter: action.payload,
      };
    case FilterActionTypes.SAVE_GOVERNANCE_LIST_OF_LPS_FILTER:
      return {
        ...state,
        ...initialState,
        listOfLpsFilter: action.payload,
      };
    default:
      return state;
  }
}
