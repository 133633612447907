import React from 'react';
import styles from './styles.module.scss';

const BackgroundRectangles = () => {
  return (
    <>
      <div className={styles['widget-top-rectangle-1']} />
      <div className={styles['widget-top-rectangle-2']} />
      <div className={styles['widget-top-rectangle-3']} />
      <div className={styles['widget-top-rectangle-4']} />
      <div className={styles['widget-bottom-rectangle-1']} />
      <div className={styles['widget-bottom-rectangle-2']} />
      <div className={styles['widget-bottom-rectangle-3']} />
      <div className={styles['widget-bottom-rectangle-4']} />
    </>
  );
};

export default BackgroundRectangles;
