import { Stack, Typography, FormLabel, Separator } from 'src/components/common';
import { Button } from '@mui/material';
import { renderCurrency } from 'src/views/utils';

type ReviewPaymentsStepProps = {
  onNextStep: () => void;
};

export const ReviewPaymentsStep = ({ onNextStep }: ReviewPaymentsStepProps) => {
  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="column">
        <Typography variant="dialogTitle">Payment structure</Typography>
        <Typography variant="dialogContentText">
          Funds that have automated transactions enabled can choose to execute the payments instantly.
        </Typography>
      </Stack>

      <Stack direction="column" spacing={1}>
        <Stack direction="column">
          <FormLabel component="p">Manual payments total amount</FormLabel>
          {/* TODO: Add data from backend */}
          <Typography variant="s16_lh24_b">{renderCurrency(10000)}</Typography>
        </Stack>

        <Separator />

        <Stack direction="column">
          <FormLabel component="p">Automated payments total amount</FormLabel>
          {/* TODO: Add data from backend */}
          <Typography variant="s16_lh24_b">{renderCurrency(20000)}</Typography>
        </Stack>
      </Stack>

      <Separator />

      <Button variant="contained" size="large" onClick={onNextStep} fullWidth>
        Confirm payment settings
      </Button>
    </Stack>
  );
};
