import { CardKPI } from 'src/components/CardKPI';
import { determineValueType } from '../utils';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import { Loader } from 'src/components/common/Loader';
import { QueryError } from 'src/components/common/QueryError';
import { EmptyState } from 'src/components/common/EmptyState';
import { useGovernanceKpi } from 'src/hooks/react-query/kpi';

interface KpiProps {
  fundName?: string;
}

export const KPI = ({ fundName }: KpiProps) => {
  const params = useParams();

  const { data: kpiData, isLoading, isError } = useGovernanceKpi(params.fundId);

  if (isLoading) return <Loader />;
  if (isError) return <QueryError />;

  return (
    <div className="from-row my-portfolio">
      <h2 className="col-lg-12">{fundName}</h2>
      <div className="row flex-grow-1">
        {kpiData.length > 0 ? (
          kpiData.map((item: KPI) => (
            <div key={item.id} className="col-sm-6 col-md-4 col-xl-3 mb-25 c-pointer">
              <CardKPI
                key={item.id}
                id={item.id}
                cardHeader={`As of ${format(new Date(item.reporting_date), 'MMM dd yyyy')}`}
                kpiDescription={item.KPI_FE}
                value={item.value}
                ValueType={determineValueType(item.unit)}
                variationNumber={0} // not provided by BE
              />
            </div>
          ))
        ) : (
          <div className="col-12">
            <EmptyState label="There are no KPI's data for the selected Fund." />
          </div>
        )}
      </div>
    </div>
  );
};

// TODO: get type from API
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type KPI = any;
