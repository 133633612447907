import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { SentryDsn } from 'src/api-keys/sentry';

export const useSentry = () => {
  const tracePropagationTargets: (string | RegExp)[] = ['localhost'];

  if (process.env.REACT_APP_BASE_URL) {
    tracePropagationTargets.push(new RegExp(process.env.REACT_APP_BASE_URL));
  }

  useEffect(() => {
    if (process.env.REACT_APP_LOG_ENV) {
      Sentry.init({
        dsn: process.env.REACT_APP_LOG_ENV === 'prod' ? SentryDsn.Prod : SentryDsn.Dev,
        integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
        tracesSampleRate: 1.0,
        tracePropagationTargets,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
      });
    }
  }, []);
};
