import { useState } from 'react';
import MarketplaceActComponent from './marketplaceActComponent';
import { Fund } from 'src/types/Funds';

const MarketplaceActContainer = ({
  closeModal,
  isVisible,
  fund,
  countersignTableData,
  // onSubmit,
  // setType,
  // type,
  // setFreq,
  // freq,
}: MarketplaceActContainerProps) => {
  const initialMetaObject = { total: 1, page: 1, limit: 10, count: 0 };

  const [movementsTablePage, setMovementsTablePage] = useState(0);
  const [movementsTableLimit, setMovementsTableLimit] = useState(10);
  const [movementsTableMetaData, setMovementsTableMetaData] = useState(initialMetaObject);

  const [countersigningTablePage, setCountersigningTablePage] = useState(0);
  const [countersigningTableLimit, setCountersigningTableLimit] = useState(10);
  const [countersigningTableMetaData, setCountersigningTableMetaData] = useState(initialMetaObject);

  const [capCallTablePage, setCapCallTablePage] = useState(0);
  const [capCallTableLimit, setCapCallTableLimit] = useState(10);
  const [capCallTableMetaData, setCapCallTableMetaData] = useState(initialMetaObject);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [capCallTableData, setCapCallTableData] = useState([
    {
      lpname: 'James Sullivan',
      fund: 'The Bell Enclave',
      date: '28 Oct 2022',
      amount: '$ 100,000.00',
    },
  ]);

  const [activeTab, setActiveTab] = useState('');

  const handleMovementsTablePageSizeChange = (pageSize: number) => {
    setMovementsTablePage(0);
    setMovementsTableLimit(pageSize);
  };

  const handleMovementsTableChangePage = (newPage: number) => {
    setMovementsTablePage(newPage);
  };

  const handleCountersigningTablePageSizeChange = (pageSize: number) => {
    setCountersigningTablePage(0);
    setCountersigningTableLimit(pageSize);
  };

  const handleCountersigningTableChangePage = (newPage: number) => {
    setCountersigningTablePage(newPage);
  };

  const handleCapCallTablePageSizeChange = (pageSize: number) => {
    setCapCallTablePage(0);
    setCapCallTableLimit(pageSize);
  };

  const handleCapCallTableChangePage = (newPage: number) => {
    setCapCallTablePage(newPage);
  };

  return (
    <MarketplaceActComponent
      closeModal={closeModal}
      isVisible={isVisible}
      fund={fund}
      handleMovementsTablePageSizeChange={handleMovementsTablePageSizeChange}
      handleMovementsTableChangePage={handleMovementsTableChangePage}
      movementsTableMetaData={movementsTableMetaData}
      movementsTablePage={movementsTablePage}
      movementsTableLimit={movementsTableLimit}
      setMovementsTableMetaData={setMovementsTableMetaData}
      handleCountersigningTablePageSizeChange={handleCountersigningTablePageSizeChange}
      handleCountersigningTableChangePage={handleCountersigningTableChangePage}
      countersigningTableMetaData={countersigningTableMetaData}
      countersigningTablePage={countersigningTablePage}
      countersigningTableLimit={countersigningTableLimit}
      countersignTableData={countersignTableData}
      setCountersigningTableMetaData={setCountersigningTableMetaData}
      handleCapCallTablePageSizeChange={handleCapCallTablePageSizeChange}
      handleCapCallTableChangePage={handleCapCallTableChangePage}
      capCallTableMetaData={capCallTableMetaData}
      capCallTablePage={capCallTablePage}
      capCallTableLimit={capCallTableLimit}
      setCapCallTableMetaData={setCapCallTableMetaData}
      capCallTableData={capCallTableData}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      //onSubmit={onSubmit}
      //setType={setType}
      //type={docType}
      //setFreq={setFreq}
      //freq={freq}
    />
  );
};

interface MarketplaceActContainerProps {
  isVisible: boolean;
  closeModal: () => void;
  fund?: Fund | null;
  // TODO: get type from API
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  countersignTableData: any[];
}

export default MarketplaceActContainer;
