export const calcPercentage = (percentage: number, total: number) => {
  return (percentage / total) * 100;
};

export const calcSumByKey = <T>(items: T[], objKey: keyof T): number => {
  return items.reduce((acc, item) => {
    const num = Number(item[objKey]);

    if (typeof num !== 'number') {
      console.error('The iterated object contains an invalid value', item[objKey]);
      return acc;
    }

    return acc + num;
  }, 0);
};
