import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { TextField, IconButton } from '@mui/material';

import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';
import IconArrowLeft from '../../uiLibrary/icons/IconArrowLeft';
import DashboardPhoto from '../../assets/images/dashboard.png';

import { Controller, useForm } from 'react-hook-form';
import { emailValidator } from '../../uiLibrary/reusables/form-validators/formValidators';
import { ForgotPasswordForm } from '../../models/forgotPasswordModels';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

const ForgotPasswordComponent = ({ resetForm, onSubmit, isEmailSend }: ForgotPasswordComponentProps) => {
  const { control, handleSubmit, reset } = useForm<ForgotPasswordForm>({
    defaultValues: {
      Email: '',
    },
  });

  useEffect(() => {
    if (resetForm) {
      reset();
    }
  }, [resetForm]);

  return (
    <div className="login-wrapper">
      <PerfectScrollbar>
        <div className="inner-wrap">
          <div className="split-view-wrapper">
            <div className="col-md-5 col-lg-5 h-100 left-side">
              <div className="fromfield-wrap">
                <div className="content-input">
                  <div className="back-login">
                    <Link className="back-text" to="/login">
                      <IconButton size="large" component="span">
                        <IconArrowLeft stroke="#8F8F8F" />
                      </IconButton>
                      Back
                    </Link>
                  </div>
                  <h2 className="login-title">Forgot Password</h2>
                  <p className="lead">Enter your registered email id and recover the password</p>
                  <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                    <div className="from-row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <Controller
                          name="Email"
                          control={control}
                          rules={{
                            required: 'You have entered a wrong email. Please check again',
                            pattern: { ...emailValidator },
                          }}
                          render={({ field, fieldState }) => (
                            <FormControl fullWidth variant="outlined" className="formControl">
                              <FormLabel>
                                User mail <span className="require">*</span>
                              </FormLabel>
                              <TextField
                                {...field}
                                error={!!fieldState.error}
                                helperText={fieldState.error ? fieldState.error.message : ''}
                                fullWidth
                                type="email"
                                margin="dense"
                                color="info"
                                required
                              />
                            </FormControl>
                          )}
                        />
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 btn-row forgot-call-to-action">
                        <Button type="submit" color="info" variant="contained">
                          Continue
                        </Button>
                      </div>
                      {isEmailSend && (
                        <div className="success-text">
                          <p>A link to reset your password has been sent to your email</p>
                        </div>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="right-side">
              <div className="title-text">
                <h2>Welcome to the future of investment management.</h2>
                <p className="subtitle">
                  Extrance makes your workflow easier, more transparent and allows you to make decisions faster.
                </p>
              </div>
              <img className="dashboard-photo" alt="dashboard" src={DashboardPhoto}></img>
            </div>
          </div>
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default ForgotPasswordComponent;
export interface ForgotPasswordComponentProps {
  resetForm: boolean;
  onSubmit: (formData: ForgotPasswordForm) => void;
  isEmailSend: boolean;
}
