import { Box, Tab, Tabs, Typography } from '@mui/material';
import React from 'react';

interface ItemList {
  tabName: string | React.ReactNode;
  component?: React.ReactNode;
}

const WrapTabs = (props: {
  itemList: ItemList[];
  handleOnChange?: (index: number) => void;
  initialSelectedTab?: number;
}) => {
  const [tabIndex, setTabIndex] = React.useState(props.initialSelectedTab || 0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
    if (props.handleOnChange) {
      props.handleOnChange(newValue);
    }
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
      value: index,
    };
  }

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography component={'span'}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  return (
    <div className="dtl-wrapper">
      <Box className="tabs-wrap mt-0" sx={{ width: '100%' }}>
        <Box className="tab-heading">
          <Tabs
            value={tabIndex}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="basic tabs example"
          >
            {props.itemList?.length > 0 &&
              props.itemList.map((item, index: number) => (
                <Tab key={`${index}`} label={item.tabName} {...a11yProps(index)} />
              ))}
          </Tabs>
        </Box>
        {props.itemList?.length > 0 &&
          props.itemList.map((item, index: number) => (
            <TabPanel key={`tpanel-${index}`} value={tabIndex} index={index}>
              {item.component}
            </TabPanel>
          ))}
      </Box>
    </div>
  );
};

export default WrapTabs;
