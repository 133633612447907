import { FundKpi } from 'src/consts/fundKpi';
import { IndicatorsData, KPIValueType } from 'src/models/partnershipModel';

export const indicatorsDefaultValues: IndicatorsData[] = [
  {
    id: 1,
    Title: 'No data',
    SubTitle: FundKpi.TotalInvestedAmount,
    Value: 0,
    ValueType: KPIValueType.CURRENCY,
    Variance: 0,
    ChartType: 'barchart',
  },
  {
    id: 2,
    Title: 'No data',
    SubTitle: FundKpi.TotalEstimatedReturn,
    Value: 0,
    ValueType: KPIValueType.CURRENCY,
    Variance: 0,
    ChartType: 'barchart',
  },
  {
    id: 3,
    Title: 'No data',
    SubTitle: FundKpi.AverageCapRate,
    Value: 0,
    ValueType: KPIValueType.PERCENTAGE,
    Variance: 0,
    ChartType: 'areachart',
  },
];
