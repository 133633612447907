import { useEffect, useState } from 'react';
import { Grid, Tooltip, Box, MenuItem, IconButton } from '@mui/material';
import Table from '../../../components/common/table/Table';
import AdminUserTablePagination from '../../../components/common/table/TablePagination';

import Select, { SelectChangeEvent } from '@mui/material/Select';

import { styled } from '@mui/material/styles';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import IconDownloadDoc from '../../icons/IconDownloadDoc';
import { Opportunity, OpportunitiesMetaData } from '../../../models/marketPlaceModels';
import { getMrktAct } from '../../../services/marketplaceService';
import { HideLoadingAction, ShowLoadingAction } from '../../../redux/actions/loadingActions';
import { ShowSnackbarAction } from '../../../redux/actions/snackbarActions';
import { RequestError } from 'src/models/supportRequestModels';

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    color: '#fff',
    backgroundColor: '#000',
    fontSize: '12px',
    fontWeight: '500',
    borderRadius: '5px',
    paddingLeft: '12px',
    paddingRight: '12px',
  },
}));

const MarketplaceMovementsTable = ({
  //ndaAction,
  fundId,
  handleChangePage,
  handlePageSizeChange,
  //handleSortChange,
  limit,
  metaData,
  page,
  // setMetaData,
}: //changeAccountStatusHandler,
//openSoftDeleteConfirmationModal,
//childPrivileges,
MarketplaceMovementsTableProps) => {
  // const validActionHeadCellHandler = () => {
  //   if (
  //     !!childPrivileges &&
  //     childPrivileges?.filter(
  //       (privilege: any) =>
  //         (privilege.Name.trim() === "Edit" ||
  //           privilege.Name.trim() === "Delete") &&
  //         !privilege.has_privilege
  //     ).length === 2
  //   )
  //     return true;
  // };

  const handleChangeSelectPage = (event: SelectChangeEvent) => {
    handlePageSizeChange(+event.target.value);
  };
  const [opportunitiesData, setOpportunitiesData] = useState<Opportunity[]>([]);

  useEffect(() => {
    refreshData();
  }, [page, limit]);

  const refreshData = () => {
    ShowLoadingAction();
    getMrktAct(fundId, page + 1, limit, 'created', 'desc', undefined, undefined)
      .then(response => {
        HideLoadingAction();
        setOpportunitiesData(response?.data?.data);
        //setMetaData(response?.data?.meta);
      })
      .catch((error: RequestError) => {
        ShowSnackbarAction({
          message:
            error?.response?.data && error?.response?.data?.message
              ? error?.response?.data?.message
              : 'Something went wrong',
          severity: 'error',
        });
        HideLoadingAction();
      });
  };

  return (
    <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
      <Grid item>
        <Table
          columns={[
            { id: 'investment', label: 'Fund', sortable: true },
            {
              id: 'RequestedAmount',
              label: 'Amount',
              sortable: false,
            },
            { id: 'Stack', label: 'Stake', sortable: false },
            { id: 'seller', label: 'Seller', sortable: true },
            { id: 'buyer', label: 'Buyer', sortable: true },
            {
              id: 'docs',
              label: 'Docs',
            },
          ]}
          data={opportunitiesData.map(mpa => ({
            ...mpa,
            id: `${mpa.id}`,
            seller: mpa.seller.UserName,
            investment: mpa.Fund.name,
            // creationdatetime: format(
            //   new Date(user.creationdatetime),
            //   "MM / dd / yyyy"
            // ),
            docs: [
              <StyledTooltip title="Download" key={2}>
                <IconButton
                  sx={{ color: '#024A72' }}
                  aria-label="download"
                  edge="end"
                  size="large"
                  //onClick={() => editUser(user.userid)}
                >
                  <IconDownloadDoc />
                </IconButton>
              </StyledTooltip>,
            ],
          }))}
          loading={opportunitiesData.length === 0}
          //onSortColumnChange={handleSortChange}
        />
      </Grid>
      <Grid item>
        <Grid container spacing={0} className={'customPagination'}>
          <Grid item xs={6}>
            <Box className="boxPerPage">
              Displaying
              <Select
                className="selectPerPage"
                value={limit.toString()}
                variant="standard"
                onChange={handleChangeSelectPage}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={30}>30</MenuItem>
                <MenuItem value={40}>40</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
              per page
            </Box>
          </Grid>
          <Grid item xs={6}>
            <AdminUserTablePagination
              count={metaData?.total}
              page={page}
              rowsPerPage={limit}
              handleChangePage={handleChangePage}
              handlePageSizeChange={handlePageSizeChange}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

interface MarketplaceMovementsTableProps {
  //mpaList: any;
  fundId: number | undefined;
  metaData: OpportunitiesMetaData;
  page: number;
  //filterStatus: 0 | 1 | undefined;
  limit: number;
  //searchString: string | undefined;
  //ndaAction: () => void;
  handleChangePage: (newPage: number) => void;
  //handleSortChange: (sortColumn: SortColumn) => void;
  handlePageSizeChange: (pageSize: number) => void;
  //changeAccountStatusHandler: (userid: number, status: number) => void;
  //openSoftDeleteConfirmationModal: (modalName: string, userid: number) => void;
  //childPrivileges: ChildPrivilege[] | undefined;
  setMetaData: React.Dispatch<
    React.SetStateAction<{
      total: number;
      page: number;
      limit: number;
      count: number;
    }>
  >;
}

export default MarketplaceMovementsTable;
