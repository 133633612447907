export const userStatus = [
  {
    value: '',
    label: 'Select',
  },
  {
    value: '1',
    label: 'Active',
  },
  {
    value: '0',
    label: 'Inactive',
  },
];
