import { AxiosResponse } from 'axios';
import { LoginPayload, LogoutPayload, ChangePasswordPayload, TermsUserDetails } from '../models/authModels';
import { CreatePasswordPayload, ResetPasswordPayload } from '../models/forgotPasswordModels';
import ServiceClient from './serviceClientConfig';
import { validateError } from '../utils/validateError';
import { OtpVerifyPayload, OtpVerifyResponse } from 'src/types/auth';

const serviceClient = new ServiceClient({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export const login = async (payload: LoginPayload) => {
  const responce = await serviceClient.post('front/login', payload);
  return responce.data as Promise<{ data: TermsUserDetails }>;
};

export const logout = (payload: LogoutPayload) => {
  return serviceClient.post('front/logout', payload);
};

export const forgotPassword = (email: string) => {
  return serviceClient.post(`auth/front/send-mail-forgot-password`, {
    Email: email,
  });
};

export const resetPassword = (payload: ResetPasswordPayload, token: string) => {
  return serviceClient.put(`auth/front/forgot-password-token/${token}`, payload);
};

export const createPassword = (payload: CreatePasswordPayload) => {
  return serviceClient.put(`users/front/lp/new-password-create`, payload);
};

export const changePassword = (payload: ChangePasswordPayload) => {
  return serviceClient.post(`users/change-password`, payload);
};

export const termsAccept = (data: unknown) => {
  return serviceClient.patch('cms/acceptance', data);
};

export const verifyOtp = async (payload: OtpVerifyPayload) => {
  const url = 'front/login/verify';

  try {
    const response: AxiosResponse<OtpVerifyResponse> = await serviceClient.post(url, payload);

    return response.data;
  } catch (error) {
    console.error('verifyOtp error: ', error);
    throw await validateError(error);
  }
};

export const resendOtp = async (email: string) => {
  const url = 'resend';

  try {
    const response = await serviceClient.post(url, { email });

    return response.data;
  } catch (error) {
    console.error('resendOtp error: ', error);
    throw await validateError(error);
  }
};
