import { useState, useMemo } from 'react';
import { Stack } from 'src/components/common';
import { Button } from '@mui/material';
import { DocumentFilters } from 'src/components/common/DocumentFilters';
import { SearchComponent } from 'src/components/common/Search';
import { SearchFilterBarContainer } from 'src/components/common/SearchFilterBarContainer';
import { parseDocumentExtensions } from 'src/views/utils';
import { filterDocumentsBySearch, filterDocumentsByExtension } from './FoldersAndDocuments.helpers';
import { FoldersAndDocumentsList } from './FoldersAndDocuments.list';
import { DocumentData } from 'src/components/documents/document-card/document-card-helpers';
import { Folder } from 'src/types/Documents';
import { Protected } from 'src/components/layouts/Protected/Protected';
import { Permission } from 'src/consts/permissions';

type FoldersAndDocumentsProps = {
  folders: Folder[];
  documents: DocumentData[];
  handleAddFolderButtonClick: () => void;
  handleEditFolderButtonClick: (folderId: number, oldFolderName: string) => void;
  handleDeleteDocumentClick: (documentId: number, documentName: string) => Promise<void>;
};

type DocumentsFilterSettings = {
  search: string;
  extension: string;
};

export const FoldersAndDocuments = ({
  folders,
  documents,
  handleAddFolderButtonClick,
  handleEditFolderButtonClick,
  handleDeleteDocumentClick,
}: FoldersAndDocumentsProps) => {
  const [documentsFilterSettings, setDocumentsFilterSettings] = useState<DocumentsFilterSettings>({
    search: '',
    extension: '',
  });

  const onSearchDocument = (value: string) => {
    setDocumentsFilterSettings(state => ({
      ...state,
      search: value,
    }));
  };

  const handleFilterChange = (extension: string) => {
    setDocumentsFilterSettings(state => ({
      ...state,
      extension,
    }));
  };

  const filterStatus = !!documentsFilterSettings.search || documentsFilterSettings.extension;

  const extensionList = parseDocumentExtensions(documents);

  const filteredDocumentsList = useMemo(() => {
    const { search, extension } = documentsFilterSettings;

    let list = documents.slice();

    if (search) {
      list = filterDocumentsBySearch(list, search);
    }

    if (extension) {
      list = filterDocumentsByExtension(list, extension);
    }

    return list;
  }, [documents, documentsFilterSettings]);

  return (
    <div className="documents-details-page-section">
      <Stack className="documents-details-page-section-header">
        <h2>Documents</h2>
        {documents && (
          <div className="documents-details-page-section-header-actions">
            <Stack direction="column" spacing={1} alignItems="flex-end">
              <SearchFilterBarContainer
                filterComponent={
                  <DocumentFilters
                    handleFilterChange={handleFilterChange}
                    // TODO: Fix it. Set boolean type
                    filterStatus={Number(filterStatus) as 0 | 1 | undefined}
                    extensionList={extensionList}
                  />
                }
                searchComponent={
                  <SearchComponent onSearch={onSearchDocument} initialSearchValue="" placeholder="Search by name" />
                }
              />
              <Protected permissions={[Permission.CustomFolderManagement]}>
                <Button disableElevation onClick={handleAddFolderButtonClick} className="min-width-primary-button">
                  <h4>Add Custom Folder</h4>
                </Button>
              </Protected>
            </Stack>
          </div>
        )}
      </Stack>

      <FoldersAndDocumentsList
        folders={folders}
        documents={filteredDocumentsList}
        handleDeleteDocumentClick={handleDeleteDocumentClick}
        handleEditFolderButtonClick={handleEditFolderButtonClick}
      />
    </div>
  );
};
