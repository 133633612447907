import { LoginRole } from '../types/Roles';

export interface LoginPayload {
  Email: string;
  Password: string;
  Location?: string | null;
}

export interface LogoutPayload {
  Location: string | null;
}
export interface UserDetails {
  AccountStatus: string;
  Address: string;
  AgencyName: string;
  City: string;
  CompanyName: string;
  ContactNumber: string;
  CountryCode: string;
  CountryId: number;
  CreationDateTime: string;
  CreationUserId: string;
  DateOfBirth: string;
  DeletionDateTime: string;
  DeletionUserID: string;
  Email: string;
  EmailNotificationsEnabled: number;
  EmailVerified: boolean;
  FirstFlowLogin: boolean;
  FirstName: string;
  IsActive: number;
  IsTemporary: boolean;
  LastLoginDateTime: null;
  LastModificationDateTime: string;
  LastModificationUserId: number;
  LastName: string;
  MobileVerified: boolean;
  Password: string;
  ProfileImage: string;
  ResetPasswordDateTime: string;
  StateId: number;
  Stripe_customer_id: string;
  SubscriptionUntil: string;
  UserName: string;
  UserType: UserType;
  ZipCode: string;
  roleId: string;
  // TODO: get type from API
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  roles: any;
  stripe_account_id: string;
  UserId: number;
  IsBankExist: boolean;
  permissions: string[];
  role: {
    id: number;
    Name: string;
    Slug: string;
    IsActive: 1 | 0;
  };
  fundPermissions: LoginRole['fund_privilege'];
}

export enum UserType {
  Admin = 1,
  GP = 2,
  LP = 3,
}

export interface ChangePasswordPayload {
  CurrentPassword: string;
  NewPassword: string;
}

export interface CreatePasswordForm {
  Password: string;
  Confirm_password: string;
}

export interface TermsUserDetails {
  accessToken?: string;
  refreshToken?: string;
  expiresIn: string;
  user: UserDetails;
  roles: LoginRole;
}
