import React from 'react';

const IconCamera = () => {
  return (
    <svg width="36" height="30" viewBox="0 0 36 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M32.5814 5.5814H26.5324L25.7526 1.68258C25.5576 0.707651 24.6945 0 23.7002 0H12.0207C11.0265 0 10.1633 0.707651 9.96837 1.68258L9.18858 5.5814H3.13953C1.4084 5.5814 0 6.98979 0 8.72093V26.8605C0 28.5916 1.4084 30 3.13953 30H32.5814C34.3125 30 35.7209 28.5916 35.7209 26.8605V8.72093C35.7209 6.98979 34.3125 5.5814 32.5814 5.5814ZM12.0207 2.09302H23.7002L24.3979 5.5814H11.323L12.0207 2.09302ZM33.6279 26.8605C33.6279 27.4375 33.1584 27.907 32.5814 27.907H3.13953C2.56249 27.907 2.09302 27.4375 2.09302 26.8605V8.72093C2.09302 8.14388 2.56249 7.67442 3.13953 7.67442H32.5814C33.1584 7.67442 33.6279 8.14388 33.6279 8.72093V26.8605Z"
        fill="#F34A29"
      />
      <path
        d="M17.8605 10.0469C13.5903 10.0469 10.1163 13.5209 10.1163 17.7911C10.1163 22.0612 13.5903 25.5352 17.8605 25.5352C22.1306 25.5352 25.6046 22.0612 25.6046 17.7911C25.6046 13.5209 22.1306 10.0469 17.8605 10.0469ZM17.8605 23.4422C14.7444 23.4422 12.2093 20.9071 12.2093 17.7911C12.2093 14.675 14.7444 12.1399 17.8605 12.1399C20.9766 12.1399 23.5116 14.675 23.5116 17.7911C23.5116 20.9071 20.9766 23.4422 17.8605 23.4422Z"
        fill="#F34A29"
      />
      <path
        d="M8.93026 10.0469H5.58142C5.00347 10.0469 4.53491 10.5154 4.53491 11.0934C4.53491 11.6713 5.00347 12.1399 5.58142 12.1399H8.93026C9.50821 12.1399 9.97677 11.6713 9.97677 11.0934C9.97677 10.5154 9.50821 10.0469 8.93026 10.0469Z"
        fill="#F34A29"
      />
    </svg>
  );
};

export default IconCamera;
