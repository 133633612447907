import { GovernanceKpi } from '../consts/governanceKpi';

export const isValidGovernanceKpi = (kpi: GovernanceKpi) =>
  [
    GovernanceKpi['Current Cost'],
    GovernanceKpi['Multiple (Realizations)'],
    GovernanceKpi['Multiple (Total Return)'],
    GovernanceKpi['Multiple (Unrealized)'],
    GovernanceKpi['Realized Proceeds'],
    GovernanceKpi['Reported Value'],
    GovernanceKpi['Total Commitment'],
    GovernanceKpi['Total Invested'],
  ].includes(kpi);
