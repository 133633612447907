export enum LoadingActionTypes {
  SHOW_LOADING = '[LOADING] Show Loading',
  HIDE_LOADING = '[LOADING] Hide Loading',
}

export interface LoadingActions {
  type: LoadingActionTypes;
  // TODO: get type from API
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
}

export const ShowLoadingAction = (payload = '') => {
  return { type: LoadingActionTypes.SHOW_LOADING, payload: payload };
};

export const HideLoadingAction = () => {
  return { type: LoadingActionTypes.HIDE_LOADING };
};
