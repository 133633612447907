import { UserDetails } from '../../models/authModels';
import { CreatePasswordPayload } from '../../models/forgotPasswordModels';
import { createPassword } from '../../services/loginService';
import { AppDispatch } from '../store';
import { HideLoadingAction, ShowLoadingAction } from './loadingActions';
import { ShowSnackbarAction } from './snackbarActions';

export enum AuthActionTypes {
  LOGIN_SUCCESS = '[AUTH] Login Success',
  LOGIN_FAILURE = '[AUTH] Login Failure',
  LOGOUT_SUCCESS = '[AUTH] Logout Success',
  LOGOUT_FAILURE = '[AUTH] Logout Failure',
  LOCAL_STORAGE_FETCHED = '[AUTH] Local Storage Fetched',
  TEMPORARY_USER = '[AUTH] Temporary User',
  CREATE_PASSWORD_SUCCESS = '[AUTH] Create Password Success',
  CREATE_PASSWORD_FAILURE = '[AUTH] Create Password Failure',
  TERMS_LOGIN_SUCCESS = '[AUTH] Terms Login Success',
  UPDATE_PROFILE_IMAGE_SUCCESS = '[AUTH] Update Profile Image Success',
}

export interface AuthActions {
  type: AuthActionTypes;
  // TODO: get type from API
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
  // TODO: get type from API
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rolePrivilege?: any;
}

export const CreatePasswordAction = (payload: CreatePasswordPayload) => {
  return (dispatch: AppDispatch) => {
    dispatch(ShowLoadingAction());
    return createPassword(payload)
      .then(response => {
        if (response.data.status === 200) {
          dispatch(
            ShowSnackbarAction({
              message: response?.data?.message,
              severity: 'success',
            }),
          );

          dispatch(CreatePasswordSuccessAction());
        } else {
          dispatch(
            ShowSnackbarAction({
              message: response?.data?.message ? response.data.message : 'Something went wrong.',
              severity: 'error',
            }),
          );
          dispatch(CreatePasswordFailureAction());
        }
        dispatch(HideLoadingAction());
      })
      .catch(error => {
        dispatch(
          ShowSnackbarAction({
            message: error?.response?.data?.message ? error.response.data.message : 'Something went wrong.',
            severity: 'error',
          }),
        );
        dispatch(CreatePasswordFailureAction());
        dispatch(HideLoadingAction());
      });
  };
};

export const CreatePasswordSuccessAction = () => {
  return { type: AuthActionTypes.CREATE_PASSWORD_SUCCESS };
};

export const CreatePasswordFailureAction = () => {
  return { type: AuthActionTypes.CREATE_PASSWORD_FAILURE };
};

export const LocalStorageFetchedAction = (payload: UserDetails) => {
  return { type: AuthActionTypes.LOCAL_STORAGE_FETCHED, payload: payload };
};

// TODO: get type from API
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const UpdateProfileImageAction = (payload: any) => {
  return { type: AuthActionTypes.UPDATE_PROFILE_IMAGE_SUCCESS, payload };
};
