import { ThemeCssVarOverrides } from '@mui/material';

export const dialogComponentsTheme: ThemeCssVarOverrides = {
  MuiDialog: {
    styleOverrides: {
      root: {
        '& > .MuiBackdrop-root': {
          background: 'rgba(246, 245, 244, 0.85) !important',
          backdropFilter: 'blur(2px)',
        },
      },
      paper: {
        borderRadius: '16px',
        border: '1px solid #E1E1E1',
        background: '#ffffff',
        padding: '44px',
        boxShadow: 'none',
      },
      paperWidthSm: {
        maxWidth: '600px',
      },
    },
  },

  MuiDialogActions: {
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
  },

  MuiDialogContent: {
    styleOverrides: {
      root: {
        padding: 0,
        marginBottom: '24px',
        overflowY: 'auto',
      },
    },
  },

  MuiDialogContentText: {
    styleOverrides: {
      root: {
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '22.4px',
        color: '#8F8F8F',
        textAlign: 'left',
        padding: 0,
        marginBottom: '8px',
      },
    },
  },

  MuiDialogTitle: {
    styleOverrides: {
      root: {
        fontSize: '20px',
        fontWeight: '600',
        lineHeight: '32px',
        color: '#000000',
        textAlign: 'left',
        padding: 0,
        marginBottom: '8px',
      },
    },
  },
};
