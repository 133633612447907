const debounce = <T, K>(fn: (...args: T[]) => K, delay: number) => {
  let timer: NodeJS.Timeout;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function returnedFunction(this: any, ...args: T[]) {
    clearTimeout(timer);
    timer = window.setTimeout(() => fn.apply(this, args), delay) as unknown as NodeJS.Timeout;
  };
};

export default debounce;
