import React from 'react';

const IconThreeDots = () => {
  return (
    <svg width="10" height="2" viewBox="0 0 10 2" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 2C0.802219 2 0.608879 1.94135 0.44443 1.83147C0.279981 1.72159 0.151809 1.56541 0.0761209 1.38268C0.000433291 1.19996 -0.01937 0.998891 0.0192152 0.80491C0.0578004 0.610929 0.153041 0.432746 0.292894 0.292893C0.432746 0.153041 0.610929 0.0577998 0.80491 0.0192146C0.998891 -0.0193705 1.19996 0.000432744 1.38268 0.0761204C1.56541 0.151808 1.72159 0.279981 1.83147 0.44443C1.94135 0.608879 2 0.802219 2 1C2 1.26522 1.89464 1.51957 1.70711 1.70711C1.51957 1.89464 1.26522 2 1 2ZM6 1C6 0.802219 5.94135 0.608879 5.83147 0.44443C5.72159 0.279981 5.56541 0.151808 5.38268 0.0761204C5.19996 0.000432792 4.99889 -0.0193705 4.80491 0.0192147C4.61093 0.0577999 4.43275 0.153041 4.29289 0.292893C4.15304 0.432746 4.0578 0.610929 4.01922 0.80491C3.98063 0.998891 4.00043 1.19996 4.07612 1.38268C4.15181 1.56541 4.27998 1.72159 4.44443 1.83147C4.60888 1.94135 4.80222 2 5 2C5.26522 2 5.51957 1.89464 5.70711 1.70711C5.89464 1.51957 6 1.26522 6 1ZM10 1C10 0.802219 9.94135 0.608879 9.83147 0.44443C9.72159 0.279981 9.56541 0.151808 9.38268 0.0761205C9.19996 0.000432839 8.99889 -0.0193704 8.80491 0.0192147C8.61093 0.0577999 8.43275 0.153041 8.29289 0.292894C8.15304 0.432746 8.0578 0.610929 8.01921 0.80491C7.98063 0.998891 8.00043 1.19996 8.07612 1.38268C8.15181 1.56541 8.27998 1.72159 8.44443 1.83147C8.60888 1.94135 8.80222 2 9 2C9.26522 2 9.51957 1.89464 9.70711 1.70711C9.89464 1.51957 10 1.26522 10 1Z"
        fill="#B6B6B6"
      />
    </svg>
  );
};

export default IconThreeDots;
