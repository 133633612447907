import { useState } from 'react';

export const useModal = <T>(modal?: T) => {
  const [openModal, setOpenModal] = useState<T | null>(modal ?? null);

  const handleCloseModal = () => setOpenModal(null);
  const handleOpenModal = (mdl: T) => setOpenModal(mdl);
  const isOpen = (mdl: T) => openModal === mdl;

  return { isOpen, handleOpenModal, handleCloseModal };
};
