import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import NotificationBarComponent from './notificationBarComponent';
import { RootState } from '../../../redux/store';
import { NotificationData } from '../../../models/notificationModels';

import { UserDetails } from '../../../models/authModels';
import { HideLoadingAction, ShowLoadingAction } from '../../../redux/actions/loadingActions';
import { ShowSnackbarAction } from '../../../redux/actions/snackbarActions';
import { SnackbarPayload } from '../../../models/snackbarModels';
import { getNotifications } from '../../../services/notificationServices';
import { RequestError } from 'src/models/supportRequestModels';
const NotificationBarContainer = ({
  ShowLoadingAction,
  HideLoadingAction,
  ShowSnackbarAction,
  userDetails,
  loadingVisible,
  isPerfectScrollBar,
  handleNotificationClose,
}: NotificationBarContainerProps) => {
  const [page, setPage] = useState<number>(1);
  const limit = 10;

  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<NotificationData[]>([]);

  const getNotificationsHandler = () => {
    setPage(page => page + 1);
  };

  useEffect(() => {
    getFetchNotificationHandler();
  }, [page, limit, userDetails]);

  const getFetchNotificationHandler = async () => {
    if (userDetails?.UserId)
      try {
        ShowLoadingAction();
        const result = await getNotifications(userDetails.UserId, page, limit);

        const currentPage = result?.data?.data?.meta?.page;
        const currentLimit = result?.data?.data?.meta?.limit;
        const totalContent = result?.data?.data?.meta?.totalContent;
        const hasNextPage = currentPage * currentLimit < totalContent ? true : false;

        if (result?.data?.data?.notifications) {
          setNotifications([...notifications, ...result.data.data.notifications]);
        }

        setHasNextPage(hasNextPage);

        HideLoadingAction();
      } catch (error) {
        const e = error as RequestError;
        HideLoadingAction();
        ShowSnackbarAction({
          message: e?.response?.data?.message ? e?.response?.data?.message : 'Something went wrong.',
          severity: 'error',
        });
      }
  };

  return (
    <NotificationBarComponent
      notifications={notifications}
      hasNextPage={hasNextPage}
      getNotificationsHandler={getNotificationsHandler}
      loadingVisible={loadingVisible}
      isPerfectScrollBar={isPerfectScrollBar}
      handleNotificationClose={handleNotificationClose}
    />
  );
};
interface NotificationBarContainerProps {
  userDetails: UserDetails;
  ShowLoadingAction: (payload?: string) => void;
  HideLoadingAction: () => void;
  ShowSnackbarAction: (payload: SnackbarPayload) => void;
  loadingVisible: boolean;
  isPerfectScrollBar?: boolean;
  handleNotificationClose?: () => void;
}
const mapStateToProps = (state: RootState) => {
  return {
    userDetails: state.auth.userDetails,
    loadingVisible: state.loading.visible,
  };
};
const mapDispatchToProps = {
  ShowLoadingAction,
  HideLoadingAction,
  ShowSnackbarAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(NotificationBarContainer);
