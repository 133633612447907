import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'src/context/ShackbarContext';
import { getUserList } from 'src/services/adminUserServices';
import { ApiError } from 'src/types/api';

export const useUsersList = (
  page: number,
  pageSize: number,
  searchString: string | undefined,
  sortField: 'Name' | 'Role' | 'Account Created' | 'Account Status',
  sortOrder: 'asc' | 'desc',
  filterStatus?: 1 | 0,
  userType?: 1 | 2 | 3,
  filterRole?: string,
) => {
  const { showErrorSnackbar } = useSnackbar();

  return useQuery({
    queryKey: ['frontendUsers', page, pageSize, searchString, sortField, sortOrder, filterStatus, userType, filterRole],
    queryFn: async () => {
      try {
        return getUserList(page, sortField, sortOrder, pageSize, filterStatus, filterRole, userType, searchString);
      } catch (error) {
        showErrorSnackbar((error as AxiosError<ApiError>).response?.data.message);
        console.error('useFunds error', error);
        throw new Error((error as AxiosError<ApiError>).response?.data.message);
      }
    },
  });
};
