import { Breadcrumbs, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import CrudComponent from '../../components/common/CrudComponent';
import Table from '../../components/common/table';
import { formatDateNoTime, renderCurrency } from '../utils';

import { getDistributionHistory } from '../../services/governanceService';
import { useDispatch } from 'react-redux';
import { ShowSnackbarAction } from '../../redux/actions/snackbarActions';
import { HideLoadingAction, ShowLoadingAction } from '../../redux/actions/loadingActions';
import { RequestError } from 'src/models/supportRequestModels';
import { Loader } from 'src/components/common/Loader';
import { getFundDetail } from 'src/services/fundsService';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useSnackbar } from 'src/context/ShackbarContext';
import { QueryKey } from 'src/consts/query';
import { ApiError } from 'src/types/api';

const DistributionHistory = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const { showErrorSnackbar } = useSnackbar();

  const {
    data: fundInfo,
    isLoading: fundLoading,
    // isError: fundError,
  } = useQuery({
    queryKey: [QueryKey.Fund, params.fundId],
    queryFn: async () => {
      if (params.fundId) {
        try {
          const responce = await getFundDetail(Number(params.fundId));
          return responce?.data;
        } catch (e) {
          console.log('fundInfo error', e);
          showErrorSnackbar((e as AxiosError<ApiError>).response?.data.message);
          throw new Error((e as AxiosError<ApiError>).response?.data.message);
        }
      }
      throw new Error('No Fund was provided');
    },
  });

  const [fundId, setFundId] = useState<number>(-1);
  const [distributions, setDistributions] = useState<HistoricDistribution[]>([]);

  useEffect(() => {
    if (params && params.fundId) {
      const fundId = parseInt(params.fundId);
      setFundId(fundId);
      fetchDistributionHistory(fundId);
    }
  }, []);

  const fetchDistributionHistory = async (fundId: number) => {
    try {
      dispatch(ShowLoadingAction());
      const response = await getDistributionHistory(fundId);
      dispatch(HideLoadingAction());
      if (response.data.data && response.data.data.length > 0) {
        const distributionsLocal = response.data.data.map((distribution: HistoricDistribution) => {
          return {
            id: distribution.id,
            Amount: distribution.Amount,
            manualAmount: distribution.manualAmount,
            doneCount: distribution.doneCount,
            EventDate: distribution.EventDate,
          };
        });
        setDistributions(distributionsLocal);
      }
    } catch (error) {
      const e = error as RequestError;
      dispatch(HideLoadingAction());
      setDistributions([]);
      dispatch(
        ShowSnackbarAction({
          message: e?.response?.data?.message || 'Something went wrong while fetching distributions history',
          severity: 'error',
        }),
      );
    }
  };

  const parsedDataForTable = useMemo(() => {
    return distributions.map(distribution => {
      return {
        ...distribution,
        id: distribution.id,
        totalDistributed: renderCurrency(distribution.Amount),
        manualTotal: renderCurrency(distribution.manualAmount),
        created: formatDateNoTime(distribution.EventDate),
        lpCoverage: `${distribution.doneCount} fulfilled`,
      };
    });
  }, [distributions]);

  const fund = fundInfo?.fund;

  if (fundLoading) return <Loader />;

  return (
    <>
      <div className="content-wrap">
        <Breadcrumbs className="breadcrumb mb-15">
          {[
            <Link key="governance" color="inherit" to="/governance">
              Banking
            </Link>,
            <Link key={fund?.name} color="inherit" to={`/governance/detail/${fundId}`}>
              {fund?.name}
            </Link>,
            <Typography key="Review Payments">Distributions history</Typography>,
          ]}
        </Breadcrumbs>
      </div>

      <div className="d-flex">
        <div className="title-and-subtitle-section">
          <h2>{fund?.name}</h2>
          <h4>Fund Level</h4>
        </div>
      </div>

      <CrudComponent
        title="Distributions history"
        tableLayout={
          <Table
            columns={columns}
            data={parsedDataForTable}
            loading={false}
            onClick={row => {
              navigate(`${row.id}`, { state: { fundName: fund?.name } });
            }}
          />
        }
        filterComponent={
          <div
            style={{
              display: 'grid',
              placeContent: 'center',
              height: '100%',
            }}
          >
            <p>Coming soon...</p>
          </div>
        }
      />
    </>
  );
};

export type HistoricDistribution = {
  id: string;
  Amount: number;
  manualAmount: number;
  doneCount: number;
  EventDate: string;
};

const columns = [
  { id: 'id', label: 'id', sortable: false },
  { id: 'created', label: 'Created', sortable: true },
  { id: 'totalDistributed', label: 'Total distributed', sortable: true },
  { id: 'manualTotal', label: 'Manual payments', sortable: true },
  { id: 'lpCoverage', label: 'LP coverage', sortable: true },
];

export enum TransactionType {
  AUTOMATED = 'Automated',
  MANUAL = 'Manual',
}

export default DistributionHistory;
