import { DocumentData } from '../components/documents/document-card/document-card-helpers';

export const currentFormat = (num: number, minFractionDigits?: number) => {
  return Number(num).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: minFractionDigits || 0,
    maximumFractionDigits: 2,
  });
};

export const currentFormatWithDecimals = (value: number) => {
  return currentFormat(parseFloat(parseFloat(value.toString()).toFixed(2)), 2);
};

export const currentFormatNoDollarSign = (num: number | string, minFractionDigits?: number) => {
  return Number(num).toLocaleString('en-US', {
    minimumFractionDigits: minFractionDigits || 0,
    maximumFractionDigits: 2,
  });
};

export function capitalizeFirstLetter(str?: string) {
  return str ? str.charAt(0).toUpperCase() + str.slice(1) : '';
}

export const renderCurrency = (amount: number | string) => `$ ${currentFormatNoDollarSign(amount, 2)}`;

export const parseToNumberFromCurrency = (amount: string) => {
  const stringWithoutCommas = amount.replace(/,/g, '');
  // Parse the string to a float with two decimal places
  const parsedNumber = parseFloat(stringWithoutCommas).toFixed(2);
  // Convert the result back to a float
  return parseFloat(parsedNumber);
};

export const renderPercentage = (percentage: number) => `${currentFormatNoDollarSign(percentage, 2)} %`;

/* Output example: 28 Oct 2022 - 5:40 pm */
export function formatDate(stringDate: string) {
  const date = new Date(stringDate);
  const month = date.toLocaleString('default', { month: 'short' });
  let hours = date.getHours();
  let minutes: string | number = date.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  const strTime = hours + ':' + minutes + ' ' + ampm;
  return date.getDate() + ' ' + month + ' ' + date.getFullYear() + ' - ' + strTime;
}

export const getTodaysDateFormatted = () => {
  const today = new Date();
  const options: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  };
  const formattedDate: string = today.toLocaleDateString('en-US', options);
  return formattedDate;
};

export function formatDateNoTime(stringDate: string, ignoreTime = false) {
  const date = new Date(ignoreTime ? stringDate + 'T12:00' : stringDate);
  const month = date.toLocaleString('default', { month: 'short' });
  return date.getDate() + ' ' + month + ' ' + date.getFullYear();
}

export const formatFileExtension = (ext: string) => {
  switch (ext) {
    case '.xlsx':
    case '.xls':
      return 'xls';
    case '.png':
      return 'png';
    case '.csv':
      return 'csv';
    case '.pdf':
      return 'pdf';
    default:
      return 'file';
  }
};

export const parseDocumentExtensions = (docs: DocumentData[] | null) => {
  if (docs) {
    return docs.map(d => d.extension).filter((v, i, a) => a.indexOf(v) === i);
  }
  return [];
};
