import { FundDetailComponent } from './FundDetailComponent';

export const FundDetailContainer = () => {
  return (
    //TODO: will be replaced after layout refactore
    <div className="relative page-without-header-container">
      <FundDetailComponent />
    </div>
  );
};
