import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Stack } from 'src/components/common';
import Button from 'src/components/common/Button/Button';
import { Loader } from 'src/components/common/Loader';

export interface ConfirmDialogComponentProps {
  isVisible: boolean;
  title: string;
  dialogContentText?: string | Array<string>;
  confirmButtonText?: string;
  loading?: boolean;
  closeButtonText?: string;
  confirmModal: () => void;
  closeModal: () => void;
}

const ConfirmDialogComponent = ({
  isVisible,
  confirmModal,
  closeModal,
  title,
  dialogContentText,
  loading,
  confirmButtonText = 'Yes',
  closeButtonText = 'No',
}: ConfirmDialogComponentProps) => {
  return (
    <Dialog
      open={isVisible}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
      maxWidth="sm"
      fullWidth
      onClose={(event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
          return;
        }
      }}
    >
      {!loading ? (
        <>
          <DialogTitle>{title}</DialogTitle>

          <DialogContent>
            {!!dialogContentText && <DialogContentText>{dialogContentText}</DialogContentText>}
          </DialogContent>

          <DialogActions>
            <Stack direction="row" justifyContent="space-between" flexBasis="100%" gap={2}>
              <Button onClick={() => closeModal()} size="small" order="secondary" variant="secondary" fullWidth>
                {closeButtonText}
              </Button>
              <Button onClick={() => confirmModal()} size="small" order="primary" variant="primary" fullWidth>
                {confirmButtonText}
              </Button>
            </Stack>
          </DialogActions>
        </>
      ) : (
        <Loader />
      )}
    </Dialog>
  );
};

export default ConfirmDialogComponent;
