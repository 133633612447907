import axios, { AxiosInstance, AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import isPast from 'date-fns/isPast';
import { LocalStorageService } from './LocalStorage';

export default class ServiceClient {
  client: AxiosInstance;
  constructor(config: AxiosRequestConfig) {
    this.client = axios.create(config);
    this.client.interceptors.request.use(
      config => {
        const accessToken = LocalStorageService.getItem('extrance_access_token');
        const token_expiry = new Date(Number(LocalStorageService.getItem('extrance_expires_in')) * 1000);
        const refresh_token = LocalStorageService.getItem('extrance_refresh_token');

        if (accessToken && token_expiry) {
          if (isPast(token_expiry)) {
            axios
              .get(process.env.REACT_APP_BASE_URL + `generate-token?refreshToken=${refresh_token}`)
              .then(response => {
                const newAccessToken = response.data?.tokens?.accessToken;
                if (newAccessToken) {
                  LocalStorageService.setItem('extrance_access_token', newAccessToken);
                }
                LocalStorageService.setItem('extrance_refresh_token', response.data?.tokens?.refreshToken);
                LocalStorageService.setItem('extrance_expires_in', response.data?.tokens?.expiresIn);
              })
              .catch(() => {
                LocalStorageService.removeItem('extrance_access_token');
                LocalStorageService.removeItem('extrance_refresh_token');
                LocalStorageService.removeItem('extrance_expires_in');
                LocalStorageService.removeItem('extrance_user');
              });
          }
          if (config.headers) {
            config.headers['Authorization'] = 'Bearer ' + accessToken;
          }
          if (!config.headers) {
            config.headers = {} as AxiosRequestHeaders;
            config.headers['Authorization'] = 'Bearer ' + accessToken;
          }
        }
        return config;
      },
      error => {
        return Promise.reject(error);
      },
    );

    this.client.interceptors.response.use(
      response => {
        return response;
      },
      async error => {
        const originalConfig = error?.config;
        if ((error?.response?.status === 401 || error?.response?.status === 403) && !originalConfig._retry) {
          originalConfig._retry = true;
          await new Promise(resolve => setTimeout(resolve, 1000));
          return this.client(originalConfig);
        } else if ((error?.response?.status === 401 || error?.response?.status === 403) && originalConfig._retry) {
          LocalStorageService.removeItem('extrance_access_token');
          LocalStorageService.removeItem('extrance_refresh_token');
          LocalStorageService.removeItem('extrance_expires_in');
          LocalStorageService.removeItem('extrance_user');
        }

        return Promise.reject(error);
      },
    );
  }

  get(endpoint: string, config: AxiosRequestConfig = {}) {
    return this.client.get(endpoint, config);
  }

  post<T>(endpoint: string, payload?: T, options?: AxiosRequestConfig) {
    return this.client.post(endpoint, payload, options);
  }

  put<T>(endpoint: string, payload: T) {
    return this.client.put(endpoint, payload);
  }

  delete<T>(endpoint: string, payload?: T) {
    return this.client.delete(endpoint, { data: payload });
  }

  patch<T>(endpoint: string, payload: T) {
    return this.client.patch(endpoint, payload);
  }
}
