import React from 'react';

const IconChatGroup = () => {
  return (
    <svg width="23" height="17" viewBox="0 0 23 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.4442 14.9967V13.4411C13.4442 12.616 13.1165 11.8247 12.533 11.2413C11.9496 10.6578 11.1583 10.3301 10.3332 10.3301H4.11106C3.28595 10.3301 2.49464 10.6578 1.91121 11.2413C1.32777 11.8247 1 12.616 1 13.4411V14.9967"
        stroke="#F34A29"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.22202 7.22212C8.94021 7.22212 10.3331 5.82925 10.3331 4.11106C10.3331 2.39287 8.94021 1 7.22202 1C5.50383 1 4.11096 2.39287 4.11096 4.11106C4.11096 5.82925 5.50383 7.22212 7.22202 7.22212Z"
        stroke="#F34A29"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.1109 15.001V13.4455C18.1104 12.7562 17.8809 12.0866 17.4586 11.5418C17.0363 10.997 16.445 10.6079 15.7776 10.4355"
        stroke="#F34A29"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6665 1.10156C13.3357 1.2729 13.9288 1.6621 14.3524 2.20778C14.776 2.75347 15.0059 3.42461 15.0059 4.1154C15.0059 4.80619 14.776 5.47733 14.3524 6.02301C13.9288 6.5687 13.3357 6.95789 12.6665 7.12924"
        stroke="#F34A29"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M19.6663 4.88672V9.5533" stroke="#F34A29" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M21.9997 7.2168H17.3331" stroke="#F34A29" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default IconChatGroup;
