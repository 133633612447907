import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconNotify from '../../icons/IconNotify';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconLogout from '../../icons/IconLogout';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import { IconClose } from '../../icons/IconClose';
import ChatListContainer from '../chat-list/chatListContainer';
import CreateGroupChatContainer from '../create-group-chat/createGroupChatContainer';
import ChatBoardContainer from '../chat-board/chatBoardContainer';
import { UserDetails } from '../../../models/authModels';
import NotificationBarContainer from '../notification-bar/notificationBarContainer';
import { Client } from '@twilio/conversations';
import IconKey from '../../icons/IconKey';
import { CustomConversation } from '../../../models/chatModels';
import IconShareNDAAgreement from '../../icons/IconShareNDAAgreement';
import IconDialogBubble from '../../icons/IconDialogBubble';

const HeaderComponent = ({
  title,
  subtitle,
  confirmLogout,
  anchorEl,
  setAnchorVal,
  userDetails,
  // navigateToSupportRequest,
  // navigateToFaq,
  // navigateToMySubscription,
  // navigateToGlobalSearch,
  totalNotification,
  resetNewNotificationCountHandler,
  client,
  changePasswordModal,
  openChat,
  setOpenChat,
  customConvo,
  setCustomConvo,
  screenVal,
  setScreenVal,
  createNdaRequestModal,
}: HeaderComponentProps) => {
  const openMenu = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorVal(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorVal(null);
  };

  const [isOpen, setIsOpen] = useState(false);

  const handleNotificationOpen = () => {
    resetNewNotificationCountHandler();
    setIsOpen(true);
  };

  const handleNotificationClose = () => {
    setIsOpen(false);
  };

  const handleChatDrawerOpen = () => {
    setOpenChat(true);
  };

  const handleChatDrawerClose = () => {
    setOpenChat(false);
    setScreenVal(0);
  };
  const getInitials = (name: string) => {
    if (name) {
      const matches = name.match(/\b\w/g);

      if (matches) {
        return matches.join('').toUpperCase();
      } else {
        return 'EU';
      }
    } else {
      return '';
    }
  };

  const handleToggle = () => {
    document.body.classList.toggle('open-sidebar');
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchText, setSearchText] = useState<string | null>('');

  // const search = () => {
  //   if (searchText) {
  //     navigateToGlobalSearch(searchText);
  //   }
  // };

  return (
    <>
      <IconButton onClick={handleToggle} className="toggle-bar" size="large">
        <span className="bar top-bar"></span>
        <span className="bar middle-bar"></span>
        <span className="bar bottom-bar"></span>
      </IconButton>
      <header className="header">
        <div className="h-title-subtitle">
          <h2>{title}</h2>
          <p className="subtitle">{subtitle}</p>
        </div>
        <div className="h-nav-items">
          <Stack className="stack-root" direction="row" spacing={1}>
            <div className="d-flex align-items-center header-navbar-container">
              {/* <form
                onSubmit={(e) => {
                  e.preventDefault();
                  search();
                }}
                className="global-search-input"
              >
                <FormControl
                  fullWidth
                  variant="outlined"
                  className="formControl"
                >
                  <TextField
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    type="search"
                    variant="outlined"
                    placeholder="Search"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconSearch color="black" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </form> */}
              <Button className="header-icon" variant="text" onClick={() => handleChatDrawerOpen()}>
                <IconDialogBubble />
              </Button>
              <Button className="header-icon" variant="text" onClick={handleNotificationOpen}>
                <IconNotify color="black" />
                {!!totalNotification && totalNotification > 0 && (
                  <span className="badge-text">{totalNotification}</span>
                )}
              </Button>
              <Button
                className={
                  openMenu === true
                    ? 'header-icon active icon-border avater-small-icon'
                    : 'header-icon icon-border avater-small-icon'
                }
                onClick={handleClick}
              >
                {userDetails?.ProfileImage ? (
                  <img src={userDetails?.ProfileImage} alt={userDetails?.UserName}></img>
                ) : (
                  <label style={{ cursor: 'pointer' }} className="avater-label">
                    {getInitials(userDetails?.UserName)}
                  </label>
                )}
              </Button>
              <Menu
                className="user-profile-menu"
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <div className="profile-border">
                  <div className="media">
                    <span className="avater-small-icon d-flex align-self-center">
                      {userDetails?.ProfileImage ? (
                        <img
                          className="d-flex align-self-center"
                          src={userDetails?.ProfileImage}
                          alt={userDetails?.UserName}
                        ></img>
                      ) : (
                        <label style={{ cursor: 'pointer' }} className="avater-label ">
                          {getInitials(userDetails?.UserName)}
                        </label>
                      )}
                    </span>
                    <div className="media-body">
                      <h5>{userDetails?.UserName}</h5>
                      <p>{userDetails?.Email}</p>
                      <Link to="/my-profile" onClick={handleClose}>
                        View Profile
                      </Link>
                    </div>
                  </div>
                </div>
                {/* <MenuItem
                  onClick={() => {
                    handleClose();
                    handleChatDrawerOpen();
                  }}
                >
                  <IconChat /> Chat With Us
                </MenuItem> */}
                {/* <MenuItem
                  onClick={() => {
                    handleClose();
                    navigateToMySubscription();
                  }}
                >
                  <IconSubscription /> My Subscription
                </MenuItem> */}
                {/* <MenuItem
                  onClick={() => {
                    handleClose();
                    navigateToSupportRequest();
                  }}
                >
                  <IconSupport /> Support Board
                </MenuItem> */}
                {userDetails && userDetails.UserType === 2 && (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      createNdaRequestModal();
                    }}
                  >
                    <IconShareNDAAgreement /> Share NDA Agreement
                  </MenuItem>
                )}
                <MenuItem
                  onClick={() => {
                    handleClose();
                    changePasswordModal();
                  }}
                >
                  <IconKey /> Change Password
                </MenuItem>
                {/* <MenuItem
                  onClick={() => {
                    handleClose();
                    navigateToFaq();
                  }}
                >
                  <IconFaq /> FAQ
                </MenuItem> */}

                <MenuItem onClick={confirmLogout}>
                  <IconLogout /> Logout
                </MenuItem>
              </Menu>
            </div>
          </Stack>
        </div>
      </header>
      <Drawer className="drawer-backdrop" anchor="right" open={isOpen} onClose={handleNotificationClose}>
        <div className="notificationdrawer" aria-expanded={isOpen} aria-hidden={!isOpen}>
          <div className="notification-header">
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <h5 className="notification-title">Notifications</h5>
              </div>
              <IconButton onClick={handleNotificationClose} className="notification-close" size="large">
                <IconClose />
              </IconButton>
            </div>
          </div>
          {isOpen && <NotificationBarContainer handleNotificationClose={handleNotificationClose} isPerfectScrollBar />}
        </div>
      </Drawer>
      <Drawer className="drawer-backdrop" anchor="right" open={openChat} onClose={handleChatDrawerClose}>
        <div className="notificationdrawer">
          <div className="notification-header">
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <h5 className="notification-title">EXtrance Chat</h5>
              </div>
              <IconButton
                onClick={() => {
                  handleChatDrawerClose();
                  setCustomConvo();
                }}
                className="notification-close"
                size="large"
              >
                <IconClose />
              </IconButton>
            </div>
          </div>
          <>
            {screenVal === 0 && (
              <ChatListContainer setScreenVal={setScreenVal} client={client} setCustomConvo={setCustomConvo} />
            )}
            {screenVal === 1 && (
              <ChatBoardContainer
                setScreenVal={setScreenVal}
                client={client}
                conversation={customConvo?.conversation}
                privateChatDetails={customConvo?.privateChatDetails}
                setCustomConvo={setCustomConvo}
              />
            )}
            {screenVal === 2 && (
              <CreateGroupChatContainer
                setScreenVal={setScreenVal}
                conversation={customConvo?.conversation}
                setCustomConvo={setCustomConvo}
              />
            )}
          </>
        </div>
      </Drawer>
    </>
  );
};

export interface HeaderComponentProps {
  title?: string;
  subtitle?: string;
  confirmLogout: () => void;
  anchorEl: HTMLElement | null;
  setAnchorVal: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  userDetails: UserDetails;
  navigateToSupportRequest: () => void;
  navigateToFaq: () => void;
  navigateToMySubscription: () => void;
  navigateToGlobalSearch: (searchText: string) => void;
  totalNotification: number;
  resetNewNotificationCountHandler: () => void;
  client?: Client;
  changePasswordModal: () => void;
  openChat: boolean;
  setOpenChat: (openChat: boolean) => void;
  customConvo?: CustomConversation;
  setCustomConvo: (customConvo?: CustomConversation) => void;
  screenVal: number;
  setScreenVal: (screenVal: number) => void;
  createNdaRequestModal: () => void;
}

export default HeaderComponent;
