import { format } from 'date-fns';
import { getKpiList } from 'src/services/governanceService';
import { determineIndicatorChartValue } from 'src/views/governance-fund-detail/utils';

export const getFundsSpotlightChartData = async (fundId: number | null) => {
  if (!fundId) return [];

  try {
    const { data: kpiList } = await getKpiList(String(fundId), 'Capitalization Rate');

    if (!kpiList?.length) return [];
    // TODO: get type from API
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return kpiList.map(({ reporting_date, unit, value }: any) => ({
      Period: format(new Date(reporting_date), 'MMM dd yyyy'),
      Value: determineIndicatorChartValue(unit, value),
    }));
  } catch (error) {
    console.log('fundsSpotlightChartData error', error);

    return [];
  }
};
