import React from 'react';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import IconArrowLeft from '../../icons/IconArrowLeft';
import { TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import IconSend from '../../icons/IconSend';
import { IconEdit } from '../../icons/IconEdit';
import IconDelete from '../../icons/IconDelete';
import { IconCheckSuccess } from '../../icons/IconCheckSuccess';
import IconCheckWarning from '../../icons/IconCheckWarning';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Conversation, Message } from '@twilio/conversations';
import { UserDetails } from '../../../models/authModels';
import { CustomConversation, PrivateChatDetails, TypingPeople } from '../../../models/chatModels';
import IconUser from '../../icons/IconUser';
import getInitials from '../../getInitials/getInitials';
import { dateFormatWithYestarday } from 'src/utils/dateFormat';

const ChatBoardComponent = ({
  setScreenVal,
  messages,
  newMessage,
  setNewMessage,
  sendMessage,
  userDetails,
  setOpenModal,
  hasPrevPage,
  fetchMoreData,
  participantsObj,
  conversation,
  privateChatDetails,
  setCustomConvo,
  readStatus,
  typingPeople,
}: ChatBoardComponentProps) => {
  const highlightMentions = (message: Message) => {
    if (
      !!message.body &&
      // TODO: get type from Message type
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      !!(message?.attributes as any).msgMentions &&
      // TODO: get type from Message type
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (message?.attributes as any).msgMentions.length >= 1
    ) {
      let newMsgArr: string[];
      let messageParts = message.body;
      const matchedNames: string[] = [];
      let mentionCount = 0;
      // TODO: get type from Message type
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (message.attributes as any).msgMentions.forEach((name: string) => {
        if ((message.body as string).indexOf(name) !== -1) {
          messageParts = messageParts.replace(name, `{mention${mentionCount}}`);
          matchedNames.push(name);
          mentionCount++;
        }
      });
      newMsgArr = messageParts.split(/\{mention\d+\}/g);
      matchedNames.forEach((name, index) => {
        newMsgArr.splice(index * 2 + 1, 0, name);
      });
      return newMsgArr.map((part, index) => (
        <span style={{ fontWeight: index % 2 === 1 ? '900' : '' }} key={index}>
          {part}
        </span>
      ));
    } else {
      return message.body;
    }
  };

  const generateTypingIndicator = () => {
    return (
      <div className="chat-typing">
        {typingPeople.length > 2 ? (
          <>
            <div>{typingPeople[0]?.name}</div>
            <div>and {typingPeople.length - 1} others are typing</div>
          </>
        ) : (
          typingPeople.map((person, index) => (
            <div key={index}>
              {person.name}
              {index !== typingPeople?.length - 1 && ','}{' '}
            </div>
          ))
        )}
        <div>{typingPeople.length > 1 ? 'are typing' : 'is typing'}</div>
        <svg
          version="1.1"
          id="L5"
          width="50px"
          height="36px"
          x="0px"
          y="0px"
          viewBox="0 0 100 100"
          enableBackground="new 0 0 0 0"
        >
          <circle fill="#a5a3a3" stroke="none" cx="6" cy="50" r="8">
            <animateTransform
              attributeName="transform"
              dur="1s"
              type="translate"
              values="0 15 ; 0 -15; 0 15"
              repeatCount="indefinite"
              begin="0.1"
            />
          </circle>
          <circle fill="#a5a3a3" stroke="none" cx="30" cy="50" r="8">
            <animateTransform
              attributeName="transform"
              dur="1s"
              type="translate"
              values="0 10 ; 0 -10; 0 10"
              repeatCount="indefinite"
              begin="0.2"
            />
          </circle>
          <circle fill="#a5a3a3" stroke="none" cx="54" cy="50" r="8">
            <animateTransform
              attributeName="transform"
              dur="1s"
              type="translate"
              values="0 5 ; 0 -5; 0 5"
              repeatCount="indefinite"
              begin="0.3"
            />
          </circle>
        </svg>
      </div>
    );
  };

  return (
    <>
      <div className="back-wrap ml justify-sb">
        <div className="d-flex align-items-center">
          <IconButton
            size="large"
            component="span"
            onClick={() => {
              setScreenVal(0);
              setCustomConvo();
            }}
          >
            <IconArrowLeft />
          </IconButton>
          {conversation?.uniqueName ? (
            <>
              <span className="chat-avater-icon">
                <Avatar src={privateChatDetails?.profileImage ? privateChatDetails.profileImage : undefined}>
                  {getInitials(privateChatDetails?.userName)}
                </Avatar>
              </span>
              <h4 className="back-text">{privateChatDetails?.userName}</h4>
            </>
          ) : (
            <>
              <span className="chat-avater-icon">
                <Avatar
                  alt={conversation?.friendlyName ?? undefined}
                  src={
                    // TODO: get type from Conversation type
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (conversation?.attributes as any)['group_icon'] ?? undefined
                  }
                />
              </span>
              <h4 className="back-text">{conversation?.friendlyName}</h4>
            </>
          )}
        </div>

        {!conversation?.uniqueName &&
          userDetails.Email ===
            // TODO: get type from Conversation type
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (conversation?.attributes as any)['createdBy'] && (
            <div>
              <IconButton size="large" component="span" onClick={() => setScreenVal(2)}>
                <IconEdit />
              </IconButton>
              <IconButton size="large" component="span" onClick={() => setOpenModal(1)}>
                <IconUser />
              </IconButton>
              <IconButton size="large" component="span" onClick={() => setOpenModal(2)}>
                <IconDelete />
              </IconButton>
            </div>
          )}
      </div>
      <div className="chat-room-wrap">
        <div className="create-group-wrap px-0">
          <div className="create">
            <div className="chatroom">
              <div className="chatroom-body">
                <div
                  id="scrollableDiv"
                  className="infinite-scroll-component"
                  style={{
                    height: 'calc(100vh - 170px)',
                    overflow: 'auto',
                    display: 'flex',
                    flexDirection: 'column-reverse',
                    paddingLeft: '15px',
                    paddingRight: '15px',
                  }}
                >
                  <InfiniteScroll
                    dataLength={messages?.length ? messages.length : 0}
                    next={fetchMoreData}
                    inverse={true}
                    hasMore={hasPrevPage}
                    loader={<h4>Loading...</h4>}
                    scrollableTarget="scrollableDiv"
                  >
                    {messages?.map(msg =>
                      msg.author === userDetails.UserId.toString() ? (
                        <div key={msg.sid} className="chatroom-me">
                          <div className="chatroom-item me">
                            <div className="balloon">
                              <p>{highlightMentions(msg)}</p>
                              {msg.index <= readStatus ? (
                                <small className="chat-succes">
                                  <IconCheckSuccess />
                                </small>
                              ) : (
                                <small className="chat-pending">
                                  <IconCheckWarning />
                                </small>
                              )}
                            </div>
                          </div>
                          <span className="date-time">
                            {!!msg.dateCreated && dateFormatWithYestarday(msg.dateCreated)}
                          </span>
                        </div>
                      ) : (
                        <div key={msg.sid} className="chatroom-you">
                          <span className="msg-author">
                            {!!participantsObj &&
                              Object.keys(participantsObj).length > 1 &&
                              !!participantsObj[msg.author!] &&
                              participantsObj[msg.author!].userName}
                          </span>
                          <div className="chatroom-item you">
                            <span className="chat-avater-icon">
                              {conversation?.uniqueName ? (
                                <Avatar
                                  src={privateChatDetails?.profileImage ? privateChatDetails?.profileImage : undefined}
                                >
                                  {getInitials(privateChatDetails?.userName)}
                                </Avatar>
                              ) : !!participantsObj && Object.keys(participantsObj).length > 1 ? (
                                <Avatar
                                  src={
                                    participantsObj[msg.author!]?.profileImage
                                      ? participantsObj[msg.author!].profileImage
                                      : undefined
                                  }
                                >
                                  {getInitials(participantsObj[msg.author!]?.userName)}
                                </Avatar>
                              ) : (
                                <Avatar />
                              )}
                            </span>
                            <div className="balloon">
                              <p>{highlightMentions(msg)}</p>
                            </div>
                          </div>
                          <span className="date-time">
                            {!!msg.dateCreated && dateFormatWithYestarday(msg.dateCreated)}
                          </span>
                        </div>
                      ),
                    )}
                  </InfiniteScroll>
                </div>
                {typingPeople?.length > 0 && generateTypingIndicator()}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="chatroom-bottom">
        <form
          onSubmit={e => {
            e.preventDefault();
            sendMessage();
          }}
          noValidate
          autoComplete="off"
        >
          <div className="table-search-input d-flex">
            <FormControl fullWidth variant="outlined" className="formControl">
              <TextField
                value={newMessage}
                onChange={e => {
                  setNewMessage((e.target as HTMLInputElement).value);
                  if (e.target.value?.length > 0) {
                    conversation?.typing();
                  }
                }}
                type="text"
                variant="outlined"
                margin="normal"
                placeholder="Type a message"
                autoFocus
              />
            </FormControl>
            <IconButton className="send-btn" size="large" type="submit">
              <IconSend />
            </IconButton>
          </div>
        </form>
      </div>
    </>
  );
};

interface ChatBoardComponentProps {
  setScreenVal: (screenNum: number) => void;
  messages?: Message[];
  newMessage: string;
  setNewMessage: (newMessage: string) => void;
  sendMessage: () => void;
  userDetails: UserDetails;
  setOpenModal: (openModal: number) => void;
  hasPrevPage: boolean;
  fetchMoreData: () => void;
  // TODO: get type from API
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  participantsObj: any;
  conversation?: Conversation;
  privateChatDetails?: PrivateChatDetails;
  setCustomConvo: (customConvo?: CustomConversation) => void;
  readStatus: number;
  typingPeople: TypingPeople[];
}

export default ChatBoardComponent;
