import { ReactNode, useState } from 'react';

type KpiListCardProps = {
  title?: string;
  list?: {
    label: string;
    value: string | ReactNode;
    // indicator?: any;
    tooltip?: { title: string; content: string; action?: React.ReactNode };
  }[];
  customClasses?: string;
  // customStyle?: any;
};

const KpiListCard = ({
  customClasses,
  // customStyle,
  list = [],
  title,
}: KpiListCardProps) => {
  const [openTooltip, setOpenTooltip] = useState(-1);
  const handleTooltipButtonClick = (index: number) => {
    if (openTooltip === index) {
      setOpenTooltip(-1);
    } else {
      setOpenTooltip(-1);
      setOpenTooltip(index);
    }
  };
  return (
    <div
      //  style={customStyle}
      className={`kpi-list-card-body ${customClasses}`}
    >
      <div className="card-title">{title}</div>
      <div className="kpi-entries">
        {list.map((p, index) => {
          return (
            <div className="kpi-entry" key={p.label}>
              <div className="entry-label">{p.label}</div>
              <div className="entry-value-wrapper">
                <div className="entry-value flex-grow-1">
                  {p.value}
                  {/* {p.indicator ? (
                    <div className="value-indicator">{p.indicator}</div>
                  ) : null} */}
                </div>
                {(p.tooltip?.title || p.tooltip?.content) && (
                  <div className="tooltip-info-bt" onClick={() => handleTooltipButtonClick(index)}>
                    i
                    {openTooltip === index && (
                      <div className="custom-tooltip-box">
                        <div className="tooltip-title">{p.tooltip.title}</div>
                        <div className="tooltip-content">{p.tooltip.content}</div>
                        {p.tooltip.action}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default KpiListCard;
