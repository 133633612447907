import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'src/context/ShackbarContext';
import { deletePartnershipOrAssetDocument } from 'src/services/documentsService';
import { QueryKey } from 'src/consts/query';

interface UseDeleteDocumentMutationMutationParams {
  id: number;
}

interface UseDeleteDocumentMutationReturnValue {
  isLoading: boolean;

  deleteDocument(id: number): void;
}

export const useDeleteDocumentMutation = (assetId: number): UseDeleteDocumentMutationReturnValue => {
  const queryClient = useQueryClient();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();

  const onSuccess = async () => {
    await queryClient.invalidateQueries({ queryKey: [QueryKey.Documents, 'asset', assetId] });
    await showSuccessSnackbar('Document has been successfully deleted');
  };

  const onError = (error: AxiosError) => showErrorSnackbar(error.message);

  const { isLoading, mutateAsync } = useMutation<AxiosResponse, AxiosError, UseDeleteDocumentMutationMutationParams>({
    mutationFn: ({ id }) => deletePartnershipOrAssetDocument(id),
    onSuccess,
    onError,
  });

  const deleteDocument = async (id: number) => {
    return mutateAsync({
      id,
    });
  };

  return {
    isLoading,
    deleteDocument,
  };
};
