import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export interface ConfirmDialogComponentProps {
  open: boolean;
  handleClose: () => void;
  confirmModal: () => void;
  title: string;
  text: string;
}

export default function ConfirmDialogComponent({
  open,
  handleClose,
  confirmModal,
  title,
  text,
}: ConfirmDialogComponentProps) {
  const fullWidth = true;
  const maxWidth = 'xs';

  return (
    <Dialog fullWidth={fullWidth} maxWidth={maxWidth} open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
        <Box
          noValidate
          component="form"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            m: 'auto',
            width: 'fit-content',
          }}
        ></Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>No</Button>
        <Button onClick={confirmModal}>Yes</Button>
      </DialogActions>
    </Dialog>
  );
}
