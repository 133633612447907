import { ProfilePayload } from '../models/profileModel';
import ServiceClient from './serviceClientConfig';

const serviceClient = new ServiceClient({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export const getGpProfile = () => {
  return serviceClient.get('users/front/gp/get-profile');
};

export const getLpProfile = () => {
  return serviceClient.get('users/front/lp/get-profile');
};

export const countryStateList = (countryId?: string) => {
  let url = 'users/user/country-state';
  if (countryId) {
    url = url + '?CountryId=' + countryId;
  }
  return serviceClient.get(url);
};

export const editProfile = (payload: ProfilePayload) => {
  return serviceClient.put('users/front/gp/update-profile', payload);
};

export const updateProfileImage = (payload: FormData) => {
  return serviceClient.put('users/front/update-profile-image', payload);
};

export const getAccountManagerList = () => {
  return serviceClient.get('users/user-account-manager-list');
};
