type ProgressbarProps = {
  progressValue: number;
  customNodeCount?: string[];
  onStepClick?: (index: number) => void;
  disabled?: boolean;
};

const Progressbar = ({ progressValue, customNodeCount, onStepClick, disabled }: ProgressbarProps) => {
  const nodeCount = customNodeCount || ['Carrito', 'Entrega', 'Pago'];

  const calcBarFill = () => {
    return {
      background: `linear-gradient(90deg, #61C2A0  calc(0% + 44px), #61C2A0  ${
        (progressValue / (nodeCount.length - 1)) * 100
      }%, #f2f2f2 ${(progressValue / (nodeCount.length - 1)) * 100}%, #f2f2f2 100%) `,
    };
  };

  const isClickNavigationEnable = onStepClick && !disabled;

  return (
    <div style={calcBarFill()} className="bar-body">
      {nodeCount.map((node, index) => {
        return (
          <div
            key={node + index}
            style={{
              ...(isClickNavigationEnable && { cursor: 'pointer' }),
            }}
            className={`bar-node-wrapper ${index <= progressValue ? 'active-node' : ''}`}
            onClick={
              isClickNavigationEnable
                ? () => {
                    onStepClick(index);
                  }
                : undefined
            }
          >
            <div className="bar-node">
              <div className="bar-node-text">{node}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default Progressbar;
