import React, { ReactNode } from 'react';
import { Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import styles from './styles.module.scss';

interface ButtonTooltipProps {
  icon: ReactNode;
  tooltipTitle: string;
  onClick(): void;
  disabled?: boolean;
}

export const ButtonTooltip = ({ icon, tooltipTitle, onClick, disabled }: ButtonTooltipProps) => {
  return (
    <Tooltip title={tooltipTitle} className={styles['tooltip']} arrow placement="top">
      <IconButton className={styles['tooltip-icon']} edge="end" size="large" onClick={onClick} disabled={disabled}>
        {icon}
      </IconButton>
    </Tooltip>
  );
};
