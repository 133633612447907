import { useEffect } from 'react';
import { Modal, ModalProps } from 'src/components/common';
import { CreateFolderForm } from 'src/components/documents/CreateFolderForm/CreateFolderForm';
import { useUpdateFolder } from 'src/hooks/react-query/documents';

type CreateNewFolderModalContainerProps = {
  open: ModalProps['open'];
  onClose: () => void;
  assetId: number;
  oldFolderName?: string;
  folderId?: number;
};

export const UpdateFolderModal = ({
  open,
  onClose,
  assetId,
  oldFolderName,
  folderId,
}: CreateNewFolderModalContainerProps) => {
  const {
    mutate: updateFolder,
    isLoading: isUpdateLoading,
    isSuccess: isUpdateFolderSuccess,
  } = useUpdateFolder({ folderId, oldFolderName });

  useEffect(() => {
    if (isUpdateFolderSuccess) {
      onClose();
    }
  }, [isUpdateFolderSuccess]);

  return (
    <Modal title="Edit custom folder" open={open} handleClose={onClose} loading={isUpdateLoading}>
      <CreateFolderForm
        onSubmit={({ name }) => {
          updateFolder({ name, assetId });
        }}
        onCancel={onClose}
        padding="16px 0 0 0"
        inputPlaceholder={oldFolderName}
      />
    </Modal>
  );
};
