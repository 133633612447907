// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import { Page, Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer';

import PoppinsRegular from '../../../assets/fonts/Poppins-Regular.ttf';
import PoppinsMedium from '../../../assets/fonts/Poppins-Medium.ttf';

Font.register({
  family: 'Poppins',
  fonts: [
    {
      src: PoppinsRegular,
      fontWeight: 400,
    },
    {
      src: PoppinsMedium,
      fontWeight: 500,
    },
  ],
});

interface FboInstructionsPDFProps {
  metadata: string;
}

const FboInstructionsPDF = ({ metadata }: FboInstructionsPDFProps) => (
  <Document>
    <Page size="LETTER" style={styles.page}>
      <View style={styles.card}>
        <View style={styles.summaryConceptContainer}>
          <Text style={styles.conceptTitle}>Wire instructions for Extrance Treasury Balance account funding</Text>
        </View>

        <View style={styles.separator} />

        <View style={[styles.item]}>
          <Text style={[styles.itemLabel]}>Bank Routing Number:</Text>
          <Text style={[styles.itemContent]}>021000021</Text>
        </View>

        <View style={[styles.item]}>
          <Text style={[styles.itemLabel]}>SWIFT Code:</Text>
          <Text style={[styles.itemContent]}>CHASUS33 (International Wires Only)</Text>
        </View>

        <View style={[styles.item]}>
          <Text style={[styles.itemLabel]}>General Bank Reference Address:</Text>
          <Text style={[styles.itemContent]}>JPMorgan Chase New York, NY 10017</Text>
        </View>

        <View style={[styles.item]}>
          <Text style={[styles.itemLabel]}>Account Number:</Text>
          <Text style={[styles.itemContent]}>691294703</Text>
        </View>

        <View style={[styles.item]}>
          <Text style={[styles.itemLabel]}>Account Name:</Text>
          <Text style={[styles.itemContent]}>JPMCHASE BANK NA AS NONFIDUCIARY CSTODAN</Text>
        </View>

        <View style={[styles.item]}>
          <Text style={[styles.itemLabel]}>Memorandum description:</Text>
          <Text style={[styles.itemContent]}>{metadata}</Text>
        </View>

        <View style={[styles.separator]} />

        <View style={[styles.item]}>
          <Text style={[styles.itemLabel]}>Important:</Text>
          <Text style={[styles.itemMessage]}>
            {`When entering funding your EXtrance Treasury Balance, it is of utmost importance`}
          </Text>
          <Text style={[styles.itemMessage]}>
            {`to correctly enter your "Transaction Key" (Memorandum Description). It must be`}
          </Text>
          <Text style={[styles.itemMessage]}>
            {`entered perfectly and in the correct spot to avoid potential delays. Enter your `}
          </Text>
          <Text style={[styles.itemMessage]}>
            {`transaction key in the wire description field. This field’s name can vary from bank to bank.`}
          </Text>

          <Text style={[styles.itemMessage, { marginTop: 12 }]}>{`For example:`}</Text>
          <Text style={[styles.itemMessage]}>{`Wells Fargo: "Message to recipient’s bank"`}</Text>
          <Text style={[styles.itemMessage]}>{`Chase: "Reference sent with payment"`}</Text>

          <Text style={[styles.itemMessage, { marginTop: 12 }]}>
            {`We have provisions to revert the funds back to your originating account to prevent`}
          </Text>
          <Text style={[styles.itemMessage]}>{`potential loss or misallocation.`}</Text>

          <Text style={[styles.itemMessage, { marginTop: 12 }]}>{`But,`}</Text>
          <Text style={[styles.itemMessage]}>
            {`Users are advised to exercise caution when entering transaction metadata to prevent`}
          </Text>
          <Text style={[styles.itemMessage]}>{`potential delays or fees.`}</Text>
          <Text style={[styles.itemMessage]}>
            {`We shall not hold responsibility for errors made by the user in the data entry process.`}
          </Text>
          <Text style={[styles.itemMessage]}>
            {`By proceeding with the transfer, you agree to comply with these terms.`}
          </Text>
        </View>
      </View>
    </Page>
  </Document>
);

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Poppins',
  },
  card: {
    width: '12in',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
  },
  summaryConceptContainer: {
    display: 'flex',
    flexGrow: 1,
  },
  conceptDescriptionWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  conceptTitle: {
    fontSize: '14pt',
    marginBottom: '4px',
    color: 'black',
    fontWeight: 'Medium',
  },
  separator: {
    minHeight: '1px',
    maxHeight: '1px',
    backgroundColor: '#E1E1E1',
    flexGrow: 0,
    alignSelf: 'stretch',
    marginVertical: '15px',
  },
  item: {
    display: 'flex',
    marginBottom: '12px',
    flexDirection: 'column',
    maxWidth: '90%',
    wordWrap: 'break-word',
  },
  itemLabel: {
    fontSize: '12pt',
    color: '#575757',
    fontWeight: 400,
    marginRight: '4px',
  },
  itemContent: {
    fontSize: '14pt',
    color: '#000000',
    fontWeight: 500,
  },
  itemMessage: {
    fontSize: '12pt',
    color: '#212121',
    fontWeight: 400,
    maxWidth: '100%',
    marginTop: 1,
  },
});

export default FboInstructionsPDF;
