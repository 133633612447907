import React from 'react';

export const IconAnalytics = () => {
  return (
    <svg
      fill="#000000"
      width="800px"
      height="800px"
      viewBox="0 0 48 48"
      data-name="Layer 1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title />
      <path d="M40,47H8a2,2,0,0,1-2-2V3A2,2,0,0,1,8,1H40a2,2,0,0,1,2,2V45A2,2,0,0,1,40,47ZM10,43H38V5H10Z" />
      <path d="M15,19a2,2,0,0,1-1.41-3.41l4-4a2,2,0,0,1,2.31-.37l2.83,1.42,5-4.16A2,2,0,0,1,30.2,8.4l4,3a2,2,0,1,1-2.4,3.2l-2.73-2.05-4.79,4a2,2,0,0,1-2.17.25L19.4,15.43l-3,3A2,2,0,0,1,15,19Z" />
      <circle cx="15" cy="24" r="2" />
      <circle cx="15" cy="31" r="2" />
      <circle cx="15" cy="38" r="2" />
      <path d="M33,26H22a2,2,0,0,1,0-4H33a2,2,0,0,1,0,4Z" />
      <path d="M33,33H22a2,2,0,0,1,0-4H33a2,2,0,0,1,0,4Z" />
      <path d="M33,40H22a2,2,0,0,1,0-4H33a2,2,0,0,1,0,4Z" />
    </svg>
  );
};
