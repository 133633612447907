import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'src/context/ShackbarContext';
import { AxiosError } from 'axios';
import { DeployCapitalCallFormData } from '../../../types/Governance';
import { createCapCall } from '../../../services/fundsService';

export const useCapCall = (...cbs: Array<() => void>) => {
  const queryClient = useQueryClient();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: (data: DeployCapitalCallFormData) => createCapCall(data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['rolesList'] });
      showSuccessSnackbar('Cap Call has been successfully created');
      cbs.forEach(cb => cb());
    },
    onError: e => {
      showErrorSnackbar((e as AxiosError).message);
    },
  });
};
