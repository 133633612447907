import { Box, Button, FormControl, FormLabel, IconButton, Tab, Tabs, TextField, Typography } from '@mui/material';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { RoleForm, schema } from './validations';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { GeneralPrivilege, FundPrivilegeRow } from './components/Privilege';
import { a11yProps, TabPanel } from 'src/components/common/TabPanel';
import { Privilege, RoleById } from 'src/types/Roles';
import IconArrowLeft from 'src/uiLibrary/icons/IconArrowLeft';
import { useNavigate } from 'react-router-dom';
import { FundPrivilegeType } from './addRoleContainer';

export interface AddRoleComponentProps {
  onSubmit: (values: RoleForm) => void;
  roleDetails?: RoleById;
  privilegeList: Privilege[];
  fundPrivilegesList: FundPrivilegeType[];
  onParentChange: (parentId: number) => void;
  onChildChange: (parentId: number, childId: number) => void;
  onFundPrivilegeChange: (privilegeId: number, fundId: number) => void;
  isNew?: boolean;
}

export const AddRoleComponent = ({
  onSubmit,
  roleDetails,
  privilegeList,
  onParentChange,
  onChildChange,
  fundPrivilegesList,
  onFundPrivilegeChange,
  isNew = false,
}: AddRoleComponentProps) => {
  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = React.useState(0);

  const { control, handleSubmit } = useForm<RoleForm>({
    resolver: yupResolver(schema as yup.ObjectSchema<RoleForm>),
    defaultValues: {
      Name: roleDetails?.role_details?.Name ?? '',
      IsActive: roleDetails?.role_details?.IsActive ? `${roleDetails?.role_details?.IsActive}` : '',
    },
  });

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const handleBackButtonClick = () => {
    navigate('/roles');
  };

  return (
    <>
      <Box className="back-fund-wrap ml mb-15" onClick={handleBackButtonClick}>
        <IconButton size="large" component="span">
          <IconArrowLeft stroke="#8F8F8F" />
        </IconButton>
        <Typography className="back-text">Back</Typography>
      </Box>
      <form noValidate autoComplete="off" className="formContainer" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-roww">
          <div className="form-set">
            <Controller
              name="Name"
              control={control}
              render={({ field, fieldState }) => (
                <FormControl fullWidth variant="outlined" className="formControl">
                  <FormLabel>
                    Role Name <span className="require">*</span>
                  </FormLabel>
                  <TextField
                    autoFocus
                    {...field}
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message : ''}
                    fullWidth
                    required
                  />
                </FormControl>
              )}
            />
          </div>
        </div>

        <div className="dtl-wrapper">
          <Box className="tabs-wrap mt-0" sx={{ width: '100%' }}>
            <Box className="tab-heading">
              <Tabs
                value={tabIndex}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="basic tabs example"
              >
                <Tab label={'General'} {...a11yProps(0)} />

                {fundPrivilegesList?.map((fund, index) => (
                  <Tab key={fund.id} label={fund.name} {...a11yProps(index + 1)} />
                ))}
              </Tabs>
            </Box>

            <TabPanel value={tabIndex} index={0}>
              <div className="privilege-list">
                {privilegeList?.map(privilege => {
                  return (
                    <GeneralPrivilege
                      key={privilege.id}
                      privilege={privilege}
                      onParentChange={id => onParentChange(id)}
                      onChildChange={(parentId, childId) => onChildChange(parentId, childId)}
                    />
                  );
                })}
              </div>
            </TabPanel>

            {fundPrivilegesList?.map((fund, index) => (
              <TabPanel key={fund.id} value={tabIndex} index={index + 1}>
                <div className="privilege-list">
                  {fund.fundPrivileges?.map(privilege => {
                    return (
                      <FundPrivilegeRow
                        key={privilege.id}
                        privilege={privilege}
                        onPrivilegeChange={id => onFundPrivilegeChange(id, fund.id)}
                      />
                    );
                  })}
                </div>
              </TabPanel>
            ))}
          </Box>

          <Button type="submit" variant="contained" size="large" className="mt-25">
            {isNew ? 'Save Role' : 'Update Role'}
          </Button>
        </div>
      </form>
    </>
  );
};
