import React from 'react';

const IconNoApprove = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="32" height="32" rx="8" fill="white" />
      <path
        d="M22.27 11.6138L17.8838 15.9999L22.27 20.3861C22.3972 20.509 22.4987 20.656 22.5685 20.8185C22.6383 20.981 22.6751 21.1558 22.6766 21.3326C22.6781 21.5095 22.6444 21.6849 22.5775 21.8486C22.5105 22.0123 22.4116 22.161 22.2865 22.286C22.1615 22.4111 22.0127 22.51 21.849 22.577C21.6854 22.6439 21.51 22.6776 21.3331 22.6761C21.1562 22.6746 20.9815 22.6378 20.819 22.568C20.6565 22.4982 20.5095 22.3967 20.3866 22.2695L16.0004 17.8833L11.6143 22.2695C11.4914 22.3967 11.3444 22.4982 11.1819 22.568C11.0194 22.6378 10.8446 22.6746 10.6678 22.6761C10.4909 22.6776 10.3155 22.6439 10.1518 22.577C9.98812 22.51 9.83941 22.4111 9.71434 22.286C9.58928 22.161 9.49038 22.0123 9.42341 21.8486C9.35643 21.6849 9.32273 21.5095 9.32427 21.3326C9.32581 21.1558 9.36255 20.981 9.43236 20.8185C9.50216 20.656 9.60364 20.509 9.73085 20.3861L14.117 15.9999L9.73085 11.6138C9.60364 11.4909 9.50216 11.3439 9.43236 11.1814C9.36255 11.0189 9.32581 10.8441 9.32427 10.6673C9.32273 10.4904 9.35643 10.315 9.42341 10.1513C9.49038 9.98763 9.58928 9.83892 9.71434 9.71385C9.83941 9.58879 9.98812 9.48989 10.1518 9.42292C10.3155 9.35594 10.4909 9.32224 10.6678 9.32378C10.8446 9.32532 11.0194 9.36206 11.1819 9.43187C11.3444 9.50168 11.4914 9.60315 11.6143 9.73037L16.0004 14.1165L20.3866 9.73037C20.5095 9.60315 20.6565 9.50168 20.819 9.43187C20.9815 9.36206 21.1562 9.32532 21.3331 9.32378C21.51 9.32224 21.6854 9.35594 21.849 9.42292C22.0127 9.48989 22.1615 9.58879 22.2865 9.71385C22.4116 9.83892 22.5105 9.98763 22.5775 10.1513C22.6444 10.315 22.6781 10.4904 22.6766 10.6673C22.6751 10.8441 22.6383 11.0189 22.5685 11.1814C22.4987 11.3439 22.3972 11.4909 22.27 11.6138Z"
        fill="#F34A29"
      />
    </svg>
  );
};

export default IconNoApprove;
