import React, { useState } from 'react';
import { FormControl, FormLabel, Grid, IconButton, MenuItem, Select } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import IconFilter2 from 'src/uiLibrary/icons/IconFilter2';
import { SearchFilters } from 'src/components/common/SearchFilters';

interface DocumentsFilterComponentProps {
  handleFilterChange: (extension: string) => void;
  filterStatus: 0 | 1 | undefined;
  extensionList?: string[];
}

export const DocumentFilters = ({ handleFilterChange, filterStatus, extensionList }: DocumentsFilterComponentProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [openFilter, setOpenFilter] = useState(false);
  const id = openFilter ? 'simple-popover' : undefined;
  const { control, handleSubmit, getValues, reset } = useForm<{
    extension: string;
  }>({
    defaultValues: {
      extension: '',
    },
  });

  const onFilter = (payload: { extension: string }) => {
    handleFilterChange(payload.extension);
  };

  const clearFilter = () => {
    reset();
    handleFilterChange(getValues().extension);
    handleCloseFilter();
  };

  const handleOpenFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenFilter(true);
  };
  const handleCloseFilter = () => {
    setAnchorEl(null);
    setOpenFilter(false);
  };

  return (
    <Grid container spacing={0}>
      <Grid item>
        <IconButton
          aria-describedby={id}
          onClick={handleOpenFilter}
          sx={{
            background: `${filterStatus ? '#E1E1E1' : ''}`,
          }}
        >
          <IconFilter2 />
        </IconButton>
        <SearchFilters
          anchorEl={anchorEl}
          onFilter={onFilter}
          clearFilter={clearFilter}
          handleCloseFilter={handleCloseFilter}
          openFilter={openFilter}
          handleSubmit={handleSubmit}
        >
          {extensionList && (
            <Grid item xs={12} className="from-row1">
              <Controller
                name="extension"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth variant="outlined" className="formControlFilter">
                    <FormLabel id="extension_filter" className="formControlLabel">
                      Extension
                    </FormLabel>
                    <Select labelId="extension_filter" className="formControlSelect" {...field}>
                      <MenuItem value="">All</MenuItem>
                      {extensionList?.map(item => {
                        return (
                          <MenuItem value={item} key={item}>
                            {item}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>
          )}
        </SearchFilters>
      </Grid>
    </Grid>
  );
};
