import { Fund } from 'src/types/Funds';
import classNames from 'classnames';

interface RenderFundCardProps {
  fund: Fund;
  activeFundId?: number | null;
  isAssetVisible?: boolean;
  onClick(id: number): void;
}

export const renderFundCard = ({ fund, activeFundId, isAssetVisible = true, onClick }: RenderFundCardProps) => {
  const { id, fundAssets, name, description } = fund;

  return (
    <div className={classNames('fund-selection-card', { active: id === activeFundId })} onClick={() => onClick(id)}>
      <div className="fund-header-tag">Fund</div>
      <div className="fund-title">{name}</div>
      <div className="fund-subtitle" dangerouslySetInnerHTML={{ __html: description }}></div>
      {isAssetVisible && (
        <div className="asset-count-box">
          Assets<span>{fundAssets.length}</span>
        </div>
      )}
    </div>
  );
};
