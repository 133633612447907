import { Close } from '@mui/icons-material';
import {
  Dialog as MuiDialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogProps as MuiDialogProps,
  Stack,
} from '@mui/material';
import { Loader } from '../Loader';

export interface ModalProps extends MuiDialogProps {
  title?: string;
  handleClose: () => void;
  closeOnClickOutside?: boolean;
  loading?: boolean;
}

export const Modal = ({
  title,
  open,
  onClose,
  handleClose,
  children,
  className,
  fullWidth = true,
  maxWidth = 'sm',
  loading,
  closeOnClickOutside,
  ...props
}: ModalProps) => {
  return (
    <MuiDialog
      className={className}
      open={open}
      onClose={closeOnClickOutside ? onClose : undefined}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      {...props}
    >
      {!loading ? (
        <>
          {title && (
            <Stack direction="row" justifyContent="space-between">
              <DialogTitle>{title}</DialogTitle>
              <IconButton onClick={handleClose} className="dialog-close-btn">
                <Close />
              </IconButton>
            </Stack>
          )}
          <DialogContent sx={{ margin: 0 }}>{children}</DialogContent>
        </>
      ) : (
        <Loader />
      )}
    </MuiDialog>
  );
};
