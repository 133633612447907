import { Grid, Tooltip, Box, MenuItem, IconButton } from '@mui/material';
import Table from '../../../components/common/table/Table';
import AdminUserTablePagination from '../../../components/common/table/TablePagination';

import Select, { SelectChangeEvent } from '@mui/material/Select';

import { styled } from '@mui/material/styles';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import IconNoApprove from '../../icons/IconNoApprove';
import IconApprove from '../../icons/IconApprove';
import { OpportunitiesMetaData } from '../../../models/marketPlaceModels';

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    color: '#fff',
    backgroundColor: '#000',
    fontSize: '12px',
    fontWeight: '500',
    borderRadius: '5px',
    paddingLeft: '12px',
    paddingRight: '12px',
  },
}));

const MarketplaceCountersigningTable = ({
  //ndaAction,
  handleChangePage,
  handlePageSizeChange,
  //handleSortChange,
  limit,
  metaData,
  page,
  tableDataList,
}: //changeAccountStatusHandler,
//openSoftDeleteConfirmationModal,
//childPrivileges,
MarketplaceCountersigningTableProps) => {
  // const validActionHeadCellHandler = () => {
  //   if (
  //     !!childPrivileges &&
  //     childPrivileges?.filter(
  //       (privilege: any) =>
  //         (privilege.Name.trim() === "Edit" ||
  //           privilege.Name.trim() === "Delete") &&
  //         !privilege.has_privilege
  //     ).length === 2
  //   )
  //     return true;
  // };

  const handleChangeSelectPage = (event: SelectChangeEvent) => {
    handlePageSizeChange(+event.target.value);
  };
  return (
    <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
      <Grid item>
        <Table
          columns={[
            { id: 'lpname', label: 'LP Name', sortable: true },
            {
              id: 'actions',
              label: 'Actions',
              sortable: true,
              fixedWidth: '124px',
            },
          ]}
          // TODO: get type from API
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          data={tableDataList.map((nda: any) => ({
            ...nda,
            actions: [
              <StyledTooltip title="Reject" key={2}>
                <IconButton
                  sx={{ color: '#024A72', padding: '0px 6px 0px 0px' }}
                  aria-label="reject"
                  size="large"
                  disableRipple
                  //onClick={() => editUser(user.userid)}
                >
                  <IconNoApprove />
                </IconButton>
              </StyledTooltip>,
              <StyledTooltip title="Approve" key={3}>
                <IconButton
                  sx={{ padding: '0px 0px 0px 6px' }}
                  aria-label="approve"
                  size="large"
                  disableRipple
                  // onClick={() =>
                  //   openSoftDeleteConfirmationModal(
                  //     "soft-delete",
                  //     user.userid
                  //   )
                  // }
                >
                  <IconApprove />
                </IconButton>
              </StyledTooltip>,
            ],
          }))}
          loading={tableDataList.length === 0}
          //onSortColumnChange={handleSortChange}
        />
      </Grid>
      <Grid item>
        <Grid container spacing={0} className={'customPagination'}>
          <Grid item xs={6}>
            <Box className="boxPerPage">
              Displaying
              <Select
                className="selectPerPage"
                value={limit.toString()}
                variant="standard"
                onChange={handleChangeSelectPage}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={30}>30</MenuItem>
                <MenuItem value={40}>40</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
              per page
            </Box>
          </Grid>
          <Grid item xs={6}>
            <AdminUserTablePagination
              count={metaData?.total}
              page={page}
              rowsPerPage={limit}
              handleChangePage={handleChangePage}
              handlePageSizeChange={handlePageSizeChange}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

interface MarketplaceCountersigningTableProps {
  // TODO: get type from API
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tableDataList: any;
  metaData: OpportunitiesMetaData;
  page: number;
  //filterStatus: 0 | 1 | undefined;
  limit: number;
  //searchString: string | undefined;
  //ndaAction: () => void;
  handleChangePage: (newPage: number) => void;
  //handleSortChange: (sortColumn: SortColumn) => void;
  handlePageSizeChange: (pageSize: number) => void;
  //changeAccountStatusHandler: (userid: number, status: number) => void;
  //openSoftDeleteConfirmationModal: (modalName: string, userid: number) => void;
  //childPrivileges: ChildPrivilege[] | undefined;
}

export default MarketplaceCountersigningTable;
