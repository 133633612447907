import { validateError } from 'src/utils/validateError';
import {
  AgreementDocRequestFilterType,
  AgreementDocStatusFilterType,
  DocSortField,
  DocSortOrder,
} from '../models/agreementDocModel';
import ServiceClient from './serviceClientConfig';
import { CreateFolderDTO, Folder } from 'src/types/Documents';
import { DocumentsLevel } from 'src/types/common';
import { AxiosResponse } from 'axios';

const serviceClient = new ServiceClient({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export const getAgreementDocuments = (
  page: number,
  limit: number,
  orderField: DocSortField,
  orderSort: DocSortOrder,
  filterStatus: AgreementDocStatusFilterType,
  filterRequestType: AgreementDocRequestFilterType,
  searchString?: string,
) => {
  let url = `agreementrequest/frontend-agreement-document-list?page=${page}&limit=${limit}&order_field=${orderField}&order_sort=${orderSort.toUpperCase()}`;
  if (filterStatus) {
    url += `&filter_status=${filterStatus}`;
  }
  if (filterRequestType) {
    url += `&RequestType=${filterRequestType}`;
  }
  if (searchString) {
    url += `&search_string=${searchString}`;
  }
  return serviceClient.get(url);
};

export const postFundAgreementRequest = (fundId: number, RequestedUserId: number, UserId: number) => {
  const url = `fundagreementrequest/create/nda`;
  return serviceClient.post(url, { fundId, RequestedUserId, UserId });
};

export const getFundAgreementDocuments = async (fundId: number) => {
  const url = `fundagreementrequest/frontend-agreement-document-list?Fund=${fundId}`;
  try {
    const response = await serviceClient.get(url);
    //TODO: add type from codegen
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return response.data as any;
  } catch (error) {
    console.error('getIndicators error: ', error);
    throw await validateError(error);
  }
};

// export const getAgreementSignedFile = (data: any) => {
//   return serviceClient.get("agreement-request/signed-file", data);
// };

export const getDocuments = (level: DocumentsLevel) => {
  const url = `documents/?level=${level}`;
  return serviceClient.get(url);
};

export const getDocumentsByLevelId = (level: DocumentsLevel, levelId?: string | number) => {
  const url = `documents/?level=${level}&id=${levelId}`;
  return serviceClient.get(url);
};

export const getLatestDocuments = (level: DocumentsLevel) => {
  const url = `documents/latests?level=${level}`;
  return serviceClient.get(url);
};

export const getDocumentsFolders = async ({ level, levelId }: { level?: 'fund' | 'asset'; levelId?: number }) => {
  const url = 'documents/folders';

  const params: { fundId?: number; assetId?: number } = {};

  if (level && levelId) {
    params[`${level}Id`] = levelId;
  }

  try {
    const response = await serviceClient.get(url, { params });

    return response.data;
  } catch (error) {
    console.error('get documents folders list error: ', error);
    throw await validateError(error);
  }
};

export const deleteFolder = (folderId: number) => {
  const url = `documents/folders/${folderId}`;
  return serviceClient.delete(url);
};

export type UpdateFolderBody = {
  name: string;
  fundId?: number;
  assetId?: number;
};

export const updateFolder = (folderId: number, body: UpdateFolderBody) => {
  const url = `documents/folders/${folderId}`;
  return serviceClient.put(url, body);
};

export const getFoldersList = async (id: string | number, level: 'fund' | 'asset' = 'fund') => {
  let url = `documents/folders`;

  if (level === 'fund') {
    url = url + `?fundId=${id}`;
  } else {
    url = url + `?assetId=${id}`;
  }

  try {
    const response = await serviceClient.get(url);

    return response.data as AxiosResponse<Folder[]>;
  } catch (error) {
    console.error('getFoldersList error: ', error);
    throw await validateError(error);
  }
};

export const getStarredDocumentsFolders = () => {
  const url = 'documents/starred-folders';
  return serviceClient.get(url);
};

export const getDocumentsByDocumentsFolder = (folderId: string | undefined) => {
  const url = `documents/folders/${folderId}`;
  return serviceClient.get(url);
};

export const createDocumentsFolder = (body: CreateFolderDTO) => {
  const url = 'documents/folders/create';
  return serviceClient.post(url, body);
};

export const updateDocumentsFolder = async (folderId: string, documentId: string) => {
  const url = `documents/folders/${folderId}/add-document/${documentId}`;

  try {
    const response = await serviceClient.post(url);

    // TODO: get type from API
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return response.data as AxiosResponse<any>;
  } catch (error) {
    console.error('getDocumentList error: ', error);
    throw await validateError(error);
  }
};

// export const deleteDocumentsFolder = (folderId: any) => {
//   let url = `documents/folders/${folderId}`;
//   return serviceClient.delete(url);
// };

export const addDocumentToDocumentsFolder = (folderId: number, documentId: number) => {
  const url = `documents/folders/${folderId}/add-document/${documentId}`;
  return serviceClient.post(url, {});
};

export const removeDocumentFromDocumentsFolder = (folderId: number, documentId: number) => {
  const url = `documents/folders/${folderId}/remove-document/${documentId}`;
  return serviceClient.post(url, {});
};

// export const starFund = (fundId: any) => {
//   let url = `documents/star-fund/${fundId}`;
//   return serviceClient.post(url, {});
// };

// export const unstarFund = (fundId: any) => {
//   let url = `documents/unstar-fund/${fundId}`;
//   return serviceClient.post(url, {});
// };

export const getStarredFunds = () => {
  const url = 'documents/starred-funds';
  return serviceClient.get(url);
};

export const deletePartnershipOrAssetDocument = (documentId: number | string) => {
  const url = `partnerships/document-upload/${documentId}`;
  return serviceClient.delete(url);
};

export const deleteFundDocument = (documentId: string | number) => {
  const url = `funds/document-upload/${documentId}`;
  return serviceClient.delete(url);
};

export const createFundDocument = (fundId: number, document: FormData) => {
  const url = `funds/document-upload/${fundId}`;
  return serviceClient.post(url, document);
};

export const uploadFIP = (payload: File) => {
  const formData = new FormData();
  formData.append('file', payload);
  return serviceClient.post(`funds/import-file`, formData);
};

export const createPartnershipOrAssetDocument = (partnershipId: number, document: FormData) => {
  // For asset level, "Document_for": {assetId} must be added to the form.
  const url = `partnerships/document-upload/${partnershipId}`;
  return serviceClient.post(url, document);
};

export const getFoldersAvailableForDocument = (docId: number) => {
  const url = `documents/folders/document/${docId}`;
  return serviceClient.get(url);
};

export const getTemplateForFIPDocument = () => {
  const url = 'documents/template?type=FIP';
  return serviceClient.get(url);
};

export const getTemplateForMultifileDocument = () => {
  const url = 'documents/template?type=MPF';
  return serviceClient.get(url);
};

export const createAssetDocument = (assetId: string | undefined, document: FormData) => {
  // For asset level, "Document_for": {assetId} must be added to the form.
  const url = `partnerships/document-upload/${assetId}`;
  return serviceClient.post(url, document);
};
