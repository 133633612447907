import React from 'react';

const IconShareNDAAgreement = () => {
  return (
    <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 1H2.6C2.17565 1 1.76869 1.16857 1.46863 1.46863C1.16857 1.76869 1 2.17565 1 2.6V15.4C1 15.8243 1.16857 16.2313 1.46863 16.5314C1.76869 16.8314 2.17565 17 2.6 17H12.2C12.6243 17 13.0313 16.8314 13.3314 16.5314C13.6314 16.2313 13.8 15.8243 13.8 15.4V5.8L9 1Z"
        stroke="#177785"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M9 1V5.8H13.8" stroke="#177785" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.6 9.79688H4.19995" stroke="#177785" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.6 13H4.19995" stroke="#177785" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M5.79995 6.60156H4.99995H4.19995"
        stroke="#177785"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconShareNDAAgreement;
