//TODO: change table approach for lp users
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Grid } from '@mui/material';
import Table from 'src/components/common/table/Table';
import TablePagination from 'src/components/common/table/TablePagination';
import useSorting from 'src/hooks/useSorting';
import { usePagination } from 'src/hooks/usePagination';
import { getLimitedPartners } from 'src/services/governanceService';
import { updateLimitedPartners } from '../utils/updateLimitedPartners';
import { lpsListTableColumns } from '../consts/lpsListTableColumns';
import { Loader } from 'src/components/common/Loader';
import { QueryError } from 'src/components/common/QueryError';
import { SortDirection } from 'src/components/common/table/types';
import { QueryKey } from 'src/consts/query';

export const LpListTable = () => {
  const { fundId } = useParams();

  const { pageSize, page, setActivePage, setPageSize } = usePagination();

  const { handleSortChange, sortOrder, sortField } = useSorting({
    sortField: 'username',
    sortOrder: SortDirection.ASC,
  });

  const {
    data: limitedPartnersResponse,
    isLoading,
    isError,
  } = useQuery({
    queryKey: [QueryKey.LimitedPartners, page, pageSize, sortOrder],
    queryFn: () => getLimitedPartners(Number(fundId), page, pageSize, sortOrder, sortField),
  });

  const limitedPartners = useMemo(
    () => updateLimitedPartners(limitedPartnersResponse?.data) || [],
    [limitedPartnersResponse],
  );

  // useEffect(() => {
  //   if (!limitedPartnersResponse) return;

  //   const { meta } = limitedPartnersResponse;

  //   // setPaginationParams({
  //   //   ...meta,
  //   // });
  // }, [limitedPartnersResponse, setPaginationParams]);

  if (isLoading) return <Loader />;
  if (isError) return <QueryError />;

  return (
    <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
      <Grid item>
        <Table
          columns={lpsListTableColumns}
          data={limitedPartners}
          sortField={sortField}
          sortOrder={sortOrder}
          loading={isLoading}
          onSortColumnChange={handleSortChange}
        />
      </Grid>
      <Grid item>
        <Grid container spacing={0} className={'customPagination'}>
          <Grid item xs={6} />
          <Grid item xs={6}>
            {/* <TablePagination
              count={count}
              page={!count || count <= 0 ? 0 : page}
              rowsPerPage={limit}
              handleChangePage={setActivePage}
            /> */}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
