import { Routes } from './routes';

export const ProtectedRoute: Record<
  'dashboard' | 'funds' | 'documents' | 'user-management' | 'role-management',
  string
> = {
  dashboard: Routes.dashboard,
  funds: Routes.myFunds,
  documents: Routes.documents,
  'user-management': Routes.users,
  'role-management': Routes.roles,
};
