import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'src/context/ShackbarContext';
import { AxiosError } from 'axios';
import { getRoleList } from 'src/services/rolesService';
import { initialMetaObject } from 'src/consts/pagination';
import { ApiError } from 'src/types/api';

export const useRolesList = (
  page: number,
  sortField: 'name' | 'created',
  sortOrder: 'asc' | 'desc',
  limit: number,
  filterStatus: 0 | 1 | undefined,
  searchString: string,
) => {
  const { showErrorSnackbar } = useSnackbar();

  return useQuery({
    queryKey: ['rolesList', page, sortField, sortOrder, limit, filterStatus, searchString],
    queryFn: async () => {
      try {
        const response = await getRoleList(page + 1, sortField, sortOrder, limit, filterStatus, searchString);
        if (response?.data) {
          return response.data;
        }
        return {
          data: [],
          meta: initialMetaObject,
        };
      } catch (error) {
        showErrorSnackbar((error as AxiosError<ApiError>).response?.data.message);
        console.error('useRolesList error', error);
        throw new Error((error as AxiosError<ApiError>).response?.data.message);
      }
    },
  });
};
