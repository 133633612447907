import React from 'react';

const IconNotifyBell = () => {
  return (
    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.0869 12.2314V8.80436C14.0869 5.78805 12.0078 3.24858 9.20746 2.54299C9.32488 2.3072 9.39129 2.04173 9.39129 1.76091C9.39129 0.789926 8.60136 0 7.63042 0C6.65947 0 5.86955 0.789926 5.86955 1.76087C5.86955 2.0417 5.93596 2.30716 6.05338 2.54296C3.25304 3.24854 1.1739 5.78805 1.1739 8.80432V12.2314C0.490711 12.4736 0 13.1261 0 13.8913C0 14.8622 0.789926 15.6521 1.76087 15.6521H4.75471C5.02734 16.9901 6.21313 18 7.63042 18C9.04771 18 10.2335 16.9901 10.5062 15.6522H13.5C14.4709 15.6522 15.2609 14.8622 15.2609 13.8913C15.2608 13.1261 14.7701 12.4737 14.0869 12.2314ZM7.63042 1.1739C7.95407 1.1739 8.21739 1.43722 8.21739 1.76087C8.21739 2.08452 7.95407 2.34784 7.63042 2.34784C7.30677 2.34784 7.04345 2.08452 7.04345 1.76087C7.04345 1.43722 7.30677 1.1739 7.63042 1.1739ZM7.63042 16.8261C6.86521 16.8261 6.21281 16.3314 5.97052 15.6483H9.29036C9.04802 16.3314 8.39563 16.8261 7.63042 16.8261ZM13.5 14.4783H1.76087C1.43722 14.4783 1.1739 14.2149 1.1739 13.8913C1.1739 13.5676 1.43722 13.3043 1.76087 13.3043H2.93481C3.25895 13.3043 3.52178 13.0415 3.52178 12.7174C3.52178 12.3932 3.25898 12.1304 2.93481 12.1304H2.3478V8.80429C2.3478 5.89145 4.71758 3.52167 7.63042 3.52167C10.5433 3.52167 12.913 5.89145 12.913 8.80429V12.1304H12.3256C12.0014 12.1304 11.7386 12.3932 11.7386 12.7174C11.7386 13.0415 12.0014 13.3043 12.3256 13.3043H13.5C13.8236 13.3043 14.087 13.5676 14.087 13.8913C14.087 14.2149 13.8236 14.4783 13.5 14.4783Z"
        fill="#177785"
      />
      <path
        d="M7.63047 4.69727C5.36493 4.69727 3.52176 6.54044 3.52176 8.80598C3.52176 9.13012 3.78455 9.39295 4.10873 9.39295C4.4329 9.39295 4.6957 9.13015 4.6957 8.80598C4.6957 7.18773 6.01223 5.8712 7.63047 5.8712C7.95461 5.8712 8.21744 5.60841 8.21744 5.28423C8.21744 4.96006 7.95461 4.69727 7.63047 4.69727Z"
        fill="#177785"
      />
    </svg>
  );
};

export default IconNotifyBell;
