import React from 'react';

const IconCheckWarning = () => {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="7.5" cy="7.5" r="6.5" fill="#EEC803" stroke="white" strokeWidth="2" />
      <path
        d="M10.3182 6L6.31818 10L4.5 8.18182"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconCheckWarning;
