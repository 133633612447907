import { validateError } from 'src/utils/validateError';
import ServiceClient from './serviceClientConfig';
import { AxiosResponse } from 'axios';
import { FundResponse, FundsResponse } from 'src/types/Funds';
import { DeployCapitalCallFormData, SellRequestDto } from '../types/Governance';

const serviceClient = new ServiceClient({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export const getFunds = async (
  tab: 'Fund access' | 'Governance' | 'Banking' = 'Fund access',
  searchString?: string,
) => {
  const url = `funds/assets-only`;

  const params = {
    ...(tab && { tab: tab }),
    ...(searchString && { search: searchString }),
  };

  try {
    const response = await serviceClient.get(url, { params });

    return response.data as AxiosResponse<FundsResponse>;
  } catch (error) {
    console.error('getFunds error: ', error);
    throw await validateError(error);
  }
};

export const getFundDetail = async (id: number | string) => {
  const url = `funds/asset-only/${id}`;
  try {
    const response = await serviceClient.get(url);

    return response.data as AxiosResponse<FundResponse>;
  } catch (error) {
    console.error('getFunds error: ', error);
    throw await validateError(error);
  }
};

export const getFundPositionsDetail = (id: number) => {
  const url = `fund-sell-request/lp-fund-details/${id}`;
  return serviceClient.get(url);
};

export const createCapCall = (payload: DeployCapitalCallFormData) => {
  return serviceClient.post(`gp-fund/frontend/Cap-Call`, payload);
};

export const createSellPositionRequest = (payload: SellRequestDto) => {
  return serviceClient.post(
    `fund-sell-request/sell-request/${payload.fund.id}`, //19 works but stake exceeded
    {
      EstimateValue: '10', // TODO remove obsolete
      RequestedAmount: payload.RequestedAmount,
      Stake: payload.Stake,
    },
  );
};

export const getFundEtbHistory = (id: number) => {
  const url = `funds/${id}/etb-history`;
  return serviceClient.get(url);
};

export const getCapitalStructure = async (fundId: number) => {
  const url = `fund-structure/${fundId}`;
  try {
    const response = await serviceClient.get(url);
    return response.data;
  } catch (error) {
    console.error('Fetch capital structure error: ', error);
    throw await validateError(error);
  }
};
