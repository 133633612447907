import { useCallback, useEffect, useState } from 'react';
import { Grid, IconButton, Dialog, DialogContent } from '@mui/material';
import { UserListMetaData } from './../../../models/frontendUserModels';
import IconArrowLeft from '../../icons/IconArrowLeft';
import TransactionApproveTable from './transactionApproveTable';
import HeaderTabSelection from '../../../components/HeaderTabSelection';
import Button from '../../../components/common/Button';
import { approveRejectSellRequest } from '../../../services/governanceService';
import { currentFormat, formatDate } from '../../../views/utils';
import { Fund } from 'src/types/Funds';

const NdaApproveComponent = ({
  closeModal,
  isVisible,
  handleChangePage,
  handlePageSizeChange,
  limit,
  metaData,
  page,
  fund,
}: NdaApproveComponentProps) => {
  const [activeTab, setActiveTab] = useState('');
  const [activeChip, setActiveChip] = useState('');

  // TODO: get type from API
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [positions, setPositions] = useState<any>([]);

  const [approvalQueue, setApprovalQueue] = useState<{ id: number; value: string }[]>([]);
  const saveChangesApproveQueue = async () => {
    const responses = await Promise.all(
      approvalQueue.map(async item => {
        return await approveOrReject(item.id, item.value === 'Approve' ? true : false);
      }),
    );
    let newPositions = [...positions];
    responses.forEach(({ fundId, GPApproved }) => {
      // TODO: get type from API
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      newPositions = newPositions.map((item: any) => (item.id === fundId ? { ...item, GPApproved: GPApproved } : item));
    });
    setPositions(newPositions);
    setApprovalQueue([]);
  };
  const approveOrReject = (fundId: number, approved: boolean) => {
    const GPApproved = approved ? 'Approved' : 'Rejected';
    return approveRejectSellRequest(fundId, { GPApproved })
      .then(() => {
        return { fundId, GPApproved };
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.log(err);
        return { fundId, GPApproved: 'Open' };
      });
  };

  const mapTransactions = () => {
    if (activeChip === 'position-sellout') {
      if (activeTab === 'new-transactions') {
        return (
          positions
            // TODO: get type from API
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .filter((p: any) => p.GPApproved === 'Open')
            // TODO: get type from API
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .map((p: any) => {
              return {
                id: p.id,
                lpname: p.seller.UserName,
                enterDate: formatDate(p.CreationDateTime),
                sellDate: '-',
                stake: '% ' + (p.stake || p.Stack),
                ammount: currentFormat(parseFloat(p.RequestedAmount.replace(',', ''))),
              };
            })
        );
      } else if (activeTab === 'historic') {
        return (
          positions
            .filter(
              // TODO: get type from API
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (p: any) => p.GPApproved === 'Approved' || p.GPApproved === 'Rejected',
            )
            // TODO: get type from API
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .map((p: any) => {
              return {
                id: p.id,
                lpname: p.seller.UserName,
                enterDate: formatDate(p.CreationDateTime),
                sellDate: p.Status === 'Closed' ? formatDate(p.LastModificationDateTime) : '-',
                stake: '% ' + (p.stake || p.Stack),
                ammount: currentFormat(parseFloat(p.RequestedAmount.replace(',', ''))),
                status: p.Status === 'Closed' ? 'Completed' : p.GPApproved === 'Rejected' ? 'Rejected' : 'Approved',
              };
            })
        );
      }
    } else if (activeChip === 'capital-call') {
      if (activeTab === 'new-transactions') {
        return [
          {
            fundName: 'Wexford',
            created: '18 Oct 2022',
            dueDate: '22 Oct 2022',
            stake: '10%',
            amount: '$ 100.000,00',
          },
        ];
      } else if (activeTab === 'historic') {
        return [
          {
            fundName: 'Wexford',
            created: '18 Oct 2022',
            dueDate: '22 Oct 2022',
            stake: '10%',
            amount: '$ 100.000,00',
            status: 'Rejected',
          },
          {
            fundName: 'Wexford',
            created: '18 Oct 2022',
            dueDate: '22 Oct 2022',
            stake: '10%',
            amount: '$ 100.000,00',
            status: 'Completed',
          },
        ];
      }
    }
    return [];
  };

  const fetchPositions = useCallback(() => {
    if (fund) {
      // getOpenPositionSellRequests(fund.id)
      //   .then(res => {
      //     setPositions(res.data);
      //   })
      //   // eslint-disable-next-line no-console
      //   .catch(error => console.log(error));
    }
  }, [fund]);

  useEffect(() => fund && fetchPositions(), [fetchPositions, fund]);

  return (
    <Dialog
      open={isVisible}
      onClose={closeModal}
      sx={{
        backdropFilter: 'blur(20px)',
      }}
      className="approvenda-dialog"
      PaperProps={{ sx: { maxWidth: '100%' } }}
    >
      <DialogContent className="approvenda-dialog-content ">
        <div className="dialog-content" style={{ width: 'unset' }}>
          <div className="back-login" onClick={closeModal}>
            <IconButton size="large" component="span">
              <IconArrowLeft stroke="#8F8F8F" />
            </IconButton>
            <p className="back-text">Back</p>
          </div>
          <div className="upload-approvenda-form-wrap">
            <h4 className="title">Approve Transactions</h4>
            <HeaderTabSelection
              tabs={[
                { key: 'new-transactions', label: 'New Transactions' },
                { key: 'historic', label: 'Historic' },
              ]}
              chipFilters={[{ key: 'position-sellout', label: 'Position Sellout' }]}
              activeChip={activeChip}
              activeTab={activeTab}
              setActiveChip={setActiveChip}
              setActiveTab={setActiveTab}
            />

            <Grid item xs={12}>
              <TransactionApproveTable
                handleChangePage={handleChangePage}
                handlePageSizeChange={handlePageSizeChange}
                limit={limit}
                metaData={metaData}
                page={page}
                newTransaction={activeTab}
                activeChip={activeChip}
                transactionList={mapTransactions()}
                approvalQueue={approvalQueue}
                setApprovalQueue={setApprovalQueue}
              />
            </Grid>

            <Grid container direction="column" justifyContent="flex-start" alignItems="stretch" spacing={0}>
              <div className="modal-footer">
                <Button
                  disabled={!approvalQueue.length}
                  size="small"
                  order="secondary"
                  onClick={saveChangesApproveQueue}
                >
                  Save Changes
                </Button>
              </div>
            </Grid>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

interface NdaApproveComponentProps {
  isVisible: boolean;
  closeModal: () => void;
  handleChangePage: (newPage: number) => void;
  handlePageSizeChange: (pageSize: number) => void;
  metaData: UserListMetaData;
  page: number;
  limit: number;
  fund: Fund | undefined;
}

export default NdaApproveComponent;
