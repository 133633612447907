import { useQuery } from '@tanstack/react-query';
import { getFundDetail } from 'src/services/fundsService';
import { useSnackbar } from 'src/context/ShackbarContext';
import { AxiosError } from 'axios';
import { QueryKey } from 'src/consts/query';
import { ApiError } from 'src/types/api';

export const useFundById = (fundId: number | string | undefined | null) => {
  const { showErrorSnackbar } = useSnackbar();

  const fetchFund = async () => {
    if (fundId) {
      try {
        const responce = await getFundDetail(fundId);
        return responce.data;
      } catch (e) {
        console.log('useFundByID error', e);
        showErrorSnackbar((e as AxiosError<ApiError>).response?.data.message);
        throw new Error((e as AxiosError<ApiError>).response?.data.message);
      }
    }
    throw new Error('No Fund was provided');
  };

  return useQuery({
    queryKey: [QueryKey.FundById, fundId],
    queryFn: () => fetchFund(),
  });
};
