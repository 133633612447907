import React from 'react';

const IconKey = () => {
  return (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.2962 5.62548L15.2397 2.68199M16.9217 1L15.2397 2.68199L16.9217 1ZM8.8397 9.08197C9.27394 9.51043 9.61913 10.0206 9.85541 10.583C10.0917 11.1454 10.2144 11.749 10.2164 12.359C10.2185 12.9691 10.0998 13.5735 9.86732 14.1375C9.63481 14.7014 9.29304 15.2139 8.86168 15.6452C8.43032 16.0766 7.9179 16.4184 7.35391 16.6509C6.78992 16.8834 6.18551 17.002 5.57548 17C4.96545 16.9979 4.36185 16.8752 3.79943 16.639C3.23701 16.4027 2.72689 16.0575 2.29843 15.6232C1.45586 14.7509 0.989636 13.5825 1.00017 12.3697C1.01071 11.1569 1.49717 9.99676 2.35477 9.13916C3.21237 8.28156 4.3725 7.7951 5.58529 7.78456C6.79807 7.77402 7.96648 8.24025 8.83885 9.08281L8.8397 9.08197ZM8.8397 9.08197L12.2962 5.62548L8.8397 9.08197ZM12.2962 5.62548L14.8192 8.14847L17.7627 5.20498L15.2397 2.68199L12.2962 5.62548Z"
        stroke="#177785"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconKey;
