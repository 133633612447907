import React, { useEffect, useState } from 'react';

import styles from './styles.module.scss';
import { LayoutType } from './types';
import { Fade } from '@mui/material';

interface CrudComponentProps {
  title?: string;
  actionButtons?: {
    layout: boolean;
    filters: boolean;
    search: boolean;
  };
  tableLayout?: React.ReactNode;
  cardsLayout?: React.ReactNode;
  defaultLayout?: LayoutType;
  filterComponent?: React.ReactNode;
}

const CrudComponent = ({
  title,
  // actionButtons = {
  //   layout: true,
  //   filters: true,
  //   search: true,
  // },
  tableLayout,
  cardsLayout,
  defaultLayout = tableLayout ? LayoutType.ROWS : LayoutType.CARDS,
  // filterComponent,
}: CrudComponentProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [layout, setLayout] = useState<LayoutType>(defaultLayout);

  useEffect(() => {
    if (!tableLayout && !cardsLayout) throw new Error('You must provide at least one layout (table or cards)');
    if (defaultLayout === LayoutType.CARDS && !cardsLayout)
      throw new Error("You can't set default layout to cards if there is no cards layout");
    if (defaultLayout === LayoutType.ROWS && !tableLayout)
      throw new Error("You can't set default layout to rows if there is no table layout");
  }, [cardsLayout, defaultLayout, tableLayout]);

  return (
    <div>
      <div className={styles.tableTitleAndFilters}>
        <h2 className={styles.title}>{title}</h2>
        {/* <ActionButtons
          layout={layout}
          setLayout={setLayout}
          buttons={{
            ...actionButtons,
            layout: actionButtons.layout && !!tableLayout && !!cardsLayout,
          }}
          filterComponent={filterComponent}
        /> */}
      </div>

      {tableLayout && (
        <Fade in={layout === LayoutType.ROWS} timeout={300} appear={false}>
          <div>{layout === LayoutType.ROWS && tableLayout}</div>
        </Fade>
      )}
      {cardsLayout && (
        <Fade in={layout === LayoutType.CARDS} timeout={300}>
          <div>{layout === LayoutType.CARDS && (cardsLayout || <div />)}</div>
        </Fade>
      )}
    </div>
  );
};

export default CrudComponent;
