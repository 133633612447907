/* eslint-disable @typescript-eslint/no-unused-vars */
import GoogleMap from 'google-map-react';
import { useEffect, useState } from 'react';
import Marker from 'src/uiLibrary/reusables/marker/marker';

export interface Coordinates {
  lat: number;
  lng: number;
}

interface LocationWidgetProps {
  // TODO: get type from API
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  asset: any;
}

export const LocationWidget = ({ asset }: LocationWidgetProps) => {
  const [centerCoords, setCenterCoords] = useState<Coordinates>({
    lat: 43.2179,
    lng: -75.7042,
  });

  useEffect(() => {
    if (asset && asset?.Location && asset?.Location?.Latitude) {
      setCenterCoords({
        lat: asset?.Location?.Latitude,
        lng: asset?.Location?.Longitude,
      });
    }
  }, [asset]);

  return (
    <>
      <div className="border-boxs">
        <div className="d-flex align-items-center heading-title mb-1">
          <div className="flex-grow-1">
            <h3>Locate The Property</h3>
            <p>Get the exact location of the property in the map</p>
          </div>
        </div>
        <div className="map-wrap">
          <GoogleMap
            style={{
              width: '100%',
              height: '250px',
              position: 'relative',
            }}
            center={centerCoords}
            defaultZoom={15}
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GOOGLE_API_KEY,
            }}
            options={{
              zoomControl: true,
              fullscreenControl: false,
            }}
          >
            <Marker lat={centerCoords.lat} lng={centerCoords.lng}></Marker>
          </GoogleMap>
        </div>
      </div>
    </>
  );
};
