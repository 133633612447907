import { format, isToday, isYesterday } from 'date-fns';

export const dateFormat = (date?: string, f = 'dd MMM yyyy hh:mm a') => (date ? format(new Date(date), f) : '-');

export const dateFormatWithYestarday = (date: Date) =>
  isToday(date)
    ? format(date, "hh':'mm a")
    : isYesterday(date)
      ? format(date, "'Yesterday at' hh':'mm a")
      : format(date, "dd MMM yyyy hh':'mm a");
