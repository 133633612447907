import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IconButton } from '@mui/material';
import FundBg from 'src/assets/images/fund_bg.png';
import IconArrowLeft from 'src/uiLibrary/icons/IconArrowLeft';
import WrapTabs from 'src/components/WrapTabs';
import { useFundById } from 'src/hooks/react-query/funds';
import { Loader } from 'src/components/common/Loader';
import { QueryError } from 'src/components/common/QueryError';

export const FundAnalytics = () => {
  const navigate = useNavigate();

  const { fundId } = useParams();

  const { data: fundInfo, isLoading, isError } = useFundById(fundId);

  const fund = useMemo(() => fundInfo?.fund, [fundInfo]);

  const tabItems = [
    {
      tabName: 'tab 1',
      component: <></>,
    },
    {
      tabName: 'tab 2',
      component: <></>,
    },
  ];

  if (isLoading) return <Loader />;
  if (isError) return <QueryError />;

  return (
    <div className="relative page-without-header-container">
      <div
        className="image-header-container"
        style={{
          backgroundImage: `url('${fund?.BannerImage ?? FundBg}')`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      >
        <div className="back-login">
          <div className="back-text" onClick={() => navigate(-1)} style={{ color: 'black' }}>
            <IconButton size="large" component="span">
              <IconArrowLeft stroke="black" />
            </IconButton>
            Back
          </div>
        </div>
      </div>
      <div className="main-content-container">
        <div className="main-body-header-container">
          <div className="main-body-header-data">
            <div className="main-body-header-data-titles d-flex align-items-center justify-content-between">
              <div>
                <div className="header-title">{fund?.name}</div>
                <div className="header-subtitle">Fund View</div>
              </div>
            </div>
          </div>
        </div>
        <div className="relative">
          <WrapTabs itemList={tabItems} />
        </div>
      </div>
    </div>
  );
};
