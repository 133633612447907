import { useMemo, useState, useEffect } from 'react';
import { useUserRole } from 'src/hooks/usePermission';
import { useProtected } from 'src/hooks/useProtected';
import { Stack } from 'src/components/common';
import { createDocumentsObj } from './FoldersAndDocuments.helpers';
import { DocumentData } from 'src/components/documents/document-card/document-card-helpers';
import { Folder } from 'src/types/Documents';
import { FolderCard } from './components/FolderCard/FolderCard';
import ConfirmDialog from 'src/uiLibrary/modals/confirm-modal/confirmDialogContainer';
import { useDeleteFolder } from 'src/hooks/react-query/documents';
import { Permission } from 'src/consts/permissions';

export type ConfirmDialogState = {
  id?: number;
  name?: string;
  open: boolean;
};

type FoldersAndDocumentsListProps = {
  folders: Folder[];
  documents: DocumentData[];
  handleDeleteDocumentClick: (documentId: number, documentName: string) => Promise<void>;
  handleEditFolderButtonClick: (folderId: number, oldFolderName: string) => void;
};

export const FoldersAndDocumentsList = ({
  folders,
  documents,
  handleDeleteDocumentClick,
  handleEditFolderButtonClick,
}: FoldersAndDocumentsListProps) => {
  const { mutate: deleteFolder, isSuccess: isDeleteFolderSuccess, reset: resetDeleteMutation } = useDeleteFolder();
  const { isLp } = useUserRole();
  const isProtected = useProtected({
    permissions: [Permission.Documents],
  });

  const documentsObj = useMemo(() => {
    return createDocumentsObj(documents);
  }, [documents]);

  const [deleteDialogData, setDeleteDialogData] = useState<ConfirmDialogState>({
    open: false,
  });

  const openDeleteDialog = (body: ConfirmDialogState) => {
    setDeleteDialogData(body);
  };

  const closeDeleteDialog = () => {
    setDeleteDialogData({
      open: false,
    });
  };

  useEffect(() => {
    if (deleteDialogData.open && isDeleteFolderSuccess) {
      closeDeleteDialog();
      resetDeleteMutation();
    }
  }, [isDeleteFolderSuccess]);

  return (
    <>
      <Stack direction="column" spacing={3}>
        {folders.map(folder => {
          const folderDocuments = documentsObj[folder.id];
          const isLpVisible = folderDocuments?.some(document => document.isLpVisible);

          if (isProtected || (isLp && isLpVisible)) {
            return (
              <FolderCard
                key={folder.id}
                folder={folder}
                documents={folderDocuments}
                handleDeleteDocumentClick={handleDeleteDocumentClick}
                onDeleteFolder={openDeleteDialog}
                onUpdateFolder={handleEditFolderButtonClick}
              />
            );
          }
        })}
      </Stack>
      <ConfirmDialog
        isVisible={deleteDialogData.open}
        title={`Are you sure you want to delete the custom folder ${deleteDialogData.name ? deleteDialogData.name : ''} ?`}
        dialogContentText='All files will be relocated to "Other Documents"'
        confirmButtonText="Delete"
        closeButtonText="Cancel"
        confirmModal={() => {
          deleteFolder(deleteDialogData.id as number);
        }}
        closeModal={closeDeleteDialog}
      />
    </>
  );
};
