export const calcActiveCapitalCallPercentage = (contributedAmount: number, calledAmount: number) => {
  if (calledAmount === 0) return 0;

  const percentage = (contributedAmount / calledAmount) * 100;

  if (Number.isInteger(percentage)) {
    return percentage;
  }

  return percentage.toFixed(1);
};
