import { useAuth } from 'src/context/AuthContext';
import { useUserRole } from './usePermission';

type UseAuthorizedProps = {
  permissions: string[];
  mode?: 'every' | 'some';
  allowForLp?: boolean;
};

export const useProtected = ({ permissions, mode = 'every', allowForLp }: UseAuthorizedProps) => {
  const { userData } = useAuth();
  const { isMainGp, isLp } = useUserRole();

  if (!userData) {
    return false;
  }

  if (isMainGp) {
    return true;
  }

  if (isLp) {
    return allowForLp;
  }

  if (mode === 'some') {
    return permissions.some(permission => userData.permissions.includes(permission));
  }
  return permissions.every(permission => userData.permissions.includes(permission));
};
