import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { Loader } from 'src/components/common/Loader';
import { Stack } from 'src/components/common';
import { calcPercentage, calcSumByKey } from 'src/utils/calcMethods';
import { useCapitalStructureWidget } from 'src/hooks/react-query/funds';

const graphColors = ['#297B80', '#1F666A', '#16575B'];

export const CapitalStructure = () => {
  const params = useParams();
  const fundID = Number(params.fundId);

  const { data: capitalStructure, isLoading: isCapitalStructureLoading } = useCapitalStructureWidget(fundID);

  if (!capitalStructure || isCapitalStructureLoading) return <Loader />;

  const total = calcSumByKey(capitalStructure, 'value');

  return (
    <div className="capital-structure-body">
      <div className="capital-main-info">
        <div className="main-label">Capital structure</div>
        <div className="total-value">$ {total.toFixed(2)}</div>
      </div>

      <div className="graph-wrapper">
        <div className="graph-bar">
          {capitalStructure.map((graph, index) => {
            const percentage = `${calcPercentage(graph.value, total).toFixed(2)}%`;
            const value = graph.value.toFixed(2);

            return (
              <div
                key={graph.id}
                className={classNames('graph-portion', {
                  debt: graph.id === 'debt',
                })}
                style={{ flexBasis: percentage }}
              >
                <div className="graph-portion-info">
                  <Stack direction="row" gap={1} alignItems="center">
                    <p className="portion-label">{graph.label}</p>
                  </Stack>

                  <Stack direction="row" className="portion-value-wrapper">
                    $<p className="portion-value">{value}</p>
                  </Stack>

                  <p className="portion-percentage">{percentage}</p>
                </div>

                <div
                  className="graph-portion-footer"
                  style={{
                    backgroundColor: graphColors[index],
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
