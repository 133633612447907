import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { UserDetails } from '../../../../models/authModels';
import { RootState } from '../../../../redux/store';
import MentionsComponent from './mentionsComponent';

const MentionsContainer = ({
  userDetails,
  participantList,
  newMessage,
  setNewMessage,
  setMsgMentions,
}: MentionsContainerProps) => {
  // TODO: get type from API
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [filteredMentions, setFilteredMentions] = useState<any[]>([]);
  const [mentionModalOpen, setMentionModalOpen] = useState(false);
  const [lastMention, setLastMention] = useState('');
  const modalRegex = useRef(/( |^)@\S*/gm);

  const setMessageWithMention = (fullName: string) => {
    const index = newMessage.lastIndexOf(lastMention);
    setNewMessage(prevMsg => prevMsg.slice(0, index) + ` @${fullName} `);
  };

  const filterList = (lastMention: string) => {
    let newList;
    if (lastMention[0] === '@') {
      newList = participantList.filter(
        participant =>
          !!participant.userName && participant.userName.toLowerCase().startsWith(lastMention.slice(1).toLowerCase()),
      );
    } else if (lastMention[1] === '@') {
      newList = participantList.filter(
        participant =>
          !!participant.userName && participant.userName.toLowerCase().startsWith(lastMention.slice(2).toLowerCase()),
      );
    } else {
      newList = [];
    }
    setFilteredMentions(newList);
  };

  useEffect(() => {
    if (newMessage) {
      const matchedArr = newMessage.match(modalRegex.current);
      const newArr = matchedArr ? Array.from(matchedArr) : [];
      const lastMention = newArr[newArr.length - 1] ?? '';
      if (!!lastMention && newMessage.endsWith(lastMention)) {
        filterList(lastMention);
        setLastMention(lastMention);
        setMentionModalOpen(true);
      } else {
        setMentionModalOpen(false);
        setFilteredMentions([]);
      }
    } else {
      setMentionModalOpen(false);
      setLastMention('');
      setFilteredMentions([]);
    }
  }, [newMessage]);

  useEffect(() => {
    filterList(lastMention);
  }, [participantList]);

  return (
    <>
      {mentionModalOpen && (
        <MentionsComponent
          userDetails={userDetails}
          filteredMentions={filteredMentions}
          setMsgMentions={setMsgMentions}
          setMessageWithMention={setMessageWithMention}
        />
      )}
    </>
  );
};
interface MentionsContainerProps {
  userDetails: UserDetails;
  // TODO: get type from API
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  participantList: any[];
  newMessage: string;
  setNewMessage: React.Dispatch<React.SetStateAction<string>>;
  setMsgMentions: React.Dispatch<React.SetStateAction<string[]>>;
}

const mapStateToProps = (state: RootState) => {
  return {
    userDetails: state.auth.userDetails,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MentionsContainer);
