import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getFoldersList } from 'src/services/documentsService';
import { SelectOption } from 'src/types/custom';
import { QueryKey } from 'src/consts/query';
import { sortByKeys } from 'src/utils/sortByKeys';
import { DocumentsLevel } from 'src/types/common';
import { AxiosError } from 'axios';
import { useSnackbar } from 'src/context/ShackbarContext';
import { Folder } from 'src/types/Documents';
import { ApiError } from 'src/types/api';

interface UseDocumentFoldersQueryReturnProps {
  folderList: Folder[];
  documentFoldersOptions: SelectOption[];
  isLoading: boolean;
  isError: boolean;
}

export const useDocumentFolders = (
  id: string | number | undefined,
  level: DocumentsLevel = 'fund',
  enabled = true,
): UseDocumentFoldersQueryReturnProps => {
  const { showErrorSnackbar } = useSnackbar();

  const {
    data: documentFolders,
    isLoading,
    isError,
  } = useQuery({
    queryKey: [QueryKey.DocumentFolders, level, id],
    queryFn: async () => {
      if (id) {
        try {
          const response = await getFoldersList(id, level);
          return response.data;
        } catch (error) {
          console.error(`useDocumentFolders error`, error);
          showErrorSnackbar((error as AxiosError<ApiError>).response?.data.message);
          throw new Error((error as AxiosError<ApiError>).response?.data.message);
        }
      }
      throw Error(`No ${level === 'fund' ? 'Fund' : 'Asset'} id was provided`);
    },
    select: data => {
      if (data) {
        let sortedData = sortByKeys(data.slice(), { keys: 'name', ignoreCase: true });
        sortedData = sortedData.sort((a, b) => {
          if (a.name === 'default') return 1;
          if (b.name === 'default') return -1;
          return 0;
        });
        return sortedData;
      }

      return [];
    },
    enabled,
  });

  const folderList = useMemo(() => {
    if (!documentFolders || !Array.isArray(documentFolders)) return [];

    return documentFolders ?? [];
  }, [documentFolders]);

  const documentFoldersOptions = useMemo(() => {
    if (!documentFolders || !Array.isArray(documentFolders)) return [];

    const folders = documentFolders.map(
      ({ id, name }): SelectOption => ({
        label: name === 'default' ? 'Other Documents' : name,
        value: String(id),
      }),
    );

    folders.sort((a: SelectOption, b: SelectOption) => {
      if (a.label === 'Other Documents') return 1;
      if (b.label === 'Other Documents') return -1;
      return a.label?.localeCompare(b.label);
    });

    return folders;
  }, [documentFolders]);

  return {
    folderList,
    documentFoldersOptions,
    isLoading,
    isError,
  };
};
