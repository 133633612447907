import { Dialog, DialogContent } from '@mui/material';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import IconButton from '@mui/material/IconButton';
import { TextField } from '@mui/material';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import { Controller, useForm } from 'react-hook-form';
import IconArrowLeft from '../../icons/IconArrowLeft';
import { ExistentFolderData } from '../../../models/documentsModels';

const ListOfFoldersRow = ({ folderName, numberOfFiles }: { folderName: string; numberOfFiles: number }) => (
  <div className="list-of-folders-body-row">
    <p>{folderName}</p>
    <div className="align-right">
      <p>{numberOfFiles}</p>
    </div>
  </div>
);

const CreateFolderModalComponent = ({
  isVisible,
  existentFoldersData,
  closeModal,
  onSubmit,
}: CreateFolderModalComponentProps) => {
  const { control, handleSubmit } = useForm<CreateFolderForm>({
    defaultValues: {
      FolderName: '',
    },
  });

  return (
    <Dialog
      open={isVisible}
      onClose={closeModal}
      sx={{
        backdropFilter: 'blur(20px)',
      }}
      className="document-dialog"
    >
      <DialogContent className="create-folder-dialog-content">
        <div className="dialog-content">
          <div className="back-login" onClick={closeModal}>
            <IconButton size="large" component="span">
              <IconArrowLeft stroke="#8F8F8F" />
            </IconButton>
            <p className="back-text">Back</p>
          </div>
          <div className="create-folder-form-wrap">
            <h4 className="title">Create new Custom Folder</h4>
            <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
              <div className="from-row theam-black">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <Controller
                    name="FolderName"
                    control={control}
                    render={({ field, fieldState }) => (
                      <FormControl fullWidth variant="outlined" className="createFolderFormControl">
                        <FormLabel>New folder&apos;s name</FormLabel>
                        <TextField
                          fullWidth
                          type="text"
                          margin="dense"
                          color="info"
                          required
                          placeholder="New folder"
                          {...field}
                          error={!!fieldState.error}
                        />
                      </FormControl>
                    )}
                  />
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 create-folder-list-of-folders">
                  <div className="list-of-folders-header">
                    <div>
                      <p>Folder Name</p>
                    </div>
                    <div className="align-right">
                      <p>Number of files</p>
                    </div>
                  </div>
                  <div className="list-of-folders-body">
                    <PerfectScrollbar>
                      {existentFoldersData.map((m: ExistentFolderData) => (
                        <ListOfFoldersRow key={m.id} folderName={m.name} numberOfFiles={m.numberOfDocs} />
                      ))}
                    </PerfectScrollbar>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 btn-row-black mt-3">
                  <Button type="submit" variant="contained" className="col-lg-12 col-md-12 col-sm-12">
                    Create
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

interface CreateFolderModalComponentProps {
  isVisible: boolean;
  existentFoldersData: ExistentFolderData[];
  closeModal: () => void;
  onSubmit: (formData: CreateFolderForm) => void;
}

export default CreateFolderModalComponent;

export interface CreateFolderForm {
  FolderName: string;
}
