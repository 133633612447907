/* eslint-disable @typescript-eslint/no-explicit-any */
import { Privilege, RoleById } from 'src/types/Roles';
import { FundPrivilegeType } from '../addRoleContainer';

export const getCheckedIds = (items: Privilege[] | Privilege['childPrivilege']): number[] => {
  return items.flatMap(item => {
    const childIds = (item as Privilege).childPrivilege ? getCheckedIds((item as Privilege).childPrivilege) : [];
    return item.checked ? [item.id, ...childIds] : childIds;
  });
};

export const getIdsWithPrivilege = <T extends RoleById['privilege']>(items: T) => {
  let ids: number[] = [];

  items.forEach(item => {
    if (item.has_privilege === 1) {
      ids.push(item.id);
    }
    if (item.childPrivilege && item.childPrivilege.length > 0) {
      ids = ids.concat(getIdsWithPrivilege(item.childPrivilege as unknown as RoleById['privilege']));
    }
  });

  return ids;
};

export const getCheckedFundPrivileges = (data: FundPrivilegeType[]) => {
  return data.map(fund => {
    const checkedPrivileges: number[] = [];

    const collectCheckedPrivileges = (privileges: FundPrivilegeType['fundPrivileges']) => {
      privileges.forEach(privilege => {
        if (privilege.checked) {
          checkedPrivileges.push(privilege.id);
        }
        if (privilege.ChildPrivilege && privilege.ChildPrivilege.length > 0) {
          collectCheckedPrivileges(privilege.ChildPrivilege);
        }
      });
    };

    collectCheckedPrivileges(fund.fundPrivileges);

    return {
      FundId: fund.id,
      FundPrivileges: checkedPrivileges,
    };
  });
};
