import { ThemeCssVarOverrides } from '@mui/material';

export const textFieldComponentsTheme: ThemeCssVarOverrides = {
  MuiTextField: {
    styleOverrides: {
      root: {
        background: '#f6f5f4',
        borderRadius: 8,
        minHeight: '46px',
        border: '1px solid #E1E1E1',

        '.MuiOutlinedInput-notchedOutline': {
          border: 0,
        },
      },
    },
  },
};
