import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createDocumentsFolder } from 'src/services/documentsService';
import { useSnackbar } from 'src/context/ShackbarContext';
import { QueryKey } from 'src/consts/query';
import { AxiosError } from 'axios';
import { CreateFolderDTO } from 'src/types/Documents';

export const useCreateDocumentsFolder = () => {
  const queryClient = useQueryClient();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: (body: CreateFolderDTO) => {
      return createDocumentsFolder(body);
    },
    onSuccess: async response => {
      const folderName = (await response?.data?.data?.name) ?? '';

      await queryClient.invalidateQueries({ queryKey: [QueryKey.DocumentFolders] });
      await showSuccessSnackbar(`New custom folder ${folderName} was successfully created`);
    },
    onError: (error: AxiosError<{ message: string }>) => {
      const message = error.response?.data?.message ?? error.message;
      showErrorSnackbar(message);
    },
  });
};
