import React from 'react';

const IconMultipleFiles = () => {
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none">
      <path
        d="M16.4987 18.6666H8.16537C7.94435 18.6666 7.73239 18.5788 7.57611 18.4225C7.41983 18.2662 7.33203 18.0543 7.33203 17.8333V7.83325C7.33203 7.61224 7.41983 7.40028 7.57611 7.244C7.73239 7.08772 7.94435 6.99992 8.16537 6.99992H16.4987C16.7197 6.99992 16.9317 7.08772 17.088 7.244C17.2442 7.40028 17.332 7.61224 17.332 7.83325V17.8333C17.332 18.0543 17.2442 18.2662 17.088 18.4225C16.9317 18.5788 16.7197 18.6666 16.4987 18.6666ZM15.6654 8.66659H8.9987V16.9999H15.6654V8.66659ZM5.66537 14.4999V5.33325H13.1654C13.3864 5.33325 13.5983 5.24545 13.7546 5.08917C13.9109 4.93289 13.9987 4.72093 13.9987 4.49992C13.9987 4.27891 13.9109 4.06694 13.7546 3.91066C13.5983 3.75438 13.3864 3.66659 13.1654 3.66659H4.83203C4.61102 3.66659 4.39906 3.75438 4.24277 3.91066C4.0865 4.06694 3.9987 4.27891 3.9987 4.49992V14.4999C3.9987 14.7209 4.0865 14.9329 4.24277 15.0892C4.39906 15.2455 4.61102 15.3333 4.83203 15.3333C5.05305 15.3333 5.26501 15.2455 5.42129 15.0892C5.57757 14.9329 5.66537 14.7209 5.66537 14.4999ZM0.665365 1.16659V11.9999C0.665365 12.2209 0.753162 12.4329 0.909443 12.5892C1.06572 12.7455 1.27769 12.8333 1.4987 12.8333C1.71971 12.8333 1.93167 12.7455 2.08795 12.5892C2.24424 12.4329 2.33203 12.2209 2.33203 11.9999V1.99992H9.83203C10.053 1.99992 10.265 1.91212 10.4213 1.75584C10.5776 1.59956 10.6654 1.3876 10.6654 1.16659C10.6654 0.945572 10.5776 0.73361 10.4213 0.57733C10.265 0.421049 10.053 0.333252 9.83203 0.333252H1.4987C1.27769 0.333252 1.06572 0.421049 0.909443 0.57733C0.753162 0.73361 0.665365 0.945572 0.665365 1.16659Z"
        fill="black"
      />
    </svg>
  );
};

export default IconMultipleFiles;
