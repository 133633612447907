import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { FormHelperText, TextField } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { LoginPayload } from 'src/models/authModels';
import { emailValidator, passwordValidator } from 'src/uiLibrary/reusables/form-validators/formValidators';

interface SignInFormProps {
  onSubmit: (formData: LoginPayload) => void;
}

export const SignInForm = ({ onSubmit }: SignInFormProps) => {
  const { control, handleSubmit } = useForm<LoginPayload>({
    defaultValues: {
      Email: '',
      Password: '',
    },
  });

  return (
    <div className="content-input">
      <h2 className="login-title">Sign in</h2>
      <p className="lead">Enter your credentials and log into your extrance account</p>
      <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <div className="from-row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <Controller
              name="Email"
              control={control}
              rules={{
                required: 'Email is required.',
                pattern: { ...emailValidator },
              }}
              render={({ field, fieldState }) => (
                <FormControl fullWidth variant="outlined" className="formControl">
                  <FormLabel>User mail</FormLabel>
                  <TextField
                    {...field}
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message : ''}
                    fullWidth
                    type="email"
                    margin="dense"
                    required
                  />
                </FormControl>
              )}
            />
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12">
            <Controller
              name="Password"
              control={control}
              rules={{
                required: 'Password is required.',
                maxLength: {
                  value: 20,
                  message: 'Maximum length must be 20',
                },
                minLength: {
                  value: 8,
                  message: 'Minimum length must be 8',
                },
                pattern: passwordValidator,
              }}
              render={({ field, fieldState }) => (
                <FormControl fullWidth variant="outlined" className="formControl">
                  <FormLabel>Password</FormLabel>
                  <OutlinedInput type={'password'} color="info" {...field} error={!!fieldState.error} />
                  {!!fieldState.error && (
                    <FormHelperText error={!!fieldState.error}>{fieldState.error.message}</FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 btn-row">
            <Button type="submit" color="info" variant="contained" className="button">
              Continue
            </Button>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12">
            <Link className="forgot-text" to="/forgot-password">
              Forgot your password?
            </Link>
          </div>
        </div>
      </form>
    </div>
  );
};
