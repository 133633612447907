/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import FloorImg from 'src/assets/images/floor-img.jpg';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

interface FloorPlanWidgetProps {
  // TODO: get type from API
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  asset: any;
}

export const FloorPlanWidget = ({ asset }: FloorPlanWidgetProps) => {
  const [floorIndex, setFloorIndex] = useState(0);
  const [isFloorBoxOpen, setIsFloorBoxOpen] = useState(false);
  // TODO: get type from API
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [floorImage, setFloorImage] = useState<any>([]);

  const openFloorBox = (i: number) => {
    setIsFloorBoxOpen(true);
    setFloorIndex(i);
  };

  const closeFloorBox = () => {
    setIsFloorBoxOpen(false);
  };

  useEffect(() => {
    if (asset) {
      const i = asset?.AssetsImages?.findIndex(
        // TODO: get type from API
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (x: any) => x.IsDefault === 1,
      );
      if (i >= 0) {
        const defaultArray = asset?.AssetsImages?.splice(i, 1);
        asset?.AssetsImages?.unshift(defaultArray[0]);
      }

      if (asset?.FloorPlanImage) {
        setFloorImage([asset?.FloorPlanImage]);
      } else {
        setFloorImage([FloorImg]);
      }
    }
  }, [asset]);

  return (
    <>
      <div className="border-boxs">
        <div className="d-flex align-items-center heading-title mb-1">
          <div className="flex-grow-1">
            <h3>Floor Plan</h3>
            <p>Check out the floor plan of the property</p>
          </div>
        </div>
        <div className="map-wrap">
          <img
            style={{ cursor: 'pointer' }}
            onClick={() => openFloorBox(0)}
            src={asset?.FloorPlanImage ? asset?.FloorPlanImage : FloorImg}
            alt=""
          />
        </div>
      </div>
      {isFloorBoxOpen && (
        <Lightbox
          mainSrc={floorImage[floorIndex]}
          nextSrc={floorImage[(floorIndex + 1) % floorImage.length]}
          prevSrc={floorImage[(floorIndex + floorImage.length - 1) % floorImage.length]}
          onCloseRequest={closeFloorBox}
          onMovePrevRequest={() => {
            setFloorIndex(prevState => (prevState + floorImage.length - 1) % floorImage?.length);
          }}
          onMoveNextRequest={() => {
            setFloorIndex(prevState => (prevState + 1) % floorImage?.length);
          }}
        />
      )}
    </>
  );
};
