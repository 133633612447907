import React, { useState } from 'react';
import { UserListMetaData } from '../../../models/frontendUserModels';
import TransactionApproveComponent from './TransactionApproveComponent';
import { Fund } from 'src/types/Funds';

const TransactionAproveContainer = ({
  closeModal,
  isVisible,
  fund,
}: // onSubmit,
// setType,
// type,
// setFreq,
// freq,
TransactionAproveContainerProps) => {
  const initialMetaObject = { total: 0, page: 0, limit: 20, count: 0 };
  const [page, setPage] = React.useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [metaData, setMetaData] = useState<UserListMetaData>(initialMetaObject);
  const [limit, setLimit] = useState(10);

  const handlePageSizeChange = (pageSize: number) => {
    setPage(0);
    setLimit(pageSize);
  };

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  return (
    <TransactionApproveComponent
      closeModal={closeModal}
      isVisible={isVisible}
      handlePageSizeChange={handlePageSizeChange}
      handleChangePage={handleChangePage}
      metaData={metaData}
      page={page}
      limit={limit}
      fund={fund}
      //onSubmit={onSubmit}
      //setType={setType}
      //type={docType}
      //setFreq={setFreq}
      //freq={freq}
    />
  );
};

interface TransactionAproveContainerProps {
  isVisible: boolean;
  closeModal: () => void;
  fund: Fund | undefined;
}

export default TransactionAproveContainer;
