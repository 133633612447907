import { useNavigate } from 'react-router-dom';
import ArrowRight from 'src/assets/images/icons/arrow-right.svg';

export const CapitalCallHistory = () => {
  const navigate = useNavigate();

  return (
    <div className="capital-action-item">
      <div className="capital-action-info">
        <div className="capital-action-title">Capital calls history</div>
        <div className="capital-action-subtitle">4 capcalls</div>
      </div>
      <div className="icon-box" onClick={() => navigate('capcall-history')}>
        <img src={ArrowRight} width="16px" height="16px" />
      </div>
    </div>
  );
};
