import { useFunds } from 'src/hooks/react-query/funds';
import { Loader } from 'src/components/common/Loader';
import { QueryError } from 'src/components/common/QueryError';
import { FundsSpotlightWidget } from './components/FundsSpotlightWidget';
import { FundsPerformanceSlideWidget } from './components/FundsPerformanceSlideWidget';
import { FundsOverviewWidget } from './components/FundsOverviewWidget';

const DashboardComponent = () => {
  const { data: funds, isLoading, isError } = useFunds();

  if (isLoading) return <Loader />;

  if (isError) return <QueryError />;

  if (!funds.length) {
    return (
      <div className="main-body-content-frame">
        <h1>You don’t have any Funds yet</h1>
      </div>
    );
  }

  return (
    <>
      {
        //TODO: add check if current user is not GP
      }
      {/* {!userDetails?.IsBankExist && (
          <div className="dashboard-row-components">
            <Banner
              title="Banking information is incomplete"
              subtitle="Please provide your banking details and setup your profile to unlock the full capabilities of Extrance"
              variant="error"
              actionComponent={
                <Link className="dashboard-banner-btn" to={'/banking'}>
                  <IconExternalLink />
                </Link>
              }
            />
          </div>
        )} */}
      <div className="dashboard-row-components">
        <FundsOverviewWidget funds={funds} />
        <div className="funds-performance-slide-component">
          <FundsPerformanceSlideWidget funds={funds} />
        </div>
      </div>
      {
        //TODO: add check if current user is not GP
      }
      {/* {!isUserGP && (
          <div className="dashboard-row-components">
            <BankingPendingPaymentsWidget />
          </div>
        )} */}
      <div className="dashboard-row-components relative">
        <FundsSpotlightWidget funds={funds} />
      </div>
    </>
  );
};

export default DashboardComponent;
