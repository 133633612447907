import React from 'react';

const IconAsset = () => {
  return (
    <svg width="15" height="19" viewBox="0 0 15 19" fill="none">
      <path
        d="M1.66644 13.6667C1.48055 13.6668 1.30002 13.729 1.15355 13.8434C1.00708 13.9579 0.903088 14.118 0.858103 14.2984L0.0247698 17.6317C-0.00586286 17.7545 -0.00811382 17.8827 0.0181879 18.0065C0.0444896 18.1303 0.0986527 18.2465 0.176566 18.3463C0.25448 18.4461 0.354096 18.5268 0.467854 18.5823C0.581612 18.6378 0.706522 18.6667 0.833103 18.6667H14.1664C14.293 18.6667 14.4179 18.6378 14.5317 18.5823C14.6454 18.5268 14.7451 18.4461 14.823 18.3463C14.9009 18.2465 14.955 18.1303 14.9814 18.0065C15.0077 17.8827 15.0054 17.7545 14.9748 17.6317L14.1414 14.2984C14.0965 14.118 13.9925 13.9579 13.846 13.8434C13.6995 13.729 13.519 13.6668 13.3331 13.6667H11.5831L10.9348 7.83337H12.4998C12.7208 7.83337 12.9327 7.74558 13.089 7.5893C13.2453 7.43302 13.3331 7.22105 13.3331 7.00004V1.16671C13.3331 0.945694 13.2453 0.733732 13.089 0.577452C12.9327 0.421171 12.7208 0.333374 12.4998 0.333374H9.16644C8.94542 0.333374 8.73346 0.421171 8.57718 0.577452C8.4209 0.733732 8.3331 0.945694 8.3331 1.16671V2.83337H6.66644V1.16671C6.66644 0.945694 6.57864 0.733732 6.42236 0.577452C6.26608 0.421171 6.05412 0.333374 5.8331 0.333374H2.49977C2.27876 0.333374 2.06679 0.421171 1.91051 0.577452C1.75423 0.733732 1.66644 0.945694 1.66644 1.16671V7.00004C1.66644 7.22105 1.75423 7.43302 1.91051 7.5893C2.06679 7.74558 2.27876 7.83337 2.49977 7.83337H4.06894L3.4206 13.6667H1.66644ZM3.3331 6.16671V2.00004H4.99977V3.66671C4.99977 3.88772 5.08757 4.09968 5.24385 4.25596C5.40013 4.41224 5.61209 4.50004 5.8331 4.50004H9.16644C9.38745 4.50004 9.59941 4.41224 9.75569 4.25596C9.91197 4.09968 9.99977 3.88772 9.99977 3.66671V2.00004H11.6664V6.16671H3.3331ZM9.90227 13.6667H5.09727L5.74977 7.83337H9.2581L9.90227 13.6667ZM12.6823 15.3334L13.0989 17H1.9006L2.31727 15.3334H12.6823Z"
        fill="black"
      />
    </svg>
  );
};

export default IconAsset;
