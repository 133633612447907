import { AccountManagerDetails } from '../../models/profileModel';
import IconCall from '../../uiLibrary/icons/IconCall';
import IconMail from '../../uiLibrary/icons/IconMail';

const AccountManagerDetailsComponent = ({ accountManagerList }: { accountManagerList: AccountManagerDetails[] }) => (
  <div className="account-manager-details-container">
    <div className="account-manager-details-header">
      <p className="title">Account Manager Details</p>
      <p className="subtitle">Check out all the details of your account manager</p>
    </div>
    <div className="account-manager-details-body">
      {accountManagerList.map(acc => (
        <div key={acc.id} className="account-manager-details-card">
          <div
            className="image-container"
            style={{
              backgroundImage: `url('${acc.image}')`,
              backgroundSize: '52px 52px',
              resize: 'both',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
          />
          <div className="details-container">
            <p className="account-manager-name">{acc.name}</p>
            <div className="account-manager-information">
              <div className="account-manager-information-item">
                <div className="item-icon">
                  <IconMail />
                </div>
                {acc.email}
              </div>
              <div className="account-manager-information-item">
                <div className="item-icon">
                  <IconCall />
                </div>
                {acc.contactNumber}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default AccountManagerDetailsComponent;
