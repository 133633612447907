import { KPIValueType } from '../models/partnershipModel';
import classNames from 'classnames';
import { formatKpiValue } from 'src/utils/formatKpiValue';

type CardKPIProps = {
  id: number;
  cardHeader: string;
  kpiDescription: string;
  value: number | null;
  ValueType: KPIValueType;
  variationNumber: number | null;
  handleOnClick?: (id: number) => void;
};

export const CardKPI = ({
  id,
  cardHeader,
  kpiDescription,
  value,
  ValueType,
  variationNumber,
  handleOnClick,
}: CardKPIProps) => {
  const getBackgroundColor = (variationNum: number) => {
    if (variationNum > 0) return '#16575B';
    if (variationNum < 0) return '#F34A29';
    return '#E1E1E1';
  };

  return (
    <div
      className={classNames('kpi-card-box', { clickable: !!handleOnClick })}
      onClick={() => handleOnClick && handleOnClick(id)}
    >
      <div className="kpi-card-header">{cardHeader}</div>
      <div className="kpi-card-info-wrapper">
        <div className="kpi-card-description">{kpiDescription}</div>
        <div className="kpi-card-value-wrapper">
          <div className="kpi-card-value">{formatKpiValue(value, ValueType)}</div>
          {variationNumber !== null && (
            <div
              className={`kpi-card-value-variation-tag`}
              style={{
                backgroundColor: getBackgroundColor(variationNumber),
              }}
            >
              {variationNumber?.toFixed(1)} %
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
