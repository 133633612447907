import React from 'react';

const IconInfo = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13Z"
        stroke="white"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.25391 5.2C5.39497 4.799 5.6734 4.46086 6.03988 4.24548C6.40636 4.0301 6.83724 3.95136 7.25621 4.02323C7.67518 4.09509 8.0552 4.31291 8.32895 4.63812C8.60271 4.96332 8.75254 5.37491 8.75191 5.8C8.75191 7 6.95191 7.6 6.95191 7.6"
        stroke="white"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M7 10H7.006" stroke="white" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default IconInfo;
