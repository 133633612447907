import { AlertColor } from '@mui/material';
import { SnackbarActions, SnackbarActionTypes } from '../actions/snackbarActions';

interface SnackbarReducerState {
  message: string | null;
  severity: AlertColor;
  duration: number;
}

const initialState: SnackbarReducerState = {
  message: '',
  severity: 'success',
  duration: 2000,
};

export default function SnackbarReducer(state: SnackbarReducerState = initialState, action: SnackbarActions) {
  switch (action.type) {
    case SnackbarActionTypes.SHOW_SNACKBAR:
      return {
        ...state,
        message: action.payload.message,
        severity: action.payload.severity,
        duration: action.payload.duration ? action.payload.duration : 2000,
      };
    case SnackbarActionTypes.HIDE_SNACKBAR:
      return { ...state, message: null };
    default:
      return state;
  }
}
