const IconApproveNDA = () => {
  return (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="60" height="60" rx="8" fill="white" fillOpacity="0.1" />
      <path
        d="M38.71 24.3L33.71 19.3H33.705C33.5135 19.1187 33.2634 19.0123 33 19H22C21.7348 19 21.4804 19.1054 21.2929 19.2929C21.1054 19.4804 21 19.7348 21 20V40C21 40.2652 21.1054 40.5196 21.2929 40.7071C21.4804 40.8946 21.7348 41 22 41H38C38.2652 41 38.5196 40.8946 38.7071 40.7071C38.8946 40.5196 39 40.2652 39 40V25C38.991 24.7393 38.888 24.4907 38.71 24.3V24.3ZM37 39H23V21H32V25C32 25.2652 32.1054 25.5196 32.2929 25.7071C32.4804 25.8946 32.7348 26 33 26H37V39ZM33.707 28.293C33.8945 28.4805 33.9998 28.7348 33.9998 29C33.9998 29.2652 33.8945 29.5195 33.707 29.707L29.707 33.707C29.5305 33.8834 29.2945 33.9874 29.0453 33.9987C28.7961 34.01 28.5517 33.9277 28.36 33.768L26.36 32.102C26.1562 31.9323 26.0281 31.6885 26.004 31.4244C25.9799 31.1602 26.0618 30.8973 26.2315 30.6935C26.4012 30.4897 26.645 30.3616 26.9091 30.3375C27.1733 30.3134 27.4362 30.3953 27.64 30.565L28.94 31.647L32.295 28.293C32.4824 28.1061 32.7363 28.0012 33.001 28.0012C33.2657 28.0012 33.5196 28.1061 33.707 28.293Z"
        fill="#909090"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.9998 29C33.9998 28.7348 33.8945 28.4805 33.707 28.293C33.5196 28.1061 33.2657 28.0012 33.001 28.0012C32.7363 28.0012 32.4824 28.1061 32.295 28.293L28.94 31.647L27.64 30.565C27.4362 30.3953 27.1733 30.3134 26.9091 30.3375C26.645 30.3616 26.4012 30.4897 26.2315 30.6935C26.0618 30.8973 25.9799 31.1602 26.004 31.4244C26.0281 31.6885 26.1562 31.9323 26.36 32.102L28.36 33.768C28.5517 33.9277 28.7961 34.01 29.0453 33.9987C29.2945 33.9874 29.5305 33.8834 29.707 33.707L33.707 29.707C33.8945 29.5195 33.9998 29.2652 33.9998 29Z"
        fill="white"
      />
    </svg>
  );
};

export default IconApproveNDA;
