import React from 'react';

const IconDocument = () => {
  return (
    <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.71 5.3L12.71 0.3H12.705C12.5135 0.118745 12.2634 0.0122827 12 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1V21C0 21.2652 0.105357 21.5196 0.292893 21.7071C0.48043 21.8946 0.734784 22 1 22H17C17.2652 22 17.5196 21.8946 17.7071 21.7071C17.8946 21.5196 18 21.2652 18 21V6C17.991 5.73932 17.888 5.4907 17.71 5.3ZM16 20H2V2H11V6C11 6.26522 11.1054 6.51957 11.2929 6.70711C11.4804 6.89464 11.7348 7 12 7H16V20Z"
        fill="black"
      />
    </svg>
  );
};

export default IconDocument;
