import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { SnackbarPayload } from '../../models/snackbarModels';
import { UpdateProfileImageAction } from '../../redux/actions/authActions';
import { HideLoadingAction, ShowLoadingAction } from '../../redux/actions/loadingActions';
import { ShowSnackbarAction } from '../../redux/actions/snackbarActions';
import { getAccountManagerList, getGpProfile, updateProfileImage } from '../../services/profileService';
import MyProfileComponent from './myProfileComponent';
import { AccountManagerDetails, UserProfileDetails } from '../../models/profileModel';
import { useNavigate } from 'react-router-dom';
import EditProfileModalComponent, {
  EditProfileForm,
} from '../../uiLibrary/modals/editProfileModal/editProfileModalComponent';
import { RequestError } from 'src/models/supportRequestModels';

const MyProfileContainer = ({
  isUserGP,
  ShowLoadingAction,
  ShowSnackbarAction,
  HideLoadingAction,
}: ProfileContainerProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [profileDetails, setProfileDetails] = useState<UserProfileDetails | null>(null);
  const [accountManagerList, setAccountManagerList] = useState<AccountManagerDetails[]>([]);

  const [isEditProfileModalOpen, setIsEditProfileModalOpen] = useState<boolean>(false);

  useEffect(() => {
    // isUserGP ? getGpProfileDetails() : getGpProfileDetails(); // TODO: getLpProfileDetails
    getGpProfileDetails();
    getAccountManagerDetails();
  }, []);

  const getAccountManagerDetails = async () => {
    ShowLoadingAction();
    try {
      const response = await getAccountManagerList();
      const accountManagers: AccountManagerDetails[] = response.data.data?.map(
        // TODO: get type from API
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (acc: any) => ({
          id: acc.AccountManager.userId,
          name: `${acc.AccountManager.FirstName} ${acc.AccountManager.LastName}`,
          email: acc.AccountManager.Email,
          contactNumber: acc.AccountManager.ContactNumber,
          image: acc.AccountManager.ProfileImage || '',
        }),
      );
      setAccountManagerList(accountManagers);
    } catch (e) {
      setAccountManagerList([]);
    }
    HideLoadingAction();
  };

  // TODO: get type from API
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateUserState = (payload: any) => {
    // payload.user = {};
    // payload.user.rolePrivilege = rolePrivilege;
    // localStorage.setItem("extrance_user", JSON.stringify(payload));
    dispatch(UpdateProfileImageAction(payload));
  };

  const getGpProfileDetails = async () => {
    try {
      ShowLoadingAction();
      const response = await getGpProfile();

      if (response.data?.data) {
        const profileDetailsLocal: UserProfileDetails = {
          id: response.data.data.userId,
          name: `${response.data.data.FirstName} ${response.data.data.LastName}`,
          email: response.data.data.Email,
          contactNumber: response.data.data.ContactNumber,
          address: response.data.data.Address,
          addressState: response.data.data.StateName,
          addressZipCode: response.data.data.ZipCode,
          billingAddress: response.data.data.Address, // TODO: we should get this information
          billingAddressState: response.data.data.StateName,
          billingAddressZipCode: response.data.data.ZipCode,
          companyAgency: response.data.data.CompanyName || 'No information',
          image: response.data.data.ProfileImage,
          plan: 'No information', // TODO: We should get this information on this endpoint.
          subscriptionRenewed: response.data.data.SubscriptionFrom,
        };

        setProfileDetails(profileDetailsLocal);
      }
    } catch (err) {
      const e = err as RequestError;
      setProfileDetails(null);
      ShowSnackbarAction({
        message: e?.response?.data?.message ?? 'Something went wrong',
        severity: 'error',
        duration: 3000,
      });
    } finally {
      HideLoadingAction();
    }
  };

  const handleBankingInformationOnClick = () => {
    navigate('/banking');
  };

  const handleEditProfileOnSubmit = async (formData: EditProfileForm) => {
    if (formData.ProfileImage !== '') {
      const payload = new FormData();
      payload.append('ProfileImage', formData.ProfileImage);
      try {
        const response = await updateProfileImage(payload);
        updateUserState(response.data.data);
        isUserGP ? getGpProfileDetails() : getGpProfileDetails(); // TODO: getLpProfileDetails
        setTimeout(() => {
          handleEditProfileOnClose();
        }, 1000);
        ShowSnackbarAction({
          message: "'Profile Image' updated successfully.",
          severity: 'success',
          duration: 3000,
        });
      } catch (e) {
        //
      }
    } else {
      // TODO: remove this when integrated
      ShowSnackbarAction({
        message: "Only 'Profile Image' can be updated at the moment.",
        severity: 'error',
        duration: 3000,
      });
    }
  };

  const handleEditProfileOnClick = () => {
    setIsEditProfileModalOpen(true);
  };

  const handleEditProfileOnClose = () => {
    setIsEditProfileModalOpen(false);
  };

  return (
    <>
      {profileDetails && (
        <EditProfileModalComponent
          isVisible={isEditProfileModalOpen}
          closeModal={handleEditProfileOnClose}
          initialValues={profileDetails}
          onSubmit={handleEditProfileOnSubmit}
        />
      )}
      <MyProfileComponent
        isUserGP={isUserGP}
        handleBankingInformationOnClick={handleBankingInformationOnClick}
        profileDetails={profileDetails}
        handleEditProfileOnClick={handleEditProfileOnClick}
        accountManagerList={accountManagerList}
      />
    </>
  );
};

interface ProfileContainerProps {
  isUserGP: boolean;
  ShowSnackbarAction: (payload: SnackbarPayload) => void;
  ShowLoadingAction: (payload?: string) => void;
  HideLoadingAction: () => void;
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {
  ShowLoadingAction,
  HideLoadingAction,
  ShowSnackbarAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyProfileContainer);
