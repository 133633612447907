import { FieldValues } from 'react-hook-form';
import FboFundingModalComponent from './FboFundingComponent';

const FboFundingModalContainer = ({
  isVisible,
  closeModal,
  handleOnSubmit,
  fboBalance,
}: FboFundingModalContainerProps) => {
  const onSubmit = (formData: FieldValues) => {
    handleOnSubmit(formData);
    closeModal();
  };

  return (
    <FboFundingModalComponent
      isVisible={isVisible}
      closeModal={closeModal}
      onSubmit={onSubmit}
      fboBalance={fboBalance}
    />
  );
};

interface FboFundingModalContainerProps {
  isVisible: boolean;
  closeModal: () => void;
  handleOnSubmit: (formData: FieldValues) => void;
  fboBalance: number | undefined | null;
}

export default FboFundingModalContainer;
