export const searchByRegex = (source: string, search: string) => {
  const regexEscape = (string: string) => {
    // eslint-disable-next-line no-useless-escape
    return string.replace(/[\/\\^$*+?.()|[\]{}-]/g, '\\$&');
  };

  const regex = new RegExp(regexEscape(search), 'i');

  return regex.test(source);
};
