import { Grid, IconButton, Dialog, DialogContent } from '@mui/material';
import IconArrowLeft from '../../icons/IconArrowLeft';
import MarketplaceMovementsTable from './movements-table';
import MarketplaceCountersigningTable from './countersigning-table';
import HeaderTabSelection from '../../../components/HeaderTabSelection';
import MarketplaceCapcallTable from './capcall-table';
import Button from '../../../components/common/Button';
import { Fund } from 'src/types/Funds';

const MarketplaceActComponent = ({
  closeModal,
  isVisible,
  fund,
  //onSubmit,
  handleMovementsTablePageSizeChange,
  handleMovementsTableChangePage,
  movementsTableMetaData,
  movementsTablePage,
  movementsTableLimit,
  setMovementsTableMetaData,
  handleCountersigningTablePageSizeChange,
  handleCountersigningTableChangePage,
  countersigningTableMetaData,
  countersigningTablePage,
  countersigningTableLimit,
  countersignTableData,
  // setCountersigningTableMetaData,
  handleCapCallTablePageSizeChange,
  handleCapCallTableChangePage,
  capCallTableMetaData,
  capCallTablePage,
  capCallTableLimit,
  // setCapCallTableMetaData,
  capCallTableData,
  activeTab,
  setActiveTab,
}: MarketplaceActComponentProps) => {
  const renderSelectedTabComponent = (selectedTab: string) => {
    switch (selectedTab) {
      case 'movements':
        return (
          <MarketplaceMovementsTable
            fundId={fund?.id}
            handleChangePage={handleMovementsTableChangePage}
            handlePageSizeChange={handleMovementsTablePageSizeChange}
            //handleSortChange={handleSortChange}
            limit={movementsTableLimit}
            metaData={movementsTableMetaData}
            page={movementsTablePage}
            setMetaData={setMovementsTableMetaData}
          />
        );
      case 'countersigning':
        return (
          <>
            <MarketplaceCountersigningTable
              handleChangePage={handleCountersigningTableChangePage}
              handlePageSizeChange={handleCountersigningTablePageSizeChange}
              //handleSortChange={handleSortChange}
              limit={countersigningTableLimit}
              metaData={countersigningTableMetaData}
              page={countersigningTablePage}
              // setMetaData={setCountersigningTableMetaData}
              tableDataList={countersignTableData}
            />
            <div className="modal-footer">
              <Button disabled={true} size="small" order="secondary">
                Save Changes
              </Button>
            </div>
          </>
        );
      case 'capcall-buy-request':
        return (
          <>
            <MarketplaceCapcallTable
              handleChangePage={handleCapCallTablePageSizeChange}
              handlePageSizeChange={handleCapCallTableChangePage}
              //handleSortChange={handleSortChange}
              limit={capCallTableLimit}
              metaData={capCallTableMetaData}
              page={capCallTablePage}
              tableDataList={capCallTableData}
            />
            <div className="modal-footer">
              <Button disabled={true} size="small" order="secondary">
                Save Changes
              </Button>
            </div>
          </>
        );
      default:
        return undefined;
    }
  };

  return (
    <Dialog
      open={isVisible}
      onClose={closeModal}
      sx={{
        backdropFilter: 'blur(20px)',
      }}
      className="dialog-no-padding"
      PaperProps={{ sx: { maxWidth: '100%', minWidth: '864px' } }}
    >
      <DialogContent className="generic-form-dialog-content">
        <div className="dialog-content">
          <div className="back-login" onClick={closeModal}>
            <IconButton size="large" component="span">
              <IconArrowLeft stroke="#8F8F8F" />
            </IconButton>
            <p className="back-text">Back</p>
          </div>
          <div className="title-and-subtitle-wrap">
            <p className="title">Marketplace Activity</p>
          </div>
          <div className="dialog-form-wrap">
            <HeaderTabSelection tabs={[]} activeTab={activeTab} setActiveTab={setActiveTab} />
            <Grid item xs={12}>
              {renderSelectedTabComponent(activeTab)}
            </Grid>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

interface MarketplaceActComponentProps {
  isVisible: boolean;
  closeModal: () => void;
  fund?: Fund | null;
  //onSubmit: (formData: any) => void;
  handleMovementsTablePageSizeChange: (newPage: number) => void;
  handleMovementsTableChangePage: (pageSize: number) => void;
  movementsTableMetaData: {
    total: number;
    page: number;
    limit: number;
    count: number;
  };
  movementsTablePage: number;
  movementsTableLimit: number;
  setMovementsTableMetaData: React.Dispatch<
    React.SetStateAction<{
      total: number;
      page: number;
      limit: number;
      count: number;
    }>
  >;
  handleCountersigningTablePageSizeChange: (newPage: number) => void;
  handleCountersigningTableChangePage: (pageSize: number) => void;
  countersigningTableMetaData: {
    total: number;
    page: number;
    limit: number;
    count: number;
  };
  countersigningTablePage: number;
  countersigningTableLimit: number;
  setCountersigningTableMetaData: React.Dispatch<
    React.SetStateAction<{
      total: number;
      page: number;
      limit: number;
      count: number;
    }>
  >;
  // TODO: get type from API
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  countersignTableData: any[];
  handleCapCallTablePageSizeChange: (newPage: number) => void;
  handleCapCallTableChangePage: (pageSize: number) => void;
  capCallTableMetaData: {
    total: number;
    page: number;
    limit: number;
    count: number;
  };
  capCallTablePage: number;
  capCallTableLimit: number;
  setCapCallTableMetaData: React.Dispatch<
    React.SetStateAction<{
      total: number;
      page: number;
      limit: number;
      count: number;
    }>
  >;
  capCallTableData: {
    lpname: string;
    fund: string;
    date: string;
    amount: string;
  }[];
  activeTab: string;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
}

export default MarketplaceActComponent;
