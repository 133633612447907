import React from 'react';

const IconUser = (props: IconUserProps) => {
  return (
    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.6657 14.9983V13.4429C12.6657 12.6178 12.338 11.8266 11.7546 11.2432C11.1712 10.6598 10.3799 10.332 9.55486 10.332H4.11086C3.28581 10.332 2.49455 10.6598 1.91115 11.2432C1.32775 11.8266 1 12.6178 1 13.4429V14.9983"
        stroke={props.color ? props.color : '#177785'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.83303 7.22172C8.55111 7.22172 9.94388 5.82894 9.94388 4.11086C9.94388 2.39278 8.55111 1 6.83303 1C5.11495 1 3.72217 2.39278 3.72217 4.11086C3.72217 5.82894 5.11495 7.22172 6.83303 7.22172Z"
        stroke={props.color ? props.color : '#177785'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.7764 4.89062V9.55691"
        stroke={props.color ? props.color : '#177785'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.1101 7.21875H13.4438"
        stroke={props.color ? props.color : '#177785'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export interface IconUserProps {
  color?: string;
}

export default IconUser;
