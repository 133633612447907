import {
  OpportunitiesFilter,
  MyOrdersFilter,
  MyRequestsFilter,
  InterestReceivedFilter,
  ApprovalRequiredFilter,
  PartnershipValuationFilter,
  PendingPaymentFilter,
  TransactionHistoryFilter,
  TransactionHistoryReceivedFilter,
} from '../../models/marketPlaceModels';
import { SupportRequestFilter } from '../../models/supportRequestModels';
import { AdminUsersFilter } from '../../models/adminUserModels';
import { RolesFilter } from '../../models/rolesModels';
import { ListOfLpsFilter } from '../../models/governanceModels';

export enum FilterActionTypes {
  SAVE_OPPORTUNITIES_FILTER = '[Filter] save opportunities filter',
  SAVE_MY_ORDERS_FILTER = '[Filter] save my orders filter',
  SAVE_MY_REQUESTS_FILTER = '[Filter] save my requests filter',
  SAVE_INTEREST_RECEIVED_FILTER = '[Filter] save interest received',
  SAVE_APPROVAL_REQUIRED_FILTER = '[Filter] save approval required',
  SAVE_PARTNERSHIP_VALUATION_FILTER = '[Filter] save partnership valuation required',
  SAVE_PENDING_PAYMENT_HISTORY = '[Filter] pending payment history',
  SAVE_TRANSACTION_HISTORY = '[Filter] transaction history',
  SAVE_TRANSACTION_HISTORY_RECEIVED = '[Filter] transaction history received',
  SAVE_SUPPORT_REQUEST = '[Filter] support request filter',
  SAVE_MARKETPLACE_TAB_INDEX = '[TAB INDEX] marketplace tab',
  SAVE_BANKING_PAYMENT_TAB_INDEX = '[TAB INDEX] banking payment tab',
  CLEAR_FILTER = '[FILTER] Clear Filter',
  SAVE_ADMIN_USERS_FILTER = '[FILTER] Save Admin Users Filter',
  SAVE_ROLES_FILTER = '[FILTER] Save Roles Filter',
  SAVE_GOVERNANCE_LIST_OF_LPS_FILTER = '[FILTER] Save Governance - List of LPs Filter',
}
export interface FilterActions {
  type: FilterActionTypes;
  // TODO: get type from API
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
}
export const SaveOpportunitiesFilterAction = (payload: OpportunitiesFilter) => {
  return {
    type: FilterActionTypes.SAVE_OPPORTUNITIES_FILTER,
    payload: payload,
  };
};
export const SaveMyOrdersFilterAction = (payload: MyOrdersFilter) => {
  return {
    type: FilterActionTypes.SAVE_MY_ORDERS_FILTER,
    payload: payload,
  };
};
export const SaveMyRequestsFilterAction = (payload: MyRequestsFilter) => {
  return {
    type: FilterActionTypes.SAVE_MY_REQUESTS_FILTER,
    payload: payload,
  };
};
export const SaveInterestReceivedFilterAction = (payload: InterestReceivedFilter) => {
  return {
    type: FilterActionTypes.SAVE_INTEREST_RECEIVED_FILTER,
    payload: payload,
  };
};
export const SaveApprovalRequiredFilterAction = (payload: ApprovalRequiredFilter) => {
  return {
    type: FilterActionTypes.SAVE_APPROVAL_REQUIRED_FILTER,
    payload: payload,
  };
};
export const SavePartnershipValuationFilterAction = (payload: PartnershipValuationFilter) => {
  return {
    type: FilterActionTypes.SAVE_PARTNERSHIP_VALUATION_FILTER,
    payload: payload,
  };
};
export const SavePendingPaymentsFilterAction = (payload: PendingPaymentFilter) => {
  return {
    type: FilterActionTypes.SAVE_PENDING_PAYMENT_HISTORY,
    payload: payload,
  };
};
export const SaveTransactionHistoryFilterAction = (payload: TransactionHistoryFilter) => {
  return {
    type: FilterActionTypes.SAVE_TRANSACTION_HISTORY,
    payload: payload,
  };
};
export const SaveTransactionHistoryReceivedFilterAction = (payload: TransactionHistoryReceivedFilter) => {
  return {
    type: FilterActionTypes.SAVE_TRANSACTION_HISTORY_RECEIVED,
    payload: payload,
  };
};
export const SaveSupportRequestFilterAction = (payload: SupportRequestFilter) => {
  return {
    type: FilterActionTypes.SAVE_SUPPORT_REQUEST,
    payload: payload,
  };
};
export const SaveMarketplaceTabIndexAction = (payload: number) => {
  return {
    type: FilterActionTypes.SAVE_MARKETPLACE_TAB_INDEX,
    payload: payload,
  };
};
export const SaveBankingPaymentTabIndexAction = (payload: number) => {
  return {
    type: FilterActionTypes.SAVE_BANKING_PAYMENT_TAB_INDEX,
    payload: payload,
  };
};
export const ClearFilterAction = () => {
  return {
    type: FilterActionTypes.CLEAR_FILTER,
  };
};
export const SaveAdminUsersFilterAction = (payload: AdminUsersFilter) => {
  return { type: FilterActionTypes.SAVE_ADMIN_USERS_FILTER, payload: payload };
};

export const SaveRolesFilterAction = (payload: RolesFilter) => {
  return { type: FilterActionTypes.SAVE_ROLES_FILTER, payload: payload };
};

export const SaveGovernanceListOfLpsAction = (payload: ListOfLpsFilter) => {
  return {
    type: FilterActionTypes.SAVE_GOVERNANCE_LIST_OF_LPS_FILTER,
    payload: payload,
  };
};
