import { useMemo, useState } from 'react';
import { Checkbox, DialogContent, FormControlLabel, MenuItem, Select } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import { Controller, useForm } from 'react-hook-form';
import Dropzone from 'react-dropzone';
import IconArrowLeft from 'src/uiLibrary/icons/IconArrowLeft';
import { yupResolver } from '@hookform/resolvers/yup';
import { UploadDocumentForm, schema } from './validations';
import FileDefaultImage from 'src/assets/images/subscrip-img.png';
import { IconClose } from 'src/uiLibrary/icons/IconClose';
import { useDocumentFolders } from 'src/hooks/react-query/documents';
import { Loader } from 'src/components/common/Loader';
import { QueryError } from 'src/components/common/QueryError';
import * as yup from 'yup';

interface UploadDocumentComponentProps {
  closeModal: () => void;
  type: string;
  freq: string;
  onSubmit: (formData: UploadDocumentForm) => void;
  level: 'fund' | 'asset';
  folderName?: string;
  id: string | undefined;
}

const UploadDocumentComponent = ({
  closeModal,
  onSubmit,
  type,
  freq,
  level,
  folderName,
  id,
}: UploadDocumentComponentProps) => {
  const { folderList: folders, isLoading, isError } = useDocumentFolders(id, level);

  const { control, handleSubmit, setValue, getValues } = useForm<UploadDocumentForm>({
    resolver: yupResolver(schema as yup.ObjectSchema<UploadDocumentForm>),
    defaultValues: {
      Title: '',
      FolderName: folderName,
      Document_for: level,
      IsActive: 1,
      DurationType: freq,
      DocType: type,
      DocDate: '',
      DocLink: '',
      FolderId: 'none',
    },
  });

  const [uploadedDoc, setUploadedDoc] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>();

  const folderList = useMemo(
    () =>
      folders?.length
        ? folders.filter(item => !item.IsDefault).map(folder => ({ id: folder.id, name: folder.name }))
        : [],
    [folders],
  );

  // TODO: get type from dropzone
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOnDrop = (files: any) => {
    const currentFile = files[0];
    setUploadedDoc(currentFile);
    !getValues('Title') && setValue('Title', currentFile.name);
    setValue('DocDate', currentFile.lastModifiedDate.toISOString());
    setValue('DocLink', currentFile);
    if (currentFile && currentFile.type.startsWith('image/')) {
      const url = URL.createObjectURL(currentFile);
      setPreviewUrl(url);
    } else {
      setPreviewUrl(FileDefaultImage);
    }
  };

  const handleRemoveFile = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.stopPropagation();
    setPreviewUrl(null);
    setUploadedDoc(null);
    setValue('Title', '');
    setValue('DocDate', '');
    setValue('DocLink', '');
  };

  if (isLoading) return <Loader />;

  if (isError) return <QueryError />;

  return (
    <DialogContent className="upload-document-dialog-content">
      <div className="dialog-content">
        <div className="back-login" onClick={closeModal}>
          <IconButton size="large" component="span">
            <IconArrowLeft stroke="#8F8F8F" />
          </IconButton>
          <p className="back-text">Back</p>
        </div>
        <div className="upload-doc-form-wrap">
          <h4 className="title">Standalone Document</h4>
          <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <div className="from-row theam-black">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <Controller
                  name="Title"
                  control={control}
                  render={({ field, fieldState }) => (
                    <FormControl fullWidth variant="outlined" className="uploadFormControl">
                      <FormLabel>Document name *</FormLabel>
                      <TextField
                        fullWidth
                        type="text"
                        margin="dense"
                        color="info"
                        required
                        placeholder=""
                        {...field}
                        error={!!fieldState.error?.message}
                        helperText={fieldState.error ? fieldState.error.message : ''}
                      />
                    </FormControl>
                  )}
                />
              </div>
              <div className="col-lg-5 col-md-5 col-sm-12">
                <Controller
                  name="Document_for"
                  control={control}
                  render={({ field, fieldState }) => (
                    <FormControl fullWidth disabled variant="outlined" className="uploadFormControl">
                      <FormLabel>Level</FormLabel>
                      <TextField
                        disabled
                        fullWidth
                        type="text"
                        margin="dense"
                        color="info"
                        {...field}
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error.message : ''}
                      />
                    </FormControl>
                  )}
                />
              </div>
              <div className="col-lg-7 col-md-7 col-sm-12">
                <Controller
                  name="FolderName"
                  control={control}
                  render={({ field, fieldState }) => (
                    <FormControl fullWidth disabled variant="outlined" className="uploadFormControl">
                      <FormLabel>Upload Location</FormLabel>
                      <TextField
                        className="text-ellipsis"
                        disabled
                        fullWidth
                        type="text"
                        margin="dense"
                        {...field}
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error.message : ''}
                      />
                    </FormControl>
                  )}
                />
              </div>

              <div className="row col-sm-12 mt-15 mb-15">
                <div className="col-lg-8 col-md-8 col-sm-12">
                  <Controller
                    name="DataProcessingRequires"
                    control={control}
                    render={({ field, fieldState }) => (
                      <FormControl error={fieldState.invalid}>
                        <FormControlLabel control={<Checkbox {...field} />} label="Requires Data Processing" />
                      </FormControl>
                    )}
                  />
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 ">
                  <Controller
                    name="IsLpVisible"
                    control={control}
                    render={({ field, fieldState }) => (
                      <FormControl error={fieldState.invalid}>
                        <FormControlLabel control={<Checkbox {...field} />} label="Visible to LPs" />
                      </FormControl>
                    )}
                  />
                </div>
              </div>

              <div className="col-sm-12 mb-15">
                <Controller
                  name="FolderId"
                  control={control}
                  render={({ field, fieldState }) => (
                    <FormControl fullWidth variant="outlined" error={fieldState.invalid}>
                      <FormLabel>Custom Folder</FormLabel>
                      <Select {...field} fullWidth displayEmpty>
                        <MenuItem value="" disabled>
                          Select custom folder
                        </MenuItem>
                        <MenuItem value="none">None</MenuItem>
                        {folderList.map(folder => (
                          <MenuItem key={folder.id} value={folder.id}>
                            {folder.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </div>

              <div className="col-lg-12 col-md-12 col-sm-12">
                <Dropzone maxFiles={1} onDrop={files => handleOnDrop(files)}>
                  {({ getRootProps, getInputProps }) => (
                    <div className="dropzone" {...getRootProps()}>
                      {!uploadedDoc ? (
                        <>
                          <input {...getInputProps()} />
                          <div className="dropzone-text">Click here to add a file</div>
                          <div className="dropzone-subtext">or</div>
                          <div className="dropzone-text">Drop it here</div>
                        </>
                      ) : (
                        <>
                          <div className="relative">
                            <span className="remove-file-preview" onClick={e => handleRemoveFile(e)}>
                              <IconClose />
                            </span>

                            {previewUrl && (
                              <img src={previewUrl} width={100} height={100} className="file-image-preview" />
                            )}
                          </div>

                          {uploadedDoc.name}
                        </>
                      )}
                    </div>
                  )}
                </Dropzone>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 btn-row mt-3">
                <Button
                  type="submit"
                  variant="contained"
                  className="col-lg-12 col-md-12 col-sm-12"
                  disabled={!uploadedDoc}
                >
                  Upload
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </DialogContent>
  );
};

export default UploadDocumentComponent;
