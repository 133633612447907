import { LoadingActions, LoadingActionTypes } from '../actions/loadingActions';

interface LoadingReducerState {
  message: string;
  visible: boolean;
}

const initialState: LoadingReducerState = {
  message: '',
  visible: false,
};

export default function LoadingReducer(state: LoadingReducerState = initialState, action: LoadingActions) {
  switch (action.type) {
    case LoadingActionTypes.SHOW_LOADING:
      return { ...state, visible: true, message: action.payload };
    case LoadingActionTypes.HIDE_LOADING:
      return { ...state, visible: false, message: '' };
    default:
      return state;
  }
}
