import React from 'react';
import { Outlet } from 'react-router-dom';
import PageLayout from 'src/components/layouts/PageLayout/PageLayout';

interface LayoutWithHeaderAndSidebarProps {
  title?: string;
  subtitle?: string;
}

const LayoutWithHeaderAndSidebar = ({ title, subtitle }: LayoutWithHeaderAndSidebarProps) => {
  return (
    <PageLayout title={title} subtitle={subtitle}>
      <Outlet />
    </PageLayout>
  );
};

export default LayoutWithHeaderAndSidebar;
