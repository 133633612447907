import { useMemo } from 'react';
import { Navigate } from 'react-router';
import { useRoutes } from 'react-router-dom';
import { Routes } from 'src/consts/routes';
import PublicLayout from 'src/components/layouts/PublicLayout/PublicLayout';
import LayoutWithHeaderAndSidebar from 'src/components/layouts/LayoutWithHeaderAndSidebar/LayoutWithHeaderAndSidebar';
import LayoutWithoutHeader from 'src/components/layouts/LayoutWithoutHeader/LayoutWithoutHeader';
import { AssetsDetailsContainer } from 'src/views/assets-details/AssetsDetailsContainer';
import LoginContainer from 'src/views/login/loginContainer';
import { FundDetailContainer } from 'src/views/my-fund-details/FundDetailContainer';
import DocumentsContainer from 'src/views/documents/documentsContainer';
import DocumentsFundDetailContainer from 'src/views/documents-fund-detail/documentsFundDetailContainer';
import DocumentsAssetDetailContainer from 'src/views/documents-asset-detail/documentsAssetDetailContainer';
import MyProfileContainer from 'src/views/my-profile/myProfileContainer';
import { MyFundsContainer } from 'src/views/my-funds/myFundsContainer';
import DashboardContainer from 'src/views/dashboard/dashboardContainer';
import ErrorConatiner from 'src/views/error/errorConatiner';
import SignUpContainer from 'src/views/sign-up/signUpContainer';
import ForgotPasswordContainer from 'src/views/forgot-password/forgotPasswordContainer';
import CreateNewPasswordContainer from 'src/views/create-new-password/createNewPasswordContainer';
import { useAuth } from 'src/context/AuthContext';
import { RoleListContainer } from 'src/views/role-list/roleListContainer';
import { AddRoleContainer } from 'src/views/add-role/addRoleContainer';
import { UserContainer } from 'src/views/user-management/UserContainer';
import { CreateUserContainer } from 'src/views/create-user/CreateUserContainer';
import GovernanceContainer from 'src/views/governance/governanceContainer';
import GovernanceFundDetailContainer from 'src/views/governance-fund-detail/governanceFundDetailContainer';
import CapcallHistory from 'src/views/governance-capcall-history/capcallHistory';
import CapcallDetailsHistory from 'src/views/governance-capcall-history/capcallDetailsHistory';
import DistributionHistory from 'src/views/governance-distribution-history/distributionHistory';
import DistributionDetailsHistory from 'src/views/governance-distribution-history/distributionDetailsHistory';
import { ProtectedPage } from '../layouts/ProtectedPage/ProtectedPage';
import { Permission } from 'src/consts/permissions';
import { ForbiddenContainer } from 'src/views/forbidden/ForbiddenContainer';
import { LayoutWithoutHeaderAndSidebar } from '../layouts/LayoutWithoutHeaderAndSidebar/LayoutWithoutHeaderAndSidebar';
import { FundAnalytics } from 'src/views/fund-analytics/FundAnalytics';
import { Analytics } from 'src/views/analytics/Analytics';

const GP_USER_ROLE_ID = 2;

//TODO: all other routes will be addeded in the related tasks
const Router = () => {
  const { userData } = useAuth();
  const { UserType, UserName } = userData ?? {};

  const isUserGP = useMemo(() => UserType === GP_USER_ROLE_ID, [UserType]);

  return useRoutes([
    {
      element: <PublicLayout />,
      children: [
        {
          path: Routes.login,
          element: <LoginContainer />,
        },
        {
          path: Routes.signUp,
          element: <SignUpContainer />,
        },
        {
          path: Routes.forgotPassword,
          element: <ForgotPasswordContainer />,
        },
        {
          path: Routes.resetPassword,
          element: <CreateNewPasswordContainer isResetPassword={true} />,
        },
        {
          path: Routes.home,
          element: <Navigate to={Routes.login} replace />,
        },
        {
          path: Routes.errorContainer,
          element: <ErrorConatiner />,
        },
      ],
    },
    // {
    //   element: <PrivateLayout />,
    //   children: [
    //     {
    //       path: Routes.editAdminUser,
    //       element: <CreateUserContainer childPrivileges={getChildPrivilege('role-management')} />,
    //     },
    //     {
    //       path: Routes.roles,
    //       element: <RoleListContainer childPrivileges={getChildPrivilege('role-management')} />,
    //     },
    //     {
    //       path: Routes.addRole,
    //       element: <AddRoleContainer childPrivileges={getChildPrivilege('role-management')} />,
    //     },
    //     {
    //       path: Routes.editRole,
    //       element: <AddRoleContainer childPrivileges={getChildPrivilege('role-management')} />,
    //     },
    //     {
    //       path: Routes.editLpRole,
    //       element: <AddRoleContainer childPrivileges={getChildPrivilege('role-management')} />,
    //     },
    //   ],
    // },
    // {
    //   element: <PrivateLayout privilegeName={'user-management'} />,
    //   children: [
    //     {
    //       path: Routes.addAdminUser1,
    //       element: <CreateUserContainer childPrivileges={getChildPrivilege('user-management')} />,
    //     },
    //   ],
    // },
    // {
    //   element: <LayoutWithHeaderAndSidebar />,
    //   children: [
    // {
    //   path: Routes.notifications,
    //   element: <NotificationContainer />,
    // },
    // {
    //   path: Routes.gpPartnerships,
    //   element: <GpPartnershipsContainer />,
    // },
    // {
    //   path: Routes.partnershipDetails,
    //   element: <PartnershipDetailsContainer />,
    // },
    // {
    //   path: Routes.partnershipAssetsDetails,
    //   element: <AssetsDetailsContainer />,
    // },
    // {
    //   path: Routes.activeSubscription,
    //   element: <MySubscriptionContainer />,
    // },
    // {
    //   path: Routes.mySubscriptions,
    //   element: <SubscriptionHistoryContainer />,
    // },
    // {
    //   path: Routes.partnership,
    //   element: <PartnershipDetailsContainer />,
    // },
    // {
    //   path: Routes.partnershipMarketplace,
    //   element: <PartnershipDetailsContainer />,
    // },
    // {
    //   path: Routes.assetDetails,
    //   element: <AssetsDetailsContainer />,
    // },
    // {
    //   path: Routes.marketplace,
    //   element: <MarketplaceContainer />,
    // },
    // {
    //   path: Routes.marketplaceOpportunity,
    //   element: <MarketplaceDetailsContainer category={MarketplaceCategory.opportunity} />,
    // },
    // {
    //   path: Routes.marketplacePartnershipValuationDetails,
    //   element: <PartnershipValuationDetailsContainer />,
    // },
    // {
    //   path: Routes.bankingTransactionDetails,
    //   element: <BankingTransactionDetailsContainer />,
    // },
    // {
    //   path: Routes.releasePaymentWithId,
    //   element: <ReleasePaymentContainer />,
    // },
    // {
    //   path: Routes.bankingQuotePaymentTransactionDetails,
    //   element: <QuotePaymentTransactionDetailsContainer />,
    // },
    // {
    //   path: Routes.faq,
    //   element: <FaqContainer />,
    // },
    // {
    //   path: Routes.supportRequests,
    //   element: <SupportRequestsContainer />,
    // },
    // {
    //   path: Routes.supportRequestsDetails,
    //   element: <SupportRequestDetailsContainer />,
    // },
    // {
    //   path: Routes.globalSearch,
    //   element: <GlobalSearchContainer />,
    // },
    //   ],
    // },
    // {
    //   element: <LayoutWithHeaderAndSidebar />,
    //   children: [
    //     {
    //       path: Routes.createNewPassword,
    //       element: <CreateNewPasswordContainer cmsModalHandler={cmsModalHandler} />,
    //     },
    //     {
    //       path: Routes.makePaymentWithId,
    //       element: <BankingMakePaymentContainer />,
    //     },
    //     {
    //       path: Routes.quotePaymentWithId,
    //       element: <QuotePaymentContainer />,
    //     },
    //     {
    //       path: Routes.subscriptionPaymentWithId,
    //       element: <SubscriptionPaymentContainer />,
    //     },
    //   ],
    // },
    {
      element: <LayoutWithoutHeaderAndSidebar />,
      children: [
        {
          path: Routes.forbidden,
          element: <ForbiddenContainer />,
        },
      ],
    },
    {
      element: <LayoutWithoutHeader />,
      children: [
        {
          path: Routes.myFundsDetail,
          element: (
            <ProtectedPage permissions={[Permission.MyFunds]} allowForLp>
              <FundDetailContainer />
            </ProtectedPage>
          ),
        },
        {
          path: Routes.documents,
          element: (
            <ProtectedPage permissions={[Permission.Documents]} allowForLp>
              <DocumentsContainer />
            </ProtectedPage>
          ),
        },
        {
          path: Routes.fundDocuments,
          element: (
            <ProtectedPage permissions={[Permission.Documents]} allowForLp>
              <DocumentsFundDetailContainer />
            </ProtectedPage>
          ),
        },
        // {
        //   path: Routes.documentsFolder,
        //   element: <DocumentsCustomFolderDetailContainer />,
        // },
        {
          path: Routes.investmentFundAssetDocuments,
          element: (
            <ProtectedPage permissions={[Permission.Documents]} allowForLp>
              <DocumentsAssetDetailContainer />
            </ProtectedPage>
          ),
        },
        // {
        //   path: Routes.investmentFundAssetFamilyDocuments,
        //   element: <DocumentsFamilyDetailContainer />,
        // },
        // {
        //   path: Routes.marketplaceAsset,
        //   element: <AssetsDetailsContainer />,
        // },
        {
          path: Routes.fundAssetDetails,
          element: (
            <ProtectedPage permissions={[Permission.MyFunds]} allowForLp>
              <AssetsDetailsContainer />
            </ProtectedPage>
          ),
        },
        {
          path: Routes.fundAssetKpiDetails,
          element: (
            <ProtectedPage permissions={[Permission.MyFunds]} allowForLp>
              <AssetsDetailsContainer />
            </ProtectedPage>
          ),
        },
        {
          path: Routes.governance,
          element: (
            <ProtectedPage permissions={[Permission.Governance]}>
              <GovernanceContainer />
            </ProtectedPage>
          ),
        },
        {
          path: Routes.governanceDetail,
          element: (
            <ProtectedPage permissions={[Permission.Governance]}>
              <GovernanceFundDetailContainer />
            </ProtectedPage>
          ),
        },
        {
          path: Routes.governanceCapCallHistory,
          element: (
            <ProtectedPage permissions={[Permission.Governance]}>
              <CapcallHistory />
            </ProtectedPage>
          ),
        },
        {
          path: Routes.governanceCapCallHistoryDetail,
          element: (
            <ProtectedPage permissions={[Permission.Governance]}>
              <CapcallDetailsHistory />
            </ProtectedPage>
          ),
        },
        {
          path: Routes.governanceDistributionHistory,
          element: (
            <ProtectedPage permissions={[Permission.Governance]}>
              <DistributionHistory />
            </ProtectedPage>
          ),
        },
        {
          path: Routes.governanceDistributionHistoryDetail,
          element: (
            <ProtectedPage permissions={[Permission.Governance]}>
              <DistributionDetailsHistory />
            </ProtectedPage>
          ),
        },
      ],
    },
    {
      element: <LayoutWithHeaderAndSidebar title={'My Profile'} subtitle={'Edit and manage your profile details'} />,
      children: [
        {
          path: Routes.myProfile,
          element: <MyProfileContainer isUserGP={isUserGP} />,
        },
      ],
    },

    {
      element: <LayoutWithHeaderAndSidebar title={'Users'} subtitle={'Manage your users'} />,
      children: [
        {
          path: Routes.users,
          element: (
            <ProtectedPage permissions={[Permission.FrontendUserManagement]}>
              <UserContainer />
            </ProtectedPage>
          ),
        },
      ],
    },
    {
      element: <LayoutWithoutHeader />,
      children: [
        {
          path: Routes.addUser,
          element: (
            <ProtectedPage permissions={[Permission.ManageUser]}>
              <CreateUserContainer />
            </ProtectedPage>
          ),
        },
      ],
    },

    {
      element: <LayoutWithoutHeader />,
      children: [
        {
          path: Routes.editUser,
          element: (
            <ProtectedPage permissions={[Permission.ManageUser]}>
              <CreateUserContainer />
            </ProtectedPage>
          ),
        },
      ],
    },

    {
      element: <LayoutWithHeaderAndSidebar title={'Roles'} subtitle={'Manage your user roles'} />,
      children: [
        {
          path: Routes.roles,
          element: (
            <ProtectedPage permissions={[Permission.RoleManagement]}>
              <RoleListContainer />
            </ProtectedPage>
          ),
        },
      ],
    },

    {
      element: <LayoutWithHeaderAndSidebar title={'Add Role'} subtitle={'Manage your user roles'} />,
      children: [
        {
          path: Routes.addRole,
          element: (
            <ProtectedPage permissions={[Permission.ManageRole]}>
              <AddRoleContainer />
            </ProtectedPage>
          ),
        },
      ],
    },

    {
      element: <LayoutWithHeaderAndSidebar title={'Edit Role'} subtitle={'Manage your user roles'} />,
      children: [
        {
          path: Routes.editRole,
          element: (
            <ProtectedPage permissions={[Permission.ManageRole]}>
              <AddRoleContainer />
            </ProtectedPage>
          ),
        },
      ],
    },

    {
      element: <LayoutWithHeaderAndSidebar title={'Dashboard'} subtitle={`Welcome back ${UserName || 'User'}!`} />,
      children: [
        {
          path: Routes.dashboard,
          element: (
            <ProtectedPage permissions={[Permission.Dashboard]} allowForLp>
              <DashboardContainer />
            </ProtectedPage>
          ),
        },
      ],
    },
    {
      element: <LayoutWithHeaderAndSidebar title={'Analytics'} subtitle={'View and manage your funds analytics'} />,
      children: [
        {
          path: Routes.analytics,
          element: <Analytics />,
        },
      ],
    },
    {
      element: <LayoutWithoutHeader />,
      children: [
        {
          path: Routes.fundAnalytics,
          element: <FundAnalytics />,
        },
      ],
    },
    {
      element: (
        <LayoutWithHeaderAndSidebar title={'My Funds'} subtitle={'View and manage your funds and their investments'} />
      ),
      children: [
        {
          path: Routes.myFunds,
          element: (
            <ProtectedPage permissions={[Permission.MyFunds]} allowForLp>
              <MyFundsContainer />
            </ProtectedPage>
          ),
        },
      ],
    },
    // {
    //   element: <LayoutWithHeaderAndSidebar title={'Banking'} subtitle={'Manage your banking and payment details'} />,
    //   children: [
    //     {
    //       path: Routes.manualPaymentStatus,
    //       element: <ManualPaymentStatusContainer />,
    //     },
    //   ],
    // },
    // {
    //   element: (
    //     <LayoutWithHeaderAndSidebar
    //       title={'Banking'}
    //       subtitle={'Manage funds banking information and approve manual payments'}
    //     />
    //   ),
    //   children: [
    //     {
    //       path: Routes.gpBanking,
    //       element: <GpBankingPreselectionContainer />,
    //     },
    //     {
    //       path: Routes.gpBankingFund,
    //       element: <GpBankingContainer isUserGP={isUserGP} isBankExist={IsBankExist} />,
    //     },
    //     {
    //       path: Routes.gpBankingPaymentsApproval,
    //       element: <GpPayments pendingType="Pending" title="Payments pending approval" />,
    //     },
    //     {
    //       path: Routes.gpBankingPendingPayments,
    //       element: <GpPayments pendingType="Approved" title="Pending payments" />,
    //     },
    //     {
    //       path: Routes.gpBankingEtbHistory,
    //       element: <GpFboHistory />,
    //     },
    //     {
    //       path: Routes.gpBankingManageBankingInfo,
    //       element: <GpBankingInfoManagementComponent />,
    //     },
    //     {
    //       path: Routes.gpBankingTransactionsHistory,
    //       element: <GpTransactionsHistory />,
    //     },
    //     {
    //       path: Routes.gpBankingInvestment,
    //       element: <GpBankingContainer isUserGP={isUserGP} isBankExist={IsBankExist} />,
    //     },
    //   ],
    // },
  ]);
};

export default Router;
