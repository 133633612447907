import React from 'react';

const IconFolder = () => {
  return (
    <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.334 3.49997H10.5157L9.08398 0.627472C9.01445 0.488309 8.90734 0.371389 8.7748 0.289946C8.64225 0.208503 8.48955 0.165789 8.33398 0.166638H1.66732C1.4463 0.166638 1.23434 0.254436 1.07806 0.410716C0.921782 0.566996 0.833984 0.778958 0.833984 0.999972V16C0.833984 16.221 0.921782 16.4329 1.07806 16.5892C1.23434 16.7455 1.4463 16.8333 1.66732 16.8333H18.334C18.555 16.8333 18.767 16.7455 18.9232 16.5892C19.0795 16.4329 19.1673 16.221 19.1673 16V4.3333C19.1673 4.11229 19.0795 3.90033 18.9232 3.74405C18.767 3.58777 18.555 3.49997 18.334 3.49997ZM2.50065 1.83331H7.81898L8.65232 3.49997H2.50065V1.83331ZM17.5007 15.1666H2.50065V5.16664H17.5007V15.1666Z"
        fill="#E1E1E1"
      />
      <path
        d="M18.334 3.49997H10.5157L9.08398 0.627472C9.01445 0.488309 8.90734 0.371389 8.7748 0.289946C8.64225 0.208503 8.48955 0.165789 8.33398 0.166638H1.66732C1.4463 0.166638 1.23434 0.254436 1.07806 0.410716C0.921782 0.566996 0.833984 0.778958 0.833984 0.999972V16C0.833984 16.221 0.921782 16.4329 1.07806 16.5892C1.23434 16.7455 1.4463 16.8333 1.66732 16.8333H18.334C18.555 16.8333 18.767 16.7455 18.9232 16.5892C19.0795 16.4329 19.1673 16.221 19.1673 16V4.3333C19.1673 4.11229 19.0795 3.90033 18.9232 3.74405C18.767 3.58777 18.555 3.49997 18.334 3.49997ZM2.50065 1.83331H7.81898L8.65232 3.49997H2.50065V1.83331ZM17.5007 15.1666H2.50065V5.16664H17.5007V15.1666Z"
        fill="#B6B6B6"
      />
    </svg>
  );
};

export default IconFolder;
