import { Grid, Box, MenuItem } from '@mui/material';
import Table from '../../../components/common/table/Table';
import AdminUserTablePagination from '../../../components/common/table/TablePagination';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { UserListMetaData } from './../../../models/frontendUserModels';
import IconNoApprove from '../../icons/IconNoApprove';
import IconApprove from '../../icons/IconApprove';
import { formatDate } from '../../../views/utils';
import DownloadIcon from '@mui/icons-material/Download';
import TableActionButtonsRow from '../../../components/common/TableActionButtonsRow';

// const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))(() => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     color: "#fff",
//     backgroundColor: "#000",
//     fontSize: "12px",
//     fontWeight: "500",
//     borderRadius: "5px",
//     paddingLeft: "12px",
//     paddingRight: "12px",
//   },
// }));

const NdaApproveTable = ({
  //ndaAction,
  handleChangePage,
  handlePageSizeChange,
  //handleSortChange,
  limit,
  metaData,
  page,
  ndaList,
  approvalQueue,
  setApprovalQueue,
}: //changeAccountStatusHandler,
//openSoftDeleteConfirmationModal,
//childPrivileges,
NdaApproveTableProps) => {
  // const validActionHeadCellHandler = () => {
  //   if (
  //     !!childPrivileges &&
  //     childPrivileges?.filter(
  //       (privilege: any) =>
  //         (privilege.Name.trim() === "Edit" ||
  //           privilege.Name.trim() === "Delete") &&
  //         !privilege.has_privilege
  //     ).length === 2
  //   )
  //     return true;
  // };

  const handleChangeSelectPage = (event: SelectChangeEvent) => {
    handlePageSizeChange(+event.target.value);
  };
  const handleDownloadDocumentOnClick = (linkToDocument: string) => {
    window.open(linkToDocument, '_blank');
  };
  const addTransactionToQueue = (transactionId: number, value: string) => {
    if (approvalQueue.find(elem => elem.id === transactionId)) {
      setApprovalQueue(approvalQueue.filter(item => item.id !== transactionId));
    } else {
      setApprovalQueue([...approvalQueue, { id: transactionId, value: value }]);
    }
  };
  return (
    <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
      <Grid item>
        <Table
          columns={[
            { id: 'lpname', label: 'LP Name', sortable: true },
            {
              id: 'method',
              label: 'Method',
              sortable: false,
            },
            { id: 'date', label: 'Date', sortable: false },
            { id: 'document', label: 'Document', sortable: true },
            {
              id: 'actions',
              label: 'Actions',
            },
          ]}
          // TODO: get type from API
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          data={ndaList.map((nda: any) => ({
            lpname: nda.buyer.FirstName,
            method: nda.RequestType,
            date: formatDate(nda.CreationDateTime),
            document: (
              <div
                onClick={() => handleDownloadDocumentOnClick(nda.AgreementFile)}
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                Download <DownloadIcon></DownloadIcon>
              </div>
            ),
            // fullname: `${user.firstname} ${user.lastname}`,
            // creationdatetime: format(
            //   new Date(user.creationdatetime),
            //   "MM / dd / yyyy"
            // ),
            // actions: [
            //   <StyledTooltip
            //     title="Detail/Edit"
            //     key={2}
            //   >
            //     <IconButton
            //       sx={{ color: "#024A72" }}
            //       aria-label="edit"
            //       edge="end"
            //       size="large"
            //     //onClick={() => editUser(user.userid)}
            //     >
            //       <IconNoApprove />
            //     </IconButton>
            //   </StyledTooltip>,
            //   <StyledTooltip
            //     title="Delete"
            //     key={3}
            //   >
            //     <IconButton
            //       sx={{ color: "#d32f2f" }}
            //       aria-label="delete"
            //       edge="end"
            //       size="large"
            //     // onClick={() =>
            //     //   openSoftDeleteConfirmationModal(
            //     //     "soft-delete",
            //     //     user.userid
            //     //   )
            //     // }
            //     >
            //       <IconApprove />
            //     </IconButton>
            //   </StyledTooltip>
            // ]
            actions: [
              <TableActionButtonsRow
                approvalQueue={approvalQueue}
                lineId={nda.id}
                content={[
                  {
                    key: 1,
                    id: nda.id,
                    childrenContent: <IconNoApprove />,
                    action: 'Reject',
                    tooltipLabel: 'Reject',
                    tooltipStyle: { bottom: '-32px' },
                    onClickAction: () => {
                      addTransactionToQueue(nda.id, 'Reject');
                    },
                  },
                  {
                    key: 2,
                    id: nda.id,
                    childrenContent: <IconApprove />,
                    action: 'Approve',
                    tooltipLabel: 'Approve',
                    tooltipStyle: { bottom: '-32px' },
                    onClickAction: () => {
                      addTransactionToQueue(nda.id, 'Approve');
                    },
                  },
                ]}
              />,
            ],
          }))}
          loading={ndaList == null}
          //onSortColumnChange={handleSortChange}
        />
      </Grid>
      <Grid item>
        <Grid container spacing={0} className={'customPagination'}>
          <Grid item xs={6}>
            <Box className="boxPerPage">
              Displaying
              <Select
                className="selectPerPage"
                value={limit.toString()}
                variant="standard"
                onChange={handleChangeSelectPage}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={30}>30</MenuItem>
                <MenuItem value={40}>40</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
              per page
            </Box>
          </Grid>
          <Grid item xs={6}>
            <AdminUserTablePagination
              count={metaData?.count}
              page={page}
              rowsPerPage={limit}
              handleChangePage={handleChangePage}
              handlePageSizeChange={handlePageSizeChange}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

interface NdaApproveTableProps {
  // TODO: get type from API
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ndaList: any;
  metaData: UserListMetaData;
  page: number;
  //filterStatus: 0 | 1 | undefined;
  limit: number;
  //searchString: string | undefined;
  //ndaAction: () => void;
  handleChangePage: (newPage: number) => void;
  //handleSortChange: (sortColumn: SortColumn) => void;
  handlePageSizeChange: (pageSize: number) => void;
  //changeAccountStatusHandler: (userid: number, status: number) => void;
  //openSoftDeleteConfirmationModal: (modalName: string, userid: number) => void;
  //childPrivileges: ChildPrivilege[] | undefined;
  approvalQueue: {
    id: number;
    value: string;
  }[];
  setApprovalQueue: React.Dispatch<
    React.SetStateAction<
      {
        id: number;
        value: string;
      }[]
    >
  >;
}

export default NdaApproveTable;
