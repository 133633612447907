import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'src/context/ShackbarContext';
import { AxiosError } from 'axios';
import { QueryKey } from 'src/consts/query';
import { getIndicators } from 'src/services/governanceService';
import { nestedClone } from 'src/utils/nestedClone';
import { KPI_INDICATORS_TITLE } from 'src/consts/fundsSpotlightIndicators';
import { updateIndicatorInfo } from 'src/utils/updateIndicatorInfo';
import { indicatorsDefaultValues } from 'src/consts/indicatorsDefaultValues';
import { ApiError } from 'src/types/api';

export const useFundKpi = (fundId: number | null) => {
  const { showErrorSnackbar } = useSnackbar();

  const fetchFundKpi = async () => {
    if (fundId) {
      try {
        const response = await getIndicators(fundId);
        let indicatorsLocal = nestedClone(indicatorsDefaultValues).slice();
        // TODO: get type from API
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        response.data.forEach((indicator: any) => {
          const indicatorName = KPI_INDICATORS_TITLE[indicator.KPI] || '';

          if (indicatorName !== '') {
            indicatorsLocal = updateIndicatorInfo(indicatorsLocal, indicatorName, indicator);
          }
        });
        return indicatorsLocal;
      } catch (e) {
        console.log('useFundKpi error', e);
        showErrorSnackbar((e as AxiosError<ApiError>).response?.data.message);
        throw new Error((e as AxiosError<ApiError>).response?.data.message);
      }
    }
    throw new Error('No Fund was provided');
  };

  return useQuery({
    queryKey: [QueryKey.Kpi, fundId],
    queryFn: () => fetchFundKpi(),
  });
};
