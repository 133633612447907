import React from 'react';
import { Outlet } from 'react-router-dom';
import PageLayout from 'src/components/layouts/PageLayout/PageLayout';

export const LayoutWithoutHeaderAndSidebar = () => {
  return (
    <PageLayout isHeaderVisible={false} isSidebarVisible={false} isBodyContent={false}>
      <Outlet />
    </PageLayout>
  );
};
