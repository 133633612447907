import * as React from 'react';
import { TablePagination as MTablePagination } from '@mui/material';
import TablePaginationActions from './TablePaginationActions';
import { ChangeEvent } from 'react';

interface TablePaginationProps {
  count: number;
  page: number;
  rowsPerPage: number;
  handleChangePage: (newPage: number) => void;
  handlePageSizeChange?: (pageSize: number) => void;
}

export default function TablePagination(props: TablePaginationProps) {
  const { count, page, rowsPerPage, handleChangePage, handlePageSizeChange } = props;

  const changePageSize = (event: ChangeEvent<HTMLInputElement>) => {
    handlePageSizeChange && handlePageSizeChange(+event.target.value);
  };

  return (
    <MTablePagination
      className={'customPagination'}
      rowsPerPageOptions={[]}
      component="div"
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={(event, newPage) => handleChangePage(newPage)}
      onRowsPerPageChange={changePageSize}
      ActionsComponent={TablePaginationActions}
      labelDisplayedRows={() => {
        return null;
      }}
    />
  );
}
