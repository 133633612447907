import Box from '@mui/material/Box';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import IconLink from 'src/uiLibrary/icons/IconLink';

type Props = {
  title: string;
  to: string;
  description: string;
  newTab?: boolean;
  isBright?: boolean;
};

export const MainLink = (props: Props) => (
  <Box
    className={classNames('property-bg link-button', {
      'property-bg-withe': props.isBright,
    })}
    component={Link}
    to={props.to}
    target={props.newTab ? '_blank' : undefined}
  >
    <div className="property-flex">
      <div className="property-col">
        <h3>{props.title}</h3>
        <h5 className="mb-0">{props.description}</h5>
      </div>
      <span>
        <IconLink />
      </span>

      {/* TODO EX-51: change icon for same domain */}
    </div>
  </Box>
);
