import { ThemeCssVarOverrides } from '@mui/material';

export const ButtonComponentsTheme: ThemeCssVarOverrides = {
  MuiButton: {
    styleOverrides: {
      contained: {
        background: '#000000',
        borderRadius: '8px',
        fontWeight: '600',
        fontSize: ' 12px',
        textAlign: 'center',
        color: '#fff',
        textTransform: 'capitalize',
        padding: '10px 25px',
        minWidth: '145px',
        '&.Mui-disabled': {
          color: 'rgba(0, 0, 0, 0.26)',
          boxShadow: 'none',
          backgroundColor: 'rgba(0, 0, 0, 0.12)',
          border: '2px solid rgba(0, 0, 0, 0.12)',
        },
        '&.MuiButton-containedPrimary:hover': {
          background: '#303030',
        },
      },

      sizeLarge: {
        fontSize: ' 16px',
        lineHeight: '24px',
        minHeight: '56px',
      },
    },
  },
};
