import React from 'react';
import { ParticipantList } from '../../../../models/chatModels';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Avatar } from '@mui/material';
import { UserDetails } from '../../../../models/authModels';
import getInitials from '../../../getInitials/getInitials';

const MentionsComponent = ({
  userDetails,
  filteredMentions,
  setMsgMentions,
  setMessageWithMention,
}: MentionsComponentProps) => {
  return (
    <div className="mention-result">
      <PerfectScrollbar>
        {!!filteredMentions &&
          filteredMentions.map(
            (participant: ParticipantList) =>
              userDetails.UserId.toString() !== participant.identity && (
                <div
                  className="chat-list search-list"
                  key={participant.sid}
                  onClick={() => {
                    if (participant.userName) {
                      setMessageWithMention(participant.userName);
                      setMsgMentions(prevState => [...prevState, `@${participant.userName}`]);
                    }
                  }}
                >
                  <div className="media">
                    <span className="avater-icon">
                      <Avatar
                        className="d-flex align-self-center"
                        src={participant.profileImage ? participant.profileImage! : undefined}
                      >
                        {getInitials(participant.userName)}
                      </Avatar>
                    </span>
                    <div className="media-body">
                      <div className="d-flex">
                        <h6 className="m-0 flex-grow-1">{participant.userName}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              ),
          )}
      </PerfectScrollbar>
    </div>
  );
};
interface MentionsComponentProps {
  userDetails: UserDetails;
  // TODO: get type from API
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filteredMentions: any[];
  setMsgMentions: React.Dispatch<React.SetStateAction<string[]>>;
  setMessageWithMention: (fullName: string) => void;
}

export default MentionsComponent;
