import { Breadcrumbs, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import styles from './styles.module.scss';
import Badge, { BadgeStyle } from '../../components/common/Badge';
import CrudComponent from '../../components/common/CrudComponent';
import Table from '../../components/common/table';
import { formatDateNoTime, renderCurrency } from '../utils';
import { useDispatch } from 'react-redux';
import { HideLoadingAction, ShowLoadingAction } from '../../redux/actions/loadingActions';
import { ShowSnackbarAction } from '../../redux/actions/snackbarActions';
import { getDistribution } from '../../services/governanceService';
import { RequestError } from 'src/models/supportRequestModels';

const DistributionDetailsHistory = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const fundName = location.state.fundName;

  const [fundId, setFundId] = useState<number>(-1);
  const [distributionId, setDistributionId] = useState<number>(-1);
  const [distribution, setDistribution] = useState<HistoricDistributionDetails>();

  useEffect(() => {
    if (params && params.fundId) {
      const fundId = parseInt(params.fundId);
      setFundId(fundId);
    }
    if (params && params.distributionId) {
      const distributionId = parseInt(params.distributionId);
      setDistributionId(distributionId);
      fetchDistribution(distributionId);
    }
  }, []);

  const fetchDistribution = async (distributionId: number) => {
    try {
      dispatch(ShowLoadingAction());
      const response = await getDistribution(distributionId);
      dispatch(HideLoadingAction());
      if (response.data.data) {
        const distributionLocal = response.data.data;
        setDistribution(distributionLocal);
      }
    } catch (error) {
      const e = error as RequestError;
      dispatch(HideLoadingAction());
      dispatch(
        ShowSnackbarAction({
          message: e?.response?.data?.message || 'Something went wrong while fetching distributions history',
          severity: 'error',
        }),
      );
    }
  };

  const parsedDataForTable = useMemo(() => {
    return (
      distribution?.payments.map(transaction => {
        return {
          id: transaction.distributionId,
          lpName: `${transaction.Seller?.FirstName ?? ''} ${transaction.Seller?.LastName ?? ''}`,
          paymentType: transaction.PaymentType,
          paymentDate: formatDateNoTime(transaction.PaymentDate),
          distributed: renderCurrency(transaction.TotalAmount),
          status: (
            <Badge
              badgeStyle={transaction.status === PaymentStatusEnum.FULFILLED ? BadgeStyle.GREEN : BadgeStyle.GRAY}
              text={transaction.TransferStatus}
            />
          ),
        };
      }) ?? []
    );
  }, [distribution]);

  return (
    <>
      <div className="content-wrap">
        <Breadcrumbs className="breadcrumb mb-15">
          {[
            <Link key="governance" color="inherit" to="/governance">
              Banking
            </Link>,
            <Link key={fundName} color="inherit" to={`/governance/detail/${fundId}`}>
              {fundName}
            </Link>,
            <Link key="governance" color="inherit" to={`/governance/detail/${fundId}/distribution-history`}>
              Distributions history
            </Link>,
            <Typography key="Review Payments">{distributionId}</Typography>,
          ]}
        </Breadcrumbs>
      </div>

      <div className="d-flex">
        <div className="title-and-subtitle-section">
          <h2>{fundName}</h2>
          <h4>Fund Level</h4>
        </div>
      </div>
      <div className={styles.splitRowSection}>
        <CrudComponent
          title="Distribution payments breakdown"
          tableLayout={<Table columns={columns} data={parsedDataForTable} loading={false} />}
          filterComponent={
            <div
              style={{
                display: 'grid',
                placeContent: 'center',
                height: '100%',
              }}
            >
              <p>Coming soon...</p>
            </div>
          }
        />
        <div className={styles.capcallGeneralInfoWrapper}>
          <div className={styles.generalInfoHeader}>
            <div className={styles.headerTitle}>Distribution general info</div>
          </div>
          <div className={styles.generalInfoCard}>
            <div className={styles.cardRow}>
              <div className={styles.cardRowItem}>
                <div className={styles.itemLabel}>Created</div>
                <div className={styles.itemContent}>{formatDateNoTime(distribution?.EventDate ?? '')}</div>
              </div>
              <div className={styles.cardRowItem}>
                <div className={styles.itemLabel}>Distributed</div>
                <div className={styles.itemContent}>{renderCurrency(distribution?.Amount ?? 0)}</div>
              </div>
            </div>

            <div className={styles.cardRow}>
              <div className={styles.cardRowItem}>
                <div className={styles.itemLabel}>Manual payments</div>
                <div className={styles.itemContent}>{renderCurrency(distribution?.manualAmount ?? 0)}</div>
              </div>
              <div className={styles.cardRowItem}>
                <div className={styles.itemLabel}>LP Coverage</div>
                <div className={styles.itemContent}>{distribution?.doneCount}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export enum PaymentStatusEnum {
  PENDING = 'Pending',
  FULFILLED = 'Fulfilled',
}

export enum TransactionType {
  AUTOMATED = 'Automated',
  MANUAL = 'Manual',
}

export type HistoricDistributionDetails = {
  id: string;
  Amount: number;
  manualAmount: number;
  doneCount: number;
  EventDate: string;
  // TODO: get type from API
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payments: any[];
};

const columns = [
  { id: 'lpName', label: 'LP name', sortable: true },
  { id: 'paymentType', label: 'Payment type', sortable: true },
  { id: 'status', label: 'Status', sortable: true },
  { id: 'distributed', label: 'Distributed', sortable: true },
  { id: 'paymentDate', label: 'Payment date', sortable: true },
];

export default DistributionDetailsHistory;
