import { IndicatorsData, KPIValueType } from 'src/models/partnershipModel';

export const fundIndicatorsDefaultValues: IndicatorsData[] = [
  {
    id: 1,
    Title: 'No data',
    SubTitle: 'Total invested amount',
    Value: 0,
    ValueType: KPIValueType.CURRENCY,
    Variance: 0,
    ChartType: 'barchart',
  },
  {
    id: 2,
    Title: 'No data',
    SubTitle: 'Total estimated return',
    Value: 0,
    ValueType: KPIValueType.CURRENCY,
    Variance: 0,
    ChartType: 'barchart',
  },
  {
    id: 3,
    Title: 'No data',
    SubTitle: 'Net Asset Value',
    Value: 0,
    ValueType: KPIValueType.CURRENCY,
    Variance: 0,
    ChartType: 'barchart',
  },
  {
    id: 4,
    Title: 'No data',
    SubTitle: 'Total Dividend Received',
    Value: 0,
    ValueType: KPIValueType.CURRENCY,
    Variance: 0,
    ChartType: 'barchart',
  },
];
