import React from 'react';
import ExtranceLogo from '../../assets/images/logo.svg';
import IconNavArrow from '../../uiLibrary/icons/IconNavArrow';
import ErrorImg from '../../assets/images/error.svg';
import { Link } from 'react-router-dom';

const ErrorComponent = () => {
  return (
    <>
      <header className="others-header-wrap">
        <div className="others-wrap">
          <div className="others-logo">
            <img alt="logo" src={ExtranceLogo} />
          </div>
          <Link className="nav-primary" to="/dashboard">
            Go to dashboard <IconNavArrow />
          </Link>
        </div>
      </header>
      <div className="others-body-content error-wrap">
        <div className="error-content">
          <img alt="logo" src={ErrorImg} />
          <h2>Page Not Found</h2>
          <p>The page you are trying to open is not found. Please try again.</p>
        </div>
      </div>
    </>
  );
};

export default ErrorComponent;
