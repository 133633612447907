type Order = 'asc' | 'desc';

interface SortByKeys<T> {
  keys: keyof T | (keyof T)[];
  order?: Order;
  ignoreCase?: boolean;
}

export const sortByKeys = <T>(array: T[], { keys, order = 'asc', ignoreCase }: SortByKeys<T>): T[] => {
  const keysArray = Array.isArray(keys) ? keys : [keys];

  return array.slice().sort((a, b) => {
    for (const key of keysArray) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let aValue: any = a[key];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let bValue: any = b[key];

      if (ignoreCase) {
        if (typeof aValue === 'string' && typeof bValue === 'string') {
          aValue = aValue.toLowerCase();
          bValue = bValue.toLowerCase();
        }
      }

      if (order === 'desc') {
        if (aValue < bValue) return 1;
        if (aValue > bValue) return -1;
      } else {
        if (aValue < bValue) return -1;
        if (aValue > bValue) return 1;
      }
    }
    return 0;
  });
};
