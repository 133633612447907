import { UserDetails, TermsUserDetails } from '../../models/authModels';
import { AuthActions, AuthActionTypes } from '../actions/authActions';

interface AuthReducerState {
  userDetails: UserDetails | null;
  userTemp: UserDetails | null;
  termsUserDetails: TermsUserDetails | null;
}

const initialState: AuthReducerState = {
  userDetails: null,
  userTemp: null,
  termsUserDetails: null,
};

export default function AuthReducer(state: AuthReducerState = initialState, action: AuthActions) {
  switch (action.type) {
    case AuthActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        userDetails: action.payload,
        roles: action.payload?.roles,
      };
    case AuthActionTypes.LOGOUT_SUCCESS:
      return { ...state, userDetails: null, userTemp: null };
    case AuthActionTypes.LOCAL_STORAGE_FETCHED:
      return { ...state, userDetails: action.payload };
    case AuthActionTypes.TEMPORARY_USER:
      return { ...state, userTemp: action.payload };
    case AuthActionTypes.CREATE_PASSWORD_SUCCESS: {
      const newUser = { ...state?.userTemp, IsTemporary: false };
      localStorage.setItem('extrance_user', JSON.stringify(newUser));
      return { ...state, userDetails: newUser, userTemp: null };
    }
    case AuthActionTypes.TERMS_LOGIN_SUCCESS:
      return {
        ...state,
        termsUserDetails: action.payload,
      };
    case AuthActionTypes.UPDATE_PROFILE_IMAGE_SUCCESS:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          ProfileImage: action.payload.ProfileImage || '',
          ProfileImageThumb: action.payload.ProfileImageThumb || '',
        },
      };
    default:
      return state;
  }
}
