import React from 'react';
import styles from './styles.module.scss';
import cn from 'classnames';

export enum BadgeStyle {
  BLUE = 'BLUE',
  YELLOW = 'YELLOW',
  MAGENTA = 'MAGENTA',
  GREEN = 'GREEN',
  GRAY = 'GRAY',
}

const Badge = ({ text, badgeStyle = BadgeStyle.GRAY }: BadgeProps) => {
  return (
    <div
      className={cn(styles.badge, {
        [styles.badgeBlue]: badgeStyle === BadgeStyle.BLUE,
        [styles.badgeYellow]: badgeStyle === BadgeStyle.YELLOW,
        [styles.badgeMagenta]: badgeStyle === BadgeStyle.MAGENTA,
        [styles.badgeGreen]: badgeStyle === BadgeStyle.GREEN,
        [styles.badgeGray]: badgeStyle === BadgeStyle.GRAY,
      })}
      style={
        typeof badgeStyle === 'object'
          ? {
              backgroundColor: badgeStyle.backgroundColor,
              borderColor: badgeStyle.color,
            }
          : {}
      }
    >
      <span className={cn(styles.badgeText)}>{text}</span>
    </div>
  );
};

interface BadgeProps {
  text: string;
  badgeStyle?: BadgeStyle | BadgeCustomStyle;
}

interface BadgeCustomStyle {
  backgroundColor?: string;
  color?: string;
}

export default Badge;
