type ButtonProps = {
  customClasses?: string;
  // customStyle?: any;
  approvalQueue?: {
    id: number;
    value: string;
  }[];
  content: {
    key: number;
    id: number;
    action: string;
    tooltipLabel?: string;
    tooltipStyle?: { [k: string]: string };
    onClickAction?: () => void;
    customStyle?: { [k: string]: string };
    customClass?: string;
    childrenContent: React.ReactNode;
    disabled?: boolean;
  }[];
  lineId?: string;
};

const TableActionButtonsRow = ({
  customClasses,
  // customStyle,
  approvalQueue,
  content,
  // lineId,
}: ButtonProps) => {
  return (
    <div
      // style={customStyle}
      className={`table-action-button-row-wrapper ${customClasses}`}
    >
      {content.map(b => {
        const queueData = approvalQueue?.find(elem => elem.id === b.id);
        return (
          <div
            className={`table-action-button-body ${b.customClass}`}
            key={b.key}
            style={
              queueData
                ? queueData.value === b.action
                  ? { display: 'flex', border: '2px solid rgba(0,0,0,0.2)' }
                  : { display: 'none' }
                : { display: 'flex' }
            }
            onClick={b.onClickAction}
          >
            {b.childrenContent}
            {b.tooltipLabel ? (
              <div className="button-tooltip" style={b.tooltipStyle}>
                {b.tooltipLabel}
              </div>
            ) : (
              ''
            )}
          </div>
        );
      })}
    </div>
  );
};

export default TableActionButtonsRow;
