import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'src/context/ShackbarContext';
import { AxiosError } from 'axios';
import { editRole } from 'src/services/rolesService';
import { RoleDTO } from 'src/views/add-role/validations';
import { ApiError } from 'src/types/api';

export const useRoleUpdate = (roleId: string | undefined, ...cbs: Array<() => void>) => {
  const queryClient = useQueryClient();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: (role: RoleDTO) => {
      if (roleId) {
        return editRole(role, roleId);
      }
      throw new Error('No Role was provided');
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['rolesList'] });
      showSuccessSnackbar('Role has been successfully updated');
      cbs.forEach(cb => cb());
    },
    onError: e => {
      showErrorSnackbar((e as AxiosError<ApiError>).response?.data.message);
    },
  });
};
