import { useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';
import { InputAdornment, TextField } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { yupResolver } from '@hookform/resolvers/yup';
import Dropzone from 'react-dropzone';
import { Fund } from 'src/types/Funds';
import { useAuth } from 'src/context/AuthContext';
import { DeployCapitalCallFormData } from 'src/types/Governance';
import { schema } from '../validations/validations';
import IconCalendar from '../../../icons/IconCalendar';
import { IconClose } from '../../../icons';
import { FILE_WITH_ARRAY_EXTENSIONS } from 'src/consts/fileExtensions';
import { ObjectSchema } from 'yup';

interface CapitalCallFormProps {
  fund?: Fund;
  onSubmit(formData: DeployCapitalCallFormData): void;
}

export const CapitalCallForm = ({ fund, onSubmit }: CapitalCallFormProps) => {
  const [uploadedFilesLocal, setUploadedFilesLocal] = useState<File | null>(null);

  const expiryRef = useRef<HTMLInputElement>(null);

  const { userData } = useAuth();

  const { control, handleSubmit, setValue, getValues } = useForm<DeployCapitalCallFormData>({
    resolver: yupResolver(schema as ObjectSchema<DeployCapitalCallFormData>),
    defaultValues: {
      FundId: fund?.id,
      UserId: userData?.UserId,
      FundCode: fund?.fundCode ?? '',
      FundName: fund?.name ?? '',
      RequestedAmount: '',
      ClosingDate: new Date(),
      CapCallFile: null,
    },
  });

  const handleRemoveFileOnClick = () => {
    setUploadedFilesLocal(null);
  };

  const onSubmitLocal = (formData: DeployCapitalCallFormData) => {
    if (userData?.UserId && fund?.id) {
      const requestedAmountFromUser = getValues('RequestedAmount');
      const requestedAmountWithoutFormat = parseFloat(requestedAmountFromUser.toString().replace(/,/g, ''));
      formData['RequestedAmount'] = requestedAmountWithoutFormat.toString();
      formData['CapCallFile'] = uploadedFilesLocal;
      onSubmit(formData);
    }
  };

  const handleOnDrop = (files: File[]) => {
    const currentFile = files[0];
    const extension = files[0].name.toLowerCase().split('.').pop();

    if (extension && FILE_WITH_ARRAY_EXTENSIONS.includes(extension)) {
      setUploadedFilesLocal(currentFile);
      setValue('CapCallFile', currentFile);
    }
  };

  return (
    <div className="dialog-form-wrap">
      <form autoComplete="off" onSubmit={handleSubmit(onSubmitLocal)}>
        <div className="from-row theam-black">
          <div className={'col-sm-5'}>
            <Controller
              name={'FundCode'}
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormControl fullWidth variant="outlined" className="dialogFormControl">
                  <FormLabel>Fund Code</FormLabel>
                  <TextField
                    fullWidth
                    type="text"
                    onChange={onChange}
                    value={value}
                    disabled
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                </FormControl>
              )}
            />
          </div>
          <div className={'col-sm-7'}>
            <Controller
              name={'FundName'}
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormControl fullWidth variant="outlined" className="dialogFormControl">
                  <FormLabel>Fund Name</FormLabel>
                  <TextField
                    fullWidth
                    type="text"
                    onChange={onChange}
                    value={value}
                    disabled
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                </FormControl>
              )}
            />
          </div>
          <div className="col-sm-7">
            <Controller
              name="RequestedAmount"
              control={control}
              render={({ field: { value }, fieldState: { error } }) => (
                <FormControl fullWidth variant="outlined" className="dialogFormControl">
                  <FormLabel>Requested Amount</FormLabel>
                  <NumericFormat
                    type="text"
                    value={value}
                    customInput={TextField}
                    onValueChange={e => setValue('RequestedAmount', e.formattedValue)}
                    thousandSeparator=","
                    decimalSeparator="."
                    allowNegative={false}
                    error={!!error}
                    helperText={error ? error.message : null}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <p style={{ margin: 0, color: 'black' }}>$</p>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              )}
            />
          </div>
          <div className="col-sm-5">
            <FormControl fullWidth variant="outlined" className="dialogFormControl calendarField">
              <FormLabel>Closing Date</FormLabel>
              <Controller
                name="ClosingDate"
                control={control}
                render={({ field, fieldState }) => (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      {...field}
                      inputFormat="MM/dd/yyyy"
                      value={field.value}
                      onChange={date => {
                        setValue('ClosingDate', date, {
                          shouldValidate: true,
                          shouldDirty: true,
                        });
                      }}
                      components={{
                        OpenPickerIcon: IconCalendar,
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          fullWidth
                          error={!!fieldState.error}
                          helperText={fieldState.error ? fieldState.error.message : ''}
                        />
                      )}
                      inputRef={expiryRef}
                    />
                  </LocalizationProvider>
                )}
              />
            </FormControl>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-1">
            <Dropzone maxFiles={1} multiple={false} onDrop={handleOnDrop}>
              {({ getRootProps, getInputProps }) => (
                <div className="dropzone" {...getRootProps()}>
                  <input {...getInputProps()} />
                  {!uploadedFilesLocal ? (
                    <>
                      <div className="dropzone-text">Click here to add files</div>
                      <div className="dropzone-subtext">or</div>
                      <div className="dropzone-text">Drop them here</div>
                    </>
                  ) : (
                    <div className="dropzone-uploaded-files-wrapper">
                      <div className="dropzone-uploaded-file-or-add-file-wrapper" key={uploadedFilesLocal.name}>
                        <div className="dropzone-uploaded-file">
                          <div className="dropzone-uploaded-file-remove-button" onClick={handleRemoveFileOnClick}>
                            <IconClose />
                          </div>
                        </div>
                        <p style={{ color: '#8F8F8F' }}>{uploadedFilesLocal.name}</p>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </Dropzone>
          </div>
          <div className="col-sm-12 btn-row-black mt-3">
            <Button type="submit" variant="contained" className="col-sm-12">
              Deploy capital call
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};
