import React from 'react';
import { TableCell, TableRow } from '@mui/material';
import cn from 'classnames';
import IconNoRecords from 'src/uiLibrary/icons/IconNoRecords';
import { Loader } from 'src/components/common/Loader';
import { Column, Row } from './types';
import styles from './styles.module.scss';

interface TableBodyProps {
  loading?: boolean;
  columns: Column[];
  data: Row[];
  onClick?: (row: Row) => void;
}

const TableBody = ({ loading, columns, data, onClick }: TableBodyProps) => {
  if (loading) {
    return (
      <tbody>
        <tr>
          <td colSpan={Number.MAX_SAFE_INTEGER}>
            <div className={styles.loadingContainer} style={{ height: data.length * 57 }}>
              <Loader />
            </div>
          </td>
        </tr>
      </tbody>
    );
  }

  if (!loading && data.length === 0) {
    return (
      <tbody>
        <TableRow>
          <TableCell align="center" colSpan={9}>
            <div className="no-search">
              <IconNoRecords />
              <p>No Results Found.</p>
            </div>
          </TableCell>
        </TableRow>
      </tbody>
    );
  }

  return (
    <tbody>
      {data.map(row => (
        <tr
          key={row.id}
          className={cn({
            [styles.clickableRow]: onClick,
          })}
        >
          {columns.map(column => (
            <td
              key={column.id}
              className={styles.rowCell}
              style={{ width: column.fixedWidth || 'auto' }}
              onClick={() => {
                onClick?.(row);
              }}
            >
              {column.id && <div className={styles.cellContent}>{row[column.id]}</div>}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};

export default TableBody;
