import { Breadcrumbs, Typography } from '@mui/material';
import { DocumentData } from '../../components/documents/document-card/document-card-helpers';
import { Link } from 'react-router-dom';
import { AssetById } from 'src/types/Assets';
import { FoldersAndDocuments } from 'src/components/documents/FoldersAndDocuments/FoldersAndDocuments';
import { Folder } from 'src/types/Documents';
import { UploadDocument } from './components/UploadDocument';

interface DocumentsAssetDetailComponentProps {
  fund: {
    id?: number;
    name: string;
  };
  asset?: AssetById;
  documents: DocumentData[];
  folders: Folder[];
  openCreateFolderModal: () => void;
  openUpdateFolderModal: (folderId: number, oldFolderName: string) => void;
  handleDeleteOnClick: (documentId: number, documentName: string) => Promise<void>;
}

const DocumentsAssetDetailComponent = (props: DocumentsAssetDetailComponentProps) => {
  const { fund, asset, documents, folders, openCreateFolderModal, openUpdateFolderModal, handleDeleteOnClick } = props;

  const renderBreadcrumb = () => (
    <Breadcrumbs className="breadcrumb mb-15">
      {[
        <Link key="documents" color="inherit" to="/documents">
          Documents
        </Link>,
        <Link key="fund" color="inherit" to={`/documents/fund/${fund?.id}`}>
          {fund?.name}
        </Link>,

        <Typography key="asset">{asset?.Title}</Typography>,
      ]}
    </Breadcrumbs>
  );

  const renderTitleAndSubtitle = () => (
    <div className="d-flex">
      <div className="title-and-subtitle-section">
        <h2>{asset?.Title}</h2>
        <h4>{documents.length} Asset Level Documents</h4>
      </div>
      <div style={{ marginLeft: 'auto' }}>
        <UploadDocument />
      </div>
    </div>
  );

  return (
    <>
      {renderBreadcrumb()}
      {renderTitleAndSubtitle()}

      <FoldersAndDocuments
        documents={documents}
        folders={folders}
        handleAddFolderButtonClick={openCreateFolderModal}
        handleEditFolderButtonClick={openUpdateFolderModal}
        handleDeleteDocumentClick={handleDeleteOnClick}
      />
    </>
  );
};

export default DocumentsAssetDetailComponent;
