import * as yup from 'yup';

export interface RoleForm {
  Name: string;
  IsActive: string;
}

export interface RoleDTO {
  Privileges_Id: number[];
  FundPrivileges: unknown[];
  Name: string;
  IsActive: string | number;
}

export const schema = yup.object().shape({
  Name: yup
    .string()
    .required('Role Name is required.')
    .max(50, 'You have exceeded the character limit')
    .min(2, 'Role must be at least 2 characters long'),
});
