import { FundsPerformanceInformation } from 'src/models/dashboardModel';
import { getIndicators } from 'src/services/governanceService';
import { Fund } from 'src/types/Funds';
import { currentFormatNoDollarSign, renderCurrency } from 'src/views/utils';

//TODO: replace type from API
export const getFundsPerformanceData = async (funds: Fund[]): Promise<FundsPerformanceInformation[][]> => {
  if (!funds || !funds.length) return [];

  try {
    const fundsPerformanceLocals = await Promise.all(
      funds.map(async ({ id, name }) => {
        // TODO: get type from API
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const response: any = await getIndicators(id);

        // TODO: get type from API
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const getValue = (kpi: string): number => response?.data?.find(({ KPI }: any) => KPI === kpi)?.value || 0;

        const totalRealizedPL = getValue('Total Realized P&L') * 100;
        const multipleTotalReturn = getValue('Multiple (Total Return)');
        const grossIrr = getValue('Gross IRR (%)') * 100;

        const grossIrrChartValue = grossIrr <= 20 ? 50 - grossIrr : 100 - grossIrr;

        return {
          id,
          title: name,
          total_realized_pl: renderCurrency(totalRealizedPL), // TODO: KPI to be defined from BE
          multiple: `$ ${currentFormatNoDollarSign(multipleTotalReturn, 2)}`,
          chart_data: [{ value: grossIrrChartValue, fill: '#E1E1E1' }, { value: grossIrr }],
        };
      }),
    );

    return fundsPerformanceLocals.reduce<FundsPerformanceInformation[][]>((fundsPerformances, item, index) => {
      const chunkSize = Math.floor(index / 2);

      if (!fundsPerformances[chunkSize]) {
        fundsPerformances[chunkSize] = [];
      }

      fundsPerformances[chunkSize].push(item);

      return fundsPerformances;
    }, []);
  } catch (e) {
    console.log('fundsPerformanceInformationData error', e);

    return [];
  }
};
