import * as yup from 'yup';

export interface UploadDocumentForm {
  FolderName: string;
  Title: string;
  Document_for: string;
  IsActive: number;
  DurationType: string;
  DocType: string;
  DocDate: string;
  DocLink: string;
  DataProcessingRequires: boolean;
  FolderId: string;
  IsLpVisible: boolean;
}

export const schema = yup.object().shape({
  Title: yup.string().required('Name is required'),
  DataProcessingRequires: yup.boolean(),
  FolderId: yup.string(),
  IsLpVisible: yup.boolean(),
});
