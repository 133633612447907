import React from 'react';
import Avatar from '@mui/material/Avatar';
import IconSearch from '../../icons/IconSearch';
import IconButton from '@mui/material/IconButton';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import Tooltip from '@mui/material/Tooltip';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconChatGroup from '../../icons/IconChatGroup';
import FormControl from '@mui/material/FormControl';
import { ChatLPList, CustomConversation, UserSuggestion } from '../../../models/chatModels';
import InfiniteScroll from 'react-infinite-scroll-component';
import ChatListItemContainer from './chat-list-item/chatListItemContainer';
import { Box, Tab, Tabs } from '@mui/material';
import IconChat from '../../icons/IconChat';
import { UserDetails } from '../../../models/authModels';
import getInitials from '../../getInitials/getInitials';
import IconNoRecords from '../../../uiLibrary/icons/IconNoRecords';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel({ children, value, index, ...other }: TabPanelProps) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
    value: index,
  };
}

const ChatListComponent = ({
  setScreenVal,
  userSuggestions,
  chatHistory,
  createGroup,
  searchText,
  setSearchText,
  fetchMoreChats,
  hasPrevPage,
  value,
  handleChange,
  setCustomConvo,
  lpUserList,
  userDetails,
}: ChatListComponentProps) => {
  return (
    <div className="chat-list-wrap">
      <div className="form-wrap">
        <form noValidate autoComplete="off" onSubmit={e => e.preventDefault()}>
          <div className="from-row theam-black">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <FormControl fullWidth variant="outlined" className="formControl pl">
                <OutlinedInput
                  type="search"
                  value={searchText}
                  onChange={e => {
                    setSearchText(e.target.value);
                  }}
                  color="info"
                  placeholder="Search any user by Name or Email ID"
                  startAdornment={
                    <>
                      <InputAdornment position="start">
                        <IconButton>
                          <IconSearch />
                        </IconButton>
                      </InputAdornment>
                    </>
                  }
                />
              </FormControl>
            </div>
          </div>
        </form>
        {!!searchText &&
          userSuggestions !== undefined &&
          (userSuggestions.length > 0 ? (
            <div className="search-result">
              <PerfectScrollbar>
                {userSuggestions.map(
                  userSuggestion =>
                    userDetails.UserId !== userSuggestion.userid && (
                      <div
                        className="chat-list search-list"
                        key={userSuggestion.userid}
                        onClick={() => createGroup(userSuggestion.userid)}
                      >
                        <div className="media">
                          <span className="avater-icon">
                            <Avatar
                              className="d-flex align-self-center"
                              src={userSuggestion.profileimage ? userSuggestion.profileimage! : undefined}
                            >
                              {getInitials(userSuggestion?.username)}
                            </Avatar>
                          </span>
                          <div className="media-body">
                            <div className="">
                              <h6 className="m-0 ">{userSuggestion?.username}</h6>
                              <p className="m-0 ">{userSuggestion?.email}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ),
                )}
              </PerfectScrollbar>
            </div>
          ) : (
            <div className="search-result not-found-result">
              <div className="chat-list search-list">
                <div className="media">
                  <span className="avater-icon">
                    <IconNoRecords></IconNoRecords>
                  </span>
                  <div className="media-body">
                    <div className="">
                      <h6 className="m-0 ">No Search Result Found.</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
      <div className="recnt-chat-wrap">
        <div className="d-flex align-items-center">
          <Box className="tab-heading">
            <Tabs value={value} onChange={handleChange}>
              <Tab label="Recent Chats" {...a11yProps(0)} />
              <Tab label="LP List" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <Tooltip
            title="Create Group"
            componentsProps={{
              tooltip: {
                sx: {
                  color: '#fff',
                  backgroundColor: '#000',
                  fontSize: '12px',
                  fontWeight: '500',
                  borderRadius: '5px',
                  paddingLeft: '12px',
                  paddingRight: '12px',
                },
              },
            }}
          >
            <IconButton size="medium" onClick={() => setScreenVal(2)}>
              <IconChatGroup />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <div className="re-chact-main">
        <TabPanel value={value} index={0}>
          <div
            id="scrollableDiv"
            className="infinite-scroll-component"
            style={{
              height: 'calc(100vh - 150px)',
              overflow: 'auto',
              display: 'flex',
              flexDirection: 'column',
              paddingBottom: 24,
            }}
          >
            <InfiniteScroll
              dataLength={chatHistory?.length ? chatHistory.length : 0}
              next={fetchMoreChats}
              hasMore={hasPrevPage}
              loader={<h4>Loading...</h4>}
              scrollableTarget="scrollableDiv"
            >
              {!!chatHistory &&
                chatHistory.length > 0 &&
                chatHistory.map((customConvo: CustomConversation) => (
                  <ChatListItemContainer
                    key={customConvo.conversation.sid}
                    customConvo={customConvo}
                    setScreenVal={setScreenVal}
                    setCustomConvo={setCustomConvo}
                  />
                ))}
            </InfiniteScroll>
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div
            style={{
              height: 'calc(100vh - 180px)',
            }}
          >
            <PerfectScrollbar>
              {!!lpUserList &&
                lpUserList.map(
                  lpUser =>
                    userDetails.UserId !== lpUser.userid && (
                      <div className="chat-list" key={lpUser.userid}>
                        <div className="media">
                          <span className="avater-icon">
                            <Avatar className="d-flex align-self-center" src={lpUser.profileimage ?? undefined}>
                              {getInitials(lpUser.username)}
                            </Avatar>
                          </span>
                          <div className="media-body">
                            <div className="d-flex align-items-center">
                              <h6 className="mb-0 flex-grow-1">{lpUser.username}</h6>
                              <span>
                                <IconButton onClick={() => createGroup(lpUser.userid)}>
                                  <IconChat />
                                </IconButton>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ),
                )}
            </PerfectScrollbar>
          </div>
        </TabPanel>
      </div>
    </div>
  );
};

interface ChatListComponentProps {
  setScreenVal: (screenNum: number) => void;
  userSuggestions?: UserSuggestion[];
  chatHistory?: CustomConversation[];
  createGroup: (userSuggestion: number) => void;
  searchText: string;
  setSearchText: (searchText: string) => void;
  fetchMoreChats: () => void;
  hasPrevPage: boolean;
  value: 0 | 1;
  handleChange: (event: React.SyntheticEvent, newValue: 0 | 1) => void;
  setCustomConvo: (customConvo?: CustomConversation) => void;
  lpUserList?: ChatLPList[];
  userDetails: UserDetails;
}

export default ChatListComponent;
