import { Box, Stack, Typography } from 'src/components/common';
import { WaterfallPreviewGraphData } from './WaterfallPreview.helpers';
import { calcPercentage } from 'src/utils/calcMethods';
import styles from './styles.module.scss';

type WaterfallPreviewItemProps = WaterfallPreviewGraphData;

const colorWheel = ['#f6f5f4', '#ecebea', '#e2e1e0'];

export const WaterfallPreviewItem = ({
  levelName,
  total,
  distribution,
  capitalToDistribute,
  gpSplit,
  lpSplit,
  irrHurdle,
}: WaterfallPreviewItemProps) => {
  const graphValues = [
    {
      id: 'gpSplit',
      value: gpSplit,
    },
    {
      id: 'lpSplit',
      value: lpSplit,
    },
    {
      id: 'irrHurdle',
      value: irrHurdle,
    },
  ];

  const firstLinePercentage = Math.floor(calcPercentage(distribution, total) * 100) / 100;
  const secondLinePercentage = (() => {
    if (capitalToDistribute) {
      return Math.floor(calcPercentage(capitalToDistribute, total) * 100) / 100;
    }

    return 0;
  })();

  const distributionTitle = (capitalToDistribute ? distribution + capitalToDistribute : distribution) / 1000;

  return (
    <Stack direction="column" className={styles.wrapper}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="s14_lh22_r" color="#000000">
          {levelName}
        </Typography>
        <Typography variant="s14_lh22_r" color="#8F8F8F">{`$${distributionTitle}K / ${total / 1000}K`}</Typography>
      </Stack>

      <Stack direction="row" className={styles.contentBox}>
        {graphValues.map(({ id, value }, index) => {
          if (id === 'irrHurdle' && !value) {
            return <></>;
          }

          return (
            <Box
              key={id}
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                background: colorWheel[index],
                width: `${value}%`,
                minWidth: 'min-content',
              }}
            >
              <Typography variant="s12_lh19_r" color="#8F8F8F" margin="0 4px">
                {value + '%'}
              </Typography>
            </Box>
          );
        })}
      </Stack>

      <Stack direction="row" className={styles.percentLine} width="100%">
        <Box width={`${firstLinePercentage}%`} minWidth="2%" maxWidth="100%" />

        <Box width={`${secondLinePercentage}%`} maxWidth="100%" />
      </Stack>
    </Stack>
  );
};
