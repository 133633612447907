import React, { BaseSyntheticEvent, useEffect, useRef } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { ShowSnackbarAction } from '../../../redux/actions/snackbarActions';
import { Button, IconButton, TextField, FormLabel, FormControl } from '@mui/material';
import IconCamera from '../../icons/IconCamera';
import IconArrowLeft from '../../icons/IconArrowLeft';
import { Conversation } from '@twilio/conversations';
import { CustomConversation } from '../../../models/chatModels';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { SnackbarPayload } from '../../../models/snackbarModels';

const CreateGroupChatComponent = ({
  onSubmit,
  setScreenVal,
  conversation,
  setCustomConvo,
}: CreateGroupChatComponentProps) => {
  const groupNameRef = useRef<HTMLInputElement>(null);

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors, submitCount },
  } = useForm<{ GroupName: string; GroupIcon: File }>({
    defaultValues: {
      GroupName: '',
      GroupIcon: undefined,
    },
  });

  const handleFileInput = (e: BaseSyntheticEvent) => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0]?.name) {
        const extension = e.target.files[0].name.toLowerCase().split('.').pop();
        if (extension === 'jpeg' || extension === 'jpg' || extension === 'png' || extension === 'JPEG') {
          setValue('GroupIcon', e.target.files[0]);
        } else {
          ShowSnackbarAction({
            message: 'Please choose a valid image',
            severity: 'error',
          });
        }
      }
    }
  };

  useEffect(() => {
    if (conversation?.sid) {
      setValue('GroupName', conversation.friendlyName!);
    }
  }, [conversation]);

  useEffect(() => {
    if (errors && Object.keys(errors).length > 0) {
      switch (Object.keys(errors)[0]) {
        case 'GroupName':
          groupNameRef.current?.focus();
          break;
      }
    }
  }, [errors, submitCount]);

  return (
    <>
      <div className="back-wrap ml">
        <IconButton
          size="large"
          component="span"
          onClick={() => {
            if (conversation?.sid) {
              setScreenVal(1);
            } else {
              setScreenVal(0);
              setCustomConvo();
            }
          }}
        >
          <IconArrowLeft />
        </IconButton>
        <h4 className="back-text">{conversation?.sid ? 'Edit Group' : 'Create New Group'}</h4>
      </div>
      <div className="chat-list-wrap group-h">
        <PerfectScrollbar>
          <div className="create-group-wrap">
            <div className="create-group-center">
              <div className="form-wrap">
                <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                  <div className="from-row theam-black">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="group-img-wrap text-center">
                        <div className="group-avater-img">
                          <label className="upload-group-img" htmlFor="upload-group-img">
                            <input
                              accept="image/*"
                              id="upload-group-img"
                              type="file"
                              className="d-none"
                              onChange={handleFileInput}
                            />
                            {watch('GroupIcon') ? (
                              <img src={URL.createObjectURL(getValues('GroupIcon')) ?? undefined} alt="Selected" />
                            ) : conversation &&
                              // TODO: get type from conversation type
                              // eslint-disable-next-line @typescript-eslint/no-explicit-any
                              (conversation?.attributes as any)['group_icon'] ? (
                              <img
                                src={
                                  // TODO: get type from conversation type
                                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                  (conversation?.attributes as any)['group_icon']
                                    ? // TODO: get type from conversation type
                                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                      (conversation?.attributes as any)['group_icon']
                                    : undefined
                                }
                                alt="Uploaded"
                              />
                            ) : (
                              <IconButton className="camera-btn" size="large" component="span">
                                <IconCamera />
                              </IconButton>
                            )}
                          </label>
                        </div>
                        <h3>Group Image</h3>
                        <p>Add an image for your group</p>
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <Controller
                        name="GroupName"
                        control={control}
                        rules={{
                          required: 'Please provide a proper group name',
                        }}
                        render={({ field, fieldState }) => (
                          <FormControl fullWidth variant="outlined" className="formControl">
                            <FormLabel>
                              Group Name<span className="require">*</span>
                            </FormLabel>
                            <TextField
                              {...field}
                              onChange={e => {
                                if (e.target.value.trim() === '') {
                                  setValue('GroupName', '');
                                } else {
                                  field.onChange(e);
                                }
                              }}
                              error={!!fieldState.error}
                              helperText={fieldState.error ? fieldState.error.message : ''}
                              inputRef={groupNameRef}
                              fullWidth
                              type="text"
                              margin="dense"
                              color="info"
                              required
                            />
                          </FormControl>
                        )}
                      />
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 btn-row text-center mt-3">
                      <Button type="submit" variant="contained">
                        {conversation?.sid ? 'Update' : 'Create'}
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </PerfectScrollbar>
      </div>
    </>
  );
};

interface CreateGroupChatComponentProps {
  ShowSnackbarAction: (payload: SnackbarPayload) => void;
  onSubmit: (formData: FieldValues) => void;
  setScreenVal: (screenNum: number) => void;
  conversation?: Conversation;
  setCustomConvo: (customConvo?: CustomConversation) => void;
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {
  ShowSnackbarAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateGroupChatComponent);
