import { useNavigate } from 'react-router-dom';
import { LoginPayload, TermsUserDetails } from 'src/models/authModels';
import LoginComponent from './loginComponent';
import { useAuth } from 'src/context/AuthContext';
import { useRedirectBasedOnPermissions } from 'src/hooks/useRedirectBasedOnPermissions';

const LoginContainer = () => {
  const navigate = useNavigate();

  const { login } = useAuth();

  const { getRedirectRoute } = useRedirectBasedOnPermissions();

  const loginCallback = (userDetailsResponse?: TermsUserDetails) => {
    if (userDetailsResponse?.roles?.privilege) {
      const redirectRoute = getRedirectRoute();

      userDetailsResponse?.accessToken && navigate(redirectRoute, { replace: true });
    }
  };

  const onSubmit = (formData: LoginPayload) => {
    navigator.geolocation.getCurrentPosition(
      location => {
        login({
          ...formData,
          Email: formData.Email.toLowerCase(),
          Location: `${location.coords.latitude},${location.coords.longitude}`,
        }).then(loginCallback);
      },
      () => {
        login({
          ...formData,
          Email: formData.Email.toLowerCase(),
          Location: null,
        }).then(loginCallback);
      },
      {
        timeout: 2000,
      },
    );
  };

  return <LoginComponent onSubmit={onSubmit} />;
};

export default LoginContainer;
