import { KPIUnitType, KPIValueType } from 'src/models/partnershipModel';

export const mappings = [
  { kpi: 'Occupancy Rate', indicator: 'occupancy_rate' },

  { kpi: 'Trend Occupancy', indicator: 'trend_occupancy' },
  { kpi: 'Delinquency Rate', indicator: 'delinquency_rate' },
  { kpi: 'Total Delinquencies', indicator: 'total_delinquencies' },
  { kpi: 'Operating Expense Ratio', indicator: 'operating_expense_ratio' },
  { kpi: 'Market Rate Avg', indicator: 'market_rate_avg' },
  { kpi: 'Leased Rate Avg', indicator: 'leased_rate_avg' },
  { kpi: 'Total Estimated Return', indicator: 'total_estimated_return' },
  { kpi: 'Stabilized Yield', indicator: 'stabilized_yield' },
  { kpi: 'Current Market Value', indicator: 'current_market_value' },
  { kpi: 'Total Revenue', indicator: 'total_revenue' },
  { kpi: 'NOI', indicator: 'net_operating_income_noi' },
  { kpi: 'DSCR', indicator: 'dscr' },
];

export type KPIIndicatorMappingDirection = 'kpiToIndicator' | 'indicatorToKpi';

export function kpiIndicatorMapping(value: string, direction: KPIIndicatorMappingDirection): string {
  if (direction === 'kpiToIndicator') {
    const mapping = mappings.find(m => m.kpi === value);
    return mapping ? mapping.indicator : value;
  } else {
    const mapping = mappings.find(m => m.indicator === value);
    return mapping ? mapping.kpi : value;
  }
}

export const mapUnitType = (unitType: string): KPIValueType => {
  switch (unitType) {
    case KPIUnitType.PERCENTAGE:
      return KPIValueType.PERCENTAGE;
    case KPIUnitType.CURRENCY:
      return KPIValueType.CURRENCY;
    case KPIUnitType.NUMBER:
      return KPIValueType.NUMBER;
    case KPIUnitType.MULTIPLE:
      return KPIValueType.MULTIPLE;
  }
  return KPIValueType.NUMBER;
};
