import * as yup from 'yup';

export const schema = yup.object().shape({
  FundId: yup.number().required('Fund id is required'),
  UserId: yup.number().required('User id is required'),
  FundCode: yup.string().required('Fund code is required'),
  FundName: yup.string().required('Fund name is required'),
  RequestedAmount: yup.string().required('Requested amount is required'),
  ClosingDate: yup.date().nullable().required('Closing date is required'),
  CapCallFile: yup.mixed().nullable(),
});
