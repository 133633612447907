import { IconButton } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { GovernanceActions } from './components/GovernanceActions';
import { CapitalStructure } from './components/CapitalStructure';
import { useNavigate } from 'react-router-dom';
import { CashKPIDetails } from '../governance-fund-detail/components/CashKPI';
import { Protected } from 'src/components/layouts/Protected/Protected';
import { Permission } from 'src/consts/permissions';

interface GovernanceOverviewComponentProps {
  // TODO: get type from API
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fund: any;
}

const GovernanceOverviewComponent = ({ fund }: GovernanceOverviewComponentProps) => {
  const navigate = useNavigate();

  const viewDetailHandler = () => {
    if (fund?.name) {
      navigate('/governance/detail/' + fund.id);
    }
  };

  return (
    <div className="overview">
      <div className="theam-black overview-header" style={{ flexWrap: 'nowrap' }}>
        <div>
          <p>Overview</p>
          <h3>{fund.name}</h3>
        </div>
        <div className="view-details">
          <h4>View Fund details</h4>
          <IconButton onClick={viewDetailHandler}>
            <ArrowForwardIcon className="arrow-icon" />
          </IconButton>
        </div>
      </div>

      <div className="d-flex gap">
        <div className="overview-titles gap">
          <CashKPIDetails id={fund?.id} />

          <Protected permissions={[Permission.GovernanceActions]} fundId={fund.id}>
            <GovernanceActions fund={fund} />
          </Protected>
        </div>
        <div className="overview-titles">
          <CapitalStructure fundId={fund.id} />
        </div>
      </div>
    </div>
  );
};

export default GovernanceOverviewComponent;
