import { ProtectedRoute } from 'src/consts/protectedRoute';
import { Routes } from 'src/consts/routes';
import { useUserRole } from './usePermission';
import { useAuth } from '../context/AuthContext';

export const useRedirectBasedOnPermissions = () => {
  const routePriority = Object.keys(ProtectedRoute);
  const { isMainGp, isLp } = useUserRole();
  const { userData } = useAuth();

  const getRedirectRoute = (allowForLp?: boolean): string => {
    const routeKey = routePriority.find(key => userData?.permissions.includes(key));

    if (isMainGp || (isLp && allowForLp)) {
      return Routes.dashboard;
    }

    return routeKey ? ProtectedRoute[routeKey as keyof typeof ProtectedRoute] : Routes.forbidden;
  };

  return {
    getRedirectRoute,
  };
};
