import { Collapse, IconButton } from '@mui/material';
import IconNavArrow from 'src/uiLibrary/icons/IconNavArrow';
import IconDownload from 'src/uiLibrary/icons/IconDownload';
import { DocumentData } from '../document-card-helpers';

type DocumentCardBodyProps = {
  document: DocumentData;
  isOpen: boolean;
  handleFamilyDetailsOnClick?: (documentId: string | number) => void;
};

export const DocumentCardBody = ({ document, isOpen, handleFamilyDetailsOnClick }: DocumentCardBodyProps) => {
  const { isExpandable, level, id, extension } = document;

  const handleDownload = (linkToDocument: string) =>
    extension !== 'png' ? (window.location.href = linkToDocument) : window.open(linkToDocument, '_blank');

  if (!isExpandable) {
    return (
      <>
        <div className="divider" />
        <div className="document-card-body">
          <div className="document-card-body-row">
            <div>
              <h6>Level</h6>
              <h4>{level}</h4>
            </div>
          </div>
          {document.route && document.route.length > 0 ? (
            <div className="document-card-body-row">
              <div>
                <h6>Route</h6>
                <h4>{document.route.join(' > ')}</h4>
              </div>
            </div>
          ) : undefined}
        </div>
      </>
    );
  } else {
    return (
      <Collapse
        in={isOpen}
        timeout={{ appear: 100, enter: 300, exit: 0 }}
        unmountOnExit
        sx={{ padding: 0, width: '100%' }}
      >
        <div className="divider" />
        <div className="document-card-body">
          <div className="document-card-body-row">
            <div>
              <h6>Level</h6>
              <h4>{level}</h4>
            </div>
          </div>
          {extension === 'fam' ? (
            <>
              <div className="document-card-body-row">
                <div>
                  <h6>Past Instances</h6>
                  <h4>{document.past_instances || 'N/A'}</h4>
                </div>
                <div className="align-right">
                  <h6>Upcoming</h6>
                  <h4>{document.upcoming || 'N/A'}</h4>
                </div>
              </div>
              <div className="document-card-body-row">
                <div className="family-details-doc">
                  <button className="download-latest-button">Download latest</button>
                  <button
                    className="family-details-button"
                    onClick={handleFamilyDetailsOnClick ? () => handleFamilyDetailsOnClick(id) : undefined}
                  >
                    <span>
                      Family Details <IconNavArrow />
                    </span>
                  </button>
                </div>
              </div>
            </>
          ) : (
            <div className="document-card-body-row">
              <div className="download-doc">
                <div>
                  <h3>Download Document</h3>
                </div>
                <IconButton onClick={() => handleDownload(document.downloadable_document)}>
                  <IconDownload />
                </IconButton>
              </div>
            </div>
          )}
        </div>
      </Collapse>
    );
  }
};
