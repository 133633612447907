import { TablePagination, TableSortLabel, Tooltip } from '@mui/material';
import MuiTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useMemo } from 'react';
import { HeadCellItem } from 'src/models/tableModels';
import IconNoRecords from 'src/uiLibrary/icons/IconNoRecords';
import Box from '@mui/material/Box';
import { visuallyHidden } from '@mui/utils';

interface TableProps<T> {
  columns: HeadCellItem<T>[];
  data: Array<T & { id?: number | string; userId?: number | string }>;
  pagination: {
    page: number;
    rowsPerPage: number;
    total?: number;
    onPageChange: (page: number) => void;
    onPageSizeChange: (pageSize: number) => void;
  };
  isCustomPagination?: boolean;
  emptyStateTitle?: string;
  orderBy?: string;
  order?: 'asc' | 'desc';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleSortChange?: any;
}

export const Table = <T,>({
  columns,
  data,
  pagination: { page, rowsPerPage, total, onPageChange, onPageSizeChange },
  emptyStateTitle = 'No Results Found.',
  orderBy,
  order,
  handleSortChange,
}: TableProps<T>) => {
  const visibleRows = useMemo(
    () => (total ? data : data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)),
    [data, page, rowsPerPage, total],
  );

  return (
    <>
      <TableContainer>
        <MuiTable sx={{ minWidth: 750 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {columns
                .filter(column => !column.hidden)
                .map(({ id, label, minWidth, maxWidth, sortable }) => (
                  <TableCell
                    key={id}
                    align="left"
                    sx={{
                      ...(minWidth && { minWidth: `${minWidth}px` }),
                      ...(maxWidth && { maxWidth: `${maxWidth}px` }),
                    }}
                    sortDirection={orderBy === id ? order : false}
                  >
                    {sortable && handleSortChange ? (
                      <TableSortLabel
                        active={orderBy === id}
                        direction={orderBy === id ? order : 'asc'}
                        onClick={() => handleSortChange(id, orderBy === id && order === 'asc' ? 'desc' : 'asc')}
                      >
                        {label}
                        {orderBy === id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    ) : (
                      label
                    )}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleRows.length ? (
              visibleRows.map(row => (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                  {columns
                    .filter(column => !column.hidden)
                    .map(({ render, key, id, tooltip, minWidth, maxWidth }) => (
                      <TableCell
                        key={id}
                        align="left"
                        sx={{
                          ...(minWidth && { minWidth: `${minWidth}px` }),
                          ...(maxWidth && { maxWidth: `${maxWidth}px` }),
                        }}
                      >
                        {tooltip ? (
                          <Tooltip title={tooltip(row)} arrow placement="top">
                            <span>{render ? render(row) : row[key as keyof T]}</span>
                          </Tooltip>
                        ) : (
                          <>{render ? render(row) : row[key as keyof T]}</>
                        )}
                      </TableCell>
                    ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={columns.length}>
                  <div className="no-search">
                    <IconNoRecords />
                    <p>{emptyStateTitle}</p>
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </MuiTable>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 20, 30, 40, 50]}
        component="div"
        count={total ?? data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(_, newPage) => onPageChange(newPage)}
        onRowsPerPageChange={event => onPageSizeChange(+event.target.value)}
      />
    </>
  );
};
