import { KPIValueType } from 'src/models/partnershipModel';
import { currentFormatWithDecimals } from 'src/views/utils';

export const formatKpiValue = (value: number | null | undefined, ValueType: KPIValueType = KPIValueType.PERCENTAGE) => {
  if (value) {
    if (ValueType === KPIValueType.PERCENTAGE) {
      return `${parseFloat(value.toString()).toFixed(2)}%`;
    }
    if (ValueType === KPIValueType.CURRENCY) {
      return currentFormatWithDecimals(value);
    }
    if (ValueType === KPIValueType.MULTIPLE) {
      return `${parseInt(value.toString()).toFixed(2)}x`;
    }
    return parseInt(value.toString()).toFixed(2);
  }
  return '-';
};
