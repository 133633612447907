import { Roles } from 'src/consts/roles';
import { useAuth } from 'src/context/AuthContext';
import { UserType } from 'src/models/authModels';

export const useUserRole = () => {
  const { userData } = useAuth();

  return {
    isMainGp: userData?.role?.Slug === Roles.MainGp,
    isLp: userData?.UserType === (3 as UserType),
  };
};
