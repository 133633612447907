import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from 'src/context/AuthContext';
import HeaderContainer from 'src/uiLibrary/reusables/header/headerContainer';
import SidebarContainer from 'src/uiLibrary/reusables/sidebar/sidebarContainer';

interface PageLayoutProps {
  children: ReactNode;
  isHeaderVisible?: boolean;
  isSidebarVisible?: boolean;
  title?: string;
  subtitle?: string;
  isBodyContent?: boolean;
}

function PageLayout({
  children,
  isHeaderVisible = true,
  isSidebarVisible = true,
  title,
  subtitle,
  isBodyContent = true,
}: PageLayoutProps) {
  const { userData } = useAuth();

  if (!userData) {
    return <Navigate to="/login" replace />;
  }

  return (
    <>
      {isHeaderVisible && <HeaderContainer title={title} subtitle={subtitle} />}
      {isSidebarVisible && <SidebarContainer />}
      {isBodyContent ? <div className="main-body-content">{children}</div> : children}
    </>
  );
}

export default PageLayout;
