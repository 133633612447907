import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'src/context/ShackbarContext';
import { getPromoteStructure } from 'src/services/promoteStructureServices';
import { QueryKey } from 'src/consts/query';

export const usePromoteStructure = (fundId: number) => {
  const { showErrorSnackbar } = useSnackbar();

  return useQuery({
    queryKey: [QueryKey.PromoteStructure, fundId],
    queryFn: async () => {
      try {
        const response = await getPromoteStructure(fundId);

        if (response?.data.data) {
          return response?.data.data;
        }
      } catch (error) {
        showErrorSnackbar((error as AxiosError).message);
        console.error('usePromoteStructure error', error);
        throw new Error((error as AxiosError).message);
      }
    },
  });
};
