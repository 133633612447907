import React from 'react';

const IconDownloadDoc = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26 30H6C5.44772 30 5 29.5523 5 29V3C5 2.44772 5.44772 2 6 2H18C18.527 2 18.8031 2.00333 19.0399 2.04637C19.233 2.08149 19.4193 2.14804 19.6953 2.33205C20.3396 2.76157 21.247 3.66125 23.2929 5.70711C25.3387 7.75296 26.2384 8.66042 26.6679 9.3047C26.852 9.58071 26.9185 9.76696 26.9536 9.96014C26.9967 10.1969 27 10.473 27 11V29C27 29.5523 26.5523 30 26 30Z"
        stroke="#B6B6B6"
        strokeWidth="2"
      />
      <path
        d="M15.3052 10.2929C15.4927 10.1054 15.7471 10 16.0123 10C16.2775 10 16.5319 10.1054 16.7194 10.2929C16.9069 10.4804 17.0123 10.7348 17.0123 11V20.586L19.3053 18.293C19.4939 18.1108 19.7465 18.01 20.0087 18.0123C20.2709 18.0146 20.5217 18.1198 20.7071 18.3052C20.8925 18.4906 20.9977 18.7414 21 19.0036C21.0022 19.2658 20.9014 19.5184 20.7193 19.707L16.7193 23.707C16.6262 23.7996 16.5158 23.873 16.3943 23.923C16.2733 23.9738 16.1435 24 16.0123 24C15.8811 24 15.7512 23.9738 15.6303 23.923C15.5088 23.873 15.3984 23.7996 15.3053 23.707L11.3053 19.707C11.2098 19.6148 11.1336 19.5044 11.0812 19.3824C11.0288 19.2604 11.0012 19.1292 11 18.9964C10.9989 18.8636 11.0242 18.7319 11.0745 18.609C11.1247 18.4861 11.199 18.3745 11.2929 18.2806C11.3868 18.1867 11.4984 18.1125 11.6213 18.0622C11.7442 18.0119 11.8759 17.9866 12.0087 17.9877C12.1415 17.9889 12.2727 18.0165 12.3947 18.0689C12.5167 18.1213 12.627 18.1975 12.7193 18.293L15.0123 20.586V11C15.0123 10.7348 15.1176 10.4804 15.3052 10.2929Z"
        fill="#B6B6B6"
      />
    </svg>
  );
};

export default IconDownloadDoc;
