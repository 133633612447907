const IconCompare = () => {
  return (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="60" height="60" rx="8" fill="white" fillOpacity="0.1" />
      <path
        d="M30 33H22.414L24.707 30.707C24.8892 30.5184 24.99 30.2658 24.9877 30.0036C24.9854 29.7414 24.8803 29.4906 24.6948 29.3052C24.5094 29.1198 24.2586 29.0146 23.9964 29.0123C23.7342 29.01 23.4816 29.1108 23.293 29.293L19.293 33.293C19.1056 33.4805 19.0002 33.7348 19.0002 34C19.0002 34.2651 19.1056 34.5195 19.293 34.707L23.293 38.707C23.4816 38.8891 23.7342 38.9899 23.9964 38.9877C24.2586 38.9854 24.5094 38.8802 24.6948 38.6948C24.8803 38.5094 24.9854 38.2586 24.9877 37.9964C24.99 37.7342 24.8892 37.4816 24.707 37.293L22.414 35H30C30.2652 35 30.5196 34.8946 30.7071 34.7071C30.8947 34.5196 31 34.2652 31 34C31 33.7348 30.8947 33.4804 30.7071 33.2929C30.5196 33.1053 30.2652 33 30 33Z"
        fill="#909090"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.2929 25.2929C29.1054 25.4804 29 25.7348 29 26C29 26.2652 29.1054 26.5196 29.2929 26.7071C29.4804 26.8947 29.7348 27 30 27H37.586L35.293 29.293C35.1975 29.3853 35.1213 29.4956 35.0689 29.6176C35.0165 29.7396 34.9889 29.8708 34.9877 30.0036C34.9866 30.1364 35.0119 30.2681 35.0622 30.391C35.1125 30.5139 35.1867 30.6255 35.2806 30.7194C35.3745 30.8133 35.4861 30.8876 35.609 30.9378C35.7319 30.9881 35.8636 31.0134 35.9964 31.0123C36.1292 31.0111 36.2604 30.9835 36.3824 30.9311C36.5044 30.8787 36.6148 30.8025 36.707 30.707L40.707 26.707C40.8945 26.5195 40.9998 26.2652 40.9998 26C40.9998 25.7349 40.8945 25.4805 40.707 25.293L36.707 21.293C36.6148 21.1975 36.5044 21.1213 36.3824 21.0689C36.2604 21.0165 36.1292 20.9889 35.9964 20.9878C35.8636 20.9866 35.7319 21.0119 35.609 21.0622C35.4861 21.1125 35.3745 21.1867 35.2806 21.2806C35.1867 21.3745 35.1125 21.4862 35.0622 21.6091C35.0119 21.732 34.9866 21.8636 34.9877 21.9964C34.9889 22.1292 35.0165 22.2604 35.0689 22.3824C35.1213 22.5044 35.1975 22.6148 35.293 22.707L37.586 25H30C29.7348 25 29.4804 25.1054 29.2929 25.2929Z"
        fill="white"
      />
    </svg>
  );
};

export default IconCompare;
