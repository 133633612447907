import React from 'react';
import { RootState } from '../../../../redux/store';
import { connect } from 'react-redux';
import { HideLoadingAction, ShowLoadingAction } from '../../../../redux/actions/loadingActions';
import { ShowSnackbarAction } from '../../../../redux/actions/snackbarActions';
import { SnackbarPayload } from '../../../../models/snackbarModels';
import { removeMemberFromGroup } from '../../../../services/chatService';
import { Conversation } from '@twilio/conversations';
import { UserDetails } from '../../../../models/authModels';
import DeleteParticipantComponent from './deleteParticipantComponent';

const DeleteParticipantContainer = ({
  userDetails,
  ShowSnackbarAction,
  ShowLoadingAction,
  HideLoadingAction,
  setOpenModal,
  conversation,
  participantList,
  getParticipantList,
}: DeleteParticipantContainerProps) => {
  const removeFromGroup = (memberSid: string) => {
    ShowLoadingAction();
    const payload = {
      groupSid: conversation?.sid,
      memberSid: memberSid,
    };
    if (conversation?.sid) {
      removeMemberFromGroup(payload)
        .then(() => {
          HideLoadingAction();
          getParticipantList();
        })
        .catch(() => {
          HideLoadingAction();
          ShowSnackbarAction({
            message: 'Error Removing User',
            severity: 'error',
          });
        });
    }
  };

  return (
    <DeleteParticipantComponent
      setOpenModal={setOpenModal}
      participantList={participantList}
      removeFromGroup={removeFromGroup}
      userDetails={userDetails}
    />
  );
};

interface DeleteParticipantContainerProps {
  userDetails: UserDetails;
  ShowSnackbarAction: (payload: SnackbarPayload) => void;
  ShowLoadingAction: (payload?: string) => void;
  HideLoadingAction: () => void;
  setOpenModal: (openModal: number) => void;
  conversation?: Conversation;
  // TODO: get type from API
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  participantList: any[];
  getParticipantList: () => void;
}

const mapStateToProps = (state: RootState) => {
  return { userDetails: state.auth.userDetails };
};

const mapDispatchToProps = {
  ShowSnackbarAction,
  ShowLoadingAction,
  HideLoadingAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteParticipantContainer);
