import React from 'react';

const IconArea = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.4727 12.7266H5.27344V0.527344C5.27344 0.236069 5.03737 0 4.74609 0H0.527344C0.236069 0 0 0.236069 0 0.527344V17.4727C0 17.7639 0.236069 18 0.527344 18H17.4727C17.7639 18 18 17.7639 18 17.4727V13.2539C18 12.9626 17.7639 12.7266 17.4727 12.7266ZM16.9453 16.9453H1.05469V1.05469H4.21875V2.17969H3.33984C3.04857 2.17969 2.8125 2.41576 2.8125 2.70703C2.8125 2.99831 3.04857 3.23438 3.33984 3.23438H4.21875V4.28906H3.69141C3.40013 4.28906 3.16406 4.52513 3.16406 4.81641C3.16406 5.10768 3.40013 5.34375 3.69141 5.34375H4.21875V6.39844H3.33984C3.04857 6.39844 2.8125 6.63451 2.8125 6.92578C2.8125 7.21706 3.04857 7.45312 3.33984 7.45312H4.21875V8.50781H3.69141C3.40013 8.50781 3.16406 8.74388 3.16406 9.03516C3.16406 9.32643 3.40013 9.5625 3.69141 9.5625H4.21875V10.6172H3.33984C3.04857 10.6172 2.8125 10.8533 2.8125 11.1445C2.8125 11.4358 3.04857 11.6719 3.33984 11.6719H4.21875V12.7266H3.69141C3.40013 12.7266 3.16406 12.9626 3.16406 13.2539C3.16406 13.5452 3.40013 13.7812 3.69141 13.7812H4.21875V14.3086C4.21875 14.5999 4.45482 14.8359 4.74609 14.8359C5.03737 14.8359 5.27344 14.5999 5.27344 14.3086V13.7812H6.32812V14.6602C6.32812 14.9514 6.56419 15.1875 6.85547 15.1875C7.14674 15.1875 7.38281 14.9514 7.38281 14.6602V13.7812H8.4375V14.3086C8.4375 14.5999 8.67357 14.8359 8.96484 14.8359C9.25612 14.8359 9.49219 14.5999 9.49219 14.3086V13.7812H10.5469V14.6602C10.5469 14.9514 10.7829 15.1875 11.0742 15.1875C11.3655 15.1875 11.6016 14.9514 11.6016 14.6602V13.7812H12.6562V14.3086C12.6562 14.5999 12.8923 14.8359 13.1836 14.8359C13.4749 14.8359 13.7109 14.5999 13.7109 14.3086V13.7812H14.7656V14.6602C14.7656 14.9514 15.0017 15.1875 15.293 15.1875C15.5842 15.1875 15.8203 14.9514 15.8203 14.6602V13.7812H16.9453V16.9453Z"
        fill="#909090"
      />
      <path
        d="M15.6558 4.30733L12.4917 2.19795C12.3146 2.07985 12.0839 2.07985 11.9067 2.19795L8.74265 4.30733C8.59598 4.40511 8.50781 4.56976 8.50781 4.74609V8.96484C8.50781 9.25612 8.74388 9.49219 9.03516 9.49219H15.3633C15.6544 9.49219 15.8906 9.25612 15.8906 8.96484V4.74609C15.8906 4.56976 15.8025 4.40511 15.6558 4.30733ZM12.7266 8.4375H11.6719V6.67969H12.7266V8.4375ZM14.8359 8.4375H13.7812V6.15234C13.7812 5.86107 13.5452 5.625 13.2539 5.625H11.1445C10.8533 5.625 10.6172 5.86107 10.6172 6.15234V8.4375H9.5625V5.02831L12.1992 3.27049L14.8359 5.02831V8.4375Z"
        fill="#909090"
      />
      <path
        d="M17.4727 0H6.92578C6.63451 0 6.39844 0.236069 6.39844 0.527344V11.0742C6.39844 11.3655 6.63451 11.6016 6.92578 11.6016H17.4727C17.7639 11.6016 18 11.3655 18 11.0742V0.527344C18 0.236069 17.7639 0 17.4727 0ZM16.9453 10.5469H7.45312V1.05469H16.9453V10.5469Z"
        fill="#909090"
      />
    </svg>
  );
};

export default IconArea;
