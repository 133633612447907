import { getIndicators } from 'src/services/governanceService';
import { KPI_INDICATORS_TITLE } from 'src/consts/fundsSpotlightIndicators';
import { updateIndicatorInfo } from './updateIndicatorInfo';
import { fundIndicatorsDefaultValues } from 'src/views/dashboard/consts/fundIndicatorsDefaultValues';

export const getFundsSpotlightIndicators = async (fundId: number | null) => {
  if (!fundId) return fundIndicatorsDefaultValues;

  try {
    const { data: indicators } = await getIndicators(fundId);

    let indicatorsLocal = fundIndicatorsDefaultValues.slice();
    // TODO: get type from API
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    indicators?.map((indicator: any) => {
      const indicatorName = KPI_INDICATORS_TITLE[indicator.KPI] || '';

      if (indicatorName !== '') {
        indicatorsLocal = updateIndicatorInfo(indicatorsLocal, indicatorName, indicator);
      }
    });

    return indicatorsLocal;
  } catch (error) {
    console.log('fundsSpotlightIndicatorsData error', error);

    return fundIndicatorsDefaultValues;
  }
};
