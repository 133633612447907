/* eslint-disable @typescript-eslint/no-unused-vars */
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { IconButton, Button, Divider } from '@mui/material';
import { IconClose } from 'src/uiLibrary/icons/IconClose';
import AssetCard from 'src/components/AssetCard';
import { Loader } from 'src/components/common/Loader';
import { forwardRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Asset, Fund, FundAsset } from 'src/types/Funds';
import { QueryError } from 'src/components/common/QueryError';
import { KPIValueType } from 'src/models/partnershipModel';
import { formatKpiValue } from 'src/utils/formatKpiValue';
import { useFundById, useFundKpi } from 'src/hooks/react-query/funds';
import { FundKpi } from 'src/consts/fundKpi';

const stripHtmlTags = (input: string) => {
  return input?.replace(/<\/?[^>]+(>|$)/g, '');
};

interface FundSlideInfoProps {
  handleClose: () => void;
  viewDetailHandler: (id: number) => void;
  fundId: number | null;
}

export const FundSlideInfo = forwardRef<HTMLDivElement, FundSlideInfoProps>(
  ({ handleClose, viewDetailHandler, fundId }, ref) => {
    const navigate = useNavigate();

    const { data: fundInfo, isLoading, isError } = useFundById(fundId);

    const { data: kpiData, isLoading: isKpiDataLoading, isError: isKpiDataError } = useFundKpi(fundId);
    const fund: Fund = useMemo(() => fundInfo?.fund ?? ({} as Fund), [fundInfo]);
    const assets: FundAsset[] = useMemo(() => fundInfo?.assets ?? [], [fundInfo]);

    const viewAssetHandler = ({ id, KpiRoleParam, ExternalId }: Asset) => {
      if (KpiRoleParam && ExternalId) {
        navigate(`/${fund.name}/${fund.id}/asset-details/${id}/${KpiRoleParam}/${ExternalId}`);
      } else {
        navigate(`/${fund.name}/${fund.id}/asset-details/${id}`);
      }
    };

    if (isLoading || isKpiDataLoading)
      return (
        <div className="fund-overview" ref={ref}>
          <Loader />
        </div>
      );

    if (isError || isKpiDataError)
      return (
        <div className="fund-overview" ref={ref}>
          <QueryError />
        </div>
      );

    const visibleAssets = assets.filter(({ asset: { IncludeInFundOverview } }) => IncludeInFundOverview);

    return (
      <div className="fund-overview" ref={ref}>
        <div className="close-icon">
          <IconButton onClick={handleClose} size="large">
            <IconClose />
          </IconButton>
        </div>
        <div className="overview-row theam-black">
          <div className={'col-lg-3 fund-overview-margin'}>
            <h3 className="fund-title">{fund.name}</h3>
            <div>
              <p>{stripHtmlTags(fund.description)}</p>
            </div>
            <div className="fund-values">
              <p className="field-text">Total invested amount</p>
              <span className="fund-percentage">
                <h3>
                  {formatKpiValue(
                    kpiData?.find(item => item.SubTitle === FundKpi.TotalInvestedAmount)?.Value,
                    KPIValueType.CURRENCY,
                  )}
                </h3>
                <p className="p-grey">0.0%</p> {/* TODO: BE pending */}
              </span>
              <Divider className="divider" />
              <p className="field-text">Total estimated return </p>
              <span className="fund-percentage">
                <h3>{formatKpiValue(kpiData?.find(item => item.SubTitle === FundKpi.TotalEstimatedReturn)?.Value)}</h3>
                <p className="p-grey">0.0%</p> {/* TODO: BE pending */}
              </span>
              <Divider className="divider" />
              <p className="field-text">Average cap rate </p>
              <span className="fund-percentage">
                <h3>{formatKpiValue(kpiData?.find(item => item.SubTitle === FundKpi.AverageCapRate)?.Value)}</h3>
                <p className="p-grey">0.0%</p> {/* TODO: BE pending */}
              </span>
            </div>
            <Divider orientation="vertical" flexItem />
          </div>
          <Divider orientation="vertical" flexItem />

          <div className={'flex-grow-1 fund-overview-margin'}>
            <h3 className="investment-title">Assets</h3>
            <div className="asset-grid asset-grid-one-line">
              {visibleAssets.map(({ asset }) => {
                return (
                  <AssetCard key={asset.id} fund={fund} asset={asset} clickHandler={() => viewAssetHandler(asset)} />
                );
              })}
              {!visibleAssets.length && <p>There are no Assets for the selected Fund.</p>}
            </div>

            <div className="view-details-button button-flex-end">
              <Button
                disableElevation
                onClick={() => viewDetailHandler(fund.id)}
                endIcon={<ArrowForwardIcon className="arrow-icon" />}
                className="view-details-items"
              >
                <div>
                  <h4>View Fund details</h4>
                </div>
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  },
);
