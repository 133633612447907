import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import useSlideAnimation from 'src/hooks/animation/useSlideAnimation';
import { currentFormatNoDollarSign } from 'src/views/utils';
import BackgroundRectangles from 'src/components/common/BackgroundRectangles/BackgroundRectangles';
import { Fund } from 'src/types/Funds';
import { useProtected } from 'src/hooks/useProtected';
import { Permission } from 'src/consts/permissions';

interface FundsDetailsSlideComponentProps {
  funds: Fund[];
  selectedFundsSlide: number;
  handleSelectSlide: (slideIndex: number, event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const FundsDetailsSlideComponent = ({
  funds,
  selectedFundsSlide,
  handleSelectSlide,
}: FundsDetailsSlideComponentProps) => {
  const { showFundsInformation, selectedSlidePage } = useSlideAnimation(selectedFundsSlide, funds.length);

  const [selectedFund, setSelectedFund] = useState<Fund>(funds[selectedSlidePage]);

  useEffect(() => {
    setSelectedFund(funds[selectedSlidePage]);
  }, [funds, selectedSlidePage]);

  const isFundDetailsSlideProtected = useProtected({
    permissions: [Permission.MyFunds],
  });

  if (!selectedFund) return <></>;

  const { name, description, currentValue, investedValue, id } = selectedFund;

  const handleChangeFund = (alight: 'prev' | 'next') => {
    if (!funds) return;

    const fundsCount = funds.length;

    const currentIndex =
      (funds.findIndex((_, index) => index === selectedSlidePage) + (alight === 'next' ? 1 : -1) + fundsCount) %
      fundsCount;

    handleSelectSlide(currentIndex);
  };

  if (!funds)
    return (
      <div className="funds-details-slide-component">
        <p>Loading funds...</p>
        <BackgroundRectangles />
      </div>
    );

  if (!funds.length)
    return (
      <div className="funds-details-slide-component">
        <p>There are no funds available.</p>
        <BackgroundRectangles />
      </div>
    );

  return (
    <div className="funds-details-slide-component">
      <div className="prev-area" onClick={() => handleChangeFund('prev')} />
      <div className={`funds-slide ${showFundsInformation}`}>
        <div className="slide-title">
          <p>Funds</p>
        </div>
        <div className="slide-body">
          <h2>{name}</h2>
          <div className="fund-description">
            <h4 dangerouslySetInnerHTML={{ __html: description }}></h4>
          </div>
        </div>
        <div className="slide-values">
          <div className="slide-values-data">
            <h4>Current value</h4>
            <h2>{currentValue ? `$ ${currentFormatNoDollarSign(currentValue)}` : '-'}</h2>
          </div>
          <div className="slide-values-data">
            <h4>Invested value</h4>
            <h2>{investedValue ? `$ ${currentFormatNoDollarSign(investedValue)}` : '-'}</h2>
          </div>
        </div>
      </div>
      <div className="funds-actions">
        <div className="funds-actions-slide-selector">
          {funds?.map(({ id }, index) => (
            <button
              key={id}
              value={index}
              className={classNames('selector', { active: selectedSlidePage === index })}
              onClick={event => handleSelectSlide(index, event)}
            />
          ))}
        </div>
        {isFundDetailsSlideProtected && <Link to={`/my-funds/detail/${id}`}>View details &gt;</Link>}
      </div>
      <BackgroundRectangles />
      <div className="next-area" onClick={() => handleChangeFund('next')} />
    </div>
  );
};
