import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import InfiniteScroll from 'react-infinite-scroll-component';
import NotificationItem from './notificationItem';
import { NotificationData } from '../../../models/notificationModels';
import IconNoRecords from '../../../uiLibrary/icons/IconNoRecords';
const NotificationBarComponent = ({
  notifications,
  hasNextPage,
  getNotificationsHandler,
  loadingVisible,
  isPerfectScrollBar,
  handleNotificationClose,
}: NotificationBarComponentProps) => {
  const navigate = useNavigate();

  const notificationNavigateHandler = () => {
    if (handleNotificationClose) {
      handleNotificationClose();
    }
    navigate('/notifications');
  };
  return (
    <>
      <div className={isPerfectScrollBar ? 'notification-list-wrap' : 'notification-list-wrap h-100'}>
        {notifications && notifications.length > 0 ? (
          isPerfectScrollBar ? (
            <PerfectScrollbar
              options={{
                wheelPropagation: false,
                suppressScrollX: true,
              }}
              id="scrollableDiv"
            >
              <InfiniteScroll
                dataLength={notifications.length}
                next={getNotificationsHandler}
                hasMore={hasNextPage}
                loader={<></>}
                scrollableTarget="scrollableDiv"
              >
                {notifications.map((notification: NotificationData) => (
                  <NotificationItem
                    key={notification.id}
                    id={notification.id}
                    body={notification.Body}
                    notificationType={notification.NotificationType}
                    isRead={notification.IsRead}
                    creationDateTime={notification.CreationDateTime}
                    slug={notification.Slug}
                    userDetailId={notification.UserDetailId}
                    handleNotificationClose={handleNotificationClose}
                  />
                ))}
              </InfiniteScroll>
            </PerfectScrollbar>
          ) : (
            <InfiniteScroll
              dataLength={notifications.length}
              next={getNotificationsHandler}
              hasMore={hasNextPage}
              loader={<></>}
            >
              {notifications.map((notification: NotificationData) => (
                <NotificationItem
                  key={notification.id}
                  id={notification.id}
                  body={notification.Body}
                  notificationType={notification.NotificationType}
                  isRead={notification.IsRead}
                  creationDateTime={notification.CreationDateTime}
                  slug={notification.Slug}
                  userDetailId={notification.UserDetailId}
                  handleNotificationClose={handleNotificationClose}
                />
              ))}
            </InfiniteScroll>
          )
        ) : (
          !loadingVisible && (
            <div className="no-search" style={{ textAlign: 'center' }}>
              <IconNoRecords />
              <p>No Notification Found.</p>
            </div>
          )
        )}
      </div>
      {!!isPerfectScrollBar && !!notifications && notifications.length > 0 && (
        <div className="notify-view-all">
          <Button onClick={notificationNavigateHandler}>
            <strong>View All</strong>
          </Button>
        </div>
      )}
    </>
  );
};
interface NotificationBarComponentProps {
  notifications: NotificationData[];
  hasNextPage: boolean;
  getNotificationsHandler: () => void;
  loadingVisible: boolean;
  isPerfectScrollBar?: boolean;
  handleNotificationClose?: () => void;
}
export default NotificationBarComponent;
