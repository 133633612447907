import React from 'react';
import { Room } from '@mui/icons-material';

interface MarkerProps {
  lat: number;
  lng: number;
}

export default function Marker(props: MarkerProps) {
  return (
    <div {...props} style={{ position: 'absolute', transform: 'translate(-50%, -50%)' }}>
      <Room color="primary" fontSize="large" />
    </div>
  );
}
