import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'src/context/ShackbarContext';
import { verifyOtp } from 'src/services/loginService';
import { OtpVerifyPayload } from 'src/types/auth';

export const useVerifyOtp = () => {
  const { showErrorSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: (body: OtpVerifyPayload) => {
      return verifyOtp(body);
    },
    onSuccess: async () => {
      console.log('Verified is completed');
    },
    onError: ({ response }) => {
      showErrorSnackbar(response?.data?.message);
    },
  });
};
