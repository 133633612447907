import React from 'react';

const IconBuilding = () => {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none">
      <path
        d="M18.144 3.72578L9.81065 0.392448C9.68415 0.341751 9.54714 0.322852 9.41164 0.337409C9.27613 0.351966 9.14627 0.399534 9.03342 0.475945C8.92057 0.552356 8.82819 0.655277 8.76436 0.775689C8.70053 0.896102 8.66721 1.03033 8.66732 1.16661V4.49995H1.16732C0.946304 4.49995 0.734342 4.58775 0.578062 4.74403C0.421782 4.90031 0.333984 5.11227 0.333984 5.33328V17.8333C0.333984 18.0543 0.421782 18.2663 0.578062 18.4225C0.734342 18.5788 0.946304 18.6666 1.16732 18.6666H17.834C18.055 18.6666 18.267 18.5788 18.4232 18.4225C18.5795 18.2663 18.6673 18.0543 18.6673 17.8333V4.49995C18.6674 4.33338 18.6177 4.1706 18.5244 4.03261C18.4311 3.89462 18.2986 3.78774 18.144 3.72578ZM2.00065 6.16662H8.66732V16.9999H2.00065V6.16662ZM10.334 16.9999V2.39745L17.0006 5.06412V6.16662H12.0007V7.83328H17.0006V9.49995H12.0007V11.1666H17.0006V12.8333H12.0007V14.4999H17.0006V16.9999H10.334ZM5.33398 9.49995H7.83398V11.1666H5.33398V9.49995ZM2.83398 9.49995H4.50065V11.1666H2.83398V9.49995ZM5.33398 6.99995H7.83398V8.66662H5.33398V6.99995ZM2.83398 6.99995H4.50065V8.66662H2.83398V6.99995ZM5.33398 11.9999H7.83398V13.6666H5.33398V11.9999ZM2.83398 11.9999H4.50065V13.6666H2.83398V11.9999ZM5.33398 14.4999H7.83398V16.1666H5.33398V14.4999ZM2.83398 14.4999H4.50065V16.1666H2.83398V14.4999Z"
        fill="black"
      />
    </svg>
  );
};

export default IconBuilding;
