import AccountManagerDetailsComponent from './accountManagerDetailsComponent';

import UserDetailsComponent from './userDetailsComponent';
import { AccountManagerDetails, UserProfileDetails } from '../../models/profileModel';

const MyProfileComponent = ({
  profileDetails,
  handleEditProfileOnClick,
  accountManagerList,
}: MyProfileComponentProps) => {
  return (
    <div className="my-profile-body-container">
      <UserDetailsComponent userDetails={profileDetails} handleEditProfileOnClick={handleEditProfileOnClick} />
      {/* {isUserGP && <BankingInformationComponent handleBankingInformationOnClick={handleBankingInformationOnClick} />} */}
      <AccountManagerDetailsComponent accountManagerList={accountManagerList} />
    </div>
  );
};

interface MyProfileComponentProps {
  isUserGP: boolean;
  handleBankingInformationOnClick: () => void;
  profileDetails: UserProfileDetails | null;
  handleEditProfileOnClick: () => void;
  accountManagerList: AccountManagerDetails[];
}

export default MyProfileComponent;
