import React, { useState } from 'react';
import { FormControl, FormLabel, Grid, IconButton, MenuItem, Select } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { UserFilterForm } from 'src/models/frontendUserModels';
import { Controller, useForm } from 'react-hook-form';
import { RoleItem } from 'src/models/rolesModels';
import { SearchFilters } from 'src/components/common/SearchFilters';

export const FilterComponent = ({ handleFilterChange, filterRole, filterStatus, roleList }: FilterComponentProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [openFilter, setOpenFilter] = useState(false);
  const id = openFilter ? 'simple-popover' : undefined;
  const { control, handleSubmit, getValues, reset } = useForm<UserFilterForm>({
    defaultValues: {
      status: '1',
      role: 'all',
    },
  });

  const onFilter = (payload: UserFilterForm) => {
    handleFilterChange(payload.status, payload.role);
  };

  const clearFilter = () => {
    reset();
    handleFilterChange(getValues().status, getValues().role);
    handleCloseFilter();
  };

  const handleOpenFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setAnchorEl(null);
    setOpenFilter(false);
  };

  return (
    <Grid container spacing={0} justifyContent="flex-end">
      <Grid item>
        <IconButton aria-describedby={id} onClick={handleOpenFilter}>
          <FilterListIcon />
          {(!!filterStatus || !!filterRole) && <small></small>}
        </IconButton>
        <SearchFilters
          anchorEl={anchorEl}
          onFilter={onFilter}
          clearFilter={clearFilter}
          handleCloseFilter={handleCloseFilter}
          openFilter={openFilter}
          handleSubmit={handleSubmit}
        >
          <Grid item xs={12} className="from-row1">
            <Controller
              name="status"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth variant="outlined" className="formControlFilter">
                  <FormLabel id="account_status_filter" className="formControlLabel">
                    Account Status
                  </FormLabel>
                  <Select labelId="account_status_filter" className="formControlSelect" {...field}>
                    <MenuItem value="all">All</MenuItem>
                    <MenuItem value={'1'}>Active</MenuItem>
                    <MenuItem value={'0'}>Inactive</MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} className="from-row1">
            <Controller
              name="role"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth variant="outlined" className="formControlFilter">
                  <FormLabel id="role_filter" className="formControlLabel">
                    Role
                  </FormLabel>
                  <Select labelId="role_filter" className="formControlSelect" {...field}>
                    <MenuItem value="all">All</MenuItem>
                    {roleList?.map(item => {
                      return (
                        <MenuItem value={item.Name} key={item.id}>
                          {item.Name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
        </SearchFilters>
      </Grid>
    </Grid>
  );
};

interface FilterComponentProps {
  handleFilterChange: (status: string, role: string) => void;
  filterStatus: 0 | 1 | undefined;
  filterRole: string | undefined;
  roleList?: RoleItem[];
}
