import IconAsset from '../uiLibrary/icons/IconAsset';
import IconBuilding from '../uiLibrary/icons/IconBuilding';

export interface FolderData {
  id: number | string;
  title: string;
  subtitle: string;
}

type FolderCardProps = {
  information: FolderData;
  onClick: (value: number | string) => void;
  type?: 'fund' | 'asset';
};

const FolderCard = ({ information, onClick, type = 'fund' }: FolderCardProps) => (
  // eslint-disable-next-line no-console
  <div className="folder-card" onClick={() => onClick(information.id)}>
    <div className="card-icon">
      {type === 'fund' ? (
        <>
          <IconBuilding /> <p>FND</p>
        </>
      ) : (
        <>
          <IconAsset /> <p>AST</p>
        </>
      )}
    </div>
    <div className="card-data">
      <div className="card-title-container">
        <h3>{information.title}</h3>
      </div>
      <h5>{information.subtitle}</h5>
    </div>
  </div>
);

export default FolderCard;
