import { useEffect } from 'react';
import { Modal, ModalProps } from 'src/components/common';
import { CreateFolderForm } from 'src/components/documents/CreateFolderForm/CreateFolderForm';
import { useCreateDocumentsFolder } from 'src/hooks/react-query/documents';

type CreateNewFolderModalContainerProps = {
  open: ModalProps['open'];
  onClose: () => void;
  fundId: number;
};

export const CreateNewFolderModal = ({ open, onClose, fundId }: CreateNewFolderModalContainerProps) => {
  const {
    mutate: createFolder,
    isLoading: isCreateLoading,
    isSuccess: isCreateFolderSuccess,
  } = useCreateDocumentsFolder();

  useEffect(() => {
    if (isCreateFolderSuccess) {
      onClose();
    }
  }, [isCreateFolderSuccess]);

  return (
    <Modal title="Create new custom folder" open={open} handleClose={onClose} loading={isCreateLoading}>
      <CreateFolderForm
        onSubmit={({ name }) => {
          createFolder({ name, fundId });
        }}
        onCancel={onClose}
        padding="16px 0 0 0"
      />
    </Modal>
  );
};
