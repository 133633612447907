import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AddRoleComponent } from './addRoleComponent';
import {
  useFundPrivileges,
  usePrivileges,
  useRoleById,
  useRoleCreate,
  useRoleUpdate,
} from 'src/hooks/react-query/roles';
import { Loader } from 'src/components/common/Loader';
import { RoleDTO, RoleForm } from './validations';
import { FundPrivilege, Privilege } from 'src/types/Roles';
import { getCheckedFundPrivileges, getCheckedIds, getIdsWithPrivilege } from './utils/getPrivilegeIds';
import { Permission } from 'src/consts/permissions';
import { useFunds } from 'src/hooks/react-query/funds';

const validSlugs = Object.values(Permission);

export type FundPrivilegeType = {
  id: number;
  name: string;
  fundPrivileges: FundPrivilege[];
};

export const AddRoleContainer = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [privilegesState, setPrivilegesState] = useState<Privilege[]>([]);
  const [fundPrivilegesState, setFundPrivilegesState] = useState<FundPrivilegeType[]>([]);

  const { data: funds, isLoading } = useFunds();
  const { data: privileges, isLoading: isPrivilegesLoading } = usePrivileges();
  const { data: fundPrivileges, isLoading: isFundPivilegesLoading } = useFundPrivileges();
  const { data: roleDetails, isLoading: isRoleDetailLoading } = useRoleById(params.roleId);

  const { mutateAsync: createRole, isLoading: isCreating } = useRoleCreate(() => navigate('/roles'));
  const { mutateAsync: updateRole, isLoading: isUpdating } = useRoleUpdate(params.roleId, () => navigate('/roles'));

  const privilegeList: Privilege[] = useMemo(() => (privileges ? [...privileges.privilege] : []), [privileges]);

  const handleEdit = (formData: RoleDTO) => {
    if (params.roleId) {
      updateRole(formData);
    }
  };

  const handleSave = (formData: RoleDTO) => {
    createRole(formData);
  };

  const onSubmit = async (values: RoleForm) => {
    const body = {
      Name: values.Name,
      IsActive: 1,
      Privileges_Id: getCheckedIds(privilegesState),
      FundPrivileges: getCheckedFundPrivileges(fundPrivilegesState),
    };
    if (params.roleId) {
      return handleEdit(body);
    }
    handleSave(body);
  };

  useEffect(() => {
    if (privilegeList) {
      const selectedIds = getIdsWithPrivilege(roleDetails?.privilege ?? []);
      const updatedState = privilegeList.map(parent => {
        const isParentChecked = selectedIds.includes(parent.id);
        const updatedChildren = parent.childPrivilege.map(child => ({
          ...child,
          checked: selectedIds.includes(child.id),
        }));
        const isAllChildChecked = !!updatedChildren.length && updatedChildren.every(child => child.checked);
        return {
          ...parent,
          checked: isParentChecked || isAllChildChecked,
          childPrivilege: updatedChildren,
        };
      });
      setPrivilegesState(updatedState);
    }
  }, [roleDetails, privilegeList]);

  useEffect(() => {
    if (fundPrivileges && funds?.length) {
      const getSelectedIds = (fundId: number) => {
        return (
          roleDetails?.fund_privilege
            ?.filter(item => item['Fund_Id'] === fundId)
            .map(item => item['fundprivileges_id']) ?? []
        );
      };

      const selectedFunds = roleDetails?.fund_privilege?.map(item => item['Fund_Id']) ?? [];

      const updatePrivilegeChecked = (privilege: FundPrivilege, ids: number[]): FundPrivilege => {
        privilege.checked = ids.includes(privilege.id);

        if (privilege.ChildPrivilege && privilege.ChildPrivilege.length > 0) {
          privilege.ChildPrivilege = privilege.ChildPrivilege.map(child => updatePrivilegeChecked(child, ids));
        }

        return structuredClone(privilege);
      };

      setFundPrivilegesState(
        funds?.map(fund => ({
          ...fund,
          fundPrivileges: selectedFunds.includes(fund.id)
            ? (structuredClone(fundPrivileges) as FundPrivilege[]).map(privilege => {
                return updatePrivilegeChecked(privilege, getSelectedIds(fund.id));
              })
            : (structuredClone(fundPrivileges) as FundPrivilege[]),
        })),
      );
    }
  }, [funds, fundPrivileges, roleDetails]);

  const filteredPrivileges: Privilege[] = useMemo(
    () =>
      privilegesState
        .map(parent => {
          const filteredChildren = parent.childPrivilege.filter(child => validSlugs.includes(child.Slug as Permission));
          if (validSlugs.includes(parent.Slug as Permission) || filteredChildren.length > 0) {
            return {
              ...parent,
              childPrivilege: filteredChildren,
            };
          }
          return null as unknown as Privilege;
        })
        .filter(parent => parent !== null),
    [privilegesState],
  );

  const getGeneralRoleSettings = () => {
    const privilege = filteredPrivileges;

    const handlePatentChange = (parentId: number) => {
      setPrivilegesState(prevState => {
        return prevState.map(parent =>
          parent.id === parentId
            ? {
                ...parent,
                checked: !parent.checked,
                ...(parent.checked && {
                  childPrivilege: parent.childPrivilege.map(child => ({
                    ...child,
                    checked: !parent.checked,
                  })),
                }),
              }
            : parent,
        );
      });
    };

    const handleChildChange = (parentId: number, childId: number) => {
      setPrivilegesState(prevState => {
        return prevState.map(parent => {
          if (parent.id === parentId) {
            const updatedChildren = parent.childPrivilege.map(child =>
              child.id === childId ? { ...child, checked: !child.checked } : child,
            );

            return {
              ...parent,
              checked: parent.checked,
              childPrivilege: updatedChildren,
            };
          }
          return parent;
        });
      });
    };

    return {
      privileges: privilege,
      parentChange: handlePatentChange,
      childChange: handleChildChange,
    };
  };

  const getFundRoleSetting = () => {
    const privileges = fundPrivilegesState;

    const updateChildPrivilegesOnUncheck = (privileges: FundPrivilege[], parentChecked: boolean) => {
      if (!parentChecked) {
        return privileges?.map(privilege => {
          const updatedPrivilege = { ...privilege, checked: false };
          if (privilege.ChildPrivilege && privilege?.ChildPrivilege.length > 0) {
            updatedPrivilege.ChildPrivilege = updateChildPrivilegesOnUncheck(privilege.ChildPrivilege, false);
          }
          return updatedPrivilege;
        });
      }
      return privileges;
    };

    const handleFundChange = (privileges: FundPrivilege[], childId: number): FundPrivilege[] => {
      return privileges?.map(privilege => {
        if (privilege.id === childId) {
          const newChecked = !privilege.checked;

          return {
            ...privilege,
            checked: newChecked,
            ChildPrivilege: newChecked
              ? privilege.ChildPrivilege
              : updateChildPrivilegesOnUncheck(privilege.ChildPrivilege, false),
          };
        }
        if (privilege.ChildPrivilege && privilege.ChildPrivilege.length > 0) {
          return {
            ...privilege,
            ChildPrivilege: handleFundChange(privilege.ChildPrivilege, childId),
          };
        }
        return privilege;
      });
    };

    const handleChange = (id: number, fundId: number) => {
      setFundPrivilegesState(funds => {
        return funds.map(fund => {
          if (fund.id === fundId) {
            return {
              ...fund,
              fundPrivileges: handleFundChange(fund.fundPrivileges, id),
            };
          }

          return fund;
        });
      });
    };

    return {
      privileges,
      handleChange,
    };
  };

  const GeneralRoleSettings = getGeneralRoleSettings();
  const FundRoleSetting = getFundRoleSetting();

  if (
    isLoading ||
    isPrivilegesLoading ||
    isCreating ||
    isUpdating ||
    isFundPivilegesLoading ||
    (params.roleId && isRoleDetailLoading)
  )
    return <Loader />;

  return (
    <>
      {params.roleId ? (
        <AddRoleComponent
          onSubmit={onSubmit}
          roleDetails={roleDetails}
          privilegeList={GeneralRoleSettings.privileges}
          onParentChange={GeneralRoleSettings.parentChange}
          onChildChange={GeneralRoleSettings.childChange}
          onFundPrivilegeChange={FundRoleSetting.handleChange}
          fundPrivilegesList={FundRoleSetting.privileges}
        />
      ) : (
        <AddRoleComponent
          onSubmit={onSubmit}
          privilegeList={GeneralRoleSettings.privileges}
          onParentChange={GeneralRoleSettings.parentChange}
          onChildChange={GeneralRoleSettings.childChange}
          onFundPrivilegeChange={FundRoleSetting.handleChange}
          fundPrivilegesList={FundRoleSetting.privileges}
          isNew
        />
      )}
    </>
  );
};
