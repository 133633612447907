import { Grid } from '@mui/material';

export const SearchFilterBarContainer = ({ filterComponent, searchComponent }: SearchFilterBarContainerProps) => {
  return (
    <Grid container spacing={0} justifyContent="flex-end" alignItems="center">
      {filterComponent && <Grid item>{filterComponent}</Grid>}
      {searchComponent && <Grid item>{searchComponent}</Grid>}
    </Grid>
  );
};
interface SearchFilterBarContainerProps {
  filterComponent?: React.ReactNode;
  searchComponent?: React.ReactNode;
}
