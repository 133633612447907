import React from 'react';

const IconRedirect = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="32" height="32" rx="8" fill="white" />
      <path
        transform="translate(9, 9)"
        d="M13.6673 7.00065V13.0007C13.6673 13.1775 13.5971 13.347 13.4721 13.4721C13.347 13.5971 13.1775 13.6673 13.0007 13.6673H1.00065C0.82384 13.6673 0.654271 13.5971 0.529246 13.4721C0.404222 13.347 0.333984 13.1775 0.333984 13.0007V1.00065C0.333984 0.82384 0.404222 0.654271 0.529246 0.529246C0.654271 0.404222 0.82384 0.333984 1.00065 0.333984H7.00065C7.17746 0.333984 7.34703 0.404222 7.47205 0.529246C7.59708 0.654271 7.66732 0.82384 7.66732 1.00065C7.66732 1.17746 7.59708 1.34703 7.47205 1.47206C7.34703 1.59708 7.17746 1.66732 7.00065 1.66732H1.66732V12.334H12.334V7.00065C12.334 6.82384 12.4042 6.65427 12.5292 6.52925C12.6543 6.40422 12.8238 6.33398 13.0007 6.33398C13.1775 6.33398 13.347 6.40422 13.4721 6.52925C13.5971 6.65427 13.6673 6.82384 13.6673 7.00065ZM13.2553 0.385318C13.1747 0.351521 13.0881 0.334072 13.0007 0.333984H9.66732C9.49051 0.333984 9.32094 0.404222 9.19591 0.529246C9.07089 0.654271 9.00065 0.82384 9.00065 1.00065C9.00065 1.17746 9.07089 1.34703 9.19591 1.47206C9.32094 1.59708 9.49051 1.66732 9.66732 1.66732H11.3913L6.52932 6.52932C6.46564 6.59082 6.41486 6.66438 6.37992 6.74571C6.34498 6.82705 6.32659 6.91453 6.32582 7.00305C6.32505 7.09157 6.34192 7.17936 6.37544 7.26129C6.40896 7.34322 6.45846 7.41765 6.52105 7.48025C6.58365 7.54284 6.65808 7.59234 6.74001 7.62586C6.82195 7.65939 6.90973 7.67625 6.99825 7.67548C7.08677 7.67472 7.17425 7.65632 7.25559 7.62139C7.33692 7.58645 7.41049 7.53566 7.47198 7.47198L12.334 2.60998V4.33398C12.334 4.5108 12.4042 4.68036 12.5292 4.80539C12.6543 4.93041 12.8238 5.00065 13.0007 5.00065C13.1775 5.00065 13.347 4.93041 13.4721 4.80539C13.5971 4.68036 13.6673 4.5108 13.6673 4.33398V1.00065C13.6669 0.913235 13.6495 0.826736 13.616 0.745984C13.5483 0.582707 13.4186 0.452978 13.2553 0.385318Z"
        fill="black"
      />
    </svg>
  );
};

export default IconRedirect;
