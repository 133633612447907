const IconDistributeCap = () => {
  return (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="60" height="60" rx="8" fill="white" fillOpacity="0.1" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5 21.5C18.5 19.8431 19.8431 18.5 21.5 18.5H24.5C26.1569 18.5 27.5 19.8431 27.5 21.5V25.5V26.5V27.5V32.5H32.5H33.5H34.5H38.5C40.1569 32.5 41.5 33.8431 41.5 35.5V38.5C41.5 40.1569 40.1569 41.5 38.5 41.5H34.5H33.5H32.5H27.5H26.5H25.5H21.5C19.8431 41.5 18.5 40.1569 18.5 38.5V34.5V33.5V32.5V27.5V26.5V25.5V21.5ZM32.5 39.5V34.5H27.5V39.5H32.5ZM34.5 39.5H38.5C39.0523 39.5 39.5 39.0523 39.5 38.5V35.5C39.5 34.9477 39.0523 34.5 38.5 34.5H34.5V39.5ZM25.5 27.5V32.5H20.5V27.5H25.5ZM25.5 34.5V39.5H21.5C20.9477 39.5 20.5 39.0523 20.5 38.5V34.5H25.5ZM25.5 21.5V25.5H20.5V21.5C20.5 20.9477 20.9477 20.5 21.5 20.5H24.5C25.0523 20.5 25.5 20.9477 25.5 21.5Z"
        fill="#909090"
      />
      <path
        d="M31 22.5H29.5C28.1193 22.5 27 23.6193 27 25V38C27 39.3807 28.1193 40.5 29.5 40.5H31C32.3807 40.5 33.5 39.3807 33.5 38V25C33.5 23.6193 32.3807 22.5 31 22.5Z"
        fill="white"
        stroke="#909090"
        strokeWidth="2"
      />
    </svg>
  );
};

export default IconDistributeCap;
