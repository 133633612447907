import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteFolder } from 'src/services/documentsService';
import { useSnackbar } from 'src/context/ShackbarContext';
import { QueryKey } from 'src/consts/query';
import { AxiosError } from 'axios';
import { ApiError } from 'src/types/api';

export const useDeleteFolder = () => {
  const queryClient = useQueryClient();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: (folderId: number) => {
      return deleteFolder(folderId);
    },
    onSuccess: async response => {
      const folderName = response?.data?.data?.name ?? '';

      await queryClient.invalidateQueries({
        predicate: query => query.queryKey[0] === QueryKey.DocumentFolders,
      });
      await queryClient.invalidateQueries({
        predicate: query => query.queryKey[0] === QueryKey.Documents,
      });

      await showSuccessSnackbar(`The custom folder ${folderName} was successfully deleted.`);
    },
    onError: e => {
      showErrorSnackbar((e as AxiosError<ApiError>).response?.data.message);
    },
  });
};
