import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFundDocuments, useDeleteFundDocument } from 'src/hooks/react-query/documents';
import { getFundDetail } from 'src/services/fundsService';
import DocumentsFundDetailComponent from './documentsFundDetailComponent';
import { useQuery } from '@tanstack/react-query';
import { Loader } from 'src/components/common/Loader';
import { FundAsset } from 'src/types/Funds';
import ConfirmDialog from 'src/uiLibrary/modals/confirm-modal/confirmDialogContainer';
import { QueryKey } from 'src/consts/query';
import { useDocumentFolders } from 'src/hooks/react-query/documents';
import { CreateNewFolderModal } from './components/CreateNewFolderModal';
import { UpdateFolderModal } from './components/UpdateFolderModal';

type ConfirmDialogState = {
  id?: number;
  name?: string;
  open: boolean;
};

type UpdateFolderModalState = {
  open: boolean;
  folderId?: number;
  oldFolderName?: string;
};

const DocumentsFundDetailContainer = () => {
  const navigate = useNavigate();
  const params = useParams();
  const fundId = Number(params.fundId);

  const { data: fundInfo, isLoading } = useQuery({
    queryKey: [QueryKey.FundDetailsData, fundId],
    queryFn: () => getFundDetail(fundId),
  });

  const { data: fundDocuments, isLoading: isFundDocumentLoading } = useFundDocuments(fundId);
  const { folderList, isLoading: isDocumentsFoldersLoading } = useDocumentFolders(fundId);

  const { mutate: deleteFundDocument } = useDeleteFundDocument(fundId);

  const fund = fundInfo?.data?.fund;
  const assets: FundAsset[] = useMemo(() => fundInfo?.data?.assets ?? [], [fundInfo]);

  const [assetSearchTerm, setAssetSearchTerm] = useState<string>('');

  const [confirmDialogData, setConfirmDialogData] = useState<ConfirmDialogState>({
    open: false,
  });
  const [createFolderModalData, setCreateFolderModalData] = useState({
    open: false,
  });
  const [updateFolderModalData, setUpdateFolderModalData] = useState<UpdateFolderModalState>({
    open: false,
  });

  const handleInvestmentSearch = (payload: string) => {
    setAssetSearchTerm(payload);
  };

  const filteredAssets = useMemo(
    () =>
      assets.length
        ? assets.filter(({ asset }) => asset.Title.toLocaleLowerCase().includes(assetSearchTerm.toLocaleLowerCase()))
        : [],
    [assets, assetSearchTerm],
  );

  const handleFolderCardOnClick = (assetId: string | number) => {
    navigate(`/documents/fund/${fund?.id}/asset/${assetId}`);
  };

  const openCreateFolderModal = () => {
    setCreateFolderModalData({ open: true });
  };

  const openUpdateFolderModal = (folderId: number, oldFolderName: string) => {
    setUpdateFolderModalData({ open: true, folderId, oldFolderName });
  };

  const handleDeleteOnClick = async (documentId: number, documentName: string) => {
    setConfirmDialogData({ id: documentId, name: documentName, open: true });
  };

  const closeConfirmDialog = () => {
    setConfirmDialogData({
      open: false,
    });
  };

  const deleteDocument = async (documentId?: number) => {
    if (!documentId) return;

    await deleteFundDocument(documentId);
    await closeConfirmDialog();
  };

  if (isLoading || isFundDocumentLoading || isDocumentsFoldersLoading) return <Loader />;

  return (
    <>
      <ConfirmDialog
        isVisible={confirmDialogData.open}
        title={`Are you sure you want to delete the ${confirmDialogData.name ? confirmDialogData.name : 'document'} ?`}
        dialogContentText="You will not be able to restore the file."
        confirmButtonText="Delete"
        closeButtonText="Cancel"
        confirmModal={() => {
          deleteDocument(confirmDialogData.id);
        }}
        closeModal={closeConfirmDialog}
      />

      <CreateNewFolderModal
        open={createFolderModalData.open}
        onClose={() => {
          setCreateFolderModalData({ open: false });
        }}
        fundId={fundId}
      />

      <UpdateFolderModal
        open={updateFolderModalData.open}
        onClose={() => {
          setUpdateFolderModalData({ open: false });
        }}
        fundId={fundId}
        folderId={updateFolderModalData.folderId}
        oldFolderName={updateFolderModalData.oldFolderName}
      />

      <DocumentsFundDetailComponent
        fund={fund}
        assets={filteredAssets}
        documents={fundDocuments ?? []}
        folders={folderList ?? []}
        numberOfDocuments={fundDocuments?.length || 0}
        handleInvestmentSearch={handleInvestmentSearch}
        handleFolderCardOnClick={handleFolderCardOnClick}
        handleDeleteOnClick={handleDeleteOnClick}
        openCreateFolderModal={openCreateFolderModal}
        openUpdateFolderModal={openUpdateFolderModal}
      />
    </>
  );
};

export default DocumentsFundDetailContainer;
