import React from 'react';
import { useNavigate } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import WhiteLogo from '../../uiLibrary/icons/WhiteLogo';
import IconEyeOff from '../../uiLibrary/icons/IconEyeOff';
import IconEye from '../../uiLibrary/icons/IconEye';
import Popover from '@mui/material/Popover';
import IconInfo from '../../uiLibrary/icons/IconInfo';
import { IconClose } from '../../uiLibrary/icons/IconClose';
import { CreatePasswordForm } from '../../models/authModels';
import { Controller, useForm } from 'react-hook-form';
import { passwordValidator } from '../../uiLibrary/reusables/form-validators/formValidators';
import { FormHelperText } from '@mui/material';
import DashboardPhoto from '../../assets/images/dashboard.png';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

interface State {
  password: string;
  confirmpassword: string;
  showPassword: boolean;
  showConfirmPassword: boolean;
}
const CreateNewPasswordComponent = ({ onSubmit, isResetPassword }: CreatePasswordComponentProps) => {
  const navigate = useNavigate();
  const [values, setValues] = React.useState<State>({
    password: '',
    confirmpassword: '',
    showPassword: false,
    showConfirmPassword: false,
  });
  const { control, handleSubmit, watch } = useForm<CreatePasswordForm>({
    defaultValues: {
      Password: '',
      Confirm_password: '',
    },
  });

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleClickConfirmShowPassword = () => {
    setValues({
      ...values,
      showConfirmPassword: !values.showConfirmPassword,
    });
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const password = React.useRef({});
  password.current = watch('Password', '');

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className="login-wrapper">
      <PerfectScrollbar>
        <div className="inner-wrap">
          <div className="split-view-wrapper">
            <div className="col-md-6 col-lg-6 h-100 left-side">
              <div className="fromfield-wrap">
                <div className="login-header" style={{ cursor: 'pointer' }} onClick={() => navigate('/login')}>
                  <WhiteLogo />
                </div>
                <div className="content-input">
                  <h2 className="title">{isResetPassword ? 'Reset' : 'Create New'} Password</h2>
                  <p className="lead">Reset your password and login to your account</p>
                  <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                    <div className="from-row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <Controller
                          name="Password"
                          control={control}
                          rules={{
                            maxLength: {
                              value: 20,
                              message: 'Maximum length must be 20',
                            },
                            minLength: {
                              value: 8,
                              message: 'Minimum length must be 8',
                            },
                            pattern: passwordValidator,
                            required: 'Password is required.',
                          }}
                          render={({ field, fieldState }) => (
                            <FormControl fullWidth variant="outlined" className="formControl">
                              <FormLabel>
                                New Password <span className="require">*</span>
                              </FormLabel>
                              <OutlinedInput
                                {...field}
                                type={values.showPassword ? 'text' : 'password'}
                                color="info"
                                error={!!fieldState.error}
                                endAdornment={
                                  <>
                                    <InputAdornment position="end">
                                      <IconButton onClick={handleClickShowPassword} edge="end">
                                        {values.showPassword ? <IconEyeOff /> : <IconEye />}
                                      </IconButton>
                                    </InputAdornment>
                                    <InputAdornment position="end">
                                      <IconButton edge="end" aria-describedby={id} onClick={handleClick}>
                                        <IconInfo />
                                      </IconButton>
                                    </InputAdornment>
                                  </>
                                }
                              />

                              <Popover
                                className="popup-content"
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'left',
                                }}
                              >
                                <div className="pass-info-text">
                                  <div className="d-flex align-items-center">
                                    <p className="flex-grow-1 mb-0">Rules:</p>
                                    <IconButton onClick={handleClose}>
                                      <IconClose />
                                    </IconButton>
                                  </div>
                                  <ul>
                                    <li>(1) Min 8 characters</li>
                                    <li>(2) Max 20 characters</li>
                                    <li>
                                      (3) Should be a combination of at least one upper, one lower, one number and one
                                      special character
                                    </li>
                                  </ul>
                                </div>
                              </Popover>
                              {!!fieldState.error && (
                                <FormHelperText error={!!fieldState.error}>{fieldState.error.message}</FormHelperText>
                              )}
                            </FormControl>
                          )}
                        />
                      </div>
                      {!isResetPassword && (
                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <Controller
                            name="Confirm_password"
                            control={control}
                            rules={{
                              required: 'Password is required.',
                              validate: value => value === password.current || 'Both passwords must match',
                            }}
                            render={({ field, fieldState }) => (
                              <FormControl fullWidth variant="outlined" className="formControl">
                                <FormLabel>
                                  Confirm Password <span className="require">*</span>
                                </FormLabel>
                                <OutlinedInput
                                  type={values.showConfirmPassword ? 'text' : 'password'}
                                  {...field}
                                  error={!!fieldState.error}
                                  color="info"
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton onClick={handleClickConfirmShowPassword} edge="end">
                                        {values.showConfirmPassword ? <IconEyeOff /> : <IconEye />}
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                />
                                {!!fieldState.error && (
                                  <FormHelperText error={!!fieldState.error}>{fieldState.error.message}</FormHelperText>
                                )}
                              </FormControl>
                            )}
                          />
                        </div>
                      )}

                      <div className="col-lg-12 col-md-12 col-sm-12 btn-row">
                        <Button type="submit" color="info" variant="contained">
                          Submit
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="copywright">
                  <p>
                    © 2022 EXtrance Inc. All Rights Reserved.{' '}
                    <span style={{ cursor: 'pointer' }}>Terms & Conditions</span> |{' '}
                    <span style={{ cursor: 'pointer' }}>Privacy Policy</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="right-side">
              <div className="title-text">
                <h2>Welcome to the future of investment management.</h2>
                <p className="subtitle">
                  Extrance makes your workflow easier, more transparent and allows you to make decisions faster.
                </p>
              </div>
              <img className="dashboard-photo" alt="dashboard" src={DashboardPhoto}></img>
            </div>
          </div>
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default CreateNewPasswordComponent;
export interface CreatePasswordComponentProps {
  onSubmit: (formData: CreatePasswordForm) => void;
  isResetPassword?: boolean;
}
