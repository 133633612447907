import { Breadcrumbs, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import styles from './styles.module.scss';
import Badge, { BadgeStyle } from '../../components/common/Badge';
import CrudComponent from '../../components/common/CrudComponent';
import Table from '../../components/common/table';
import { formatDateNoTime, renderCurrency } from '../utils';
import { HistoricCapcall } from './capcallHistory';
import Button from '../../components/common/Button';
import { getFundOpportunity } from '../../services/governanceService';
import { UserType } from '../../models/authModels';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

const CapcallDetailsHistory = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [fundId, setFundId] = useState<number>(-1);
  const [capcallId, setCapcallId] = useState<number>(-1);
  const [fundOpp, setFundOpp] = useState<HistoricCapcall>();

  const location = useLocation();
  const fundName = location.state.fundName;

  // TODO: get type from API
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const parsePaymentBreakdown = (req: any) => {
    return {
      userId: req.users?.userId,
      capcallId: req.id,
      lpName: `${req.users?.FirstName} ${req.users?.LastName}`,
      status: req.PaymentStatus || 'Pending',
      asked: req.RequestedAmount,
      method: req.payment?.PaymentType || 'Pending',
      paymentDate: req.payment ? formatDateNoTime(req.payment?.CreationDateTime) : 'Pending',
      transactionId: req.RequestCode,
      paymentId: req.payment?.id,
    };
  };

  const userDetails = useSelector((state: RootState) => state.auth.userDetails);

  useEffect(() => {
    if (params && params.fundId) {
      const fundId = parseInt(params.fundId);
      setFundId(fundId);
    }
    if (params && params.capcallId) {
      const capcallId = parseInt(params.capcallId);
      setCapcallId(capcallId);
      getFundOpportunity(capcallId).then(response => {
        if (response && response.data && response.data.data.fundOpportunity) {
          const opp = response.data.data.fundOpportunity;
          const requests = response.data.data.fundRequestedUsers;
          const lpCoverage = response.data.data.lpCoverage;
          const gpRequest = requests.find(
            // TODO: get type from API
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (req: any) => req.users?.UserType === UserType.GP,
          );
          const data = {
            capcallId: opp.id,
            created: formatDateNoTime(opp.CreationDateTime),
            deadline: formatDateNoTime(opp.ClosingDate, true),
            status: opp.Status,
            called: opp.RequestedAmount,
            contributed: opp.InvestedAmount,
            lpCoverage: `${lpCoverage?.fulfilled || 0}/${lpCoverage?.total || 0} fulfilled`,
            files: opp.CapCallDoc,
            breakdown: [
              ...requests
                // TODO: get type from API
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .filter((req: any) => req.users?.UserType === UserType.LP)
                .map(parsePaymentBreakdown),
            ],
            gpBreakdown: gpRequest ? parsePaymentBreakdown(gpRequest) : null,
          };
          setFundOpp(data);
        }
      });
    }
  }, []);

  const parsedDataForTable = useMemo(() => {
    return fundOpp?.breakdown.map(transaction => {
      return {
        ...transaction,
        id: transaction.capcallId,
        asked: renderCurrency(transaction.asked),
        method: transaction.method,
        status: (
          <Badge
            badgeStyle={
              transaction.status === BreakdownStatusEnum.FULFILLED
                ? BadgeStyle.GREEN
                : transaction.status === BreakdownStatusEnum.UNCONFIRMED
                  ? BadgeStyle.YELLOW
                  : transaction.status === BreakdownStatusEnum.PENDING
                    ? BadgeStyle.GRAY
                    : BadgeStyle.MAGENTA
            }
            text={transaction.status}
          />
        ),
        files: (
          <div
            style={
              transaction.status === BreakdownStatusEnum.FULFILLED ||
              transaction.status === BreakdownStatusEnum.UNCONFIRMED
                ? { color: 'black' }
                : { color: '#b6b6b6', userSelect: 'none' }
            }
          >
            {transaction.status === BreakdownStatusEnum.FULFILLED ||
            transaction.status === BreakdownStatusEnum.UNCONFIRMED
              ? transaction.paymentDate
              : transaction.status === BreakdownStatusEnum.FAILED
                ? 'Failed'
                : 'Pending'}
          </div>
        ),
      };
    });
  }, [fundOpp]);

  const handleViewTransaction = () => {
    const requestId = fundOpp?.gpBreakdown?.capcallId;
    if (requestId) {
      navigate(`/release-payment/${requestId}`);
    }
  };

  return (
    <>
      <div className="content-wrap">
        <Breadcrumbs className="breadcrumb mb-15">
          {[
            <Link key="governance" color="inherit" to="/governance">
              Governance
            </Link>,
            <Link key={fundName} color="inherit" to={`/governance/detail/${fundId}`}>
              {fundName}
            </Link>,
            <Link
              key="governance"
              color="inherit"
              to={`/governance/detail/${fundId}/capcall-history`}
              state={{ fundName: fundName }}
            >
              Capital calls history
            </Link>,
            <Typography key="Review Payments">{capcallId}</Typography>,
          ]}
        </Breadcrumbs>
      </div>

      <div className="d-flex">
        <div className="title-and-subtitle-section">
          <h2>{fundName}</h2>
          <h4>Fund Level</h4>
        </div>
      </div>
      <div className={styles.splitRowSection}>
        <div className={styles.tableWrapper}>
          <CrudComponent
            title="LP payments breakdown"
            tableLayout={
              <Table
                columns={columns}
                data={parsedDataForTable || []}
                loading={false}
                onClick={row => {
                  if (row.method === 'Manual') {
                    navigate(`/manual-payment-status/${row.paymentId}`);
                  } else return null;
                }}
              />
            }
            filterComponent={
              <div
                style={{
                  display: 'grid',
                  placeContent: 'center',
                  height: '100%',
                }}
              >
                <p>Coming soon...</p>
              </div>
            }
          />
        </div>
        <div className={styles.generalInfoColumn}>
          <div className={styles.capcallGeneralInfoWrapper}>
            <div className={styles.generalInfoHeader}>
              <div className={styles.headerTitle}>Capcall general info</div>
              <div className={styles.statusBadgeWrapper}>
                <Badge
                  badgeStyle={fundOpp?.status === StatusEnum.OPEN ? BadgeStyle.GREEN : BadgeStyle.GRAY}
                  text={fundOpp?.status || ''}
                />
              </div>
            </div>
            <div className={styles.generalInfoCard}>
              <div className={styles.cardRow}>
                <div className={styles.cardRowItem}>
                  <div className={styles.itemLabel}>Created</div>
                  <div className={styles.itemContent}>{fundOpp?.created}</div>
                </div>
                <div className={styles.cardRowItem}>
                  <div className={styles.itemLabel}>Deadline</div>
                  <div className={styles.itemContent}>{fundOpp?.deadline}</div>
                </div>
              </div>

              <div className={styles.cardRow}>
                <div className={styles.cardRowItem}>
                  <div className={styles.itemLabel}>Called</div>
                  <div className={styles.itemContent}>{renderCurrency(fundOpp?.called || 0)}</div>
                </div>
                <div className={styles.cardRowItem}>
                  <div className={styles.itemLabel}>Contributed</div>
                  <div className={styles.itemContent}>{renderCurrency(fundOpp?.contributed || 0)}</div>
                </div>
              </div>

              <div className={styles.cardRow}>
                <div className={styles.cardRowItem}>
                  <div className={styles.itemLabel}>LP coverage</div>
                  <div className={styles.itemContent}>{fundOpp?.lpCoverage}</div>
                </div>
                <div className={styles.cardRowItem}>
                  <div className={styles.itemLabel}>Files</div>
                  <div className={styles.itemContent}>{fundOpp?.files ? 'Download' : 'None'}</div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.gpPaymentInfoCard}>
            <div className={styles.cardRow}>
              <div className={styles.cardRowItem}>
                <div className={styles.rowTitle}>GP capcall payment</div>
              </div>
              <div className={styles.cardRowItem}>
                <div className={styles.statusBadgeWrapper}>
                  <Badge
                    badgeStyle={
                      fundOpp?.gpBreakdown?.status !== BreakdownStatusEnum.PENDING ? BadgeStyle.GREEN : BadgeStyle.GRAY
                    }
                    text={fundOpp?.gpBreakdown?.status ?? 'Pending'}
                  />
                </div>
              </div>
            </div>
            <div className={styles.cardRow}>
              <div className={styles.cardRowItem}>
                <div className={styles.itemLabel}>Asked</div>
                <div className={styles.itemContent}>
                  {fundOpp?.gpBreakdown ? renderCurrency(fundOpp.gpBreakdown.asked) : '-'}
                </div>
              </div>
              <div className={styles.cardRowItem}>
                <div className={styles.itemLabel}>Payment date</div>
                <div className={styles.itemContent}>{fundOpp?.gpBreakdown?.paymentDate}</div>
              </div>
            </div>
            <Button
              order="primary"
              size="small"
              customStyle={{ width: '100%' }}
              disabled={userDetails?.userId != fundOpp?.gpBreakdown?.userId}
              onClick={handleViewTransaction}
            >
              View transaction
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export enum BreakdownStatusEnum {
  NEW = 'New',
  IN_PROGRESS = 'In Progress',
  ACCEPTED = 'Accepted',
  REJECTED = 'Rejected',
  PENDING = 'Pending',
  UNCONFIRMED = 'Unconfirmed',
  FULFILLED = 'Fulfilled',
  FAILED = 'Failed',
  CANCEL = 'Cancel',
  REPORT_SUBMITTED = 'Report Submitted',
  PENDING_FOR_PAYMENT = 'Pending For Payment',
  CLOSED = 'Closed',
}

export enum StatusEnum {
  CLOSED = 'Closed',
  OPEN = 'Open',
}

export type HistoricCapcallDetails = {
  userId?: number;
  capcallId: string;
  lpName: string;
  status: BreakdownStatusEnum;
  asked: number;
  method: 'Manual' | 'Automated' | 'Pending';
  paymentDate: string;
  transactionId: string;
};

// const fundName = "The Bell Enclave";
const columns = [
  { id: 'lpName', label: 'LP name', sortable: true },
  { id: 'status', label: 'Status', sortable: true },
  { id: 'asked', label: 'Asked', sortable: true },
  { id: 'method', label: 'Method', sortable: false },
  { id: 'paymentDate', label: 'Payment date', sortable: true },
];

// const data: HistoricCapcall = {
//   capcallId: '1',
//   created: '28 Oct 2023',
//   deadline: '12 Nov 2023',
//   status: StatusEnum.OPEN,
//   called: 1000000.00,
//   contributed: 440000.00,
//   lpCoverage: '4/10 fulfilled',
//   files: '',
//   breakdown: [
//     { capcallId: '1', lpName: 'Paul Rudd', status: BreakdownStatusEnum.FULFILLED, asked: 300000, method: 'Manual', paymentDate: '12 Nov 2023', transactionId: '999'},
//     { capcallId: '2', lpName: 'Neil DeGrasse Tyson', status: BreakdownStatusEnum.PENDING, asked: 100000, method: 'Manual', paymentDate: '12 Nov 2023', transactionId: '998'},
//     { capcallId: '3', lpName: 'Neil DeGrasse Tyson', status: BreakdownStatusEnum.PENDING, asked: 120000, method: 'Manual', paymentDate: '12 Nov 2023', transactionId: '997'},
//     { capcallId: '4', lpName: 'Paul Rudd', status: BreakdownStatusEnum.PENDING, asked: 120000, method: 'Manual', paymentDate: '12 Nov 2023', transactionId: '996'}
//   ]
// }

export default CapcallDetailsHistory;
