import { useState } from 'react';
import { ArrowGoBackButton, Stack, IconButton } from 'src/components/common';
import Progressbar from 'src/uiLibrary/reusables/progress-bar/progress-bar';
import { DistributeStep } from './components/DistributeStep/DistributeStep';
import { ReviewPaymentsStep } from './components/ReviewPaymentsStep/ReviewPaymentsStep';
import { FinalReviewStep } from './components/FinalReviewStep/FinalReviewStep';
import { DistributeCapitalContextProvider } from './DistributeCapital.context';
import { DistributeCapitalGeneralForm } from './components/DistributeStep/DistributeStep.form';
import { Close } from '@mui/icons-material';

enum Steps {
  Distribution = 0,
  ReviewPayments = 1,
  FinalReview = 2,
}

type FundData = {
  id: number;
  name: string;
  code: string;
};

export type DistributeCapitalProps =
  | {
      type: 'modal';
      fundData: FundData;
      onClose: () => void;
    }
  | {
      type: 'page';
      fundData: FundData;
      onClose?: () => void;
    };

export const DistributeCapital = ({ type, fundData, onClose }: DistributeCapitalProps) => {
  const [activeStep, setActiveStep] = useState(Steps.Distribution);
  const [formData, setFormData] = useState<DistributeCapitalGeneralForm | null>(null);

  const goBackOnClick = () => {
    if (activeStep !== Steps.Distribution) {
      setActiveStep(state => state - 1);
    } else {
      if (onClose) {
        onClose();
      }
    }
  };

  const onSubmit = (data: DistributeCapitalGeneralForm) => {
    setFormData(data);
    setActiveStep(Steps.ReviewPayments);
  };

  return (
    <DistributeCapitalContextProvider
      value={{
        fundData,
        onSubmit,
        onClose,
        capitalToDistribute: formData?.CapitalToDistribute ? Number(formData?.CapitalToDistribute) : undefined,
      }}
    >
      <Stack direction="column" spacing={1} minWidth="400px">
        <Stack direction="column">
          {type === 'modal' && (
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <ArrowGoBackButton onClick={goBackOnClick} />
              <IconButton onClick={onClose} className="dialog-close-btn">
                <Close />
              </IconButton>
            </Stack>
          )}

          <Progressbar
            progressValue={activeStep}
            customNodeCount={['Distribution', 'Review payments', 'Final Review']}
            onStepClick={setActiveStep}
            disabled={!formData}
          />
        </Stack>

        {activeStep === Steps.Distribution && <DistributeStep />}
        {activeStep === Steps.ReviewPayments && (
          <ReviewPaymentsStep
            onNextStep={() => {
              setActiveStep(Steps.FinalReview);
            }}
          />
        )}
        {activeStep === Steps.FinalReview && <FinalReviewStep />}
      </Stack>
    </DistributeCapitalContextProvider>
  );
};
