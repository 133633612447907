import React from 'react';

const IconInactive = (props: IconInactiveProps) => {
  return (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.7541 4.24707C12.6349 5.12811 13.2346 6.25052 13.4775 7.47239C13.7204 8.69426 13.5955 9.96071 13.1187 11.1116C12.6419 12.2625 11.8345 13.2462 10.7986 13.9383C9.76277 14.6303 8.54497 14.9997 7.29919 14.9997C6.05342 14.9997 4.83562 14.6303 3.79976 13.9383C2.76391 13.2462 1.95653 12.2625 1.4797 11.1116C1.00287 9.96071 0.878001 8.69426 1.12089 7.47239C1.36378 6.25052 1.96351 5.12811 2.84427 4.24707"
        stroke={props.color ? props.color : '#F34A29'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.30249 1V7.9991"
        stroke={props.color ? props.color : '#F34A29'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export interface IconInactiveProps {
  color?: string;
}

export default IconInactive;
