export const uploadImageOutput = {
  width: '126px',
  height: '126px',
  backgroundColor: '#eee',
  borderRadius: '5px',
  border: '1px solid #dcdedf',
  position: 'relative',
  marginRight: '16px',

  img: {
    objectFit: 'contain',
    width: '100%',
    height: '100%',
  },
};

export const uploadImageCloseButton = {
  position: 'absolute',
  right: '-10px',
  top: '-10px',
  boxShadow: '0 0 8px rgba(0, 0, 0, 0.15)',
  zIndex: 9,
  backgroundColor: '#fff',
  svg: {
    path: {
      stroke: '#d32f2f',
    },
  },
  '&:hover': {
    backgroundColor: '#fff',
  },
};

export const paperImage = {
  textAlign: 'center',
  height: '126px',
  background: '#f6f5f4',
  border: '1px solid #e1e1e1',
  borderRadius: '8px',
  padding: '5px',
  width: '126px',
  marginRight: '16px',
};

export const paperImageNested = {
  height: '116px',
  background: '#f6f5f4',
  border: '1px solid #e1e1e1',
  borderRadius: '8px',
  width: '116px',

  svg: {
    marginTop: '35px',
    color: '#d9d9d9',
    fontSize: '40px',
  },
};

export const uploadButton = {
  width: '115px',
  height: '37px',
  marginTop: '22px',
};
