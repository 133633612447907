import { useState } from 'react';
import { useUserRole } from 'src/hooks/usePermission';
import { useProtected } from 'src/hooks/useProtected';
import { Box, Stack, Typography, Grid, Collapse } from 'src/components/common';
import DocumentCard from 'src/components/documents/document-card/DocumentCard';
import { FolderActions } from '../FolderActions/FolderActions';
import { Folder } from 'src/types/Documents';
import { DocumentData } from 'src/components/documents/document-card/document-card-helpers';
import { ConfirmDialogState } from 'src/components/documents/FoldersAndDocuments/FoldersAndDocuments.list';
import { Permission } from 'src/consts/permissions';
import classNames from 'classnames';
import styles from './styles.module.scss';

type FolderCardProps = {
  folder: Folder;
  documents: DocumentData[];
  handleDeleteDocumentClick: (documentId: number, documentName: string) => Promise<void>;
  onDeleteFolder: (body: ConfirmDialogState) => void;
  onUpdateFolder: (folderId: number, oldFolderName: string) => void;
};

export const FolderCard = ({
  folder,
  documents,
  handleDeleteDocumentClick,
  onDeleteFolder,
  onUpdateFolder,
}: FolderCardProps) => {
  const [isDocumentsOpen, setIsDocumentsOpen] = useState(false);
  const { isLp } = useUserRole();
  const isProtected = useProtected({
    permissions: [Permission.Documents],
  });

  const handleFolderDelete = () => {
    onDeleteFolder({ id: folder.id, name: folder.name, open: true });
  };

  const handleFolderUpdate = () => {
    onUpdateFolder(folder.id, folder.name);
  };

  const openCloseDocuments = () => {
    setIsDocumentsOpen(state => !state);
  };

  return (
    <Stack direction="column" rowGap={2}>
      <Stack direction="row" spacing={2} alignItems="center" className={styles['folder-card-title-box']}>
        <Typography component="h2" className={styles['folder-name']} onClick={openCloseDocuments}>
          {folder.name === 'default' ? 'Other Documents' : folder.name}
        </Typography>

        <Box
          className={classNames(styles['folder-counter'], {
            [styles.empty]: !documents?.length,
          })}
        >
          {documents?.length ?? 0}
        </Box>

        {folder.name !== 'default' && (
          <Box className={styles['folder-actions']}>
            <FolderActions
              isDocumentsOpen={isDocumentsOpen}
              openCloseDocuments={openCloseDocuments}
              onDeleteFolder={handleFolderDelete}
              onUpdateFolder={handleFolderUpdate}
            />
          </Box>
        )}
      </Stack>

      <Collapse
        in={isDocumentsOpen}
        timeout={{ appear: 100, enter: 300, exit: 0 }}
        unmountOnExit
        sx={{ padding: 0, width: '100%' }}
      >
        <Grid container spacing={2}>
          {documents ? (
            documents.map(document => {
              if (isProtected || (isLp && document.isLpVisible)) {
                return (
                  <Grid item xs={12} sm={6} md={4} lg={3} width="100%" key={document.id}>
                    <DocumentCard document={document} handleDeleteOnClick={handleDeleteDocumentClick} />
                  </Grid>
                );
              }
              return <></>;
            })
          ) : (
            <Grid item xs={12}>
              <Typography component="p">There are no documents.</Typography>
            </Grid>
          )}
        </Grid>
      </Collapse>
    </Stack>
  );
};
