/* eslint-disable @typescript-eslint/no-unused-vars */
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { CardKPI } from 'src/components/CardKPI';
import { Loader } from 'src/components/common/Loader';
import { QueryError } from 'src/components/common/QueryError';
import { IndicatorsData, KPIValueType } from 'src/models/partnershipModel';
import { getIndicators } from 'src/services/governanceService';
import { KPI_INDICATORS_TITLE } from 'src/consts/fundsSpotlightIndicators';
import { updateIndicatorInfo } from 'src/utils/updateIndicatorInfo';
import { useParams } from 'react-router-dom';
import { nestedClone } from 'src/utils/nestedClone';
import { QueryKey } from 'src/consts/query';

const indicatorsDefaultValues: IndicatorsData[] = [
  {
    id: 1,
    Title: 'No data',
    SubTitle: 'Total invested amount',
    Value: 0,
    ValueType: KPIValueType.CURRENCY,
    Variance: 0,
    ChartType: 'barchart',
  },
  {
    id: 2,
    Title: 'No data',
    SubTitle: 'Total estimated return',
    Value: 0,
    ValueType: KPIValueType.CURRENCY,
    Variance: 0,
    ChartType: 'barchart',
  },
  {
    id: 3,
    Title: 'No data',
    SubTitle: 'Average cap rate',
    Value: 0,
    ValueType: KPIValueType.PERCENTAGE,
    Variance: 0,
    ChartType: 'areachart',
  },
  {
    id: 4,
    Title: 'No data',
    SubTitle: 'Total Dividend Received',
    Value: 0,
    ValueType: KPIValueType.CURRENCY,
    Variance: 0,
    ChartType: 'barchart',
  },
];

export const Overview = () => {
  const params = useParams();
  const {
    data: kpiData,
    isLoading,
    isError,
  } = useQuery({
    queryKey: [QueryKey.Kpi, params.fundId],
    queryFn: async () => {
      try {
        if (params.fundId) {
          const response = await getIndicators(Number(params.fundId));
          let indicatorsLocal = nestedClone(indicatorsDefaultValues).slice();
          // TODO: get type from API
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          response.data.forEach((indicator: any) => {
            const indicatorName = KPI_INDICATORS_TITLE[indicator.KPI] || '';

            if (indicatorName !== '') {
              indicatorsLocal = updateIndicatorInfo(indicatorsLocal, indicatorName, indicator);
            }
          });
          return indicatorsLocal;
        }
        return indicatorsDefaultValues;
      } catch (e) {
        console.log('kpi error', e);
        return indicatorsDefaultValues;
      }
    },
  });

  if (isLoading) return <Loader />;
  if (isError) return <QueryError />;

  return (
    <>
      <div className="box-wrapper mt-25">
        <div className="heading-title mt-45 mb-25">
          <h3>Overall Fund Stats</h3>
        </div>
        <div className="overall-investmets-stats-body">
          {
            // TODO: layout template for EX-31 task
          }
          {/* <div className="overall-investmets-stats-kpis-container"> */}
          {kpiData.map(item => (
            <CardKPI
              key={item.id}
              id={item.id}
              cardHeader={item.Title}
              kpiDescription={item.SubTitle}
              value={item.Value}
              ValueType={item.ValueType}
              variationNumber={item.Variance}
            />
          ))}
          {/* </div> */}

          {/* <div className="overall-investmets-stats-fund-widget-container">Fund level actions</div> */}
        </div>
      </div>
    </>
  );
};
