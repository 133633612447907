import { Box } from '@mui/material';
import { LeftSideSection } from '../../models/documentsModels';
import FolderCard, { FolderData } from '../../components/FolderCard';
import DocumentCard from 'src/components/documents/document-card/DocumentCard';
import { DocumentData } from 'src/components/documents/document-card/document-card-helpers';

interface DocumentsComponentProps {
  leftSideData: LeftSideSection[];
  fundFolder: FolderData[] | null;
  documents: DocumentData[] | null;
  handleStarredFundOnClick: (fundId: string | number) => void;
}

const DocumentsComponent = (props: DocumentsComponentProps) => {
  const {
    // leftSideData,
    fundFolder,
    documents,
    handleStarredFundOnClick,
  } = props;

  // const renderLeftSideSections = (section: LeftSideSection | undefined) =>
  //   section && (
  //     <React.Fragment key={section.id}>
  //       <div className="documents-left-side-section">
  //         <span className="left-side-section-header">
  //           <h4>{section.title}</h4>
  //           {section.actionType !== "none" ? (
  //             <button
  //               type="button"
  //               className={
  //                 section.actionType === "link" ? "text-button" : "icon-button"
  //               }
  //               onClick={section.action}
  //             >
  //               {section.actionPlaceholder}
  //             </button>
  //           ) : undefined}
  //         </span>
  //         <div className="left-side-section-body">
  //           <List className="left-side-section-body-list">
  //             {section.items ? (
  //               section.items.length > 0 ? (
  //                 section.items.map((item: LeftSideSectionListItem) => (
  //                   <ListItem
  //                     key={item.id}
  //                     button
  //                     className="left-side-section-body-listitem"
  //                     onClick={
  //                       section.itemsAction
  //                         ? () => section.itemsAction(item.id)
  //                         : () => { }
  //                     }
  //                   >
  //                     {item.icon}
  //                     <p>{item.text}</p>
  //                   </ListItem>
  //                 ))
  //               ) : (
  //                 <p className="no-items">There are no items.</p>
  //               )
  //             ) : undefined}
  //           </List>
  //         </div>
  //       </div>
  //       <div className="border-bottom" />
  //     </React.Fragment>
  //   );

  // const height =
  //   window.innerHeight ||
  //   document.documentElement.clientHeight ||
  //   document.body.clientHeight;

  return (
    <Box className="documents-container">
      <div className="from-row theam-black" style={{ height: '100%' }}>
        {/* <div
          className="col-md-3 no-padding-right"
          style={{ overflowY: "auto", height: height }}
        >
          <div className="documents-heading border-bottom">
            <h2>Documents</h2>
          </div> */}
        {/* .map() not used because we need this specific order */}
        {/* {leftSideData
            ? renderLeftSideSections(leftSideData.find((i) => i.id === "Custom Folders"))
            : undefined}  */}
        {/* {leftSideData
            ? renderLeftSideSections(leftSideData.find((i) => i.id === "File Type"))
            : undefined} */}
        {/* {leftSideData
            ? renderLeftSideSections(leftSideData.find((i) => i.id === "Starred Funds"))
            : undefined}
        </div>  */}

        <div className="col-md-12 right-documents-screen">
          <div className="right-documents-screen-section">
            <h2>Funds</h2>
            <div className="folders-cards-container">
              {fundFolder?.length ? (
                fundFolder.map((fund: FolderData) => (
                  <FolderCard key={fund.id} onClick={() => handleStarredFundOnClick(fund.id)} information={fund} />
                ))
              ) : (
                <p>There are no funds.</p>
              )}
            </div>
          </div>

          <div className="right-documents-screen-section">
            <h2>Latest Uploads</h2>
            <div className="documents-cards-container no-scrollbar">
              {documents ? (
                documents.length > 0 ? (
                  documents.map(document => <DocumentCard key={document.id} document={document} />)
                ) : (
                  <p>There are no documents.</p>
                )
              ) : undefined}
            </div>
          </div>

          {/* <div className="right-documents-screen-section">
            <h2>Data Ingestion Index</h2>
            <div className="data-ingestion-chart">
              <div className="from-row theam-black">
                <div className="col-lg-12 chart-information">
                  <div style={{ width: "44%" }}>
                    <p>Completed</p>
                    <h2>44%</h2>
                  </div>
                  <div style={{ width: "24%" }}>
                    <p>In Progress</p>
                    <h2>24%</h2>
                  </div>
                  <div style={{ width: "12%" }}>
                    <p>Failed</p>
                    <h2>12%</h2>
                  </div>
                  <div style={{ width: "20%" }}>
                    <p>Not Processable</p>
                    <h2>20%</h2>
                  </div>
                </div>
                <div className="col-lg-12">
                  <ResponsiveContainer width="100%" height={90}>
                    <BarChart
                      data={[
                        {
                          completed: 44,
                          in_progress: 24,
                          failed: 12,
                          not_processable: 20,
                        },
                      ]}
                      layout="vertical"
                      margin={{
                        top: 10,
                        right: 0,
                        left: 0,
                        bottom: 0,
                      }}
                    >
                      <XAxis type="number" hide domain={[0, 100]} />
                      <YAxis type="category" hide />
                      <Bar dataKey="completed" stackId="a" fill="#297B80" />
                      <Bar dataKey="in_progress" stackId="a" fill="#1F666A" />
                      <Bar dataKey="failed" stackId="a" fill="#000000" />
                      <Bar
                        dataKey="not_processable"
                        stackId="a"
                        fill="#E1E1E1"
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </Box>
  );
};

export default DocumentsComponent;
