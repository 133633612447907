import { Stack, Box } from 'src/components/common';
import { WaterfallPreviewItem } from './WaterfallPreview.item';
import { WaterfallPreviewGraphData } from './WaterfallPreview.helpers';

type WaterfallPreviewProps = {
  graphsData: WaterfallPreviewGraphData[];
};

export const WaterfallPreview = ({ graphsData }: WaterfallPreviewProps) => {
  return (
    <Box display="flex" justifyContent="center" width="100%">
      <Stack direction="column" spacing={1} minWidth="600px" width="100%">
        {graphsData.map((graph, index: number) => (
          <Box
            style={{
              marginLeft: `${index * 80}px`,
            }}
            key={graph.id}
          >
            <WaterfallPreviewItem {...graph} />
          </Box>
        ))}
      </Stack>
    </Box>
  );
};
