import { useForm, Controller } from 'react-hook-form';
import { Stack, FormControl, TextField, FormLabel, InputAdornment, FormHelperText } from 'src/components/common';
import { Button } from '@mui/material';
import { useDistributeCapitalContext } from '../../DistributeCapital.context';
import { NumericFormat } from 'react-number-format';

export type DistributeCapitalGeneralForm = {
  FundCode: string;
  FundName: string;
  CapitalToDistribute: string;
};

export const DistributeStepForm = () => {
  const { fundData, capitalToDistribute, onSubmit } = useDistributeCapitalContext();

  const { control, setValue, handleSubmit } = useForm<DistributeCapitalGeneralForm>({
    mode: 'onChange',
    defaultValues: {
      FundCode: fundData!.code,
      FundName: fundData!.name,
      CapitalToDistribute: capitalToDistribute ? String(capitalToDistribute) : '',
    },
    shouldFocusError: true,
  });

  return (
    <Stack component="form" spacing={3} onSubmit={handleSubmit(onSubmit!)}>
      <Stack direction="row" spacing={2}>
        <Controller
          name="FundCode"
          control={control}
          render={({ field: { value }, fieldState: { error } }) => (
            <FormControl
              sx={{
                width: '60%',
              }}
              variant="outlined"
            >
              <FormLabel>Fund code</FormLabel>
              <TextField
                fullWidth
                type="text"
                placeholder={fundData!.code || ''}
                value={value}
                error={!!error}
                disabled
              />
            </FormControl>
          )}
        />

        <Controller
          name="FundName"
          control={control}
          render={({ field: { value }, fieldState: { error } }) => (
            <FormControl fullWidth variant="outlined">
              <FormLabel>Fund name</FormLabel>
              <TextField
                fullWidth
                type="text"
                placeholder={fundData!.name || ''}
                value={value}
                error={!!error}
                helperText={error ? error.message : null}
                disabled
              />
            </FormControl>
          )}
        />
      </Stack>

      <Controller
        name="CapitalToDistribute"
        control={control}
        rules={{ required: 'This is required field' }}
        render={({ field: { value }, fieldState: { error } }) => (
          <FormControl fullWidth variant="outlined" className="dialogFormControl">
            <FormLabel>Capital to distribute</FormLabel>
            <NumericFormat
              type="text"
              value={value}
              customInput={TextField}
              thousandSeparator=","
              decimalSeparator="."
              error={!!error}
              onValueChange={e => setValue('CapitalToDistribute', e.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <p style={{ margin: 0, color: 'black' }}>$</p>
                  </InputAdornment>
                ),
              }}
            />
            {!!error && <FormHelperText error>{error.message ?? ''}</FormHelperText>}
          </FormControl>
        )}
      />

      <Button type="submit" variant="contained" size="large" fullWidth>
        Continue
      </Button>
    </Stack>
  );
};
