import { WaterfallPreviewGraphData } from 'src/components/governance/WaterfallPreview/WaterfallPreview.helpers';

type GetDistributionPaymentsBreakdownDataProps = {
  waterfallGraphsData: WaterfallPreviewGraphData[];
};

export const getDistributionPaymentsBreakdownData = ({
  waterfallGraphsData,
}: GetDistributionPaymentsBreakdownDataProps) => {
  let gpAmount = 0;
  let lpAmount = 0;

  waterfallGraphsData.forEach(graph => {
    const { capitalToDistribute, gpSplit, lpSplit } = graph;

    if (capitalToDistribute) {
      gpAmount += (capitalToDistribute / 100) * gpSplit;
      lpAmount += (capitalToDistribute / 100) * lpSplit;
    }
  });

  return { gpAmount, lpAmount };
};
