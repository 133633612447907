import { useQuery } from '@tanstack/react-query';
import { getFboAvailable } from 'src/services/governanceService';
import { useSnackbar } from 'src/context/ShackbarContext';
import { AxiosError } from 'axios';
import { QueryKey } from 'src/consts/query';
import { ApiError } from 'src/types/api';

export const useFboBalance = (fundId: number | undefined) => {
  const { showErrorSnackbar } = useSnackbar();

  return useQuery({
    queryKey: [QueryKey.FboAvailable, fundId],
    queryFn: async () => {
      if (fundId) {
        try {
          const response = await getFboAvailable(fundId);
          return response.data[0]?.Value;
        } catch (error) {
          showErrorSnackbar((error as AxiosError<ApiError>).response?.data.message);
          console.error('useFboBalance error', error);
          throw new Error((error as AxiosError<ApiError>).response?.data.message);
        }
      }
      showErrorSnackbar('No Fund was provided');
    },
  });
};
