import GovernanceOverviewComponent from './governanceOverviewComponent';

interface GovernanceOverviewContainerProps {
  // TODO: get type from API
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fund: any;
}

const GovernanceOverviewContainer = ({ fund }: GovernanceOverviewContainerProps) => {
  return <GovernanceOverviewComponent fund={fund} />;
};

export default GovernanceOverviewContainer;
