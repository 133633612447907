import React from 'react';

const IconCollapsed = () => {
  return (
    <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.73714 0.713934C5.65616 0.74724 5.58254 0.796171 5.52047 0.857934L0.18714 6.19127C0.0657012 6.317 -0.00149466 6.4854 2.45334e-05 6.6602C0.00154373 6.835 0.0716559 7.00221 0.195262 7.12581C0.318867 7.24942 0.486076 7.31953 0.660874 7.32105C0.835672 7.32257 1.00407 7.25537 1.12981 7.13393L5.32514 2.9386L5.99181 2.32935L6.65847 2.9386L10.8538 7.13393C10.9153 7.19761 10.9889 7.2484 11.0702 7.28334C11.1515 7.31828 11.239 7.33667 11.3275 7.33744C11.4161 7.3382 11.5038 7.32134 11.5858 7.28782C11.6677 7.2543 11.7421 7.20479 11.8047 7.1422C11.8673 7.0796 11.9168 7.00517 11.9504 6.92324C11.9839 6.84131 12.0007 6.75352 12 6.665C11.9992 6.57648 11.9808 6.489 11.9459 6.40767C11.9109 6.32633 11.8601 6.25277 11.7965 6.19127L6.46314 0.857934C6.40108 0.796171 6.32745 0.74724 6.24647 0.713934C6.16585 0.68005 6.07927 0.662597 5.99181 0.662597C5.90435 0.662597 5.81777 0.68005 5.73714 0.713934Z"
        fill="#8F8F8F"
      />
    </svg>
  );
};

export default IconCollapsed;
