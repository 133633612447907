import { IconButton, Tooltip } from '@mui/material';
import { UserProfileDetails } from '../../models/profileModel';
import { IconEdit } from '../../uiLibrary/icons/IconEdit';
import { formatDateNoTime } from '../utils';

const UserDetailsComponent = ({
  userDetails,
  handleEditProfileOnClick,
}: {
  userDetails: UserProfileDetails | null;
  handleEditProfileOnClick: () => void;
}) => (
  <div className="user-details-component">
    <div className="user-details-header">
      <div className="user-image-and-info">
        <div
          className="user-image-container"
          style={{
            backgroundImage: `url('${userDetails?.image}')`,
            backgroundSize: '51px 51px',
            resize: 'both',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        />
        <div className="user-info-container">
          <p className="user-name">{userDetails?.name}</p>
          <p className="user-email">{userDetails?.email}</p>
        </div>
      </div>
      <div className="user-details-header-actions">
        <Tooltip
          title="Edit Profile"
          placement="left"
          componentsProps={{
            tooltip: {
              sx: {
                width: '79px',
                height: '26px',
                padding: '4px 8px',
                gap: '4px',
                background: '#000000',
                borderRadius: '4px',
                fontFamily: 'Poppins',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '12px',
                lineHeight: '18px',
                textAlign: 'center',
                color: '#FFFFFF',
              },
            },
          }}
        >
          <IconButton className="header-edit-button" disableRipple onClick={() => handleEditProfileOnClick()}>
            <IconEdit color="black" />
          </IconButton>
        </Tooltip>
      </div>
    </div>
    <div className="user-details-section-divider" />
    <div className="user-details-detailed-information">
      <div className="user-details-detailed-information-column">
        <p className="column-title">User details</p>
        <div className="column-info-section">
          <p className="section-title">Contact number</p>
          <p className="section-information">{userDetails?.contactNumber}</p>
        </div>
        <div className="column-info-section">
          <p className="section-title">Address</p>
          <p className="section-information">{`${userDetails?.address}, ${userDetails?.addressState}, ${userDetails?.addressZipCode}`}</p>
        </div>
        <div className="column-info-section">
          <p className="section-title">Company / Agency</p>
          <p className="section-information">{userDetails?.companyAgency}</p>
        </div>
      </div>
      <div className="user-details-detailed-information-column">
        <p className="column-title">Billing information</p>
        <div className="column-info-section">
          <p className="section-title">Plan</p>
          <p className="section-information">{userDetails?.plan}</p>
        </div>
        <div className="column-info-section">
          <p className="section-title">Billing address</p>
          <p className="section-information">{`${userDetails?.billingAddress}, ${userDetails?.billingAddressState}, ${userDetails?.billingAddressZipCode}`}</p>
        </div>
        <div className="column-info-section">
          <p className="section-title">Subscription renewed</p>
          <p className="section-information">
            {userDetails?.subscriptionRenewed ? formatDateNoTime(userDetails?.subscriptionRenewed) : 'No information'}
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default UserDetailsComponent;
