import { AxiosError, AxiosResponse } from 'axios';
import { UseMutateAsyncFunction, useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'src/context/ShackbarContext';
import { updateDocumentsFolder } from 'src/services/documentsService';
import { QueryKey } from 'src/consts/query';

interface UseManageDocumentFolderMutationParams {
  folderId: string;
  documentId: string;
}

interface UseManageDocumentFolderMutationReturnValue {
  isLoading: boolean;
  mutateAsync: UseMutateAsyncFunction<AxiosResponse, AxiosError, UseManageDocumentFolderMutationParams>;
}

export const useManageDocumentFolderMutation = (): UseManageDocumentFolderMutationReturnValue => {
  const queryClient = useQueryClient();

  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();

  const onError = (error: AxiosError) => showErrorSnackbar(error.message);

  return useMutation<AxiosResponse, AxiosError, UseManageDocumentFolderMutationParams>({
    mutationFn: ({ folderId, documentId }) => updateDocumentsFolder(folderId, documentId),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [QueryKey.Documents] });
      showSuccessSnackbar('Folder has been successfully updated');
    },
    onError,
  });
};
