/* eslint-disable no-console */
import { Grid, Box, MenuItem } from '@mui/material';
import Table from '../../../components/common/table/Table';
import AdminUserTablePagination from '../../../components/common/table/TablePagination';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { UserListMetaData } from './../../../models/frontendUserModels';
import IconNoApprove from '../../icons/IconNoApprove';
import IconApprove from '../../icons/IconApprove';

import TableActionButtonsRow from '../../../components/common/TableActionButtonsRow';

// const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))(() => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     color: "#fff",
//     backgroundColor: "#000",
//     fontSize: "12px",
//     fontWeight: "500",
//     borderRadius: "5px",
//     paddingLeft: "12px",
//     paddingRight: "12px",
//   },
// }));

const TransactionApproveTable = ({
  //transactionAction,
  handleChangePage,
  handlePageSizeChange,
  //handleSortChange,
  limit,
  metaData,
  page,
  transactionList,
  newTransaction,
  activeChip,
  approvalQueue,
  setApprovalQueue,
}: //changeAccountStatusHandler,
//openSoftDeleteConfirmationModal,
//childPrivileges,
TransactionApproveTableProps) => {
  // const validActionHeadCellHandler = () => {
  //   if (
  //     !!childPrivileges &&
  //     childPrivileges?.filter(
  //       (privilege: any) =>
  //         (privilege.Name.trim() === "Edit" ||
  //           privilege.Name.trim() === "Delete") &&
  //         !privilege.has_privilege
  //     ).length === 2
  //   )
  //     return true;
  // };

  const handleChangeSelectPage = (event: SelectChangeEvent) => {
    handlePageSizeChange(+event.target.value);
  };

  const addTransactionToQueue = (transactionId: number, value: string) => {
    if (approvalQueue.find(elem => elem.id === transactionId)) {
      setApprovalQueue(approvalQueue.filter(item => item.id !== transactionId));
    } else {
      setApprovalQueue([...approvalQueue, { id: transactionId, value: value }]);
    }
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      // alignItems="stretch"
    >
      <Grid item>
        <Table
          columns={
            activeChip == 'position-sellout'
              ? [
                  { id: 'lpname', label: 'LP Name', sortable: true },
                  { id: 'enterDate', label: 'Enter date', sortable: false },
                  { id: 'sellDate', label: 'Sell date', sortable: false },
                  { id: 'stake', label: 'Stake', sortable: false },
                  { id: 'ammount', label: 'Amount', sortable: false },
                  {
                    id: newTransaction === 'new-transactions' ? 'actions' : 'status',
                    label: newTransaction === 'new-transactions' ? 'Actions' : 'Status',
                  },
                ]
              : [
                  { id: 'fundName', label: 'Fund Name', sortable: true },
                  { id: 'created', label: 'Created', sortable: false },
                  { id: 'dueDate', label: 'Due date', sortable: false },
                  { id: 'stake', label: 'Stake', sortable: false },
                  { id: 'ammount', label: 'Amount', sortable: false },
                  {
                    id: newTransaction === 'new-transactions' ? 'actions' : 'status',
                    label: newTransaction === 'new-transactions' ? 'Actions' : 'Status',
                  },
                ]
          }
          // TODO: get type from API
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          data={transactionList.map((transaction: any) => ({
            ...transaction,
            // fullname: `${user.firstname} ${user.lastname}`,
            // creationdatetime: format(
            //   new Date(user.creationdatetime),
            //   "MM / dd / yyyy"
            // ),
            // operation: [
            //   <div className="position-sell-badge">
            //     <p>{transaction.operation}</p>
            //   </div>,
            // ],
            actions: [
              <TableActionButtonsRow
                approvalQueue={approvalQueue}
                lineId={transaction.id}
                content={[
                  {
                    key: 1,
                    id: transaction.id,
                    childrenContent: <IconNoApprove />,
                    action: 'Reject',
                    tooltipLabel: 'Reject',
                    tooltipStyle: { bottom: '-32px' },
                    onClickAction: () => {
                      addTransactionToQueue(transaction.id, 'Reject');
                    },
                  },
                  {
                    key: 2,
                    id: transaction.id,
                    childrenContent: <IconApprove />,
                    action: 'Approve',
                    tooltipLabel: 'Approve',
                    tooltipStyle: { bottom: '-32px' },
                    onClickAction: () => {
                      addTransactionToQueue(transaction.id, 'Approve');
                    },
                  },
                ]}
              />,
            ],
            status: [
              <div
                style={{
                  color:
                    transaction.status === 'Approved'
                      ? '#8F8F8F' //gray
                      : transaction.status === 'Rejected'
                        ? '#F34A29' //red
                        : '#297B80', //green
                }}
              >
                {transaction.status === 'Rejected' ? `☓ ${transaction.status}` : `✔ ${transaction.status}`}
              </div>,
            ],
          }))}
          loading={transactionList == null}
          //onSortColumnChange={handleSortChange}
        />
      </Grid>
      <Grid item>
        <Grid container spacing={0} className={'customPagination'}>
          <Grid item xs={6}>
            <Box className="boxPerPage">
              Displaying
              <Select
                className="selectPerPage"
                value={limit.toString()}
                variant="standard"
                onChange={handleChangeSelectPage}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={30}>30</MenuItem>
                <MenuItem value={40}>40</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
              per page
            </Box>
          </Grid>
          <Grid item xs={6}>
            <AdminUserTablePagination
              count={metaData?.count}
              page={page}
              rowsPerPage={limit}
              handleChangePage={handleChangePage}
              handlePageSizeChange={handlePageSizeChange}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

interface TransactionApproveTableProps {
  // TODO: get type from API
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transactionList: any;
  metaData: UserListMetaData;
  page: number;
  //filterStatus: 0 | 1 | undefined;
  limit: number;
  //searchString: string | undefined;
  //transactionAction: () => void;
  handleChangePage: (newPage: number) => void;
  //handleSortChange: (sortColumn: SortColumn) => void;
  handlePageSizeChange: (pageSize: number) => void;
  newTransaction: string;
  activeChip: string;
  approvalQueue: {
    id: number;
    value: string;
  }[];
  setApprovalQueue: React.Dispatch<
    React.SetStateAction<
      {
        id: number;
        value: string;
      }[]
    >
  >;
  //changeAccountStatusHandler: (userid: number, status: number) => void;
  //openSoftDeleteConfirmationModal: (modalName: string, userid: number) => void;
  //childPrivileges: ChildPrivilege[] | undefined;
}

export default TransactionApproveTable;
