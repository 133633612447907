import { Dialog, DialogContent } from '@mui/material';

import 'react-perfect-scrollbar/dist/css/styles.css';
import IconButton from '@mui/material/IconButton';
import { TextField } from '@mui/material';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import { Controller, useForm } from 'react-hook-form';
import IconArrowLeft from '../../icons/IconArrowLeft';
import { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { IconClose } from '../../icons/IconClose';
import { UserProfileDetails } from '../../../models/profileModel';

const EditProfileModalComponent = ({
  isVisible,
  closeModal,
  initialValues,
  onSubmit,
}: EditProfileModalComponentProps) => {
  const [profileImage, setProfileImage] = useState<string | null>(null);

  const { control, handleSubmit, setValue, reset, formState } = useForm<EditProfileForm>({
    mode: 'onChange',
    defaultValues: {
      Address: initialValues.address,
      State: initialValues.addressState,
      ZipCode: initialValues.addressZipCode,
      BillingAddress: initialValues.billingAddress,
      BillingState: initialValues.billingAddressState,
      BillingZipCode: initialValues.billingAddressZipCode,
      Email: initialValues.email,
      PhoneNumber: initialValues.contactNumber,
      ProfileImage: '', // TODO: do not send this if there was no uploaded image.
    },
    shouldFocusError: true,
  });

  const handleOnClose = () => {
    setTimeout(() => {
      reset();
      setProfileImage(null);
    }, 500);
    closeModal();
  };

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset();
      setProfileImage(null);
    }
  }, [formState, reset]);

  const handleOnDrop = (files: File[]) => {
    const currentFile = files[0];
    const extension = currentFile.name.toLowerCase().split('.').pop();
    if (extension === 'jpeg' || extension === 'jpg' || extension === 'png' || extension === 'JPEG') {
      setValue('ProfileImage', currentFile as unknown as string);
      const image = URL.createObjectURL(currentFile);
      setProfileImage(image);
    }
  };

  const UploadProfileImage = () => (
    <div className="col-lg-12 col-md-12 col-sm-12 mt-1">
      <p className="custom-input-label">Profile image</p>
      <Dropzone maxFiles={1} onDrop={files => handleOnDrop(files)} disabled={profileImage !== null}>
        {({ getRootProps, getInputProps }) => (
          <div className="dropzone" {...getRootProps()}>
            <input {...getInputProps()} />
            {!profileImage && (
              <>
                <div className="dropzone-text">Click here to add a file</div>
                <div className="dropzone-subtext">or</div>
                <div className="dropzone-text">Drop it here</div>
              </>
            )}
            {profileImage && (
              <div
                className="dropzone-uploaded-file"
                style={{
                  backgroundImage: `url('${profileImage}')`,
                  backgroundSize: '70px 70px',
                  resize: 'both',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                }}
              >
                <div className="dropzone-uploaded-file-remove-button" onClick={() => setProfileImage(null)}>
                  <IconClose />
                </div>
              </div>
            )}
          </div>
        )}
      </Dropzone>
    </div>
  );

  const CustomFormField = ({
    fieldKey,
    fieldName,
    placeholder,
    fullWidth,
  }: {
    fieldKey: keyof EditProfileForm;
    fieldName: string;
    placeholder?: string;
    fullWidth?: boolean;
  }) => (
    <div className={fullWidth ? 'col-sm-12' : 'col-sm-6'}>
      <Controller
        name={fieldKey}
        control={control}
        rules={{ required: 'Required' }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormControl fullWidth variant="outlined" className="dialogFormControl">
            <FormLabel>{fieldName}</FormLabel>
            <TextField
              fullWidth
              type="text"
              placeholder={placeholder || ''}
              onChange={onChange}
              value={value}
              error={!!error}
              helperText={error ? error.message : null}
            />
          </FormControl>
        )}
      />
    </div>
  );

  const CustomSubmitButton = ({ text }: { text: string }) => (
    <div className="col-sm-12 btn-row-black mt-3">
      <Button type="submit" variant="contained" className="col-sm-12" disabled={false}>
        {text}
      </Button>
    </div>
  );

  return (
    <Dialog
      open={isVisible}
      onClose={handleOnClose}
      sx={{
        backdropFilter: 'blur(20px)',
      }}
      className="dialog-no-padding"
    >
      <DialogContent className="generic-form-dialog-content">
        <div className="dialog-content">
          <div className="back-login" onClick={handleOnClose}>
            <IconButton size="large" component="span">
              <IconArrowLeft stroke="#8F8F8F" />
            </IconButton>
            <p className="back-text">Back</p>
          </div>
          <div className="title-and-subtitle-wrap">
            <p className="title">Edit Profile</p>
          </div>
          <div className="dialog-form-wrap">
            <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
              <div className="from-row theam-black">
                <CustomFormField key="Address" fieldKey="Address" fieldName="Address" fullWidth />
                <CustomFormField key="State" fieldKey="State" fieldName="State" />
                <CustomFormField key="ZipCode" fieldKey="ZipCode" fieldName="Zip Code" />
                <CustomFormField key="BillingAddress" fieldKey="BillingAddress" fieldName="Billing Address" fullWidth />
                <CustomFormField key="BillingState" fieldKey="BillingState" fieldName="State" />
                <CustomFormField key="BillingZipCode" fieldKey="BillingZipCode" fieldName="Zip Code" />
                <CustomFormField key="Email" fieldKey="Email" fieldName="Email" />
                <CustomFormField key="PhoneNumber" fieldKey="PhoneNumber" fieldName="Phone Number" />
                <UploadProfileImage />
                <CustomSubmitButton text="Save Changes" />
              </div>
            </form>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

interface EditProfileModalComponentProps {
  isVisible: boolean;
  closeModal: () => void;
  initialValues: UserProfileDetails;
  onSubmit: (formData: EditProfileForm) => void;
}

export default EditProfileModalComponent;

export interface EditProfileForm {
  Address: string;
  State: string;
  ZipCode: string;
  BillingAddress: string;
  BillingState: string;
  BillingZipCode: string;
  Email: string;
  PhoneNumber: string;
  ProfileImage: string;
}
