import { useNavigate } from 'react-router-dom';
import AssetCard from 'src/components/AssetCard';
import { Asset, Fund, FundAsset } from 'src/types/Funds';

interface AssetsProps {
  assets: FundAsset[];
  fund: Fund;
}

export const Assets = ({ assets, fund }: AssetsProps) => {
  const navigate = useNavigate();

  const viewAssetHandler = ({ id, KpiRoleParam, ExternalId }: Asset) => {
    if (KpiRoleParam && ExternalId) {
      navigate(`/${fund.name}/${fund.id}/asset-details/${id}/${KpiRoleParam}/${ExternalId}`);
    } else {
      navigate(`/${fund.name}/${fund.id}/asset-details/${id}`);
    }
  };

  return (
    <>
      <div className="asset-grid">
        {assets?.map(({ asset }) => {
          return <AssetCard key={asset.id} fund={fund} asset={asset} clickHandler={() => viewAssetHandler(asset)} />;
        })}
      </div>
    </>
  );
};
