const IconCalendar = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.00004 13.6663H13C13.1769 13.6663 13.3464 13.5961 13.4714 13.4711C13.5965 13.3461 13.6667 13.1765 13.6667 12.9997V2.99967C13.6667 2.82286 13.5965 2.65329 13.4714 2.52827C13.3464 2.40325 13.1769 2.33301 13 2.33301H10.3334V0.999674C10.3334 0.822863 10.2631 0.653294 10.1381 0.52827C10.0131 0.403246 9.84352 0.333008 9.66671 0.333008C9.4899 0.333008 9.32033 0.403246 9.1953 0.52827C9.07028 0.653294 9.00004 0.822863 9.00004 0.999674V2.33301H5.00004V0.999674C5.00004 0.822863 4.9298 0.653294 4.80478 0.52827C4.67975 0.403246 4.51019 0.333008 4.33337 0.333008C4.15656 0.333008 3.98699 0.403246 3.86197 0.52827C3.73695 0.653294 3.66671 0.822863 3.66671 0.999674V2.33301H1.00004C0.82323 2.33301 0.65366 2.40325 0.528636 2.52827C0.403612 2.65329 0.333374 2.82286 0.333374 2.99967V12.9997C0.333374 13.1765 0.403612 13.3461 0.528636 13.4711C0.65366 13.5961 0.82323 13.6663 1.00004 13.6663ZM1.66671 3.66634H12.3334V5.66634H1.66671V3.66634ZM1.66671 6.99967H12.3334V12.333H1.66671V6.99967Z"
        fill="black"
      />
    </svg>
  );
};

export default IconCalendar;
