import React from 'react';

const IconGovernance = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21 8H12V5C12 4.20435 12.3161 3.44129 12.8787 2.87868C13.4413 2.31607 14.2044 2 15 2C15.2652 2 15.5196 1.89464 15.7071 1.70711C15.8946 1.51957 16 1.26522 16 1C16 0.734784 15.8946 0.48043 15.7071 0.292893C15.5196 0.105357 15.2652 0 15 0C14.0581 0.00286898 13.1363 0.272086 12.3409 0.776563C11.5455 1.28104 10.9091 2.0002 10.505 2.851C9.45816 1.82045 8.0854 1.18621 6.62212 1.05703C5.15883 0.927857 3.69619 1.31179 2.485 2.143C2.28145 2.26522 2.12802 2.45588 2.05217 2.68086C1.97632 2.90585 1.98299 3.15048 2.071 3.371C2.71951 4.8329 3.90958 5.98641 5.391 6.589C6.05247 6.83844 6.75407 6.96452 7.461 6.961C8.3274 6.94484 9.18522 6.78605 10 6.491V8H1C0.734784 8 0.48043 8.10536 0.292893 8.29289C0.105357 8.48043 0 8.73478 0 9V21C0 21.2652 0.105357 21.5196 0.292893 21.7071C0.48043 21.8946 0.734784 22 1 22H21C21.2652 22 21.5196 21.8946 21.7071 21.7071C21.8946 21.5196 22 21.2652 22 21V9C22 8.73478 21.8946 8.48043 21.7071 8.29289C21.5196 8.10536 21.2652 8 21 8ZM6.123 4.728C5.43583 4.45951 4.83854 4.00227 4.4 3.409C5.25296 3.04164 6.2014 2.95813 7.10543 3.17077C8.00946 3.38341 8.82123 3.88094 9.421 4.59C8.37238 5.02367 7.20418 5.07255 6.123 4.728ZM20 18.184C19.5813 18.3326 19.201 18.5727 18.8869 18.8869C18.5727 19.201 18.3326 19.5813 18.184 20H3.816C3.66741 19.5813 3.42729 19.201 3.11314 18.8869C2.79898 18.5727 2.4187 18.3326 2 18.184V11.816C2.4187 11.6674 2.79898 11.4273 3.11314 11.1131C3.42729 10.799 3.66741 10.4187 3.816 10H18.184C18.3326 10.4187 18.5727 10.799 18.8869 11.1131C19.201 11.4273 19.5813 11.6674 20 11.816V18.184ZM13 15C13 15.3956 12.8827 15.7822 12.6629 16.1111C12.4432 16.44 12.1308 16.6964 11.7654 16.8478C11.3999 16.9991 10.9978 17.0387 10.6098 16.9616C10.2219 16.8844 9.86549 16.6939 9.58579 16.4142C9.30608 16.1345 9.1156 15.7781 9.03843 15.3902C8.96126 15.0022 9.00087 14.6001 9.15224 14.2346C9.30362 13.8692 9.55996 13.5568 9.88886 13.3371C10.2178 13.1173 10.6044 13 11 13C11.5304 13 12.0391 13.2107 12.4142 13.5858C12.7893 13.9609 13 14.4696 13 15Z"
        fill="black"
      />
    </svg>
  );
};

export default IconGovernance;
