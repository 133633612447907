import React, { ChangeEvent, useRef } from 'react';
import { Button, Stack, Box, IconButton, FormHelperText, Paper, Typography } from '@mui/material';
import * as styles from './UploadImage.styles';
import { IconClose } from 'src/uiLibrary/icons';
import FormLabel from '@mui/material/FormLabel';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';

const defaultAccept = 'image/jpg, image/jpeg, image/png, image/JPEG';

type UploadImageProps = {
  name: string;
  accept?: string;
  imageSrc?: string;
  imageAlt?: string;
  isRequired?: boolean;
  isError?: boolean;
  errorText?: string;
  onChange: (value?: File) => void;
  buttonText?: string;
  label?: string;
};

export const UploadImage = ({
  name,
  accept = defaultAccept,
  imageSrc,
  imageAlt = '',
  isRequired,
  isError,
  errorText,
  onChange,
  buttonText = 'Upload',
  label,
}: UploadImageProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      onChange(files[0]);
    }
  };

  const handleDelete = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.value = '';
    }
    onChange();
  };

  return (
    <Stack direction="row">
      <Stack direction="column">
        {label && <FormLabel required={isRequired}>{label}</FormLabel>}
        <Stack direction="row" mt={1} alignItems={'flex-start'}>
          {imageSrc ? (
            <Box sx={styles.uploadImageOutput}>
              <img src={imageSrc} width={100} height={100} alt={imageAlt} />
              <IconButton size="medium" onClick={handleDelete} sx={styles.uploadImageCloseButton}>
                <IconClose />
              </IconButton>
            </Box>
          ) : (
            <Paper variant="outlined" square sx={styles.paperImage}>
              <Paper variant="outlined" square sx={styles.paperImageNested}>
                <AddAPhotoIcon />
              </Paper>
            </Paper>
          )}
          <Stack direction="column">
            <Typography className="noPicText">no picture selected</Typography>
            <Button variant="contained" component="label" sx={styles.uploadButton}>
              {buttonText}
              <input type="file" name={name} hidden onChange={handleChange} accept={accept} ref={inputRef} />
            </Button>
          </Stack>
        </Stack>
        {isError && <FormHelperText>{errorText}</FormHelperText>}
      </Stack>
    </Stack>
  );
};
