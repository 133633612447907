import React from 'react';
import { Outlet } from 'react-router-dom';
import PageLayout from 'src/components/layouts/PageLayout/PageLayout';

const LayoutWithoutHeader = () => {
  return (
    <PageLayout isHeaderVisible={false}>
      <Outlet />
    </PageLayout>
  );
};

export default LayoutWithoutHeader;
