import { useEffect, useState } from 'react';
import { useDocumentFolders, useManageDocumentFolderMutation } from 'src/hooks/react-query/documents';
import Button from 'src/components/common/Button';
import { Select } from 'src/components/common/Select/Select';
import { SelectOption } from 'src/types/custom';
import { DocumentData } from 'src/components/documents/document-card/document-card-helpers';
import { useParams } from 'react-router-dom';
import { DocumentsLevel } from 'src/types/common';

interface ManageDocumentModalProps {
  document: DocumentData;
  closeModal: () => void;
}

export const ManageDocumentModal = ({ document, closeModal }: ManageDocumentModalProps) => {
  const params = useParams();
  const [selectedFolder, setSelectedFolder] = useState<SelectOption | null>(null);

  let level: DocumentsLevel | undefined;
  let levelId: number | undefined;
  if (params.assetId) {
    level = 'asset';
    levelId = Number(params.assetId);
  } else if (params.fundId && !params.assetId) {
    level = 'fund';
    levelId = Number(params.fundId);
  }

  useEffect(() => {
    setSelectedFolder({ label: document.folderName || '', value: String(document.folderId) });
  }, [document]);

  const { documentFoldersOptions, isLoading } = useDocumentFolders(levelId, level);

  const { isLoading: isFolderUpdating, mutateAsync: updateFolder } = useManageDocumentFolderMutation();

  const handleChange = (value: SelectOption) => {
    setSelectedFolder(value);
  };

  const onUpdateFolder = async () => {
    if (!selectedFolder) return;

    await updateFolder({ folderId: String(selectedFolder.value), documentId: String(document.id) });

    closeModal();
  };

  return (
    <>
      <Select
        options={documentFoldersOptions}
        selectItem={selectedFolder}
        isLoading={isLoading}
        onChange={handleChange}
      />
      <div className="actions-tag-container">
        <Button order="primary" variant="secondary" size="medium" onClick={closeModal}>
          Cancel
        </Button>
        <Button order="primary" disabled={isFolderUpdating} variant="primary" size="medium" onClick={onUpdateFolder}>
          Save
        </Button>
      </div>
    </>
  );
};
