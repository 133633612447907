const IconDialogBubble = () => {
  return (
    <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.9999 -9.25978e-07C9.46915 -0.00099799 7.95858 0.349448 6.58461 1.02433C5.21064 1.69922 4.00995 2.68053 3.07506 3.89265C2.14016 5.10477 1.49602 6.51534 1.19226 8.01567C0.888508 9.516 0.933249 11.066 1.32304 12.5464C1.71283 14.0267 2.43726 15.3977 3.44051 16.5539C4.44377 17.7101 5.69906 18.6205 7.10967 19.215C8.52028 19.8095 10.0485 20.0723 11.5767 19.983C13.1049 19.8937 14.5921 19.4548 15.9239 18.7L19.6839 19.953C19.7861 19.9853 19.8928 20.0011 19.9999 20C20.1583 19.9999 20.3144 19.9621 20.4553 19.8899C20.5962 19.8177 20.718 19.713 20.8106 19.5845C20.9031 19.456 20.9638 19.3074 20.9877 19.1508C21.0116 18.9942 20.998 18.8343 20.9479 18.684L19.6999 14.924C20.5643 13.4035 21.0142 11.6826 21.0047 9.93363C20.9952 8.18463 20.5265 6.46879 19.6457 4.95777C18.7648 3.44674 17.5026 2.19352 15.9854 1.32346C14.4681 0.45341 12.7489 -0.00296355 10.9999 -9.25978e-07ZM17.6529 15.121L18.4189 17.421L16.1189 16.655C15.9786 16.6071 15.8293 16.5913 15.6821 16.6086C15.5348 16.6259 15.3933 16.6759 15.2679 16.755C13.6001 17.8105 11.6016 18.2139 9.65486 17.8881C7.70816 17.5622 5.95001 16.5298 4.71687 14.9887C3.48373 13.4475 2.86223 11.5057 2.97131 9.53493C3.08038 7.56415 3.91237 5.70281 5.30805 4.30713C6.70373 2.91145 8.56508 2.07946 10.5359 1.97038C12.5066 1.86131 14.4484 2.48281 15.9896 3.71595C17.5308 4.94908 18.5631 6.70724 18.889 8.65394C19.2149 10.6006 18.8115 12.5992 17.7559 14.267C17.6763 14.3927 17.6257 14.5346 17.6079 14.6824C17.5901 14.8301 17.6055 14.98 17.6529 15.121Z"
        fill="black"
      />
    </svg>
  );
};

export default IconDialogBubble;
