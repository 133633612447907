import { Breadcrumbs, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Badge, { BadgeStyle } from '../../components/common/Badge';
import CrudComponent from '../../components/common/CrudComponent';
import Table from '../../components/common/table';
import { formatDateNoTime, renderCurrency } from '../utils';
import { HistoricCapcallDetails } from './capcallDetailsHistory';
import { getCapCallHistory } from '../../services/governanceService';
import { Loader } from 'src/components/common/Loader';
import { useFundById } from 'src/hooks/react-query/funds';

const CapcallHistory = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [fundId, setFundId] = useState<number>(-1);

  const {
    data: fundInfo,
    isLoading: fundLoading,
    // isError: fundError,
  } = useFundById(Number(params.fundId));

  // TODO: get type from API
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [capCallHistoryData, setCapCallHistoryData] = useState<any[]>([]);

  useEffect(() => {
    if (params && params.fundId) {
      const fundId = parseInt(params.fundId);
      setFundId(fundId);
      getCapCallHistory(fundId).then(response => {
        if (response && response.data && response.data.data.fundOpportunities) {
          const capCalls = response.data.data.fundOpportunities;
          const sortedCapCalls = capCalls.toSorted(
            // TODO: get type from API
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (a: any, b: any) => new Date(b.CreationDateTime).getTime() - new Date(a.CreationDateTime).getTime(),
          );
          setCapCallHistoryData(sortedCapCalls);
        }
      });
    }
  }, []);

  const parsedDataForTable = useMemo(() => {
    return capCallHistoryData.map(transaction => {
      return {
        //...transaction,
        id: transaction.id,
        created: formatDateNoTime(transaction.CreationDateTime),
        deadline: formatDateNoTime(transaction.ClosingDate, true),
        called: renderCurrency(transaction.RequestedAmount),
        contributed: renderCurrency(transaction.InvestedAmount),
        lpCoverage: `${transaction.lpCoverage.fulfilled}/${transaction.lpCoverage.total} fulfilled`,
        status: (
          <Badge
            badgeStyle={transaction.Status === StatusEnum.OPEN ? BadgeStyle.GREEN : BadgeStyle.GRAY}
            text={transaction.Status}
          />
        ),
        files: (
          <div
            style={
              transaction.CapCallDoc
                ? {
                    textDecoration: 'underline',
                    color: 'black',
                    cursor: 'pointer',
                  }
                : {
                    textDecoration: 'underline',
                    color: '#b6b6b6',
                    userSelect: 'none',
                  }
            }
          >
            Download
          </div>
        ),
      };
    });
  }, [capCallHistoryData]);

  const fund = fundInfo?.fund;

  if (fundLoading) return <Loader />;

  return (
    <>
      <div className="content-wrap">
        <Breadcrumbs className="breadcrumb mb-15">
          {[
            <Link key="governance" color="inherit" to="/governance">
              Governance
            </Link>,
            <Link key={fund?.name} color="inherit" to={`/governance/detail/${fundId}`}>
              {fund?.name}
            </Link>,
            <Typography key="Review Payments">Capital calls history</Typography>,
          ]}
        </Breadcrumbs>
      </div>

      <div className="d-flex">
        <div className="title-and-subtitle-section">
          <h2>{fund?.name}</h2>
          <h4>Fund Level</h4>
        </div>
      </div>

      <CrudComponent
        title="Capital calls history"
        tableLayout={
          <Table
            columns={columns}
            data={parsedDataForTable}
            loading={false}
            onClick={row => {
              navigate(`${row.id}`, { state: { fundName: fund?.name } });
            }}
          />
        }
        filterComponent={
          <div
            style={{
              display: 'grid',
              placeContent: 'center',
              height: '100%',
            }}
          >
            <p>Coming soon...</p>
          </div>
        }
      />
    </>
  );
};
export enum StatusEnum {
  CLOSED = 'Closed',
  OPEN = 'Open',
}
export type HistoricCapcall = {
  capcallId: string;
  created: string;
  deadline: string;
  status: StatusEnum;
  called: number;
  contributed: number;
  lpCoverage: string;
  files: string;
  breakdown: HistoricCapcallDetails[];
  gpBreakdown: HistoricCapcallDetails | null;
};

const columns = [
  { id: 'id', label: 'id', sortable: false },
  { id: 'created', label: 'Created', sortable: true },
  { id: 'deadline', label: 'Deadline', sortable: true },
  { id: 'status', label: 'Status', sortable: true },
  { id: 'called', label: 'Called', sortable: true },
  { id: 'contributed', label: 'Contributed', sortable: true },
  { id: 'lpCoverage', label: 'LP coverage', sortable: true },
  { id: 'files', label: 'Files uploaded', sortable: false },
];

// const data: HistoricCapcall[] = [
//   {
//     capcallId: '1',
//     created: '28 Oct 2023',
//     deadline: '12 Nov 2023',
//     status: StatusEnum.OPEN,
//     called: 1000000.00,
//     contributed: 440000.00,
//     lpCoverage: '4/10 fulfilled',
//     files: '',
//     breakdown: [
//       { capcallId: '1', lpName: 'Paul Rudd', status: BreakdownStatusEnum.FULFILLED, asked: 300000, method: 'Manual', paymentDate: '12 Nov 2023', transactionId: '999'},
//       { capcallId: '2', lpName: 'Neil DeGrasse Tyson', status: BreakdownStatusEnum.PENDING, asked: 100000, method: 'Manual', paymentDate: '12 Nov 2023', transactionId: '998'},
//       { capcallId: '3', lpName: 'Neil DeGrasse Tyson', status: BreakdownStatusEnum.PENDING, asked: 120000, method: 'Manual', paymentDate: '12 Nov 2023', transactionId: '997'},
//       { capcallId: '4', lpName: 'Paul Rudd', status: BreakdownStatusEnum.PENDING, asked: 120000, method: 'Manual', paymentDate: '12 Nov 2023', transactionId: '996'}
//     ]
//   },
// ];

export default CapcallHistory;
