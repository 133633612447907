import * as yup from 'yup';
import { ObjectSchema } from 'yup';

export const schema: ObjectSchema<yup.AnyObject> = yup.object().shape({
  firstname: yup.string().required('First name field is required.').max(20, 'First name field should be maximum 20.'),
  lastname: yup.string().required('Last name field is required').max(20, 'Last name field should be maximum 20.'),
  countryCode: yup.string().required('Country code field is required'),
  contactnumber: yup
    .string()
    .required('Contact No field is required')
    .min(10, 'Contact No field should be minimum 10')
    .max(12, 'Contact No field should be maximum 12'),
  email: yup.string().email().required('Email field is required').max(100, 'Email field should be maximum 100'),
  state: yup.string().required('State is required'),
  agencyName: yup.string().notRequired(),
  countryId: yup.string().notRequired(),
  city: yup.string().required('City is required'),
  DateOfBirth: yup.string().nullable().required('Date of Birth field is required'),
  pinCode: yup
    .string()
    .required('Zip Code field is required.')
    .matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/, 'Invalid Zip Code'),
  address: yup.string().required('Address is required'),
  profileimage: yup.mixed().notRequired(),
  userid: yup.string().notRequired(),
  username: yup.string().notRequired(),
  accountstatus: yup.string().notRequired(),
  rolename: yup.string().notRequired(),
  roleslug: yup.string().notRequired(),
  roleId: yup.string().required('Role field is required.'),
  isactive: yup.number().required('Status field is required.'),
});
