import React from 'react';
import {
  Checkbox,
  Dialog,
  DialogContent,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { useEffect, useState } from 'react';
import IconArrowLeft from '../../icons/IconArrowLeft';
import Button from '../../../components/common/Button';
import FboInstructionsPDF from './FboInstructionsPDF';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { getValidatedFundBankAccounts } from '../../../services/gpBankingService';
import { useParams } from 'react-router-dom';
import { FieldValues } from 'react-hook-form';

const FboFundingModalComponent = ({
  isVisible,
  closeModal,
  // onSubmit,
  // fboBalance,
}: FboFundingModalComponentProps) => {
  const params = useParams();
  const handleOnClose = () => {
    closeModal();
  };
  const handleChange = (event: SelectChangeEvent) => {
    setSelectedAccount(event.target.value);
  };

  const [checkboxChecked, setCheckboxChecked] = useState(false);

  const [selectedAccount, setSelectedAccount] = useState('');
  const [accounts, setAccounts] = useState([{ alias: '', accNumber: '', meta: '' }]);

  useEffect(() => {
    if (isVisible) {
      setCheckboxChecked(false);

      const getAccounts = async () => {
        const responseAccounts = await getValidatedFundBankAccounts(parseInt(params.fundId!));
        if (responseAccounts?.data?.status === 200) {
          // TODO: get type from API
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const mappedAcc = responseAccounts.data.data.map((p: any) => {
            return {
              alias: p.Alias,
              accNumber: p.AccountNumber,
              meta: p.ExternalMetadata,
            };
          });
          if (mappedAcc.length) {
            setAccounts(mappedAcc);
          } else {
            setAccounts([
              {
                alias: 'Something went wrong',
                accNumber: "We couldn't find validated bank accounts for this fund",
                meta: 'error',
              },
            ]);
          }
        } else {
          setAccounts([
            {
              alias: 'Something went wrong',
              accNumber: 'Request failed',
              meta: 'error',
            },
          ]);
        }
      };

      getAccounts();
    }
  }, [isVisible]);

  return (
    <Dialog
      open={isVisible}
      onClose={handleOnClose}
      sx={{
        backdropFilter: 'blur(20px)',
      }}
      className="dialog-no-padding"
      maxWidth={false}
    >
      <DialogContent className="generic-form-dialog-content">
        <div className="dialog-content">
          <div className="back-login" onClick={handleOnClose}>
            <IconButton size="large" component="span">
              <IconArrowLeft stroke="#8F8F8F" />
            </IconButton>
            <p className="back-text">Back</p>
          </div>
          <div className="dialog-form-wrap" style={{ maxWidth: 500 }}>
            <div className="title-and-subtitle-wrap">
              <p className="title">Funding the Extrance Treasury Balance</p>
              <p className="subtitle">
                Transferring funds to the Extrance Treasury Balance account requires a manual action outside of
                Extrance. Please select the Fund’s account performing the transaction and we will provide exact wire
                instructions for it.
              </p>
            </div>
            <div className="account-input-label">Funding account</div>
            <FormControl sx={{ width: '100%', marginBottom: '20px' }}>
              <Select
                value={selectedAccount}
                onChange={handleChange}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                style={{
                  backgroundColor: '#f6f5f4',
                  border: '#E1E1E1',
                  borderRadius: 8,
                }}
              >
                <MenuItem value="">
                  <em>Select</em>
                </MenuItem>
                {accounts.map(p => {
                  return (
                    <MenuItem
                      key={p.alias}
                      value={p.meta}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                      }}
                    >
                      <div
                        style={{
                          color: '#8f8f8f',
                          marginBottom: 2,
                          fontSize: 14,
                        }}
                      >
                        {p.alias}
                      </div>
                      <div style={{ color: 'black', fontSize: 16 }}>{p.accNumber}</div>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <div className="disclaimer-box">
              <div className="disclaimer-content-wrap">
                <div className="title">Liability disclaimer</div>
                <div className="content">
                  Not following this precise instructions will cause an incorrect validation of metadata, therefore, not
                  accrediting the transferred funds to the Fund. Extrance is not responsible for managing this
                  transaction as this type of funding is happening outside of the platform.
                </div>
              </div>
              <div className="checkbox-footer">
                <Checkbox checked={checkboxChecked} onClick={() => setCheckboxChecked(!checkboxChecked)} />I understand
                all of the stated above
              </div>
            </div>
            <PDFDownloadLink
              document={<FboInstructionsPDF metadata={selectedAccount} />}
              fileName="Extrance-treasury-balance-wire-funding-instructions.pdf"
            >
              <Button
                customStyle={{ width: '100%', marginTop: 44 }}
                size="medium"
                order="primary"
                disabled={!checkboxChecked || !selectedAccount || selectedAccount === 'error'}
              >
                Download wire instructions
              </Button>
            </PDFDownloadLink>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

interface FboFundingModalComponentProps {
  isVisible: boolean;
  closeModal: () => void;
  onSubmit: (formData: FieldValues) => void;
  fboBalance: number | undefined | null;
}

export default FboFundingModalComponent;
