/* eslint-disable @typescript-eslint/no-unused-vars */
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRoleDelete, useRolesList } from 'src/hooks/react-query/roles';
import { useProtected } from 'src/hooks/useProtected';
import { Loader } from 'src/components/common/Loader';
import { usePagination } from 'src/hooks/usePagination';
import { RoleItem } from 'src/models/rolesModels';
import { HeadCellItem, HeadToolbarButton } from 'src/models/tableModels';
import { ButtonTooltip } from 'src/components/common/ButtonTooltip/ButtonTooltip';
import { IconEdit } from 'src/uiLibrary/icons';
import IconDelete from 'src/uiLibrary/icons/IconDelete';
import EnhancedTableToolbar from 'src/uiLibrary/reusables/data-table/enhancedTableToolbar';
import { Table } from 'src/components/common/MuiTable/Table';
import ConfirmDialogContainer from 'src/uiLibrary/modals/confirm-modal/confirmDialogContainer';
import { Protected } from 'src/components/layouts/Protected/Protected';
import { Permission } from 'src/consts/permissions';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';

export const RoleListComponent = () => {
  const navigate = useNavigate();
  const { page, pageSize, setPageSize, setActivePage } = usePagination();
  const [searchString, setSearchString] = useState<string>('');
  const [sortOrder, setSortOrder] = useState<'desc' | 'asc'>('desc');
  const [sortField, setSortField] = useState<'name' | 'created'>('created');
  const roleToDelete = useRef<RoleItem | null>(null);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

  const handleCloseModal = () => {
    setIsOpenDeleteModal(false);
  };

  const {
    data: roles,
    isLoading,
    refetch,
  } = useRolesList(page, sortField, sortOrder, pageSize, undefined, searchString);
  const { mutateAsync: deleteRoleMutation, isLoading: isDeleteRoleMutationLoading } = useRoleDelete(
    refetch,
    handleCloseModal,
  );

  const resetPage = () => setActivePage(0);

  const handleChangeRowsPerPage = (pageSize: number) => {
    setPageSize(pageSize);
    resetPage();
  };

  const handleChangePage = (newPage: number) => {
    setActivePage(newPage);
  };

  const editRole = (roleId: number) => {
    navigate(`/roles/edit/${roleId}`);
  };

  const addRole = () => {
    navigate('/roles/add');
  };

  const deleteRole = (role: RoleItem) => {
    setIsOpenDeleteModal(true);
    roleToDelete.current = role;
  };

  const handleSearchEvent = (search: string) => {
    resetPage();
    setSearchString(search);
  };

  const handleDeleteRole = () => {
    if (roleToDelete.current) {
      deleteRoleMutation(roleToDelete.current.id);
    }
  };

  const isManageRoleAvailabe = useProtected({
    permissions: [Permission.ManageRole],
  });

  if (isLoading) return <Loader />;

  const columns: HeadCellItem<RoleItem>[] = [
    {
      id: 'id',
      align: 'center',
      disablePadding: false,
      label: 'Serial No.',
      sortable: true,
      maxWidth: 20,
      key: 'id',
    },
    {
      id: 'name',
      align: 'left',
      disablePadding: false,
      label: 'Role Title/Name',
      sortable: true,
      key: 'Name',
    },
    {
      id: 'actions',
      align: 'center',
      disablePadding: false,
      label: 'Actions',

      hidden: !isManageRoleAvailabe,

      sortable: false,
      maxWidth: 30,
      render: role => (
        <>
          <Protected permissions={[Permission.ManageRole]}>
            <ButtonTooltip icon={<IconEdit />} tooltipTitle={'Detail/Edit'} onClick={() => editRole(role.id)} />
          </Protected>
          <Protected permissions={[Permission.ManageRole]}>
            <ButtonTooltip icon={<IconDelete />} tooltipTitle={'Delete'} onClick={() => deleteRole(role)} />
          </Protected>
        </>
      ),
    },
  ];

  const headButtons: HeadToolbarButton[] = [
    {
      variant: 'contained',
      startIcon: <PersonAddAltIcon />,
      handleClick: addRole,
      text: 'Add Role',
      hidden: !isManageRoleAvailabe,
    },
  ];

  return (
    <>
      <div className="theme-table-wrap">
        <EnhancedTableToolbar
          onSearch={handleSearchEvent}
          placeholder="Search by role name"
          buttons={headButtons}
          initialSearchValue={searchString}
          searchCount={roles?.meta.count}
        />
        <Table
          columns={columns}
          data={roles?.data ?? []}
          pagination={{
            page,
            rowsPerPage: pageSize,
            total: roles?.meta?.count,
            onPageChange: handleChangePage,
            onPageSizeChange: handleChangeRowsPerPage,
          }}
        />
      </div>

      <ConfirmDialogContainer
        isVisible={isOpenDeleteModal}
        title="Delete"
        dialogContentText={`Are you sure you want to remove role ${roleToDelete.current?.Name}?`}
        confirmModal={handleDeleteRole}
        closeModal={handleCloseModal}
        loading={isDeleteRoleMutationLoading}
      />
    </>
  );
};
