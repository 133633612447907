import React from 'react';

const IconFilter2 = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
      <path d="M20 36h8v-4h-8v4zM6 12v4h36v-4H6zm6 14h24v-4H12v4z" />
      <path fill="none" d="M0 0h48v48H0z" />
    </svg>
  );
};

export default IconFilter2;
