import { Helmet } from 'react-helmet';
import useMetaData from 'src/hooks/useMetaData';
import Router from 'src/components/router/Router';
import 'src/App.scss';
import { useSentry } from './hooks/useSentry';

function App() {
  const metaData = useMetaData();

  useSentry();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{metaData?.metaTitle ? metaData.metaTitle : 'Extrance'}</title>
        <meta name="description" content={metaData?.metaDescription ? metaData.metaDescription : ''} />
        <meta name="keywords" content={metaData?.metaKeyWords ? metaData.metaKeyWords : ''} />
      </Helmet>
      <Router />
    </>
  );
}

export default App;
