import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'src/context/ShackbarContext';
import { AxiosError } from 'axios';
import { getFundPrivilegeListForRole } from 'src/services/rolesService';

export const useFundPrivileges = () => {
  const { showErrorSnackbar } = useSnackbar();

  return useQuery({
    queryKey: ['fundPrivilagesList'],
    queryFn: async () => {
      try {
        const responce = await getFundPrivilegeListForRole();
        return responce.data;
      } catch (e) {
        console.log('useFundPrivileges error', e);
        showErrorSnackbar((e as AxiosError).message);
        throw new Error((e as AxiosError).message);
      }
    },
  });
};
