import React, { useState } from 'react';
import { FundsDetailsSlideComponent } from './FundsDetailsSlideComponent';
import { Fund } from 'src/types/Funds';

interface FundsOverviewWidgetProps {
  funds: Fund[];
}

export const FundsOverviewWidget = ({ funds }: FundsOverviewWidgetProps) => {
  const [selectedFundsSlide, setSelectedFundsSlide] = useState(0);

  const onSelectSlide = (slideIndex: number, event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event?.stopPropagation();

    setSelectedFundsSlide(slideIndex);
  };

  return (
    <FundsDetailsSlideComponent
      funds={funds}
      selectedFundsSlide={selectedFundsSlide}
      handleSelectSlide={onSelectSlide}
    />
  );
};
