import ServiceClient from './serviceClientConfig';

const serviceClient = new ServiceClient({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export const getPromoteStructure = (fundId: number) => {
  const url = `promote-structure/${fundId}`;
  return serviceClient.get(url);
};
