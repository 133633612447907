import { Modal } from 'src/components/common';
import { DistributeCapital, DistributeCapitalProps } from 'src/components/governance';

type DistributeCapitalModalProps = {
  open: boolean;
  onClose: () => void;
  fundData: DistributeCapitalProps['fundData'];
};

export const DistributeCapitalModal = ({ open, onClose, fundData }: DistributeCapitalModalProps) => {
  return (
    <Modal open={open} handleClose={onClose} maxWidth="md" fullWidth={false}>
      <DistributeCapital type="modal" fundData={fundData} onClose={onClose} />
    </Modal>
  );
};
