import { useParams } from 'react-router-dom';
import { MainLink } from './MainLink';

type Props = {
  ExternalId: number;
  ExternalExtractorUrl?: string;
};

export const AssetInfoWidget = (props: Props) => {
  const params = useParams();

  const domain = props.ExternalExtractorUrl || 'http://data-extractor.extrance.info/sheets/ilpa';

  const token = props.ExternalExtractorUrl
    ? undefined
    : 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiYXRpZiIsImV4cGlyYXRpb24iOiIyMDIyLTEwLTMxIDA4OjU4OjM0LjE3NDUyMiJ9.fhpAnMWxDeu_ufw9x1_hqiOzZEsUSzr4pYF-kKibg60';

  const fundName = params.fundName || 'null'; // optional query param
  const kpiRole = params.kpiRole || 'null'; // optional query param

  const searchParams = new URLSearchParams([
    ['fundName', fundName],
    ['kpiRole', kpiRole],
    ['asset', String(props.ExternalId)],
    ['reporting_date', '2022-06-30'],
  ]);

  if (token) {
    searchParams.append('token', token);
  }

  const extractorLink = `${domain}?${searchParams}`;

  return (
    <MainLink
      title="Asset info"
      description="further detailed information on this asset"
      to={extractorLink}
      newTab
      isBright
    />
  );
};
