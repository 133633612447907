import { ThemeCssVarOverrides } from '@mui/material';

export const accordionComponentsTheme: ThemeCssVarOverrides = {
  MuiAccordion: {
    styleOverrides: {
      root: {
        boxShadow: 'none',
        '&.Mui-expanded': {
          margin: 0,
        },
      },
    },
  },
};
