import React from 'react';

const IconApprove = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="32" height="32" rx="8" fill="white" />
      <path
        d="M22.3655 11.0874L13.8951 18.8522L10.1783 15.1354C9.87657 14.844 9.47241 14.6827 9.05289 14.6863C8.63338 14.69 8.23208 14.8583 7.93543 15.1549C7.63877 15.4516 7.4705 15.8529 7.46686 16.2724C7.46321 16.6919 7.62448 17.0961 7.91594 17.3978L12.7159 22.1978C13.0075 22.4894 13.4004 22.6572 13.8127 22.6662C14.225 22.6751 14.6248 22.5245 14.9287 22.2458L24.5287 13.4458C24.6836 13.3038 24.809 13.1326 24.8977 12.9421C24.9864 12.7517 25.0367 12.5456 25.0458 12.3356C25.0548 12.1257 25.0224 11.916 24.9505 11.7186C24.8785 11.5212 24.7684 11.3399 24.6263 11.185C24.4843 11.0302 24.3132 10.9048 24.1227 10.8161C23.9322 10.7274 23.7261 10.6771 23.5161 10.668C23.3062 10.6589 23.0966 10.6913 22.8991 10.7633C22.7017 10.8352 22.5204 10.9454 22.3655 11.0874Z"
        fill="#61C2A0"
      />
    </svg>
  );
};

export default IconApprove;
