import { createContext, useContext, ReactNode } from 'react';
import { DistributeCapitalProps } from './DistributeCapital';
import { DistributeCapitalGeneralForm } from './components/DistributeStep/DistributeStep.form';

type DistributeCapitalContextValue = {
  fundData?: DistributeCapitalProps['fundData'];
  onSubmit?: (data: DistributeCapitalGeneralForm) => void;
  onClose?: () => void;
  capitalToDistribute?: number;
};

const DistributeCapitalContext = createContext<DistributeCapitalContextValue>({});

export const useDistributeCapitalContext = () => useContext(DistributeCapitalContext);

export const DistributeCapitalContextProvider = ({
  value,
  children,
}: {
  value: DistributeCapitalContextValue;
  children: ReactNode;
}) => <DistributeCapitalContext.Provider value={value}>{children}</DistributeCapitalContext.Provider>;
