import { validateError } from 'src/utils/validateError';
import ServiceClient from './serviceClientConfig';

const serviceClient = new ServiceClient({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export const partnershipUsers = (userId: number, search: string) => {
  return serviceClient.get(`agreementrequest/search-user/by/partnership?UserId=${userId}&Search=${search}`);
};
export const createNda = async (data: { PartnershipId: string; RequestedUserId: number; UserId: number }) => {
  try {
    const response = await serviceClient.post(`agreementrequest/create/nda`, data);
    //TODO: add type from codegen
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return response.data as any;
  } catch (error) {
    console.error('createNda error: ', error);
    throw await validateError(error);
  }
};

export const sendNdaAgreementRequest = (id: number, data: unknown) => {
  return serviceClient.post(`agreementrequest/front/nda/${id}`, data);
};
