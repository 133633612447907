import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { notificationStatus } from '../../../services/notificationServices';
import IconNotifyBell from '../../icons/IconNotifyBell';
import { connect } from 'react-redux';
import { HideLoadingAction, ShowLoadingAction } from '../../../redux/actions/loadingActions';
import { ShowSnackbarAction } from '../../../redux/actions/snackbarActions';
import { SnackbarPayload } from '../../../models/snackbarModels';
import { SaveMarketplaceTabIndexAction } from '../../../redux/actions/filterActions';
import { RequestError } from 'src/models/supportRequestModels';
import { dateFormat } from 'src/utils/dateFormat';

const NotificationItem = ({
  id,
  body,
  notificationType,
  isRead,
  creationDateTime,
  slug,
  userDetailId,
  ShowLoadingAction,
  HideLoadingAction,
  ShowSnackbarAction,
  SaveMarketplaceTabIndexAction,
  handleNotificationClose,
}: NotificationItemProps) => {
  const [notificationRead, setNotificationRead] = useState(isRead);
  const navigate = useNavigate();

  const redirectionHandler = () => {
    let redirectUrl;
    switch (slug) {
      case 'support-comment':
        redirectUrl = `/support-requests/details/${userDetailId}`;
        break;
      case 'quote':
        redirectUrl = `/marketplace/partnership-valuation-details/${userDetailId}`;
        break;
      case 'gp-approval-status':
        SaveMarketplaceTabIndexAction(1);
        redirectUrl = '/marketplace';
        break;
      case 'interest-rejected-seller':
        SaveMarketplaceTabIndexAction(3);
        redirectUrl = '/marketplace';
        break;

      case 'interest-received':
        SaveMarketplaceTabIndexAction(3);
        redirectUrl = '/marketplace';
        break;
      case 'interest-rejected':
        SaveMarketplaceTabIndexAction(1);
        redirectUrl = '/marketplace';
        break;
      case 'interest-accepted':
        SaveMarketplaceTabIndexAction(1);
        redirectUrl = '/marketplace';
        break;
      case 'payment-pending':
        redirectUrl = `/make-payment/${userDetailId}`;
        break;
      case 'partnership-agreement':
        redirectUrl = '#';
        break;
      case 'partnership-transfer':
        redirectUrl = '#';
        break;
      case 'cap-call-created':
        SaveMarketplaceTabIndexAction(0);
        redirectUrl = '/marketplace';
        break;
      case 'sell-cap-created':
        SaveMarketplaceTabIndexAction(0);
        redirectUrl = '/marketplace';
        break;
      case 'nda-agreement':
        redirectUrl = '#';
        break;
      default:
        redirectUrl = '#';
    }
    navigate(redirectUrl);

    if (handleNotificationClose) {
      handleNotificationClose();
    }
  };
  const notificationStatusHandler = async (notificationId: number) => {
    if (notificationRead) {
      redirectionHandler();
      return;
    }
    try {
      ShowLoadingAction();
      await notificationStatus({
        id: notificationId,
        IsRead: 1,
      });
      setNotificationRead(1);
      HideLoadingAction();
      redirectionHandler();
    } catch (error) {
      const e = error as RequestError;
      HideLoadingAction();
      ShowSnackbarAction({
        message: e?.response?.data?.message ? e?.response?.data?.message : 'Something went wrong.',
        severity: 'error',
      });
    }
  };

  return (
    <div
      className={`notification-list ${!notificationRead && 'isunread'}`}
      onClick={() => notificationStatusHandler(id)}
    >
      <div className="media">
        <span className="bell-icon">
          <IconNotifyBell />
        </span>
        <div className="media-body">
          <div className="d-flex">
            <h6 className="m-0 flex-grow-1">{notificationType}</h6>
            {!notificationRead && <small className="dot"></small>}
          </div>
          {!!body && <p className="m-0">{body}</p>}

          <small className="time-text">{dateFormat(creationDateTime)}</small>
        </div>
      </div>
    </div>
  );
};
interface NotificationItemProps {
  id: number;
  body: string;
  notificationType: string;
  isRead: number;
  creationDateTime: string;
  slug: string;
  userDetailId: number;
  ShowLoadingAction: (payload?: string) => void;
  HideLoadingAction: () => void;
  ShowSnackbarAction: (payload: SnackbarPayload) => void;
  SaveMarketplaceTabIndexAction: (payload: number) => void;
  handleNotificationClose?: () => void;
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {
  ShowLoadingAction,
  HideLoadingAction,
  ShowSnackbarAction,
  SaveMarketplaceTabIndexAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationItem);
