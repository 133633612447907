import React, { useState } from 'react';
import { Dialog, DialogContent } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { TextField } from '@mui/material';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';

import { useForm, Controller } from 'react-hook-form';

import { ShareNDAAgreementPayload, PartnershipUserPayload } from '../../../models/ndaAgreementModels';

const ShareNDAAgreementComponent = ({
  closeModal,
  isVisible,
  partnershipList,
  shareNDARequestSubmitHandler,
  visible,
  getPartnershipUsersHandler,
  partnershipUsersData,
}: ShareNDAAgreementComponentProps) => {
  let searchDebounceTimer: NodeJS.Timer;
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const { handleSubmit, control } = useForm<ShareNDAAgreementPayload>({
    defaultValues: {
      partnership: '',
      user: '',
    },
  });

  const onSubmit = (data: ShareNDAAgreementPayload) => {
    setButtonDisabled(true);
    setTimeout(() => {
      setButtonDisabled(false);
    }, 1000);
    shareNDARequestSubmitHandler(data);
  };

  const searchHandler = (search: string) => {
    clearTimeout(searchDebounceTimer);

    searchDebounceTimer = window.setTimeout(() => {
      getPartnershipUsersHandler(search);
    }, 500) as unknown as NodeJS.Timeout;
  };

  return (
    <Dialog open={isVisible} onClose={closeModal} fullWidth maxWidth="lg" className="themes-dialog edit-profile-dialog">
      <PerfectScrollbar>
        <IconButton className="close-dialog" aria-label="delete" size="large" onClick={closeModal}>
          <CloseIcon />
        </IconButton>
        <DialogContent className="edit-profile-content">
          <div className="max-w-400 center-content">
            <div className="form-wrap">
              <h2 className="title">Share NDA agreement</h2>

              <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <div className="from-row theam-black">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <Controller
                      name="partnership"
                      control={control}
                      rules={{
                        required: 'Partnership field is required',
                      }}
                      render={({ field, fieldState }) => (
                        <FormControl fullWidth variant="outlined" className="formControl">
                          <FormLabel>
                            Partnership <span className="require">*</span>
                          </FormLabel>
                          <Select
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            {...field}
                            onChange={event => {
                              field.onChange(event);
                            }}
                            error={!!fieldState.error}
                          >
                            <MenuItem value="">Select Partnership</MenuItem>
                            {partnershipList?.length > 0 &&
                              // TODO: get type from API
                              // eslint-disable-next-line @typescript-eslint/no-explicit-any
                              partnershipList.map((partnership: any) => (
                                <MenuItem key={partnership.id} value={partnership.id}>
                                  {partnership.PartnershipName}
                                </MenuItem>
                              ))}
                          </Select>
                          {!!fieldState.error && <FormHelperText error>{fieldState.error.message}</FormHelperText>}
                        </FormControl>
                      )}
                    />
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <Controller
                      name="user"
                      control={control}
                      rules={{
                        required: 'User field is required',
                      }}
                      render={({ field, fieldState }) => (
                        <FormControl fullWidth variant="outlined" className="formControl">
                          <FormLabel>
                            User <span className="require">*</span>
                          </FormLabel>
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={partnershipUsersData ?? []}
                            renderInput={params => (
                              <TextField
                                {...params}
                                fullWidth
                                type="text"
                                margin="dense"
                                color="info"
                                placeholder="Search User"
                                onChange={e => searchHandler(e.target.value)}
                                onBlur={() => searchHandler('')}
                              />
                            )}
                            onChange={(_, data) => {
                              field.onChange(data);
                            }}
                          />
                          {!!fieldState.error && <FormHelperText error>{fieldState.error.message}</FormHelperText>}
                        </FormControl>
                      )}
                    />
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 btn-row mt-3">
                    <Button type="submit" variant="contained" disabled={buttonDisabled || visible}>
                      Submit
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </DialogContent>
      </PerfectScrollbar>
    </Dialog>
  );
};

interface ShareNDAAgreementComponentProps {
  isVisible: boolean;
  closeModal: () => void;
  // TODO: get type from API
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  partnershipList: any;
  shareNDARequestSubmitHandler: (data: ShareNDAAgreementPayload) => void;
  visible: boolean;
  getPartnershipUsersHandler: (search: string) => void;
  partnershipUsersData?: PartnershipUserPayload[];
}

export default ShareNDAAgreementComponent;
