import React from 'react';

const IconMail = () => {
  return (
    <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 1H10C10.55 1 11 1.45 11 2V8C11 8.55 10.55 9 10 9H2C1.45 9 1 8.55 1 8V2C1 1.45 1.45 1 2 1Z"
        stroke="#177785"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 1.99963L6 5.49963L1 1.99963"
        stroke="#177785"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconMail;
