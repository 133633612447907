import { AxiosRequestConfig } from 'axios';
import ServiceClient from './serviceClientConfig';

const serviceClient = new ServiceClient({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export const getTwilioToken = (identity: number) => {
  return serviceClient.get(`/sms/chat/token?identity=${encodeURIComponent(identity)}`);
};

export const getChatUserList = (search: string) => {
  return serviceClient.get(`/sms/chat/user-list?search=${search}`);
};

export const createGroupChat = (payload: FormData) => {
  return serviceClient.post('/sms/chat/create-group', payload);
};

export const updateGroupChat = (payload: FormData) => {
  return serviceClient.put('/sms/chat/update-group', payload);
};

export const addMemberToGroup = (payload: { groupSid: string; identity: string }) => {
  return serviceClient.post('/sms/chat/add-member', payload);
};

export const removeMemberFromGroup = (payload: { groupSid: string | undefined; memberSid: string }) => {
  return serviceClient.delete('/sms/chat/remove-member', payload);
};

export const getGroupMemberList = (groupSid: string) => {
  return serviceClient.get(`/sms/chat/member-list?groupSid=${groupSid}`);
};

export const getChatMemberInfo = (config: AxiosRequestConfig) => {
  return serviceClient.get('/sms/chat/member-info?', config);
};

export const getLpUserList = () => {
  return serviceClient.get('/sms/chat/lp-users');
};
