import { useRef, useState } from 'react';
import { FundSlideInfo } from './components/FundSlideInfo';
import { useNavigate } from 'react-router-dom';
import Slide from '@mui/material/Slide';
import { useFunds } from 'src/hooks/react-query/funds/useFunds';
import { Loader } from 'src/components/common/Loader';
import { QueryError } from 'src/components/common/QueryError';
import { renderFundCard } from './utils/renderFundCard';

const MyFundsComponent = () => {
  const navigate = useNavigate();
  const [openFundInfoSlide, setOpenFundInfoSlide] = useState(false);
  const activeFundId = useRef<number | null>(null);

  const { data: funds, isLoading, isError, isFetching } = useFunds();

  const handleSelectFund = (id: number) => {
    !openFundInfoSlide && changeFundView(id);
  };

  const handleCloseDrawer = () => {
    setOpenFundInfoSlide(false);
  };

  const changeFundView = (fundId: number) => {
    setOpenFundInfoSlide(true);
    activeFundId.current = fundId;
  };

  const viewDetailHandler = (id: number) => {
    navigate('/my-funds/detail/' + id);
  };

  if (isLoading || isFetching) return <Loader />;

  if (isError) return <QueryError />;

  if (!funds?.length && !isLoading) {
    return (
      <div className="main-body-content-frame">
        <h1>You don’t have any Funds yet</h1>
      </div>
    );
  }

  return (
    <>
      <div className="content-wrap">
        <div className="fund-cards-container">
          {funds?.map(fund =>
            renderFundCard({
              fund,
              activeFundId: activeFundId.current,
              onClick: handleSelectFund,
            }),
          )}
        </div>
      </div>

      {openFundInfoSlide && (
        <Slide direction="up" in={openFundInfoSlide}>
          <FundSlideInfo
            handleClose={handleCloseDrawer}
            viewDetailHandler={viewDetailHandler}
            fundId={activeFundId.current}
          />
        </Slide>
      )}
    </>
  );
};

export default MyFundsComponent;
