import { ButtonGroup, MenuPopup, ListItemButton, IconButton } from 'src/components/common';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import popupStyles from 'src/components/common/MenuPopup/styles.module.scss';
import styles from './styles.module.scss';
import { Protected } from 'src/components/layouts/Protected/Protected';
import { Permission } from 'src/consts/permissions';

type FolderActionsProps = {
  isDocumentsOpen: boolean;
  openCloseDocuments: () => void;
  onDeleteFolder: () => void;
  onUpdateFolder: () => void;
};

export const FolderActions = ({
  isDocumentsOpen,
  openCloseDocuments,
  onDeleteFolder,
  onUpdateFolder,
}: FolderActionsProps) => {
  const ActionButtonIcon = isDocumentsOpen ? ExpandLess : ExpandMore;

  return (
    <ButtonGroup
      variant="contained"
      className={styles['folder-actions-button-group']}
      sx={{
        boxShadow: 'none',
      }}
    >
      <Protected permissions={[Permission.CustomFolderManagement]}>
        <MenuPopup title="Folder actions">
          <ListItemButton className={popupStyles['menu-popup-list-button']} onClick={onUpdateFolder}>
            <p className={popupStyles['menu-popup-list-button-title']}>Edit</p>
          </ListItemButton>
          <ListItemButton className={popupStyles['menu-popup-list-button']} onClick={onDeleteFolder}>
            <p className={popupStyles['menu-popup-list-button-red-title']}>Delete</p>
          </ListItemButton>
        </MenuPopup>
      </Protected>

      <IconButton className={styles['action-button']} onClick={openCloseDocuments}>
        <ActionButtonIcon />
      </IconButton>
    </ButtonGroup>
  );
};
