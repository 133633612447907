const IconApproveTransaction = () => {
  return (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="60" height="60" rx="8" fill="white" fillOpacity="0.1" />
      <path
        d="M38 21H22C21.2044 21 20.4413 21.3161 19.8787 21.8787C19.3161 22.4413 19 23.2044 19 24V36C19 36.7956 19.3161 37.5587 19.8787 38.1213C20.4413 38.6839 21.2044 39 22 39H38C38.7956 39 39.5587 38.6839 40.1213 38.1213C40.6839 37.5587 41 36.7956 41 36V24C41 23.2044 40.6839 22.4413 40.1213 21.8787C39.5587 21.3161 38.7956 21 38 21ZM39 36C39 36.2652 38.8946 36.5196 38.7071 36.7071C38.5196 36.8946 38.2652 37 38 37H22C21.7348 37 21.4804 36.8946 21.2929 36.7071C21.1054 36.5196 21 36.2652 21 36V24C21 23.7348 21.1054 23.4804 21.2929 23.2929C21.4804 23.1054 21.7348 23 22 23H38C38.2652 23 38.5196 23.1054 38.7071 23.2929C38.8946 23.4804 39 23.7348 39 24V36ZM37 34C37 34.2652 36.8946 34.5196 36.7071 34.7071C36.5196 34.8946 36.2652 35 36 35H33C32.7348 35 32.4804 34.8946 32.2929 34.7071C32.1054 34.5196 32 34.2652 32 34C32 33.7348 32.1054 33.4804 32.2929 33.2929C32.4804 33.1054 32.7348 33 33 33H36C36.2652 33 36.5196 33.1054 36.7071 33.2929C36.8946 33.4804 37 33.7348 37 34ZM27 27C27 27.3956 26.8827 27.7822 26.6629 28.1111C26.4432 28.44 26.1308 28.6964 25.7654 28.8478C25.3999 28.9991 24.9978 29.0387 24.6098 28.9616C24.2219 28.8844 23.8655 28.6939 23.5858 28.4142C23.3061 28.1345 23.1156 27.7781 23.0384 27.3902C22.9613 27.0022 23.0009 26.6001 23.1522 26.2346C23.3036 25.8692 23.56 25.5568 23.8889 25.3371C24.2178 25.1173 24.6044 25 25 25C25.5304 25 26.0391 25.2107 26.4142 25.5858C26.7893 25.9609 27 26.4696 27 27Z"
        fill="#909090"
      />
      <path
        d="M27 27C27 27.3956 26.8827 27.7822 26.6629 28.1111C26.4432 28.44 26.1308 28.6964 25.7654 28.8478C25.3999 28.9991 24.9978 29.0387 24.6098 28.9616C24.2219 28.8844 23.8655 28.6939 23.5858 28.4142C23.3061 28.1345 23.1156 27.7781 23.0384 27.3902C22.9613 27.0022 23.0009 26.6001 23.1522 26.2346C23.3036 25.8692 23.56 25.5568 23.8889 25.3371C24.2178 25.1173 24.6044 25 25 25C25.5304 25 26.0391 25.2107 26.4142 25.5858C26.7893 25.9609 27 26.4696 27 27Z"
        fill="white"
      />
      <path
        d="M37 34C37 34.2652 36.8946 34.5196 36.7071 34.7071C36.5196 34.8946 36.2652 35 36 35H33C32.7348 35 32.4804 34.8946 32.2929 34.7071C32.1054 34.5196 32 34.2652 32 34C32 33.7348 32.1054 33.4804 32.2929 33.2929C32.4804 33.1054 32.7348 33 33 33H36C36.2652 33 36.5196 33.1054 36.7071 33.2929C36.8946 33.4804 37 33.7348 37 34Z"
        fill="white"
      />
    </svg>
  );
};

export default IconApproveTransaction;
