import React from 'react';

const IconNotify = ({ color }: { color?: string }) => {
  return (
    <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.0027 7.00106C16.0027 5.40948 15.3704 3.88309 14.245 2.75767C13.1196 1.63225 11.5932 1 10.0016 1C8.41001 1 6.88362 1.63225 5.7582 2.75767C4.63279 3.88309 4.00053 5.40948 4.00053 7.00106C4.00053 14.0023 1 16.0027 1 16.0027H19.0032C19.0032 16.0027 16.0027 14.0023 16.0027 7.00106Z"
        stroke={color ? color : '#9CA5A7'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.7319 20.0034C11.556 20.3065 11.3036 20.5582 10.9999 20.7331C10.6963 20.908 10.352 21.0001 10.0015 21.0001C9.65111 21.0001 9.30681 20.908 9.00314 20.7331C8.69947 20.5582 8.44708 20.3065 8.27124 20.0034"
        stroke={color ? color : '#9CA5A7'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconNotify;
