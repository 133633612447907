import { useState } from 'react';
import { Box, List, ListItem, ListItemText } from '@mui/material';
import NoFundSelectionIcon from 'src/assets/images/icons/no-fund-selection-empty-state-illustration.svg';
import GovernanceOverviewContainer from '../governanceOverview/governanceOverviewContainer';
import { currentFormat } from '../utils';
import { Fund } from 'src/types/Funds';

interface GovernanceComponentProps {
  funds: Fund[];
}

const GovernanceComponent = ({ funds }: GovernanceComponentProps) => {
  const [activeIndex, setActiveIndex] = useState<number>();
  const hasAciveIndex = (activeIndex && activeIndex >= 0) || activeIndex === 0;

  const renderFunds = (item: Fund, index: number) => (
    <>
      <ListItem
        button
        onClick={() => {
          setActiveIndex(index);
        }}
        className={activeIndex === index ? 'card-item active-card-item' : 'card-item'}
      >
        <ListItemText>
          <div className="from-row theam-black">
            <div className="card-info col-7">
              <h3>{item.name}</h3>
              <p dangerouslySetInnerHTML={{ __html: item.description || '' }}></p>
              <span className="rectangle">
                <p>Assets</p>
                <p className="investment-count">{item.fundAssets ? item.fundAssets.length : '0'}</p>
              </span>
            </div>
            <div className="values col-5">
              <p>Current value</p>
              <h3 style={{ marginBottom: 18 }}>{currentFormat(item.currentValue)}</h3>
              <p>Invested value</p>
              <h3>{currentFormat(item.investedValue)}</h3>
            </div>
          </div>
        </ListItemText>
      </ListItem>
    </>
  );

  return (
    <Box className="container page-without-header-container governance-container">
      <div className="row">
        <div className="col-md-5 fund-list">
          <div className="governance-heading borders">
            <h2>Select a Fund</h2>
          </div>
          <div>
            <List className="padding-top-list">{funds.map(renderFunds)}</List>
          </div>
        </div>

        <div className="col-md-7 right-screen ">
          <div className="content">
            {hasAciveIndex ? (
              <GovernanceOverviewContainer fund={funds[activeIndex]} />
            ) : (
              <div className="non-select-fund">
                <img src={NoFundSelectionIcon} alt="no fund selection" />
                <h2>Select a Fund</h2>
                <p className="helper-text">Your selection will display an overview of the fund</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </Box>
  );
};

export default GovernanceComponent;
