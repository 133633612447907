import React from 'react';
import { Avatar } from '@mui/material';
import { CustomConversation } from '../../../../models/chatModels';
import getInitials from '../../../getInitials/getInitials';
import { dateFormatWithYestarday } from 'src/utils/dateFormat';

const ChatListItemComponent = ({
  customConvo: { conversation, privateChatDetails },
  setChatItem,
  setScreenVal,
  lastMessage,
  unreadCount,
}: ChatListItemComponentProps) => {
  if (conversation.uniqueName) {
    if (!lastMessage) return <></>;
    return (
      <div
        className="chat-list"
        key={conversation.sid}
        onClick={() => {
          setChatItem();
          setScreenVal(1);
        }}
      >
        <div className="media">
          <span className="avater-icon">
            <Avatar
              className="d-flex align-self-center"
              src={privateChatDetails?.profileImage ? privateChatDetails?.profileImage : undefined}
            >
              {getInitials(privateChatDetails?.userName)}
            </Avatar>
          </span>
          <div className="media-body">
            <div className="d-flex">
              <h6 className="m-0 flex-grow-1">{privateChatDetails?.userName}</h6>
              <span className="time-text">
                {conversation?.lastMessage?.dateCreated
                  ? dateFormatWithYestarday(conversation.lastMessage.dateCreated)
                  : conversation.dateUpdated
                    ? dateFormatWithYestarday(conversation.dateUpdated)
                    : conversation.dateCreated && dateFormatWithYestarday(conversation.dateCreated)}
                {!!unreadCount && <small className="unread">{unreadCount}</small>}
              </span>
            </div>
            <p className="m-0">{lastMessage}</p>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className="chat-list"
        key={conversation.sid}
        onClick={() => {
          setChatItem();
          setScreenVal(1);
        }}
      >
        <div className="media">
          <span className="avater-icon">
            <Avatar
              className="d-flex align-self-center"
              alt={conversation.friendlyName ? conversation.friendlyName : 'user'}
              src={
                // TODO: get type from conversation type
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (conversation?.attributes as any)['group_icon']
                  ? // TODO: get type from conversation type
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (conversation?.attributes as any)['group_icon']
                  : undefined
              }
            />
          </span>
          <div className="media-body">
            <div className="d-flex">
              <h6 className="m-0 flex-grow-1">{conversation.friendlyName}</h6>
              <span className="time-text">
                {conversation?.lastMessage?.dateCreated
                  ? dateFormatWithYestarday(conversation.lastMessage.dateCreated)
                  : conversation.dateUpdated
                    ? dateFormatWithYestarday(conversation.dateUpdated)
                    : conversation.dateCreated && dateFormatWithYestarday(conversation.dateCreated)}
                {!!unreadCount && <small className="unread">{unreadCount}</small>}
              </span>
            </div>
            <p className="m-0">{lastMessage}</p>
          </div>
        </div>
      </div>
    );
  }
};
interface ChatListItemComponentProps {
  customConvo: CustomConversation;
  setChatItem: () => void;
  setScreenVal: (screenNum: number) => void;
  lastMessage?: string | null;
  unreadCount?: number | null;
}
export default ChatListItemComponent;
