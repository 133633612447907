import React from 'react';

const IconDelete = (props: IconDeleteProps) => {
  return (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 3.80005H2.4H13.6"
        stroke={props.color ? props.color : '#B6B6B6'}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.50039 3.8V2.4C4.50039 2.0287 4.64789 1.6726 4.91044 1.41005C5.17299 1.1475 5.52909 1 5.90039 1H8.70039C9.07169 1 9.42779 1.1475 9.69034 1.41005C9.95289 1.6726 10.1004 2.0287 10.1004 2.4V3.8M12.2004 3.8V13.6C12.2004 13.9713 12.0529 14.3274 11.7903 14.5899C11.5278 14.8525 11.1717 15 10.8004 15H3.80039C3.42909 15 3.07299 14.8525 2.81044 14.5899C2.54789 14.3274 2.40039 13.9713 2.40039 13.6V3.8H12.2004Z"
        stroke={props.color ? props.color : '#B6B6B6'}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.90039 7.30005V11.5"
        stroke={props.color ? props.color : '#B6B6B6'}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.7002 7.30005V11.5"
        stroke={props.color ? props.color : '#B6B6B6'}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export interface IconDeleteProps {
  color?: string;
}

export default IconDelete;
